import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import { ValidateEmail } from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";
import { TagFaces } from '@material-ui/icons';

const queryString = require('query-string');

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername: {
    color: '#204196',
    fontWeight: 'bold'
  },
  labelerror: {
    color: '#ff1744',
    fontSize: 10,
    paddingTop: '5px'
  },
  konfirmasi: {
    paddingTop: '10px'
  },
  copyright: {
    paddingBottom: '10px'
  }
});

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

class DbSettingTargetEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        id: "",
        fulltimerid: "",
        fulltimername: "",
        fulltimertypeid: "",
        fulltimertypename: "",
        deffulltimertypename: "",
        target: 0,
        korporat: 0,
        lewihall: 0,
        lewiks: 0,
        transport: 0,
        // kolam: 0,
        // pelayanan: 0,
        lainnya: 0,
        kokyn: 15,
        timeremain: 0,
        conversion: 0,
        injil: 0,
        domba: 0,
        // baptis: 0

      },
      error: {
        fulltimerid: "",
        fulltimername: "",
        fulltimertypeid: "",
        fulltimertypename: "",
        target: "",
        korporat: "",
        lewihall: "",
        lewiks: "",
        transport: "",
        // kolam: "",
        // pelayanan: "",
        lainnya: "",
        kokyn: "",
        timeremain: "",
        conversion: "",
        injil: "",
        domba: "",
        // baptis: ""
      },
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      disableBtnSubmit: false,
      mode: 'add',
      ftpage: {
        keyword: "",
        count: 10
      },
      fttypepage: {
        keyword: "",
        count: 10
      },
      fttypes: [],
      fttypesobject: null,
      fulltimers: [],
      fulltimerobject: null,
      filtertable: {},
      filterAll: {},

    };

    this.changeFttypes = this.changeFttypes.bind(this);
    this.changeFulltimer = this.changeFulltimer.bind(this);
  }

  componentDidMount() {
    let outer = this;
    let qparam = queryString.parse(this.props.location.search);
    let filter = this.props.location.state.filter;
    this.setState({ filtertable: filter, filterAll: this.props.location.state.filterAll });
    // // console.log("qparam ",qparam);
    if (qparam && qparam.i) {
      if (this.props.param != null) {
        let targ = this.props.param.detail;
        let total = parseFloat(targ.korporat) + parseFloat(targ.lewihall) + parseFloat(targ.lewiks) +
          parseFloat(targ.transport) + parseFloat(targ.lainnya) +
          parseFloat(targ.kokyn);
        let target = parseFloat(targ.target);
        let conv = parseInt(((target - total) * 3) / 2);
        this.setState({
          form: {
            id: targ.id,
            fulltimername: targ.ftname,
            fulltimerid: targ.ftid,
            fulltimertypeid: targ.fulltimertypeid,
            fulltimertypename: targ.fulltimertypename,
            deffulltimertypename: targ.fulltimertypename,
            target: targ.target,
            korporat: targ.korporat,
            lewihall: targ.lewihall,
            lewiks: targ.lewiks,
            transport: targ.transport,
            // kolam: targ.kolam,
            // pelayanan: targ.pelayanan,
            lainnya: targ.lainnya,
            kokyn: targ.kokyn,
            timeremain: (target - total) < 0 ? 0 : parseFloat(target - total).toFixed(1),
            conversion: (Math.trunc(conv)) < 0 ? 0 : (Math.trunc(conv)),
            injil: targ.injil,
            domba: targ.domba,
            // baptis: targ.baptis
          },
          mode: 'edit'
        }, () => {
          this.getEditFttypeList();
          this.getEditFulltimerList();
        })
      } else {
        this.getFTTargetDetail(qparam.i);
      }
    } else {
      this.getFttypeList();
      this.getFulltimerList();
    }
  }

  getFTTargetDetail = (id) => {
    let outer = this;
    axios.post('bo/int/fulltimer/target/detail', { id: id }).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        let targ = data.data;
        let total = parseFloat(targ.korporat) + parseFloat(targ.lewihall) + parseFloat(targ.lewiks) +
          parseFloat(targ.transport) + parseFloat(targ.lainnya) +
          parseFloat(targ.kokyn);
        let target = parseFloat(targ.target);
        let conv = parseInt(((target - total) * 3) / 2);
        this.setState({
          form: {
            id: targ.id,
            fulltimername: targ.ftname,
            fulltimerid: targ.ftid,
            fulltimertypeid: targ.fulltimertypeid,
            fulltimertypename: targ.fulltimertypename,
            deffulltimertypename: targ.fulltimertypename,
            target: targ.target,
            korporat: targ.korporat,
            lewihall: targ.lewihall,
            lewiks: targ.lewiks,
            transport: targ.transport,
            // kolam: targ.kolam,
            // pelayanan: targ.pelayanan,
            lainnya: targ.lainnya,
            kokyn: targ.kokyn,
            timeremain: (target - total) < 0 ? 0 : parseFloat(target - total).toFixed(1),
            conversion: (Math.trunc(conv)) < 0 ? 0 : (Math.trunc(conv)),
            injil: targ.injil,
            domba: targ.domba,
            // baptis: targ.baptis
          },
          mode: 'edit'
        }, () => {
          this.getEditFttypeList();
          this.getEditFulltimerList();
        })
        // outer.setState({userList:data.data});
      }
    }).catch(function (error) {
      outer.setState({
        disableBtnSubmit: false
      });
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  inputChangeFttype(val) {
    this.setState({
      fttypepage: {
        ...this.state.fttypepage,
        keyword: val
      }
    }, () => {
      this.getFttypeList();
    })
  }



  getEditFttypeList() {
    let outer = this;
    axios.post('bo/int/master/fttype', { id: outer.state.form.fulltimertypeid }).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          fttypesobject: data.data
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  getFttypeList() {
    let outer = this;
    axios.post('bo/int/master/fttype/list', outer.state.fttypepage).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          fttypes: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changeFttypes(event, val) {
    if (val) {
      let { form, error } = this.state;
      let total = parseFloat(form["korporat"]) + parseFloat(form["lewihall"]) + parseFloat(form["lewiks"]) +
        parseFloat(form["transport"]) + parseFloat(form["lainnya"]) +
        parseFloat(form["kokyn"]);
      let target = val.target;
      form["timeremain"] = (target - total) < 0 ? 0 : parseFloat(target - total).toFixed(1);
      let conv = parseInt(((target - total) * 3) / 2); // ini beda
      form["conversion"] = (Math.trunc(conv)) < 0 ? 0 : (Math.trunc(conv));
      // let biblecount = Math.trunc(conv) - parseInt(form["injil"]);
      // form["domba"] = form["injil"] != 0 ? biblecount : 0;
      if (form["injil"] == '') {
        form["domba"] = Math.trunc(conv);
      } else {
        if (parseInt(form["injil"]) > Math.trunc(conv)) {
          form["injil"] = Math.trunc(conv);
          form["domba"] = 0;
        } else {
          let biblecount = Math.trunc(conv) - parseInt(form["injil"]);
          form["domba"] = biblecount;
        }
        let biblecount = Math.trunc(conv) - parseInt(form["injil"]);
        form["domba"] = biblecount;
      }
      this.setState({
        fttypesobject: val,
        form: {
          ...this.state.form,
          fulltimertypename: val.name,
          fulltimertypeid: val.id,
          target: val.target
        }
      })
    } else {
      this.setState({
        fttypesobject: null,
        form: {
          ...this.state.form,
          fulltimertypeid: "",
          fulltimertypename: "",
          target: 0
        }
      })
    }
  }

  inputChangeFulltimer(val) {
    this.setState({
      ftpage: {
        ...this.state.ftpage,
        keyword: val
      }
    }, () => {
      this.getFulltimerList();
    })
  }



  getEditFulltimerList() {
    let outer = this;
    axios.post('bo/int/master/cust', { id: outer.state.form.fulltimerid }).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          fulltimerobject: data.data
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  getFulltimerList() {
    let outer = this;
    axios.post('bo/int/master/cust/list', this.state.ftpage).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          fulltimers: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changeFulltimer(event, val) {
    if (val) {

      this.setState({
        fulltimerobject: val,
        form: {
          ...this.state.form,
          fulltimername: val.firstname + " " + val.lastname,
          fulltimerid: val.id
        }
      })
    } else {
      this.setState({
        fulltimerobject: null,
        form: {
          ...this.state.form,
          fulltimername: "",
          fulltimerid: ""
        }
      })
    }
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      if (this.state.dialogTitle != "Information") {
        this.setState({ dialogOpen: false });
      } else {
        this.setState({
          dialogOpen: false
        }, () => {
          // this.props.history.push("/bo/setting/target/list");
          this.backToList();
          this.props.deselectParam();
        })
      }
    }
  }

  backToList = () => {
    this.props.history.push({ pathname: "/bo/setting/target/list", filter: this.state.filtertable, filterAll: this.state.filterAll });
  }
  showDialog = (title, content) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content
    });
  }

  changeFocus = (e) => {
    //alert('masuk change focuss')
    let outer = this;
    let { form, error } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    if (inputName == "korporat" ||
      inputName == "lewihall" ||
      inputName == "lewiks" ||
      inputName == "transport" ||
      inputName == "kokyn" ||
      // inputName == "kolam" ||
      // inputName == "pelayanan" ||
      inputName == "lainnya" ||
      inputName == "injil"
      // inputName ==  "baptis"
    ) {
      if (inputValue == "") {
        form[inputName] = 0;
        let total = parseFloat(form["korporat"]) + parseFloat(form["lewihall"]) + parseFloat(form["lewiks"]) +
          parseFloat(form["transport"]) + parseFloat(form["lainnya"]) +
          parseFloat(form["kokyn"]);
        let target = parseFloat(form["target"]);
        form["timeremain"] = (target - total) < 0 ? 0 : parseFloat(target - total).toFixed(1);
        let conv = parseInt(((target - total) * 3) / 2); // ini beda
        form["conversion"] = (Math.trunc(conv)) < 0 ? 0 : (Math.trunc(conv));
        // let biblecount = Math.trunc(conv) - parseInt(form["injil"]);
        // form["domba"] = form["injil"] != 0 ? biblecount : 0;
        if (form["injil"] == '') {
          form["domba"] = Math.trunc(conv);
        } else {
          let biblecount = Math.trunc(conv) - parseInt(form["injil"]);
          form["domba"] = biblecount;
        }
      } else {
        form[inputName] = inputValue;
        let total = parseFloat(form["korporat"]) + parseFloat(form["lewihall"]) + parseFloat(form["lewiks"]) +
          parseFloat(form["transport"]) + parseFloat(form["lainnya"]) +
          parseFloat(form["kokyn"]);
        let target = parseFloat(form["target"]);
        form["timeremain"] = (target - total) < 0 ? 0 : parseFloat(target - total).toFixed(1);
        let conv = parseInt(((target - total) * 3) / 2);
        form["conversion"] = (Math.trunc(conv)) < 0 ? 0 : (Math.trunc(conv));
        // let biblecount = Math.trunc(conv) - parseInt(form["injil"]);
        // form["domba"] = form["injil"] != 0 ? biblecount : 0;
        if (form["injil"] == '') {
          form["domba"] = Math.trunc(conv);
        } else {
          let biblecount = Math.trunc(conv) - parseInt(form["injil"]);
          form["domba"] = biblecount;
        }
      }
    }

    this.setState({
      form: form
    })
  }

  handleChange = (e) => {
    let outer = this;
    let { form, error } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    let total = parseFloat(form["korporat"]) + parseFloat(form["lewihall"]) + parseFloat(form["lewiks"]) +
      parseFloat(form["transport"]) + parseFloat(form["lainnya"]) +
      parseFloat(form["kokyn"]);
    let target = parseFloat(form["target"]);
    if (!isNaN(total)) {
      form["timeremain"] = (target - total) < 0 ? 0 : parseFloat(target - total).toFixed(1);
      let conv = parseInt(((target - total) * 3) / 2);
      form["conversion"] = (Math.trunc(conv)) < 0 ? 0 : (Math.trunc(conv));
      // let biblecount = Math.trunc(conv) - parseInt(form["injil"]);
      // form["domba"] = form["injil"] != 0 ? biblecount : 0;
      if (form["injil"] == '') {  // injil 0
        form["domba"] = Math.trunc(conv);
      } else {
        let biblecount = Math.trunc(conv) - parseInt(form["injil"]);
        form["domba"] = biblecount;
      }
    } else {
      form["timeremain"] = 0;
      form["conversion"] = 0;
      form["domba"] = 0;
    }
    if (inputName === "accept") {
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if (newAccept === true) {
        error[inputName] = ""
      }
    }

    this.setState({
      form: form,
      error: error
    }, () => {
      switch (inputName) {
        case "korporat":
          if (inputValue) {
            let regex = /^[0-9.]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        case "lewihall":
          if (inputValue) {
            let regex = /^[0-9.]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        case "lewiks":
          if (inputValue) {
            let regex = /^[0-9.]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        case "transport":
          if (inputValue) {
            let regex = /^[0-9.]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        // case "kolam":
        //   if(inputValue){
        //     let regex = /^[0-9.]+$/;
        //     if (!regex.test(inputValue)) {
        //         form[inputName]=inputValue.replace(/.$/,"");
        //         outer.setState({form:form});
        //     }
        //   }
        // break;
        // case "pelayanan":
        //   if(inputValue){
        //     let regex = /^[0-9.]+$/;
        //     if (!regex.test(inputValue)) {
        //         form[inputName]=inputValue.replace(/.$/,"");
        //         outer.setState({form:form});
        //     }
        //   }
        // break;
        case "lainnya":
          if (inputValue) {
            let regex = /^[0-9.]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        case "injil":
          if (inputValue) {
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        // case "baptis":
        //   if(inputValue){
        //     let regex = /^[0-9]+$/;
        //     if (!regex.test(inputValue)) {
        //         form[inputName]=inputValue.replace(/.$/,"");
        //         outer.setState({form:form});
        //     }
        //   }
        // break;  
        default:
          break;
      }
    });
  }

  handleValidation = (e) => {
    let { form, error, mode } = this.state;

    if (form.fulltimerid.trim().length === 0) {
      error.fulltimerid = <IntlMessages id="setting.target.error.name" />;
    } else {
      error.fulltimerid = "";
    }

    if (form.fulltimertypeid.trim().length === 0) {
      error.fulltimertypeid = <IntlMessages id="setting.target.error.type" />;
    } else {
      error.fulltimertypeid = "";
    }



    if (!this.isValidForm()) {
      form.accept = false;
      error.accept = "";
    }

    this.setState({
      form: form,
      error: error
    }, () => {

      if (!this.isValidForm()) {
        return;
      }

      this.handleSubmit();

    });

  }

  handleSubmit = () => {
    let { form } = this.state;
    form.korporat = form.korporat != 0 ? parseFloat(form.korporat) : 0;
    form.lewihall = form.lewihall != 0 ? parseFloat(form.lewihall) : 0;
    form.lewiks = form.lewiks != 0 ? parseFloat(form.lewiks) : 0;
    form.transport = form.transport != 0 ? parseFloat(form.transport) : 0;
    // form.kolam = form.kolam != 0 ? parseFloat(form.kolam) : 0;
    // form.pelayanan = form.pelayanan != 0 ? parseFloat(form.pelayanan) : 0;
    form.lainnya = form.lainnya != 0 ? parseFloat(form.lainnya) : 0;
    form.kokyn = form.kokyn != 0 ? parseFloat(form.kokyn) : 0;
    form.injil = form.injil != 0 ? parseInt(form.injil) : 0;
    // form.baptis = form.baptis != 0 ? parseInt(form.baptis) : 0;
    const outer = this;
    this.setState({
      disableBtnSubmit: true
    }, () => {
      if (this.state.mode == "add") {

        axios.post('bo/int/fulltimer/target/add', form
        ).then(({ data }) => {
          if (data.sta && data.stacod === 200) {
            outer.emptyFied();
            outer.setState({
              disableBtnSubmit: false
            }, () => {
              outer.showDialog('Information', <IntlMessages id="setting.target.success.addtarget" />);
            });

          } else {
            outer.setState({
              disableBtnSubmit: false
            });
            outer.showDialog('Warning', data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', Errormsg["500"]);
        });
      } else {
        axios.post('bo/int/fulltimer/target/edit', form
        ).then(({ data }) => {
          if (data.sta && data.stacod === 200) {
            outer.emptyFied();
            outer.setState({
              disableBtnSubmit: false
            }, () => {
              outer.showDialog('Information', <IntlMessages id="setting.target.success.edittarget" />);
            })

          } else {
            outer.setState({
              disableBtnSubmit: false
            });
            outer.showDialog('Warning', data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', Errormsg["500"]);
        });
      }
    });

  }

  emptyFied() {
    this.setState({
      fulltimerobject: null,
      fttypesobject: null,
      form: {
        ...this.state.form,
        id: "",
        fulltimerid: "",
        fulltimername: "",
        fulltimertypeid: "",
        fulltimertypename: "",
        target: 0,
        korporat: 0,
        lewihall: 0,
        lewiks: 0,
        transport: 0,
        // kolam: 0,
        // pelayanan: 0,
        lainnya: 0,
        kokyn: 15,
        timeremain: 0,
        conversion: 0,
        injil: 0,
        domba: 0,
        // baptis: 0
      },
      error: {
        ...this.state.error,
        id: "",
        fulltimerid: "",
        fulltimername: "",
        fulltimertypeid: "",
        fulltimertypename: "",
        target: "",
        korporat: "",
        lewihall: "",
        lewiks: "",
        transport: "",
        // kolam: "",
        // pelayanan: "",
        lainnya: "",
        kokyn: "",
        timeremain: "",
        conversion: "",
        injil: "",
        domba: "",
        // baptis: ""
      },
    })
  }

  isValidForm = () => {
    return (
      !this.state.error.fulltimerid &&
      !this.state.error.fulltimertypeid)
  }





  render() {
    const { classes, theme } = this.props;
    const { error, fttypes, fulltimers } = this.state;
    const title = "Profile"
    return (
      <div style={{ marginTop: theme.spacing(4) }}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Pengaturan
            </Link>
            <Link color="inherit"
              // href="/bo/setting/target/list"
              href="#"
              onClick={() => { this.backToList() }}
            >
              Daftar Target
            </Link>
            <Link color="textPrimary" href="/bo/setting/target/edit">
              {
                this.state.mode == "add" ?
                  "Tambah Target" : "Ubah Target"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>


              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <Autocomplete
                    options={fulltimers}
                    getOptionLabel={option => option && option.firstname}
                    value={this.state.fulltimerobject}
                    disabled={this.state.mode == "edit"}
                    onChange={this.changeFulltimer}
                    onFocus={(e) => { this.inputChangeFulltimer('') }}
                    onInputChange={(event, val) => this.inputChangeFulltimer(val)}
                    renderInput={
                      param => (
                        <TextField
                          {...param}
                          label="Fulltimer"
                          fullWidth
                          required
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={error.fulltimerid ? true : false}
                        />
                      )
                    }
                  />
                  {
                    error.fulltimerid &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.fulltimerid}
                    />
                  }
                </Grid>

                {(this.state.form.deffulltimertypename != '' && this.state.form.deffulltimertypename != this.state.form.fulltimertypename) &&
                  <div style={{ paddingLeft: 10 }}>
                    Tipe Target FT sebelumnya: {this.state.form.deffulltimertypename}
                  </div>
                }
                <Grid item xs={12}>

                  <Autocomplete
                    options={fttypes}
                    getOptionLabel={option => option && option.name}
                    value={this.state.fttypesobject}
                    onChange={this.changeFttypes}
                    onFocus={(e) => { this.inputChangeFttype('') }}
                    onInputChange={(event, val) => this.inputChangeFttype(val)}
                    renderInput={
                      param => (
                        <TextField
                          {...param}
                          label="Tipe"
                          fullWidth
                          required
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={error.fulltimertypeid ? true : false}
                        />
                      )
                    }
                  />
                  {
                    error.fulltimertypeid &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.fulltimertypeid}
                    />
                  }
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="target"
                    name="target"
                    label={<IntlMessages id="setting.target.target" />}
                    value={this.state.form.target}
                    size="small"
                    variant="outlined"
                    fullWidth
                    disabled
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="korporat"
                    name="korporat"
                    label={<IntlMessages id="setting.target.corporat" />}
                    value={this.state.form.korporat}
                    size="small"
                    variant="outlined"
                    // type="number"
                    onBlur={this.changeFocus}
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="lewihall"
                    name="lewihall"
                    label={<IntlMessages id="setting.target.lewihall" />}
                    value={this.state.form.lewihall}
                    size="small"
                    variant="outlined"
                    // type="number"
                    onBlur={this.changeFocus}
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="lewiks"
                    name="lewiks"
                    onBlur={this.changeFocus}
                    label={<IntlMessages id="setting.target.lewiks" />}
                    value={this.state.form.lewiks}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="transport"
                    name="transport"
                    onBlur={this.changeFocus}
                    label={<IntlMessages id="setting.target.transport" />}
                    value={this.state.form.transport}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                      <TextField
                        id="kolam"
                        name="kolam"
                        onBlur={this.changeFocus}
                        label={<IntlMessages id="setting.target.pool"/>}
                        value={this.state.form.kolam}
                        size="small"
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="pelayanan"
                        name="pelayanan"
                        onBlur={this.changeFocus}
                        label={<IntlMessages id="setting.target.service"/>}
                        value={this.state.form.pelayanan}
                        size="small"
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    id="lainnya"
                    name="lainnya"
                    onBlur={this.changeFocus}
                    label={<IntlMessages id="setting.target.etc" />}
                    value={this.state.form.lainnya}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="kokyn"
                    name="kokyn"
                    onBlur={this.changeFocus}
                    label={<IntlMessages id="setting.target.kokyn" />}
                    value={this.state.form.kokyn}
                    size="small"
                    variant="outlined"
                    fullWidth
                    // disabled
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="timeremain"
                    name="timeremain"
                    label={<IntlMessages id="setting.target.timeremain" />}
                    value={this.state.form.timeremain}
                    size="small"
                    variant="outlined"
                    fullWidth
                    disabled
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="konversi"
                    name="konversi"
                    label={<IntlMessages id="setting.target.conversion" />}
                    value={this.state.form.conversion}
                    disabled
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="injil"
                    name="injil"
                    label={<IntlMessages id="setting.target.bible" />}
                    value={this.state.form.injil}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onBlur={this.changeFocus}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="domba"
                    name="domba"
                    label={<IntlMessages id="setting.target.sheep" />}
                    value={this.state.form.domba}
                    size="small"
                    variant="outlined"
                    disabled
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                      <TextField
                        id="baptis"
                        name="baptis"
                        label={<IntlMessages id="setting.target.baptism"/>}
                        value={this.state.form.baptis}
                        size="small"
                        fullWidth
                        onBlur={this.changeFocus}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>                       */}

              </Grid>
              <Button
                type="button"
                className={classes.submit}
                color="primary"
                variant="contained"
                fullWidth
                onClick={this.handleValidation}
                disabled={this.state.disableBtnSubmit}
              >
                UPDATE
              </Button>
            </form>
          </div>
        </Container>

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
      </div>
    );
  }
}

DbSettingTargetEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, { deselectParam }
)(withStyles(styles)(withTheme(DbSettingTargetEdit)));