import React from 'react';
import {
  ListItem, ListItemIcon, ListItemText, Divider,
  List, ListSubheader, makeStyles
} from '@material-ui/core';
import {
  RoomService, DashboardOutlined, HourglassEmpty, Category, Ballot, FavoriteBorder, Favorite,
  PersonAdd, GroupAdd, AccountTree, Schedule, TrackChanges, Alarm, InvertColors, PeopleAlt,
  SettingsInputComponent, Tune, ListAlt, HourglassFull, DirectionsWalk, SettingsInputAntenna, 
  Functions, Assignment, Cloud, NoteAdd, Public, Room
} from '@material-ui/icons';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  linkto: {
      color: 'inherit',
      textDecoration:'inherit'
  },
  listitem: {
    padding: '2px 8px',
  },
  header: {
    padding: '0px 8px',
    backgroundColor: 'white'
  },
  menu: {
    padding:0
  }
});

function AdminListItems(props){
   const classes = useStyles();
   const token = useSelector(state => state.token.token);
    return (
      <div>
      <Divider />
      {
        token.role == "Administrator" || token.role == "Internal" ?
        <List className={classes.menu}>
          <Link to="/bo/dashboard" className={classes.linkto}>
            <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
              <ListItemIcon><DashboardOutlined /></ListItemIcon>
              <ListItemText primary="Dashboard"/>
            </ListItem>      
          </Link>
        </List> :
        token.role == "Penatua" ?
        <List className={classes.menu}>
          <Link to="/bo/dashboardpenatua" className={classes.linkto}>
            <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
              <ListItemIcon><DashboardOutlined /></ListItemIcon>
              <ListItemText primary="Dashboard"/>
            </ListItem>      
          </Link>
        </List> :
        <List className={classes.menu}>
          <Link to="/bo/dashboardft" className={classes.linkto}>
            <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
              <ListItemIcon><DashboardOutlined /></ListItemIcon>
              <ListItemText primary="Dashboard"/>
            </ListItem>      
          </Link>
        </List>
      }
      {
        token.role == "Administrator" || token.role == "Internal" ?
        <div>
          <ListSubheader className={classes.header}>Master</ListSubheader>
          <List className={classes.menu}>
            <Link to="/bo/master/user/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><PersonAdd /></ListItemIcon>
                <ListItemText primary="User"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/master/fulltimer/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><GroupAdd /></ListItemIcon>
                <ListItemText primary="Fulltimer"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/master/penatua/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><GroupAdd /></ListItemIcon>
                <ListItemText primary="Penatua"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/master/servicetype/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><RoomService /></ListItemIcon>
                <ListItemText primary="Tipe Pelayanan"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/master/subservicetype/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><RoomService /></ListItemIcon>
                <ListItemText primary="Sub Tipe Pelayanan"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/master/category/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Category /></ListItemIcon>
                <ListItemText primary="Kategori"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/master/subcategory/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Category /></ListItemIcon>
                <ListItemText primary="Sub Kategori"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/master/type/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><TrackChanges /></ListItemIcon>
                <ListItemText primary="Status"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/master/lokal/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Public /></ListItemIcon>
                <ListItemText primary="Lokal"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/master/cluster/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Room /></ListItemIcon>
                <ListItemText primary="Wilayah"/>
              </ListItem>      
            </Link>
          </List>
        </div> : null
      }
      {
        token.role == "Administrator" || token.role == "Internal" ?
        <div>
          <ListSubheader className={classes.header}>Pengaturan</ListSubheader>
          <List className={classes.menu}>
            <Link to="/bo/setting/shepherding/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><AccountTree /></ListItemIcon>
                <ListItemText primary="Penggembalaan"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/setting/target/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><SettingsInputComponent /></ListItemIcon>
                <ListItemText primary="Target"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/setting/targetperiode/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Ballot /></ListItemIcon>
                <ListItemText primary="Target Periode"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/setting/input/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Tune /></ListItemIcon>
                <ListItemText primary="Input Setting"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/setting/defaultconv" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Functions /></ListItemIcon>
                <ListItemText primary="Rumus Konversi"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/setting/usrdefconv/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Functions /></ListItemIcon>
                <ListItemText primary="Rumus per FT"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/setting/driveconn" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Cloud /></ListItemIcon>
                <ListItemText primary="Koneksi Google"/>
              </ListItem>      
            </Link>
          </List>
        </div> : null
      }
      {/* {
        token.role == "Administrator" || token.role == "Internal" ?
        <div>
          <ListSubheader className={classes.header}>Laporan</ListSubheader>
          <List className={classes.menu}>
            <Link to="/bo/report/task/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><ListAlt /></ListItemIcon>
                <ListItemText primary="Pengumpulan Tugas"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/report/fulltask/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><ListAlt /></ListItemIcon>
                <ListItemText primary="Pengumpulan Tugas (Printed)"/>
              </ListItem>      
            </Link>
          </List>
        </div> : null
      } */}
      {
        token.role == "Administrator" || token.role == "Internal" || token.role == "Penatua" ?
        <div>
          <ListSubheader className={classes.header}>Laporan Mingguan</ListSubheader>
          
          <List className={classes.menu}>
            <Link to="/bo/report/bibleweek/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><HourglassFull /></ListItemIcon>
                <ListItemText primary="Injil Anugerah"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/report/jalaweek/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><SettingsInputAntenna /></ListItemIcon>
                <ListItemText primary="Jala"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/report/nonshepherdweek/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><DirectionsWalk /></ListItemIcon>
                <ListItemText primary="Non Penggembalaan"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/report/servicetimeuserweek/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><HourglassEmpty /></ListItemIcon>
                <ListItemText primary="Waktu Pelayanan"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/report/baptis/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><InvertColors /></ListItemIcon>
                <ListItemText primary="Baptis"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/report/vitalgroup/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><FavoriteBorder /></ListItemIcon>
                <ListItemText primary="Kelompok Vital"/>
              </ListItem>      
            </Link>
          </List>
            <List className={classes.menu}>
              <Link to="/bo/report/vital/list" className={classes.linkto}>
                <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                  <ListItemIcon><Favorite/></ListItemIcon>
                  <ListItemText primary="Vital"/>
                </ListItem>      
              </Link>
            </List>
            <List className={classes.menu}>
              <Link to="/bo/report/spr/list" className={classes.linkto}>
                <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                  <ListItemIcon><PeopleAlt /></ListItemIcon>
                  <ListItemText primary="Kandidat SPR"/>
                </ListItem>      
              </Link>
            </List>
        {(token.role == "Administrator" || token.role == "Internal") &&
          <List className={classes.menu}>
            <Link to="/bo/report/pengumpulantugas/alluser" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><ListAlt /></ListItemIcon>
                <ListItemText primary="Pengumpulan Tugas" />
              </ListItem>
            </Link>
          </List>
          }
          
        </div> : null
      }
      {
        token.role == "Administrator" || token.role == "Internal" || token.role == "Penatua" ?
        <div>
          <ListSubheader className={classes.header}>Laporan Fulltimer</ListSubheader>
          <List className={classes.menu}>
            <Link to="/bo/report/bible/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><HourglassFull /></ListItemIcon>
                <ListItemText primary="Injil Anugerah"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/report/jala/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><SettingsInputAntenna /></ListItemIcon>
                <ListItemText primary="Jala"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/report/nonshepherd/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><DirectionsWalk /></ListItemIcon>
                <ListItemText primary="Non Penggembalaan"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/report/servicetimeuser/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><HourglassEmpty /></ListItemIcon>
                <ListItemText primary="Waktu Pelayanan"/>
              </ListItem>      
            </Link>
          </List>
          
        </div> : null
      }
      {
        token.role == "Administrator" || token.role == "Internal" || token.role == "Penatua" ?
        <div>
          <ListSubheader className={classes.header}>Laporan List Detail</ListSubheader>
          <List className={classes.menu}>
            <Link to="/bo/report/nonshepherd/detail" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><DirectionsWalk /></ListItemIcon>
                <ListItemText primary="Non Penggembalaan"/>
              </ListItem>      
            </Link>
          </List>          
        </div> : null
      }
      {
        token.role == "Administrator" || token.role == "Internal" ?
        <div>
          <ListSubheader className={classes.header}>Laporan Mingguan / Harian</ListSubheader>
          <List className={classes.menu}>
            <Link to="/bo/report/nonshepherd/alluser" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><DirectionsWalk /></ListItemIcon>
                <ListItemText primary="Non Penggembalaan"/>
              </ListItem>      
            </Link>
          </List>          
        </div> : null
      }

      {
        token.role == "Administrator" || token.role == "Internal" ?
          <div>
            <ListSubheader className={classes.header}>Laporan Rekap Jadwal</ListSubheader>
            <List className={classes.menu}>
                <Link to="/bo/report/laporanrekapjadwal/alluser" className={classes.linkto}>
                <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                  <ListItemIcon><DirectionsWalk /></ListItemIcon>
                  <ListItemText primary="Rekap Jadwal" />
                </ListItem>
              </Link>
            </List>
          </div> : null
      }

      {
        token.role == "Administrator" || token.role == "Internal" || token.role == "Penatua" ?
        <div>
          <ListSubheader className={classes.header}>Rekap Jadwal FT</ListSubheader>
          <List className={classes.menu}>
            <Link to="/bo/report/nonshepherd/schedule" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><DirectionsWalk /></ListItemIcon>
                <ListItemText primary="Non Penggembalaan"/>
              </ListItem>      
            </Link>
          </List>          
        </div> : null
      }
      {
        token.role == "Administrator" || token.role == "Internal" || token.role == "Penatua" ?
        <div>
          <ListSubheader className={classes.header}>Export</ListSubheader>
          <List className={classes.menu}>
            <Link to="/bo/export/daily" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Assignment /></ListItemIcon>
                <ListItemText primary="Daily Report"/>
              </ListItem>      
            </Link>
          </List>
        </div> : null
      }
      {
        token.role == "User" ?
        <div>
          <ListSubheader className={classes.header}>Fulltimer</ListSubheader>
          <List className={classes.menu}>
            <Link to="/bo/target/edit" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><TrackChanges /></ListItemIcon>
                <ListItemText primary="Edit / View Target"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/targetperiode/edit" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Ballot /></ListItemIcon>
                <ListItemText primary="Edit / View Target Periode"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/schedule/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Schedule /></ListItemIcon>
                <ListItemText primary="Schedule"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/schedule/active/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Alarm /></ListItemIcon>
                <ListItemText primary="Schedule Active"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/penuntutanft" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><NoteAdd /></ListItemIcon>
                <ListItemText primary="Penuntutan FT"/>
              </ListItem>      
            </Link>
          </List>
          
        </div> : null
      }
      {
        token.role == "User" ?
        <div>
          <ListSubheader className={classes.header}>Laporan</ListSubheader>
          {/* <List className={classes.menu}>
            <Link to="/bo/ft/report/fulltask/list" className={classes.linkto}>
              <ListItem button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><ListAlt /></ListItemIcon>
                <ListItemText primary="Printed Task"/>
              </ListItem>      
            </Link>
          </List> */}
          <List className={classes.menu}>
            <Link to="/bo/ft/report/bible/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><HourglassFull /></ListItemIcon>
                <ListItemText primary="Injil Anugerah"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/ft/report/jala/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><SettingsInputAntenna /></ListItemIcon>
                <ListItemText primary="Jala"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/ft/report/nonshepherd/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><DirectionsWalk /></ListItemIcon>
                <ListItemText primary="Non Penggembalaan"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/ft/report/servicetimeuser/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><HourglassEmpty /></ListItemIcon>
                <ListItemText primary="Waktu Pelayanan"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/ft/report/baptis/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><InvertColors /></ListItemIcon>
                <ListItemText primary="Baptis"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/ft/report/spr/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><PeopleAlt /></ListItemIcon>
                <ListItemText primary="Kandidat SPR"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/ft/report/vitalgroup/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><FavoriteBorder /></ListItemIcon>
                <ListItemText primary="Kelompok Vital"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/ft/report/vital/list" className={classes.linkto}>
              <ListItem onClick={() => props.mobile && props.closeDrawer()} button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Favorite /></ListItemIcon>
                <ListItemText primary="Vital"/>
              </ListItem>      
            </Link>
          </List>
          
        </div> : null
      }
      {/* <Divider />
      <List>
        <Link to="/logout" className={classes.linkto}>
          <ListItem button key="li-logout" className={classes.listitem}>
            <ListItemIcon><ExitToAppOutlined /></ListItemIcon>
            <ListItemText primary="Logout"/>
          </ListItem>   
        </Link>   
      </List> */}
  </div>
    )
}

export default AdminListItems