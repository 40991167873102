import React from 'react';
import {Card, CssBaseline, Grid, Paper, 
  Typography, Chip, CardContent} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import axios from '../config/axios';
import DialogBasic from '../component/dialogbasic';
import {
  AccountCircleRounded, TrackChangesRounded
} from '@material-ui/icons'
import MUIDataTable from "mui-datatables";
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../config/errormsg";
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

const columns = [
  {
    name: "Tanggal",
    options: {
      filter: true
    }
  },
  {
    name: "Nama",
    options: {
      filter: true
    }
  },
  {
    name: "Email",
    options: {
      filter: true
    }
  },
  {
    name: "Kota",
    options: {
      filter: true
    }
  },
  {
    name: "UTBK",
    options: {
      filter: true
    }
  }   
];

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions:[10,25,100],
  print:false,
  download:false,
  selectableRows:'single',
  isRowSelectable:function(){return false}
};

class DbHomePenatua extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        dashData: {
          sheeptotal: 0,
          morethantarget: 0,
          ttachieved: []
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        visibleloader:false,
    };
  }

  componentDidMount() {
    this.getDashboard();
  }

  getDashboard() {
    let outer = this;
    axios.post('bo/penatua/dashboard', {}).then(({data}) => {
      if(data.sta && data.stacod === 200) {
        outer.setState({
          dashData: {
            ...outer.state.dashData,
            sheeptotal: data.data.sheeptotal,
            morethantarget: data.data.morethantarget,
            ttachieved: data.data.totaltargetachievedbyweek
          }
        })
      } else {
        outer.showDialog("warning", data.msg);
      }
    }).catch(function (error) {
      outer.showDialog("warning", Errormsg["500"], "");
    })
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {

      this.setState({dialogOpen:false});
    }
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  render(){
    const { classes } = this.props;
    const { rows,total, dashData} = this.state;
    const title = "Dashboard Admin"

    return (
      <div>
        <CssBaseline />
        <div className={classes.paper}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography component="h5" variant="h5">
                Dashboard Penatua
              </Typography>
              <div style={{marginTop:'20px',marginBottom:'20px'}}>
                <Grid container spacing={5}>
                  <Grid item md={6} xs={12}>
                    <Card>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={3}> 
                            <AccountCircleRounded
                              style={{
                                fontSize: 100,
                                color: 'green'
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography 
                              component="h5" 
                              variant="h5"
                              style={{textAlign: 'right'}}
                            >
                              Total Fulltimer
                            </Typography>
                            <Typography 
                              component="h2" 
                              variant="h3"
                              style={{textAlign: 'right'}}
                            >
                              {dashData.sheeptotal}
                            </Typography>
                          </Grid>
                        </Grid>
                        
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Card>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={3}>
                            <TrackChangesRounded
                              style={{
                                fontSize: 100,
                                color: 'purple'
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography 
                              component="h5" 
                              variant="h5"
                              style={{textAlign: 'right'}}
                            >
                              Melebihi Target
                            </Typography>
                            <Typography 
                              component="h2" 
                              variant="h3"
                              style={{textAlign: 'right'}}
                            >
                              {dashData.morethantarget}
                            </Typography>
                          </Grid>
                        </Grid>
                        
                      </CardContent>
                    </Card>
                  </Grid>

                </Grid>
              </div>
              
              <div style={{textAlign:'left'}}>
                  <Loader
                      visible={this.state.visibleloader}
                      type="ThreeDots"
                      color="#204196"
                      height={45}
                      width={45}
                  />
                </div>
                {
                  dashData.ttachieved.length > 0 &&
                  <Paper className={classes.root}>
                        <div
                          style={{
                            textAlign: 'center',
                            marginTop: 10,
                          }}
                        >
                          <Typography
                            component="h5"
                            variant="h5"
                          >
                            Target Tercapai (Mingguan)
                          </Typography>
                        </div>
                        <div style={{width: '100%', height: 300}}>

                          <ResponsiveContainer>

                            <LineChart
                              data={dashData.ttachieved}
                              margin={{
                                top: 10,
                                bottom: 10,
                                right: 15
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="week" />
                              <YAxis dataKey="total"/>
                              <Tooltip />
                              <Legend />
                              <Line type="monotone" dataKey="total" stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                          </ResponsiveContainer>
                        </div>
                  </Paper>
                }
            </Grid>
          </Grid>
        </div>
  
        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );

    // return (
    //   <DashboardAdmin title={title} content={content} />
    // );
  }
}

DbHomePenatua.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DbHomePenatua);