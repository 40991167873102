import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
  IconButton, TextField, Paper, Card, FormControl,
  CardMedia, CardContent, Typography, Select, InputLabel, MenuItem
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import DataTable from 'react-data-table-component';
import moment from 'moment';
//import actions
import { addParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete: {
    color: '#204196',
    fontWeight: 'bold'
  },
  incomplete: {
    color: '#ff1744',
    fontWeight: 'bold'
  }
});

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions: [10, 25, 100],
  print: false,
  download: false,
  selectableRows: 'single',
  isRowSelectable: function () { return false }
};

class DbReportNonPenggembalaanft extends React.Component {
  constructor(props) {
    super(props);
    let dat = new Date();
    dat.setDate(dat.getDate() - 7);
    this.state = {
      setdate: "",
      showdata: "table",
      page: {
        type: "weekly",
        start: moment().subtract(7, "day").format('YYYY-MM-DD'),
        startPar: dat,
        end: moment().format('YYYY-MM-DD'),
        endPar: new Date()
      },
      pageUser: {
        search: "",
        page: 1,
        results: 10
      },
      users: [],
      korporatList: [],
      lewihallList: [],
      lewiksList: [],
      transportasiList: [],
      lainnyaList: [],
      penuntutanList: [],
      loadingTrigger: false,
      // kolamList: [],
      // pelayanankhususList: [],
      usrobject: null,
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      dialogMode: '',
      detailOpen: false,
    }
  }

  columns = [

    {
      name: <IntlMessages id="report.task.date" />,
      selector: 'date',
      sortable: true,
      format: row =>
        this.state.page.type == "weekly" ?
          moment(row.date).format("DD-MM-YYYY") :
          moment(row.date).format("MMMM YYYY")

    },
    {
      name: <IntlMessages id="report.non.shepherding.target" />,
      selector: 'target',
    },
    {
      name: <IntlMessages id="report.non.shepherding.real" />,
      selector: 'realization',
    },
    {
      name: <IntlMessages id="report.bible.percent" />,
      selector: 'percent',
      cell: row => <span
        style={
          row.percentnum == 0 ?
            { color: 'red' } : row.percentnum > 0 && row.percentnum < 26 ? { color: "pink" } :
              row.percentnum > 25 && row.percentnum < 51 ? { color: "orange" } : row.percentnum > 50 && row.percentnum < 76 ? { color: 'green' } :
                row.percentnum > 75 && row.percentnum < 101 ? { color: "blue" } : row.percentnum > 100 && { color: "black" }
        }
      >
        {row.percent}
      </span>
    },
    {
      name: <IntlMessages id="report.bible.freq" />,
      selector: 'freq',
    },
    {
      name: <IntlMessages id="report.bible.time" />,
      selector: 'time',
    },
    {
      name: <IntlMessages id="report.bible.efficiency" />,
      selector: 'efficiency',
    }
  ];

  componentDidMount() {
    this.getNonshReport();
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen: false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  // getUserList() {
  //   let outer = this;
  //   axios.post('bo/int/master/cust/list', outer.state.pageUser).then(({data})=>{
  //     if(data.sta && data.stacod === 200){
  //       outer.setState({
  //         users:data.data.list
  //       });
  //     } else {
  //       outer.showDialog("Warning", data.msg, "");
  //     }
  //   }).catch(function (error) {
  //       outer.showDialog('Warning',Errormsg["500"],"");
  //   });
  // }

  // changeUser = (event, val) => {
  //   if(val) {
  //     this.setState({
  //       usrobject: val,
  //       page: {
  //         ...this.state.page,
  //         userid: val.id
  //       }
  //     }, () => {
  //       this.getNonshReport();
  //     })
  //   }
  // }

  changeType = (val) => {
    if (val) {
      this.setState({
        page: {
          ...this.state.page,
          type: val.target.value
        }
      }, () => {
        this.getNonshReport();
      })
    }
  }


  getNonshReport = () => {
    let outer = this;
    this.setState({
      loadingTrigger: true
    });
    axios.post('bo/ft/report/nonshepherding/user', outer.state.page).then(({ data }) => {
      this.setState({
        loadingTrigger: false
      });
      if (data.sta && data.stacod === 200) {
        this.setState({
          korporatList: data.data.korporat,
          lewihallList: data.data.lewihall,
          lewiksList: data.data.lewiks,
          transportasiList: data.data.transportasi,
          lainnyaList: data.data.lainnya,
          penuntutanList: data.data.penuntutan,
          // kolamList: data.data.kolam,
          // pelayanankhususList: data.data.pelayanankhusus,
        })
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }



  changeStart = (date) => {
    let momentParse = moment(date).format("YYYY-MM-DD");
    this.setState({
      page: {
        ...this.state.page,
        startPar: date,
        start: momentParse,
      }
    }, () => {
      this.getNonshReport();
    })
  }

  changeEnd = (date) => {
    let momentParse = moment(date).format("YYYY-MM-DD");
    this.setState({
      page: {
        ...this.state.page,
        endPar: date,
        end: momentParse,
      }
    }, () => {
      this.getNonshReport();
    })
  }

  handleChangeSet = (val) => {
    let outer = this;
    this.setState({
      setdate: val.target.value
    }, () => {
      let dat = new Date();
      // dat.setDate(dat.getDate() - 7);
      if (outer.state.setdate == "1minggu") {
        dat.setDate(dat.getDate() - 7);
        outer.setState({
          page: {
            ...this.state.page,
            start: moment().subtract(7, "day").format('YYYY-MM-DD'),
            startPar: dat,
            end: moment().format('YYYY-MM-DD'),
            endPar: new Date()
          }
        }, () => {
          outer.getNonshReport();
        });
      }
      if (outer.state.setdate == "2minggu") {
        dat.setDate(dat.getDate() - 14);
        outer.setState({
          page: {
            ...this.state.page,
            start: moment().subtract(14, "day").format('YYYY-MM-DD'),
            startPar: dat,
            end: moment().format('YYYY-MM-DD'),
            endPar: new Date()
          }
        }, () => {
          outer.getNonshReport();
        });
      }
      if (outer.state.setdate == "bulan") {
        dat.setDate(dat.getDate() - 30);
        outer.setState({
          page: {
            ...this.state.page,
            start: moment().subtract(30, "day").format('YYYY-MM-DD'),
            startPar: dat,
            end: moment().format('YYYY-MM-DD'),
            endPar: new Date()
          }
        }, () => {
          outer.getNonshReport();
        });
      }
    })
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      this.setState({ dialogOpen: false });
    }
  }

  openDate() {
    this.setState({
      korporatList: [],
      lewihallList: [],
      lewiksList: [],
      transportasiList: [],
      lainnyaList: [],
      penuntutanList: [],
    })
  }

  closeDate() {
    this.getNonshReport()
  }

  handleChangeShow = (val) => {
    this.setState({
      showdata: val.target.value
    })
  }


  render() {
    const { theme } = this.props;
    const { korporatList, lewihallList, lewiksList,
      transportasiList, lainnyaList, penuntutanList, users } = this.state;

    return (
      <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
        <div style={{ paddingTop: 10 }}>
          <Typography component="h5" variant="h5">
            Laporan Non Penggembalaan
          </Typography>
        </div>
        <div className="filter">
          <div>
            <FormControl
              style={{
                minWidth: '100%',
                marginBottom: 10
              }}
            >

              <InputLabel id="settype" style={{ backgroundColor: "#fff", paddingLeft: 4, paddingRight: 4 }} shrink variant="outlined">
                Pilih Tipe
              </InputLabel>
              <Select
                value={this.state.page.type}
                onChange={this.changeType}
                labelId="settype"
                variant="outlined"
                label="Pilih Tipe"
                style={{ width: '100%' }}
              >
                <MenuItem value="weekly">Mingguan</MenuItem>
                <MenuItem value="monthly">Bulanan</MenuItem>
                {/* <MenuItem value="yearly">Tahunan</MenuItem> */}

              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl
              style={{
                minWidth: '100%',
                marginBottom: 10
              }}
            >

              <InputLabel id="set" style={{ backgroundColor: "#fff", paddingLeft: 4, paddingRight: 4 }} shrink variant="outlined">
                Jangka Waktu Tanggal
              </InputLabel>
              <Select
                value={this.state.setdate}
                onChange={this.handleChangeSet}
                labelId="set"
                variant="outlined"
                label="Jangka Waktu Tanggal"
                style={{ width: '100%' }}
              >
                <MenuItem value="1minggu">1 Minggu</MenuItem>
                <MenuItem value="2minggu">2 Minggu</MenuItem>
                <MenuItem value="bulan">1 Bulan</MenuItem>

              </Select>
            </FormControl>
          </div>
          <div style={{ marginBottom: 10 }}>
            {/* <InputLabel style={{fontSize: 12}} id="formtype">
                Tanggal Mulai
              </InputLabel> */}
            <KeyboardDatePicker
              clearable
              label="Tanggal Mulai"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.page.startPar}
              onOpen={() => this.openDate()}
              onClose={() => this.closeDate()}
              // placeholder="Start"
              onChange={date => this.changeStart(date)}
              format="DD-MM-YYYY"
              fullWidth
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            {/* <InputLabel style={{fontSize: 12}} id="formtype">
                Tanggal Berakhir
              </InputLabel> */}
            <KeyboardDatePicker
              clearable
              label="Tanggal Berakhir"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.page.endPar}
              onOpen={() => this.openDate()}
              onClose={() => this.closeDate()}
              // placeholder="End"
              onChange={date => this.changeEnd(date)}
              format="DD-MM-YYYY"
              fullWidth
            />
          </div>
          <div>
            <FormControl
              style={{
                minWidth: '100%',
                marginBottom: 10
              }}
            >
              <InputLabel id="show" style={{ backgroundColor: "#fff", paddingLeft: 4, paddingRight: 4 }} shrink variant="outlined">
                Tampilkan Sebagai
              </InputLabel>
              <Select
                value={this.state.showdata}
                onChange={this.handleChangeShow}
                variant="outlined"
                label="Tampilkan Sebagai"
                labelId="show"
                style={{ width: '100%' }}
              >
                <MenuItem value="table">Tabel</MenuItem>
                <MenuItem value="chart">Grafik</MenuItem>

              </Select>
            </FormControl>
          </div>
          <div>
            <Typography variant="subtitle2">
              * Keterangan Persentase:
            </Typography>
            <div>
              <span style={{ color: 'red' }}>0</span> / <span style={{ color: 'pink' }}>0-25%</span> / <span style={{ color: 'orange' }}>26%-50%</span> /&nbsp;
              <span style={{ color: 'green' }}>51%-75%</span> / <span style={{ color: 'blue' }}>76%-100%</span> / <span style={{ color: 'black' }}>&gt;100%</span>
            </div>
          </div>
        </div>
        {
          this.state.showdata == "table" &&
          <>
            <DataTable
              title="Korporat"
              columns={this.columns}
              data={korporatList}
              noDataComponent={
                <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                  <CardMedia style={{ textAlign: 'center' }}>
                    <MoodBad color="action" style={{
                      fontSize: 40,
                      marginTop: 10,
                      marginBottom: 10
                    }} />
                  </CardMedia>
                  <CardContent>
                    <Typography>
                      Data tidak tersedia
                    </Typography>
                  </CardContent>
                </Card>
              }
              highlightOnHover
              customStyles={customStyles}
              pagination={false}
              progressPending={this.state.loadingTrigger}
              progressComponent={
                <Loader type="TailSpin" color="#204196" height={100} width={100} />
              }
            />
            <DataTable
              title="Lewi Hall"
              columns={this.columns}
              data={lewihallList}
              noDataComponent={
                <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                  <CardMedia style={{ textAlign: 'center' }}>
                    <MoodBad color="action" style={{
                      fontSize: 40,
                      marginTop: 10,
                      marginBottom: 10
                    }} />
                  </CardMedia>
                  <CardContent>
                    <Typography>
                      Data tidak tersedia
                    </Typography>
                  </CardContent>
                </Card>
              }
              highlightOnHover
              customStyles={customStyles}
              pagination={false}
              progressPending={this.state.loadingTrigger}
              progressComponent={
                <Loader type="TailSpin" color="#204196" height={100} width={100} />
              }
            />
            <DataTable
              title="Lewi Kaum Saleh"
              columns={this.columns}
              data={lewiksList}
              noDataComponent={
                <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                  <CardMedia style={{ textAlign: 'center' }}>
                    <MoodBad color="action" style={{
                      fontSize: 40,
                      marginTop: 10,
                      marginBottom: 10
                    }} />
                  </CardMedia>
                  <CardContent>
                    <Typography>
                      Data tidak tersedia
                    </Typography>
                  </CardContent>
                </Card>
              }
              highlightOnHover
              customStyles={customStyles}
              pagination={false}
              progressPending={this.state.loadingTrigger}
              progressComponent={
                <Loader type="TailSpin" color="#204196" height={100} width={100} />
              }
            />
            <DataTable
              title="Transportasi"
              columns={this.columns}
              data={transportasiList}
              noDataComponent={
                <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                  <CardMedia style={{ textAlign: 'center' }}>
                    <MoodBad color="action" style={{
                      fontSize: 40,
                      marginTop: 10,
                      marginBottom: 10
                    }} />
                  </CardMedia>
                  <CardContent>
                    <Typography>
                      Data tidak tersedia
                    </Typography>
                  </CardContent>
                </Card>
              }
              highlightOnHover
              customStyles={customStyles}
              pagination={false}
              progressPending={this.state.loadingTrigger}
              progressComponent={
                <Loader type="TailSpin" color="#204196" height={100} width={100} />
              }
            />
            <DataTable
              title="Lainnya"
              columns={this.columns}
              data={lainnyaList}
              noDataComponent={
                <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                  <CardMedia style={{ textAlign: 'center' }}>
                    <MoodBad color="action" style={{
                      fontSize: 40,
                      marginTop: 10,
                      marginBottom: 10
                    }} />
                  </CardMedia>
                  <CardContent>
                    <Typography>
                      Data tidak tersedia
                    </Typography>
                  </CardContent>
                </Card>
              }
              highlightOnHover
              customStyles={customStyles}
              pagination={false}
              progressPending={this.state.loadingTrigger}
              progressComponent={
                <Loader type="TailSpin" color="#204196" height={100} width={100} />
              }
            />
            <DataTable
              title="Penuntutan"
              columns={this.columns}
              data={penuntutanList}
              noDataComponent={
                <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                  <CardMedia style={{ textAlign: 'center' }}>
                    <MoodBad color="action" style={{
                      fontSize: 40,
                      marginTop: 10,
                      marginBottom: 10
                    }} />
                  </CardMedia>
                  <CardContent>
                    <Typography>
                      Data tidak tersedia
                    </Typography>
                  </CardContent>
                </Card>
              }
              highlightOnHover
              customStyles={customStyles}
              pagination={false}
              progressPending={this.state.loadingTrigger}
              progressComponent={
                <Loader type="TailSpin" color="#204196" height={100} width={100} />
              }
            />
            {/* <DataTable
                  title="Kolam"
                  columns={this.columns}
                  data={kolamList}
                  noDataComponent={
                    <Card style={{maxWidth: 200, borderWidth: 1, borderStyle: 'solid'}}>
                      <CardMedia style={{textAlign: 'center'}}>
                        <MoodBad color="action" style={{
                          fontSize: 40, 
                          marginTop: 10, 
                          marginBottom: 10
                        }}/>
                      </CardMedia>
                      <CardContent>
                        <Typography>
                          Data tidak tersedia
                        </Typography>
                      </CardContent>
                    </Card>
                  }
                  highlightOnHover
                  customStyles={customStyles}
                  pagination={false}
                />
                <DataTable
                  title="Pelayanan Khusus"
                  columns={this.columns}
                  data={pelayanankhususList}
                  noDataComponent={
                    <Card style={{maxWidth: 200, borderWidth: 1, borderStyle: 'solid'}}>
                      <CardMedia style={{textAlign: 'center'}}>
                        <MoodBad color="action" style={{
                          fontSize: 40, 
                          marginTop: 10, 
                          marginBottom: 10
                        }}/>
                      </CardMedia>
                      <CardContent>
                        <Typography>
                          Data tidak tersedia
                        </Typography>
                      </CardContent>
                    </Card>
                  }
                  highlightOnHover
                  customStyles={customStyles}
                  pagination={false}
                /> */}
          </>
        }
        {
          this.state.showdata == "chart" &&
          <>
            <Paper style={{ marginTop: 350 }}>
              <div
                style={{
                  marginTop: 10,
                  paddingLeft: 5
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                >
                  Korporat
                </Typography>
              </div>
              <div style={{ width: '100%', height: 300 }}>

                <ResponsiveContainer>

                  <LineChart
                    data={korporatList}
                    margin={{
                      top: 10,
                      bottom: 10,
                      right: 15
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip formatter={(value, name, props) => {
                      switch (name) {
                        case 'Realization':
                          return props.payload.realization
                          break;
                        case 'Target':
                          return value + ' Jam';
                        default:
                          return value;
                          break;
                      }
                    }} />
                    <Legend />
                    <Line name="Target" type="monotone" dataKey="target" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line name="Realization" type="monotone" dataKey="realizationnum" stroke="#FF0000" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Paper>
            <Paper style={{ marginTop: 20 }}>
              <div
                style={{
                  marginTop: 10,
                  paddingLeft: 5
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                >
                  Lewi Hall
                </Typography>
              </div>
              <div style={{ width: '100%', height: 300 }}>

                <ResponsiveContainer>

                  <LineChart
                    data={lewihallList}
                    margin={{
                      top: 10,
                      bottom: 10,
                      right: 15
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip formatter={(value, name, props) => {
                      switch (name) {
                        case 'Realization':
                          return props.payload.realization
                          break;
                        case 'Target':
                          return value + ' Jam';
                        default:
                          return value;
                          break;
                      }
                    }} />
                    <Legend />
                    <Line name="Target" type="monotone" dataKey="target" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line name="Realization" type="monotone" dataKey="realizationnum" stroke="#FF0000" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Paper>
            <Paper style={{ marginTop: 20 }}>
              <div
                style={{
                  marginTop: 10,
                  paddingLeft: 5
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                >
                  Lewi Kaum Saleh
                </Typography>
              </div>
              <div style={{ width: '100%', height: 300 }}>

                <ResponsiveContainer>

                  <LineChart
                    data={lewiksList}
                    margin={{
                      top: 10,
                      bottom: 10,
                      right: 15
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip formatter={(value, name, props) => {
                      switch (name) {
                        case 'Realization':
                          return props.payload.realization
                          break;
                        case 'Target':
                          return value + ' Jam';
                        default:
                          return value;
                          break;
                      }
                    }} />
                    <Legend />
                    <Line name="Target" type="monotone" dataKey="target" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line name="Realization" type="monotone" dataKey="realizationnum" stroke="#FF0000" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Paper>
            <Paper style={{ marginTop: 20 }}>
              <div
                style={{
                  marginTop: 10,
                  paddingLeft: 5
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                >
                  Transportasi
                </Typography>
              </div>
              <div style={{ width: '100%', height: 300 }}>

                <ResponsiveContainer>

                  <LineChart
                    data={transportasiList}
                    margin={{
                      top: 10,
                      bottom: 10,
                      right: 15
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip formatter={(value, name, props) => {
                      switch (name) {
                        case 'Realization':
                          return props.payload.realization
                          break;
                        case 'Target':
                          return value + ' Jam';
                        default:
                          return value;
                          break;
                      }
                    }} />
                    <Legend />
                    <Line name="Target" type="monotone" dataKey="target" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line name="Realization" type="monotone" dataKey="realizationnum" stroke="#FF0000" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Paper>
            <Paper style={{ marginTop: 20 }}>
              <div
                style={{
                  marginTop: 10,
                  paddingLeft: 5
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                >
                  Lainnya
                </Typography>
              </div>
              <div style={{ width: '100%', height: 300 }}>

                <ResponsiveContainer>

                  <LineChart
                    data={lainnyaList}
                    margin={{
                      top: 10,
                      bottom: 10,
                      right: 15
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip formatter={(value, name, props) => {
                      switch (name) {
                        case 'Realization':
                          return props.payload.realization
                          break;
                        case 'Target':
                          return value + ' Jam';
                        default:
                          return value;
                          break;
                      }
                    }} />
                    <Legend />
                    <Line name="Target" type="monotone" dataKey="target" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line name="Realization" type="monotone" dataKey="realizationnum" stroke="#FF0000" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Paper>
            <Paper style={{ marginTop: 20 }}>
              <div
                style={{
                  marginTop: 10,
                  paddingLeft: 5
                }}
              >
                <Typography
                  component="h5"
                  variant="h5"
                >
                  Penuntutan
                </Typography>
              </div>
              <div style={{ width: '100%', height: 300 }}>

                <ResponsiveContainer>

                  <LineChart
                    data={penuntutanList}
                    margin={{
                      top: 10,
                      bottom: 10,
                      right: 15
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip formatter={(value, name, props) => {
                      switch (name) {
                        case 'Realization':
                          return props.payload.realization
                          break;
                        case 'Target':
                          return value + ' Jam';
                        default:
                          return value;
                          break;
                      }
                    }} />
                    <Legend />
                    <Line name="Target" type="monotone" dataKey="target" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line name="Realization" type="monotone" dataKey="realizationnum" stroke="#FF0000" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Paper>
            {/* <Paper style={{marginTop: 20}}>
                <div
                  style={{
                    marginTop: 10,
                    paddingLeft: 5
                  }}
                >
                  <Typography
                    component="h5"
                    variant="h5"
                  >
                    Kolam
                  </Typography>
                </div>
                <div style={{width: '100%', height: 300}}>

                  <ResponsiveContainer>

                    <LineChart
                      data={kolamList}
                      margin={{
                        top: 10,
                        bottom: 10,
                        right: 15
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis/>
                      <Tooltip formatter={(value,name, props) => {
                        switch (name) {
                          case 'Realization':
                            return props.payload.realization                          
                            break;
                          case 'Target':
                            return value + ' Jam';
                          default:
                            return value;
                            break;
                        }
                      }}/>
                      <Legend />
                      <Line name="Target" type="monotone" dataKey="target" stroke="#8884d8" activeDot={{ r: 8 }} />
                      <Line name="Realization" type="monotone" dataKey="realizationnum" stroke="#FF0000" activeDot={{ r: 8 }} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
            </Paper>
            <Paper style={{marginTop: 20}}>
                <div
                  style={{
                    marginTop: 10,
                    paddingLeft: 5
                  }}
                >
                  <Typography
                    component="h5"
                    variant="h5"
                  >
                    Pelayanan Khusus
                  </Typography>
                </div>
                <div style={{width: '100%', height: 300}}>

                  <ResponsiveContainer>

                    <LineChart
                      data={pelayanankhususList}
                      margin={{
                        top: 10,
                        bottom: 10,
                        right: 15
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis/>
                      <Tooltip formatter={(value,name, props) => {
                        switch (name) {
                          case 'Realization':
                            return props.payload.realization                          
                            break;
                          case 'Target':
                            return value + ' Jam';
                          default:
                            return value;
                            break;
                        }
                      }}/>
                      <Legend />
                      <Line name="Target" type="monotone" dataKey="target" stroke="#8884d8" activeDot={{ r: 8 }} />
                      <Line name="Realization" type="monotone" dataKey="realizationnum" stroke="#FF0000" activeDot={{ r: 8 }} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
            </Paper> */}
          </>
        }

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />

      </div>
    );
  }
}

DbReportNonPenggembalaanft.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbReportNonPenggembalaanft)));