import idLang from "./entries/id-IN";
import enLang from "./entries/en-US";
// import {addLocaleData} from "react-intl";

const AppLocale = {
  en: enLang,
  id: idLang
};

// addLocaleData(AppLocale.id.data);

export default AppLocale;
