import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
    IconButton, TextField, Paper, Card, FormControl,
    CardMedia, CardContent, Typography, Select, InputLabel, MenuItem, Button, Breadcrumbs, Link
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IntlMessages from "../../../../../util/IntlMessages";
import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import DataTable, { defaultThemes } from 'react-data-table-component';
import moment from 'moment';
//import actions
import { addParam } from "../../../../../store/actions/configAction";
import './../../../../App.css';

const customStyles = {
    tableWrapper: {
        style: {
            display: "inline"
        }
    },
    headRow: {
        style: {
            borderBottomStyle: 'solid',
            borderBottomWidth: '0px',
            borderBottomColor: defaultThemes.default.divider.default,
        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '14px',
            borderRightStyle: 'solid',
            borderRightWidth: '1px',
            borderRightColor: defaultThemes.default.divider.default,
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: defaultThemes.default.divider.default,
        },
    },
    cells: {
        style: {
            borderRightStyle: 'solid',
            borderRightWidth: '1px',
            borderRightColor: defaultThemes.default.divider.default,
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: defaultThemes.default.divider.default,
        },
    },
    rows: {
        style: {
            '&:not(:last-of-type)': {
                borderBottomStyle: 'solid',
                borderBottomWidth: '0px',
                borderBottomColor: defaultThemes.default.divider.default,
            },
        },
    },
    pagination: {
        style: {
            borderTopStyle: 'solid',
            borderTopWidth: '0px',
            borderTopColor: defaultThemes.default.divider.default,
        },
    }
};



const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    table: {
        minWidth: 320,
    },
    complete: {
        color: '#204196',
        fontWeight: 'bold'
    },
    incomplete: {
        color: '#ff1744',
        fontWeight: 'bold'
    }
});

const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "scrollFullHeight",
    rowsPerPageOptions: [10, 25, 100],
    print: false,
    download: false,
    selectableRows: 'single',
    isRowSelectable: function () { return false }
};

class pengumpulanTugas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setdate: "",
            username: "",
            useremail: "",
            showdata: "table",
            loadingTrigger: false,
            page: {
                date: moment().format('YYYY-MM-DD'),
                datePar: new Date(),
                page: 1,
                count: 100,
                keyword: "",
                all: false
            },
            pageUser: {
                keyword: "",
                page: 1,
                results: 10
            },
            pagevw: {
                w1d1: "W1D1",
                w1d2: "W1D2",
                w1d3: "W1D3",
                w1d4: "W1D4",

                w2d1: "W2D1",
                w2d2: "W2D2",
                w2d3: "W2D3",
                w2d4: "W2D4",

                w3d1: "W3D1",
                w3d2: "W3D2",
                w3d3: "W3D3",
                w3d4: "W3D4"
            },
            localparam: {
                keyword: '',
                page: 1,
                count: 10
            },
            locallist: [],
            selectedLocal: {},
            loading: false,
            users: [],
            realList: [],
            realListTotal: 0,
            usrobject: null,
            dialogOpen: false,
            dialogTitle: '',
            dialogContent: '',
            dialogMode: '',
            detailOpen: false,
            resetPage: false,
            disableBtnExport: false
        }
    }

    columns = [

        {
            name: "Name",
            selector: 'name',
            sortable: true,
            width: '110px'
        },
        {
            name: "TW Korporat",
            selector: 'twkorporat'
        },
        {
            name: "TW Lewi Hall",
            selector: 'twlewihall'
        },
        {
            name: "TW Lewi Kaum Saleh",
            selector: 'twlewiks'
        },
        {
            name: "TW Transportasi",
            selector: 'twtransport'
        },
        {
            name: "TW Lain-lain",
            selector: 'twlain'
        },
        {
            name: "TW Kokyn",
            selector: 'twkokyn'
        },
        {
            name: "LW Korporat",
            selector: 'lwkorporat'
        },
        {
            name: "LW Lewi Hall",
            selector: 'lwlewihall'
        },
        {
            name: "LW Lewi Kaum Saleh",
            selector: 'lwlewiks'
        },
        {
            name: "LW Transportasi",
            selector: 'lwtransport'
        },
        {
            name: "LW Lain-lain",
            selector: 'lwlain'
        },
        {
            name: "LW Kokyn",
            selector: 'lwkokyn'
        },
    ];

    componentDidMount() {
        this.getRealReport();
        this.getLocal();
    }

    getLocal() {
        let outer = this;
        axios.post('bo/int/master/local/list', outer.state.localparam).then(({ data }) => {
            if (data.sta && data.stacod === 200) {
                outer.setState({
                    locallist: data.data.list
                });
            } else {
                outer.showDialog('Warning', data.msg, "");
            }
        }).catch(function (error) {
            outer.showDialog('Warning', Errormsg["500"], "");
        });
    }

    changeLocal = (event, val) => {
        let outer = this;
        if (val) {
            this.setState({
                selectedLocal: val,
                page: {
                    ...this.state.page,
                    lokalid: val.id
                }
            }, () => { outer.getRealReport() });
        } else {
            this.setState({
                selectedLocal: {},
                page: {
                    ...this.state.page,
                    lokalid: "",
                }
            }, () => { outer.getRealReport() });
        }
        this.inputChangeLocal('');
    }

    inputChangeLocal(val) {
        this.setState({
            localparam: {
                ...this.state.localparam,
                keyword: val
            }
        }, () => {
            this.getLocal();
        })
    }

    showDialog = (title, content, mode) => {
        this.setState({
            dialogOpen: true,
            dialogTitle: title,
            dialogContent: content,
            dialogMode: mode
        });
    }

    handleDetailClose = () => {
        this.setState({
            detailOpen: false
        })
    }

    handleDialogClose = () => {
        this.setState({
            dialogOpen: false
        })
    }

    handleDialogOk = () => {
        if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
            this.setState({ dialogOpen: false });
            this.props.history.push("/logout");
        } else {
            this.setState({ dialogOpen: false });
        }
    }

    getUserList() {
        let outer = this;
        let url = 'bo/int/master/cust/list';
        if (this.props.token.token.role == "Penatua") {
            url = 'bo/penatua/sheep/list';
        };
        axios.post(url, outer.state.pageUser).then(({ data }) => {
            if (data.sta && data.stacod === 200) {
                outer.setState({
                    users: data.data.list
                });
            } else {
                outer.showDialog('Warning', data.msg, "");
            }
        }).catch(function (error) {
            outer.showDialog('Warning', Errormsg["500"], "");
        });
    }

    inputChangeFT(val) {
        this.setState({
            pageUser: {
                ...this.state.pageUser,
                keyword: val
            }
        }, () => {
            this.getUserList();
        })
    }

    getRealReport = () => {
        let outer = this;
        this.setState({
            loadingTrigger: true
        });

        this.setState({
            loading: true
        }, () => {
            axios.post('/bo/int/report/taskp', outer.state.page).then(({ data }) => {

                this.setState({
                    loadingTrigger: false
                });
                if (data.sta && data.stacod === 200) {
                    if (data.data.list.length > 0) {
                        this.setState({
                            pagevw: {
                                w1d1: data.data.list[0].w1d1.datevw,
                                w1d2: data.data.list[0].w1d2.datevw,
                                w1d3: data.data.list[0].w1d3.datevw,
                                w1d4: data.data.list[0].w1d4.datevw,
                                w2d1: data.data.list[0].w2d1.datevw,
                                w2d2: data.data.list[0].w2d2.datevw,
                                w2d3: data.data.list[0].w2d3.datevw,
                                w2d4: data.data.list[0].w2d4.datevw,
                                w3d1: data.data.list[0].w3d1.datevw,
                                w3d2: data.data.list[0].w3d2.datevw,
                                w3d3: data.data.list[0].w3d3.datevw,
                                w3d4: data.data.list[0].w3d4.datevw
                            }
                        })
                    }
                    this.setState({
                        realListTotal: data.data.total,
                        realList: data.data.list
                    })
                } else {
                    outer.showDialog('Warning', data.msg, "");
                }
                this.setState({ loading: false });
            }).catch(function (error) {
                outer.showDialog('Warning', Errormsg["500"], "");
                outer.setState({ loading: false });
            });
        })
    }

    changeDate = (date) => {
        let momentParse = moment(date).format("YYYY-MM-DD");
        this.setState({
            page: {
                ...this.state.page,
                datePar: date,
                date: momentParse,
                keyword: ''
            },
            realList: []
        }, () => {
            this.getRealReport();
        })
    }

    search = (val) => {
        this.setState({
            page: {
                ...this.state.page,
                keyword: val.target.value
            }
        }, () => {
            this.getRealReport();
        })
    }

    onChangeRows(val) {
        let outer = this;
        this.setState({
            page: {
                ...this.state.page,
                page: 1,
                count: val
            },
            resetPage: true
        }, () => {
            outer.setState({ resetPage: false });
            outer.getRealReport();
        })
    }

    onChangePage(val) {
        let outer = this;
        this.setState({
            page: {
                ...this.state.page,
                page: val
            }
        }, () => {
            outer.getRealReport();
        })

    }

    convertArrayOfObjectsToCSV = (array) => {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);

        result = '';
        keys.forEach(key => {
            switch (key) {
                case 'name':
                    result += ',Nama';
                    break;
                case 'saintid':
                    result += 'Saints ID';
                    break;

                default:
                    result += columnDelimiter + array[0][key].datevw.replace(',', '');
                    break;
            }
            // if (key === 'name') {
            // } else {
            // }
        });
        // result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (key === 'name' || key === 'saintid') {
                    if (ctr > 0) result += columnDelimiter;
                    result += item[key];
                } else {
                    if (ctr > 0) result += columnDelimiter;
                    result += item[key].task;
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    downloadCSV = () => {
        //get all report
        let outer = this;
        this.setState({
            disableBtnExport: true,
            loading: true
        }, () => {
            let page = {
                date: outer.state.page.date,
                keyword: '',
                page: 1,
                count: 10,
                all: true
            }
            axios.post('/bo/int/report/taskp', page).then(({ data }) => {
                if (data.sta && data.stacod === 200) {
                    if (data.data.list.length > 0) {
                        let array = data.data.list;
                        if (array.length > 0) {
                            const link = document.createElement('a');
                            let csv = this.convertArrayOfObjectsToCSV(array);
                            if (csv == null) return;

                            const filename = 'PengumpulanTugas ' + moment(array[0].w3d1.date).format('DD-MM-YYYY') + ' s/d ' + moment(array[0].w1d4.date).format('DD-MM-YYYY') + '.csv';

                            if (!csv.match(/^data:text\/csv/i)) {
                                csv = `data:text/csv;charset=utf-8,${csv}`;
                            }

                            link.setAttribute('href', encodeURI(csv));
                            link.setAttribute('download', filename);
                            link.click();
                        }
                        outer.setState({ disableBtnExport: false, loading: false });
                    }
                } else {
                    outer.setState({ disableBtnExport: false, loading: false });
                    outer.showDialog('Warning', data.msg, "");
                }
                this.setState({ loading: false });
            }).catch(function (error) {
                outer.showDialog('Warning', Errormsg["500"], "");
                outer.setState({ disableBtnExport: false, loading: false });
            });
        })
    }


    render() {
        const { theme } = this.props;
        const { realList, users, locallist } = this.state;

        return (
            <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
                <div style={{ paddingTop: 10 }}>
                    {this.state.page.type == 'weekly' ?
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href='/bo/dashboard'>
                            Laporan Mingguan
                            </Link>
                            <Link color="textPrimary"
                            // href="/bo/master/user/list" 
                            href='/bo/report/vital/list'>
                        Laporan Pengumpulan Tugas
                            </Link>            
                        </Breadcrumbs>
                        :
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href='/bo/dashboard'>
                            Laporan Mingguan
                            </Link>
                            <Link color="textPrimary"
                            // href="/bo/master/user/list" 
                            href='/bo/report/vital/list'>
                                Laporan Pengumpulan Tugas
                            </Link>            
                        </Breadcrumbs>
                    }
                </div>
                <div className="filter">
                    <div style={{ marginBottom: 10 }}>
                        <Autocomplete
                            options={locallist}
                            getOptionLabel={option => option.name}
                            value={this.state.selectedLocal}
                            onChange={this.changeLocal}
                            onFocus={(e)=>{this.inputChangeLocal('')}}
                            onInputChange={(event, val) => this.inputChangeLocal(val)}
                            renderInput={
                                param => (
                                    <TextField
                                        {...param}
                                        id="outlined-basic"
                                        // variant="outlined" 
                                        label="Lokal"
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )
                            }
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                        <KeyboardDatePicker
                            clearable
                            value={this.state.page.datePar}
                            // placeholder="Start"
                            label="Tanggal"
                            inputVariant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={date => this.changeDate(date)}
                            format="DD-MM-YYYY"
                            style={{ paddingRight: 5 }}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                        <FormControl
                            style={{
                                minWidth: '100%'
                            }}
                        >
                            <TextField
                                id="outlined-basic"
                                label="Search FT"
                                variant="outlined"
                                size="small"
                                value={this.state.page.keyword}
                                style={{ margin: '5px' }}
                                onChange={(val) => this.search(val)}
                            />
                        </FormControl>
                    </div>
                    <div>
                        <Button onClick={() => this.downloadCSV()} color="primary"
                            disabled={this.state.disableBtnExport}
                            variant="contained" fullWidth>Export to CSV</Button>
                    </div>

                </div>
                <DataTable
                    columns={[
                        {
                            name: "Saints ID",
                            selector: 'saintid',
                        },
                        {
                            name: "Name",
                            selector: 'name',
                            sortable: true,
                            width: '180px'
                        },
                        {
                            name: this.state.pagevw.w1d1,
                            selector: 'w1d1.task',
                            center: true
                        },
                        {
                            name: this.state.pagevw.w1d2,
                            selector: 'w1d2.task',
                            center: true
                        },
                        {
                            name: this.state.pagevw.w1d3,
                            selector: 'w1d3.task',
                            center: true
                        },
                        {
                            name: this.state.pagevw.w1d4,
                            selector: 'w1d4.task',
                            center: true
                        },

                        {
                            name: this.state.pagevw.w2d1,
                            selector: 'w2d1.task',
                            center: true
                        },
                        {
                            name: this.state.pagevw.w2d2,
                            selector: 'w2d2.task',
                            center: true
                        },
                        {
                            name: this.state.pagevw.w2d3,
                            selector: 'w2d3.task',
                            center: true
                        },
                        {
                            name: this.state.pagevw.w2d4,
                            selector: 'w2d4.task',
                            center: true
                        },

                        {
                            name: this.state.pagevw.w3d1,
                            selector: 'w3d1.task',
                            center: true
                        },
                        {
                            name: this.state.pagevw.w3d2,
                            selector: 'w3d2.task',
                            center: true
                        },
                        {
                            name: this.state.pagevw.w3d3,
                            selector: 'w3d3.task',
                            center: true
                        },
                        {
                            name: this.state.pagevw.w3d4,
                            selector: 'w3d4.task',
                            center: true
                        },

                    ]}
                    data={realList}
                    noDataComponent={
                        <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                            <CardMedia style={{ textAlign: 'center' }}>
                                <MoodBad color="action" style={{
                                    fontSize: 40,
                                    marginTop: 10,
                                    marginBottom: 10
                                }} />
                            </CardMedia>
                            <CardContent>
                                <Typography>
                                    Data tidak tersedia
                                </Typography>
                            </CardContent>
                        </Card>
                    }
                    highlightOnHover
                    noHeader
                    customStyles={customStyles}
                    pagination={true}
                    paginationRowsPerPageOptions={[100,250,500]}
                    paginationServer
                    paginationTotalRows={this.state.realListTotal}
                    paginationPerPage={this.state.page.count}
                    paginationResetDefaultPage={this.state.resetPage}
                    onChangePage={(val) => this.onChangePage(val)}
                    onChangeRowsPerPage={(val) => this.onChangeRows(val)}
                    progressPending={ this.state.loadingTrigger}
                    progressComponent={
                    <Loader type="TailSpin" color="#204196" height={100} width={100}/>
                    }
                />
                <DialogBasic
                    open={this.state.dialogOpen}
                    title={this.state.dialogTitle}
                    content={this.state.dialogContent}
                    handleClose={this.handleDialogClose}
                    handleOk={this.handleDialogOk}
                />

            </div>
        );
    }
}

pengumpulanTugas.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
    return {
        token: state.token
    };
};

const dispatchToProps = dispatch => {
    return {
        onAddParam: param => dispatch(addParam(param))
    };
};

export default connect(
    mapStatestoProps,
    dispatchToProps
)(withStyles(styles)(withTheme(pengumpulanTugas)));