import React from 'react';
import {Card, CssBaseline, Grid, Paper, 
  Typography, Chip, CardContent} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import axios from '../config/axios';
import DialogBasic from '../component/dialogbasic';
import {
  AccountCircleRounded, TrackChangesRounded
} from '@material-ui/icons'
import MUIDataTable from "mui-datatables";
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../config/errormsg";
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import DataTable from 'react-data-table-component';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

const columns = [
  {
    name: "Tanggal",
    options: {
      filter: true
    }
  },
  {
    name: "Nama",
    options: {
      filter: true
    }
  },
  {
    name: "Email",
    options: {
      filter: true
    }
  },
  {
    name: "Kota",
    options: {
      filter: true
    }
  },
  {
    name: "UTBK",
    options: {
      filter: true
    }
  }   
];

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions:[10,25,100],
  print:false,
  download:false,
  selectableRows:'single',
  isRowSelectable:function(){return false}
};

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

class DbHomeFt extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        dashData: {
          performcount: [],
          skipcount: [],
          realtimecount: [],
          percentcount: []
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        visibleloader:false,
    };
  }

  columns = [
    {
      name: "Tipe Pelayanan",
      selector: 'servicetypename'
    },
    {
      name: "Total Waktu",
      selector: 'totaltime'
    }
  ];

  componentDidMount() {
    this.getDashboard();
  }

  getDashboard() {
    let outer = this;
    axios.post('bo/ft/user/dashboard', {}).then(({data}) => {
      if(data.sta && data.stacod === 200) {
        outer.setState({
          dashData: {
            ...outer.state.dashData,
            performcount: data.data.performcount,
            skipcount: data.data.skipcount,
            realtimecount: data.data.realtimecount,
            percentcount: data.data.percentcount
          }
        })
      } else {
        outer.showDialog("warning", data.msg);
      }
    }).catch(function (error) {
      outer.showDialog("warning", Errormsg["500"], "");
    })
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {

      this.setState({dialogOpen:false});
    }
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  render(){
    const { classes } = this.props;
    const { dashData} = this.state;
    const title = "Dashboard Admin"

    return (
      <div>
        <CssBaseline />
        <div className={classes.paper}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography component="h5" variant="h5">
                Dashboard Fulltimer
              </Typography>
              
              <div style={{textAlign:'left'}}>
                  <Loader
                      visible={this.state.visibleloader}
                      type="ThreeDots"
                      color="#204196"
                      height={45}
                      width={45}
                  />
              </div>
              {
                dashData.performcount.length > 0 &&
                <Paper className={classes.root}>
                      <div
                        style={{
                          textAlign: 'center',
                          marginTop: 10,
                          marginBottom: 10
                        }}
                      >
                        <Typography
                          component="h5"
                          variant="h5"
                        >
                          Total Kegiatan yang Dilakukan
                        </Typography>
                      </div>
                    <div
                      style={{
                        width: '100%',
                        height: 300
                      }}
                    >

                      <ResponsiveContainer>
                          <AreaChart data={dashData.performcount}
                            margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
                            <XAxis dataKey="servicetypename" />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Area type="monotone" dataKey="total" stroke="#8884d8" fill="#8884d8" />
                          </AreaChart>
                      </ResponsiveContainer>
                    </div>
                </Paper>
              }
              <br/>
              {
                dashData.skipcount.length > 0 &&
                <Paper className={classes.root}>
                      <div
                        style={{
                          textAlign: 'center',
                          marginTop: 10,
                          marginBottom: 10
                        }}
                      >
                        <Typography
                          component="h5"
                          variant="h5"
                        >
                          Total Kegiatan yang Dilewati
                        </Typography>
                      </div>
                    <div
                      style={{
                        width: '100%',
                        height: 300
                      }}
                    >

                      <ResponsiveContainer>
                          <AreaChart data={dashData.skipcount}
                            margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
                            <XAxis dataKey="servicetypename" />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Area type="monotone" dataKey="total" stroke="#8884d8" fill="#8884d8" />
                          </AreaChart>
                      </ResponsiveContainer>
                    </div>
                </Paper>
              }
              
              <br/>
              {
                dashData.percentcount.length > 0 &&
                <Paper className={classes.root}>
                      <div
                        style={{
                          textAlign: 'center',
                          marginTop: 10,
                          marginBottom: 10
                        }}
                      >
                        <Typography
                          component="h5"
                          variant="h5"
                        >
                          Persentase kegiatan
                        </Typography>
                      </div>
                    <div
                      style={{
                        width: '100%',
                        height: 300
                      }}
                    >
                      <ResponsiveContainer>
                          <AreaChart data={dashData.percentcount}
                            margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
                            <XAxis dataKey="servicetypename" />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Area type="monotone" dataKey="total" stroke="#8884d8" fill="#8884d8" />
                          </AreaChart>
                      </ResponsiveContainer>
                    </div>
                </Paper>
              }
              <br/>
              {
                dashData.realtimecount.length > 0 &&
                <Paper className={classes.root}>
                     
                    <div>
                      <DataTable
                        title="Total Waktu Nyata"
                        columns={this.columns}
                        data={dashData.realtimecount}
                        customStyles={customStyles}
                      />
                    </div>
                </Paper>
              }
            </Grid>
          </Grid>
        </div>
  
        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );

    // return (
    //   <DashboardAdmin title={title} content={content} />
    // );
  }
}

DbHomeFt.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DbHomeFt);