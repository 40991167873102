import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import { ValidateEmail } from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";

const queryString = require('query-string');

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername: {
    color: '#204196',
    fontWeight: 'bold'
  },
  labelerror: {
    color: '#ff1744',
    fontSize: 10,
    paddingTop: '5px'
  },
  konfirmasi: {
    paddingTop: '10px'
  },
  copyright: {
    paddingBottom: '10px'
  }
});

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

class DbMasterLokalEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        id: "",
        name: "",
        code: ""
        // desc:""

      },
      error: {
        id: "",
        name: "",
        code: ""
      },
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      disableBtnSubmit: false,
      mode: 'add',
      filtertable:{},
    };
  }

  componentDidMount() {
    // // console.log("user ", this.props.param);
    let qparam = queryString.parse(this.props.location.search);
    let filter= this.props.location.state.filter;
    this.setState({filtertable:filter});
    // // console.log("qparam ",qparam);
    if (qparam && qparam.i) {
      if (this.props.param != null) {
        let lokal = this.props.param.detail;
        this.setState({
          form: {
            id: lokal.id,
            name: lokal.name,
            code: lokal.code,
          },
          mode: 'edit'
        })
      } else {
        //get detail
        this.getLokalDetail(qparam.i);
      }
    }
    // if(isEmpty(this.props.param) != true){
    //   let typ = this.props.param.detail;
    //   this.setState({
    //     form:{
    //       id:typ.id,
    //       name:typ.name,
    //       desc:typ.desc,
    //       target:typ.target,
    //       married:typ.married,
    //       child:typ.child,
    //     },
    //     mode:'edit'
    //   });
    // }
  }

  getLokalDetail = (id) => {
    let outer = this;
    axios.post('bo/int/master/local', { id: id }).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        let typ = data.data;
        this.setState({
          form: {
            id: typ.id,
            name: typ.name,
            code: typ.code,
          },
          mode: 'edit'
        })
        // outer.setState({userList:data.data});
      }
    }).catch(function (error) {
      outer.setState({
        disableBtnSubmit: false
      });
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      if (this.state.dialogTitle != "Information") {
        this.setState({ dialogOpen: false });
      } else {
        this.setState({
          dialogOpen: false
        }, () => {
          // this.props.history.push('/bo/master/lokal/list');
          this.backToList();
          this.props.deselectParam();
        })
      }
    }
  }

  backToList=()=>{
    this.props.history.push({ pathname: "/bo/master/lokal/list", filter:this.state.filtertable });
  }

  showDialog = (title, content) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content
    });
  }

  changeFocus = (e) => {
    let { form } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    if (inputValue == "") {
      form[inputName] = 0;
    } else {
      form[inputName] = inputValue;
    }

    this.setState({
      form: form
    })
  }

  handleChange = (e) => {
    let outer = this;
    let { form, error } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if (inputName === "accept") {
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if (newAccept === true) {
        error[inputName] = ""
      }
    }

    this.setState({
      form: form,
      error: error
    });
  }

  handleValidation = (e) => {
    let { form, error, mode } = this.state;

    if (form.name.trim().length === 0) {
      error.name = "Harap isi nama lokal";
    } else {
      error.name = "";
    }

    if (form.code.trim().length === 0) {
      error.code = "Harap isi kode lokal";
    } else {
      error.code = "";
    }

    if (!this.isValidForm()) {
      form.accept = false;
      error.accept = "";
    }

    this.setState({
      form: form,
      error: error
    }, () => {

      if (!this.isValidForm()) {
        return;
      }

      this.handleSubmit();

    });

  }

  handleSubmit = () => {
    let { form } = this.state;
    const outer = this;
    this.setState({
      disableBtnSubmit: true
    }, () => {
      if (this.state.mode == "add") {

        axios.post('bo/int/master/local/add', form
        ).then(({ data }) => {
          if (data.sta && data.stacod === 200) {
            outer.setState({
              form: {
                name: "",
                code: ""
              },
              error: {
                name: "",
                code: ""
              },
              disableBtnSubmit: false
            }, () => {
              outer.showDialog('Information', "Lokal berhasil ditambahkan");
            });

          } else {
            outer.setState({
              disableBtnSubmit: false
            });
            outer.showDialog('Warning', data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', Errormsg["500"]);
        });
      } else {
        axios.post('bo/int/master/local/edit', form
        ).then(({ data }) => {
          if (data.sta && data.stacod === 200) {
            outer.setState({
              disableBtnSubmit: false
            }, () => {
              outer.showDialog('Information', "Lokal berhasil diubah");
            });

          } else {
            outer.setState({
              disableBtnSubmit: false
            });
            outer.showDialog('Warning', data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', Errormsg["500"]);
        });
      }
    });

  }

  isValidForm = () => {
    return (
      !this.state.error.name &&
      !this.state.error.code )
  }





  render() {
    const { classes, theme } = this.props;
    const { error, penatuas, fulltimers } = this.state;
    const title = "Profile"
    return (
      <div style={{ marginTop: theme.spacing(4) }}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href='/bo/dashboard'>
              Master
            </Link>
            <Link color="inherit" 
            // href="/bo/master/lokal/list"
            href='#'
            onClick={()=>{this.backToList()}}
            >
              Lokal
            </Link>
            <Link color="textPrimary" href="/bo/master/lokal/edit">
              {
                this.state.mode == "add" ?
                  "Tambah Lokal" : "Ubah Lokal"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    name="name"
                    label="Nama"
                    value={this.state.form.name}
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.name ? true : false}
                  />
                  {
                    error.name &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.name}
                    />
                  }
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="code"
                    name="code"
                    label="Kode"
                    value={this.state.form.code}
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.code ? true : false}
                  />
                  {
                    error.code &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.code}
                    />
                  }
                </Grid>
              </Grid>
              <Button
                type="button"
                className={classes.submit}
                color="primary"
                variant="contained"
                fullWidth
                onClick={this.handleValidation}
                disabled={this.state.disableBtnSubmit}
              >
                UPDATE
                  </Button>
            </form>
          </div>
        </Container>

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
      </div>
    );
  }
}

DbMasterLokalEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  // console.log("state", state)
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, { deselectParam }
)(withStyles(styles)(withTheme(DbMasterLokalEdit)));