import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

export default function DialogDetail(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      disableBackdropClick
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
          <Grid container spacing={3}>
            {props.content.map((content)=>{
              if(content.view){
                return (
                  <>
                    <Grid key={content.id} item xs={3} sm={3} md={3}>
                      {content.name}
                    </Grid>
                    <Grid key={content.id} item xs={9} sm={9} md={9}>
                      {content.value}
                    </Grid>
                  </>
                )  
              }
            })}
          </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleOk} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}