import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
  IconButton, TextField, Paper, Card, FormControl,
  CardMedia, CardContent, Typography, Select, InputLabel, MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
//import actions
import { addParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete: {
    color: '#204196',
    fontWeight: 'bold'
  },
  incomplete: {
    color: '#ff1744',
    fontWeight: 'bold'
  }
});

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions: [10, 25, 100],
  print: false,
  download: false,
  selectableRows: 'single',
  isRowSelectable: function () { return false }
};

class DbReportNPSchedule extends React.Component {
  constructor(props) {
    super(props);
    let dat = new Date();
    dat.setDate(dat.getDate() - 7);
    this.state = {
      setdate: "",
      page: {
        userid: ""
      },
      pageUser: {
        keyword: "",
        page: 1,
        results: 10
      },
      users: [],
      korp: {},
      lewihall: {},
      lewiks: {},
      transport: {},
      lainnya: {},
      penuntutan: {},
      localparam: {
        keyword: '',
        page: 1,
        count: 10
      },
      locallist: [],
      selectedLocal: {},
      loadingTrigger:false,
      // kolamList: [],
      // pelayanankhususList: [],
      usrobject: null,
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      dialogMode: '',
      detailOpen: false,
    }
  }

  columns = [

    {
      name: <IntlMessages id="report.npschedule.day" />,
      selector: 'day',
      width: '100px'
    },
    {
      name: <IntlMessages id="report.npschedule.waktu" />,
      selector: 'waktu',
      width: '130px'
    },
    {
      name: <IntlMessages id="report.npschedule.title" />,
      selector: 'title',
    },
    {
      name: <IntlMessages id="report.npschedule.desc" />,
      selector: 'desc'
    },
    {
      name: <IntlMessages id="report.npschedule.totalhour" />,
      selector: 'jumlahjam',
      center: true,
      width: '80px',
      format: row => Math.round((row.jumlahjam + Number.EPSILON) * 100) / 100
    }
  ];

  componentDidMount() {
    this.getUserList();
    this.getLocal();
  }

  getLocal() {
    let outer = this;
    axios.post('bo/int/master/local/list', outer.state.localparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          locallist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  inputChangeLocal(val) {
    this.setState({
      localparam: {
        ...this.state.localparam,
        keyword: val
      }
    }, () => {
      this.getLocal();
    })
  }

  changeLocal = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedLocal: val,
        pageUser: {
          ...this.state.pageUser,
          lokalid: val.id,
        }
      }, () => {
        outer.getUserList();
      });
    } else {
      this.setState({
        selectedLocal: {},
        pageUser: {
          ...this.state.pageUser,
          lokalid: "",
        }
      }, () => {
        outer.getUserList();
      });
    }
    this.inputChangeLocal('');
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen: false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      this.setState({ dialogOpen: false });
    }
  }

  getUserList() {
    let outer = this;
    this.setState({
      loadingTrigger:true
    });
    let url = 'bo/int/master/cust/list';
    if (this.props.token.token.role == "Penatua") {
      url = 'bo/penatua/sheep/list';
    };
    axios.post(url, outer.state.pageUser).then(({ data }) => {
      this.setState({
        loadingTrigger:false
      });
      if (data.sta && data.stacod === 200) {
        outer.setState({
          users: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changeUser = (event, val) => {
    if (val) {
      this.setState({
        usrobject: val,
        page: {
          ...this.state.page,
          userid: val.id
        }
      }, () => {
        this.getNonshReport();
      })
    }
  }

  inputChangeFT(val) {
    this.setState({
      pageUser: {
        ...this.state.pageUser,
        keyword: val
      }
    }, () => {
      this.getUserList();
    })
  }

  getNonshReport = () => {
    let outer = this;
    axios.post('bo/int/report/schedule/nonshepherding/detail', outer.state.page).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        this.setState({
          korp: data.data.korporat,
          lewihall: data.data.lewihall,
          lewiks: data.data.lewiks,
          transport: data.data.transport,
          lainnya: data.data.lainnya,
          penuntutan: data.data.penuntutan,
        })
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  closeDate() {
    this.getNonshReport()
  }


  render() {
    const { theme } = this.props;
    const { korp, lewihall, lewiks,
      transport, lainnya, penuntutan, users, locallist } = this.state;

    return (
      <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
        <div style={{ paddingTop: 10 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Rekap Jadwal FT
            </Link>
            <Link color="textPrimary"
            // href="/bo/master/user/list" 
            href='/bo/report/nonshepherd/schedule'>
              Rekap Jadwal Non Penggembalaan
            </Link>            
          </Breadcrumbs>
        </div>
        <div className="filter">
          <div style={{ marginBottom: 10 }}>
            <Autocomplete
              options={locallist}
              getOptionLabel={option => option.name}
              value={this.state.selectedLocal}
              onChange={this.changeLocal}
              onFocus={(e)=>{this.inputChangeLocal('')}}
              onInputChange={(event, val) => this.inputChangeLocal(val)}
              renderInput={
                param => (
                  <TextField
                    {...param}
                    id="outlined-basic"
                    // variant="outlined" 
                    label="Lokal"
                    size="small"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
              }
            />
          </div>
          <div
            style={{
              marginBottom: 10
            }}
          >

            <Autocomplete
              options={users}
              getOptionLabel={option => (option.firstname)}
              value={this.state.usrobject}
              onChange={this.changeUser}
              style={{ width: 200 }}
              onFocus={(e)=>{this.inputChangeFT('')}}
              onInputChange={(event, val) => this.inputChangeFT(val)}
              renderInput={
                param => (
                  <TextField
                    {...param}
                    id="usrauto"
                    label="Pilih Fulltimer"
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
              }
            />
          </div>
        </div>
        <>
          <DataTable
            title="Korporat"
            columns={this.columns}
            data={korp.schedulelist}
            noDataComponent={
              <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                <CardMedia style={{ textAlign: 'center' }}>
                  <MoodBad color="action" style={{
                    fontSize: 40,
                    marginTop: 10,
                    marginBottom: 10
                  }} />
                </CardMedia>
                <CardContent>
                  <Typography>
                    Data tidak tersedia
                  </Typography>
                </CardContent>
              </Card>
            }
            highlightOnHover
            subHeader
            subHeaderAlign={'left'}
            subHeaderComponent={
              (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h3>Target : {korp.total_target} Jam | Total Waktu di Jadwal : {korp.total_waktu ? Math.round((korp.total_waktu + Number.EPSILON) * 100) / 100 : ""} Jam</h3>
                </div>
              )
            }
            customStyles={customStyles}
            pagination={false}
            progressPending={ this.state.loadingTrigger}
            progressComponent={
            <Loader type="TailSpin" color="#204196" height={100} width={100}/>
            }
          />
          <DataTable
            title="Lewi Hall"
            columns={this.columns}
            data={lewihall.schedulelist}
            noDataComponent={
              <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                <CardMedia style={{ textAlign: 'center' }}>
                  <MoodBad color="action" style={{
                    fontSize: 40,
                    marginTop: 10,
                    marginBottom: 10
                  }} />
                </CardMedia>
                <CardContent>
                  <Typography>
                    Data tidak tersedia
                  </Typography>
                </CardContent>
              </Card>
            }
            subHeader
            subHeaderAlign={'left'}
            subHeaderComponent={
              (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h3>Target : {lewihall.total_target} Jam | Total Waktu di Jadwal : {lewihall.total_waktu ? Math.round((lewihall.total_waktu + Number.EPSILON) * 100) / 100 : ""} Jam</h3>
                </div>
              )
            }
            highlightOnHover
            customStyles={customStyles}
            pagination={false}
            progressPending={ this.state.loadingTrigger}
            progressComponent={
            <Loader type="TailSpin" color="#204196" height={100} width={100}/>
            }
          />
          <DataTable
            title="Lewi Kaum Saleh"
            columns={this.columns}
            data={lewiks.schedulelist}
            noDataComponent={
              <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                <CardMedia style={{ textAlign: 'center' }}>
                  <MoodBad color="action" style={{
                    fontSize: 40,
                    marginTop: 10,
                    marginBottom: 10
                  }} />
                </CardMedia>
                <CardContent>
                  <Typography>
                    Data tidak tersedia
                  </Typography>
                </CardContent>
              </Card>
            }
            subHeader
            subHeaderAlign={'left'}
            subHeaderComponent={
              (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h3>Target : {lewiks.total_target} Jam | Total Waktu di Jadwal : {lewiks.total_waktu ? Math.round((lewiks.total_waktu + Number.EPSILON) * 100) / 100 : ""} Jam</h3>
                </div>
              )
            }
            highlightOnHover
            customStyles={customStyles}
            pagination={false}
            progressPending={ this.state.loadingTrigger}
            progressComponent={
            <Loader type="TailSpin" color="#204196" height={100} width={100}/>
            }
          />
          <DataTable
            title="Transportasi"
            columns={this.columns}
            data={transport.schedulelist}
            noDataComponent={
              <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                <CardMedia style={{ textAlign: 'center' }}>
                  <MoodBad color="action" style={{
                    fontSize: 40,
                    marginTop: 10,
                    marginBottom: 10
                  }} />
                </CardMedia>
                <CardContent>
                  <Typography>
                    Data tidak tersedia
                  </Typography>
                </CardContent>
              </Card>
            }
            subHeader
            subHeaderAlign={'left'}
            subHeaderComponent={
              (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h3>Target : {transport.total_target} Jam | Total Waktu di Jadwal : {transport.total_waktu ? Math.round((transport.total_waktu + Number.EPSILON) * 100) / 100 : ""} Jam</h3>
                </div>
              )
            }
            highlightOnHover
            customStyles={customStyles}
            pagination={false}
            progressPending={ this.state.loadingTrigger}
            progressComponent={
            <Loader type="TailSpin" color="#204196" height={100} width={100}/>
            }
          />
          <DataTable
            title="Lainnya"
            columns={this.columns}
            data={lainnya.schedulelist}
            noDataComponent={
              <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                <CardMedia style={{ textAlign: 'center' }}>
                  <MoodBad color="action" style={{
                    fontSize: 40,
                    marginTop: 10,
                    marginBottom: 10
                  }} />
                </CardMedia>
                <CardContent>
                  <Typography>
                    Data tidak tersedia
                  </Typography>
                </CardContent>
              </Card>
            }
            subHeader
            subHeaderAlign={'left'}
            subHeaderComponent={
              (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h3>Target : {lainnya.total_target} Jam | Total Waktu di Jadwal : {lainnya.total_waktu ? Math.round((lainnya.total_waktu + Number.EPSILON) * 100) / 100 : ""} Jam</h3>
                </div>
              )
            }
            highlightOnHover
            customStyles={customStyles}
            pagination={false}
            progressPending={ this.state.loadingTrigger}
            progressComponent={
            <Loader type="TailSpin" color="#204196" height={100} width={100}/>
            }
          />
          <DataTable
            title="Penuntutan"
            columns={this.columns}
            data={penuntutan.schedulelist}
            noDataComponent={
              <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                <CardMedia style={{ textAlign: 'center' }}>
                  <MoodBad color="action" style={{
                    fontSize: 40,
                    marginTop: 10,
                    marginBottom: 10
                  }} />
                </CardMedia>
                <CardContent>
                  <Typography>
                    Data tidak tersedia
                  </Typography>
                </CardContent>
              </Card>
            }
            subHeader
            subHeaderAlign={'left'}
            subHeaderComponent={
              (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h3>Target : {penuntutan.total_target} Jam | Total Waktu di Jadwal : {penuntutan.total_waktu ? Math.round((penuntutan.total_waktu + Number.EPSILON) * 100) / 100 : ""} Jam</h3>
                </div>
              )
            }
            highlightOnHover
            customStyles={customStyles}
            pagination={false}
            progressPending={ this.state.loadingTrigger}
            progressComponent={
            <Loader type="TailSpin" color="#204196" height={100} width={100}/>
            }
          />
        </>

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />

      </div>
    );
  }
}

DbReportNPSchedule.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    token: state.token
  };
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbReportNPSchedule)));