import React from 'react';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

//import actions
import { deselectToken } from "../store/actions/configAction";

class Logout extends React.Component {
  constructor(props){
      super(props);
  }

  componentDidMount(){
    if(this.props.token !== null){
      this.props.deselectToken();
    }
  }

  render(){
    // localStorage.removeItem("uid");
    // localStorage.removeItem("email");
    // localStorage.removeItem("tu");
    // localStorage.removeItem("pict");
    // localStorage.removeItem("tkn");
    // localStorage.removeItem("tknref");
    let path ='/';
    return <Redirect to={path} />
  }
}

const mapStatestoProps = state => {
  return {
    token: state.token
  };
};

export default connect(
  mapStatestoProps,
  {deselectToken}
)(Logout);
