import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
  IconButton, TextField, Checkbox, Card,
  CardMedia, CardContent, Typography, Breadcrumbs, Link
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
//import { Link } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from "react-redux";

import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';

import DataTable from 'react-data-table-component';

//import actions
import { addParam, deselectParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete: {
    color: '#204196',
    fontWeight: 'bold'
  },
  incomplete: {
    color: '#ff1744',
    fontWeight: 'bold'
  }
});

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions: [100, 25, 100],
  print: false,
  download: false,
  selectableRows: 'single',
  isRowSelectable: function () { return false }
};

class DbMasterCluster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        keyword: '',
        page: 1,
        count: 100,
        localid: ''
      },
      localparam: {
        keyword: '',
        page: 1,
        count: 100,
      },
      list: [],
      listTotal: 0,
      dialogOpen: false,
      loadingTrigger: false,
      dialogTitle: '',
      dialogContent: '',
      dialogMode: '',
      detailOpen: false,
      detailTitle: 'Detail Wilayah',
      detailContent: [],
      selectedRows: [],
      locallist: [],
      selectedLocal: {},
      mount:false,
    }
  }

  columns = [
    {
      name: 'Action',
      button: true,
      cell: row => {
        return <div>
          <IconButton color="primary" aria-label="detail" component="span" size="small" style={{ marginRight: 5 }} onClick={() => this.openClusterDetail(row)}>
            <List />
          </IconButton>
          <IconButton color="primary" aria-label="edit" component="span" size="small" onClick={() => this.editCluster(row)}>
            <Edit />
          </IconButton>
        </div>
      },
    },
    {
      name: "Nama Wilayah",
      selector: 'name',
      sortable: true,
    },
    {
      name: "Lokal",
      selector: 'local.name',
      sortable: true,
    }
  ];

  componentDidMount() {
if (this.props.location.filter) {
  this.setState(
    {
      page: this.props.location.filter, 
      selectedLocal:this.props.location.filterAll,
    }, () => {
      this.getClusterList();
    }
  )
} else {
  this.getClusterList();
}
    this.getLocal();
    this.setState({mount:true});
  }

  getLocal() {
    let outer = this;
    axios.post('bo/int/master/local/list', outer.state.localparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          locallist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen: false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({
        dialogOpen: false
      });
      this.props.history.push("/logout");

    } else {
      this.setState({
        dialogOpen: false
      });
      switch (this.state.dialogMode) {
        case "delete":
          this.handleDeleteCluster();
          break;

        default:
          break;
      }
    }
  }

  getClusterList = () => {
    let outer = this;
    this.setState({
      loadingTrigger: true
    });
    axios.post('bo/int/master/cluster/list', outer.state.page).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        // // console.log("data type", data.data.list)
        this.setState({
          list: data.data.list,
          listTotal: data.data.total
        })
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
      this.setState({
        loadingTrigger: false
      });
    }).catch(function (error) {
      outer.setState({
        disableBtnSubmit: false
      });
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  openClusterDetail = (row) => {
    // // console.log(row)
    let detailContent = [];
    Object.keys(row).forEach(function (key) {
      let name = '';
      let seq = 0;
      let view = true;
      switch (key) {
        case 'name':
          name = "Nama Wilayah";
          seq = 1;
          view = true;
          break;
        case 'local':
          name = "Lokal";
          seq = 2;
          view = true;
          break;

        default:
          view = false;
          break;
      }
      // // console.log("key", key);
      if (key != 'local') {
        let content = { id: key, name: name, value: row[key], seq: seq, view: view }
        detailContent.push(content);
      } else {
        let content = { id: key, name: name, value: row[key].name, seq: seq, view: view }
        detailContent.push(content);
      }
      // // console.log(key, row[key]);

    });
    detailContent.sort(compareSequence);
    // console.log(detailContent);
    this.setState({
      detailOpen: true,
      detailContent
    });

  }

  editCluster = (row) => {
    this.props.onAddParam({ detail: row });
    this.props.history.push({ pathname: "/bo/master/cluster/edit", search: "?i=" + row.id,state:{ filter:this.state.page, filterAll:this.state.selectedLocal} });
  }

  handleSelectedRows = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    // // console.log('Selected Rows: ', state.selectedRows);
    this.setState({ selectedRows: state.selectedRows });
  };

  deleteCluster = () => {
    let length = this.state.selectedRows.length;
    if (length == 0) {
      this.showDialog("Peringatan", "Pilih salah satu item untuk menghapus", "");
    } else {
      this.showDialog("Hapus tipe", "Anda akan menghapus " + length + " cluster. Apakah anda yakin?", "delete");
    }
  }

  handleDeleteCluster = () => {

    const outer = this;
    const { selectedRows } = this.state;
    let deleteCluster = [];
    selectedRows.forEach((cluster) => {
      deleteCluster.push(cluster.id);
    })

    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/master/cluster/delete', { id: deleteCluster }
      ).then(({ data }) => {
        if (data.sta && data.stacod === 200) {
          outer.setState({
            disableBtnSubmit: false
          }, () => {
            outer.getClusterList();
            outer.showDialog('Information', "Cluster telah berhasil dihapus.", "");
          })

        } else {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', data.msg, "");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"], "");
      });
    });
  }

  search(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page:1,
        keyword: val.target.value
      }
    }, () => {
      outer.getClusterList();
    })
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        count: val
      }
    }, () => {
      outer.getClusterList();
    })
  }

  onChangePage(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getClusterList();
    })
  }

  changeLocal = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedLocal: val,
        page: {
          ...this.state.page,
          page:1,
          localid: val.id
        }
      }, () => { outer.getClusterList() });
    } else {
      this.setState({
        selectedLocal: {},
        page: {
          ...this.state.page,
          page:1,
          localid: "",
        }
      }, () => { outer.getClusterList() });
    }
    this.inputChangeLocal('');
  }

  inputChangeLocal(val) {
    this.setState({
      localparam: {
        ...this.state.localparam,
        keyword: val
      }
    }, () => {
      this.getLocal();
    })
  }

  render() {
    const { classes, container, theme, content, title } = this.props;
    const { list, locallist } = this.state;


    return (
      <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
        <div style={{ paddingTop: 10 }}>
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Master
            </Link>
            <Link color="textPrimary"
            // href="/bo/master/user/list" 
            href='/bo/master/cluster/list'>
              Wilayah
            </Link>            
          </Breadcrumbs>
        </div>
        <div className="filter">
          <Autocomplete
            options={locallist}
            onFocus={(e)=>{this.inputChangeLocal('')}}
            getOptionLabel={option => option.name}
            value={this.state.selectedLocal}
            onChange={this.changeLocal}
            onInputChange={(event, val) => this.inputChangeLocal(val)}
            renderInput={
              param => (
                <TextField
                  {...param}
                  id="outlined-basic"
                  // variant="outlined" 
                  label="Lokal"
                  size="small"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )
            }
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              id="outlined-basic"
              label="Search"
              value={this.state.page.keyword}
              variant="outlined"
              size="small"
              style={{ margin: '5px' }}
              onChange={(val) => this.search(val)}
            />
            <IconButton
              color="primary"
              aria-label="detail"
              component="span"
              onClick={() => {
                this.props.onDeselectParam(); 
                // this.props.history.push("/bo/master/cluster/edit")
                this.props.history.push({ pathname: "/bo/master/cluster/edit",state:{ filter:this.state.page, filterAll:this.state.selectedLocal }});
              }}
            >
              <Add />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="detail"
              component="span"
              onClick={() => { this.deleteCluster() }}
            >
              <Remove />
            </IconButton>
          </div>
        </div>
        {(this.state.mount)&&
        <DataTable
          title="Status"
          columns={this.columns}
          data={list}
          noDataComponent={
            <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid', marginTop: 10 }}>
              <CardMedia style={{ textAlign: 'center' }}>
                <MoodBad color="action" style={{
                  fontSize: 40,
                  marginTop: 10,
                  marginBottom: 10
                }} />
              </CardMedia>
              <CardContent>
                <Typography>
                  Data tidak tersedia
                </Typography>
              </CardContent>
            </Card>

          }
          selectableRows
          selectableRowsComponent={Checkbox}
          highlightOnHover
          // subHeader
          noHeader
          paginationDefaultPage={this.state.page.page}
          customStyles={customStyles}
          onSelectedRowsChange={this.handleSelectedRows}
          pagination
          paginationRowsPerPageOptions={[100,250,500]}
          paginationServer
          paginationTotalRows={this.state.listTotal}
          paginationPerPage={this.state.page.count}
          onChangePage={(val) => this.onChangePage(val)}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          progressPending={this.state.loadingTrigger}
          progressComponent={
            <Loader type="TailSpin" color="#204196" height={100} width={100} />
          }
          subHeaderComponent={
            (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                  size="small"
                  style={{ margin: '5px' }}
                  onChange={(val) => this.search(val)}
                />
                <IconButton
                  color="primary"
                  aria-label="detail"
                  component="span"
                  onClick={() => {
                    this.props.history.push("/bo/master/cluster/edit")
                  }}
                >
                  <Add />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="detail"
                  component="span"
                  onClick={() => { this.deleteCluster() }}
                >
                  <Remove />
                </IconButton>
              </div>
            )
          }
        />
        }
        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          mode={this.state.dialogMode}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
        <DialogDetail
          open={this.state.detailOpen}
          title={this.state.detailTitle}
          content={this.state.detailContent}
          handleClose={this.handleDetailClose}
          handleOk={this.handleDetailClose}
        />
      </div>
    );
  }
}

DbMasterCluster.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param)),
    onDeselectParam: () => dispatch(deselectParam()),
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbMasterCluster)));