import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select,
  MenuItem, Breadcrumbs, Link, Card, CardMedia, CardContent, Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MoodBad, CheckCircle, PanoramaFishEye } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../config/axios';
import DialogBasic from '../../../../component/dialogbasic';
import { ValidateEmail } from '../../../../utility/validation';
import Errormsg from "../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { addParam, deselectParam } from "../../../../store/actions/configAction";
import moment from 'moment';
import queryString from 'query-string';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner'

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../util/IntlMessages";

// const customStyles = {
//   tableWrapper: {
//     style: {
//       display: "inline"
//     }
//   },
//   headCells: {
//     style: {
//       color: '#202124',
//       fontSize: '14px',
//     },
//   },
// };

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername: {
    color: '#204196',
    fontWeight: 'bold'
  },
  labelerror: {
    color: '#ff1744',
    fontSize: 10,
    paddingTop: '5px'
  },
  konfirmasi: {
    paddingTop: '10px'
  },
  copyright: {
    paddingBottom: '10px'
  }
});

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

class DbPenuntutanFT extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        book: "",
        date: "",
        tugas: null,
      },
      error: {
        date: "",
        book: "",
        tugas: ""
      },
      inputkey: new Date(),
      date: new Date(),
      code: "",
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      disableBtnSubmit: false,
      mode: 'add',
      penuntutanList: [],
      loadingTrigger: false,
    };
  }

  componentDidMount() {
    this.getTugasStatus()
  }

  handleChange = (e) => {
    let outer = this;
    let form = this.state.form;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;

    this.setState({
      form: form
    });
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      if (this.state.dialogTitle != "Information") {
        this.setState({ dialogOpen: false });
      } else {
        this.setState({
          dialogOpen: false
        }, () => {
          this.props.history.push('/bo/master/type/list');
          this.props.onDeselectParam();
        })
      }
    }
  }

  showDialog = (title, content) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content
    });
  }

  changeFocus = (e) => {
    let { form } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    if (inputValue == "") {
      form[inputName] = 0;
    } else {
      form[inputName] = inputValue;
    }

    this.setState({
      form: form
    })
  }

  handleValidation = (e) => {
    let { date, error, mode, form } = this.state;

    if (date == null) {
      error.date = <IntlMessages id="export.daily.error.date" />;
    } else if (moment(date).isoWeekday() > 4) {
      error.date = "Hari tanggal harus antara Senin s/d Kamis";
    } else {
      error.date = "";
    }

    if (form.book == "") {
      error.book = "Nama buku harus diisi";
    } else {
      error.book = "";
    }

    if (form.tugas == null) {
      error.tugas = "File tugas harus diisi";
    } else {
      error.tugas = "";
    }

    // if(form.desc.trim().length === 0){
    //   error.desc=<IntlMessages id="master.type.error.desc"/>;
    // }else{
    //   error.desc="";
    // }

    // if(form.married.trim().length === 0){
    //   error.married=<IntlMessages id="master.user.error.married"/>;
    // }else{
    //   error.married="";
    // }


    if (!this.isValidForm()) {
      error.accept = "";
    }

    this.setState({
      error: error
    }, () => {

      if (!this.isValidForm()) {
        return;
      }

      this.handleSubmit();

    });

  }

  handleSubmit = () => {
    let { date } = this.state;
    let parseDate = moment(date).format("YYYY-MM-DD");
    const formData = new FormData();
    formData.append('date', parseDate);
    formData.append('book', this.state.form.book);
    formData.append('tugas', this.state.form.tugas);
    const outer = this;
    this.setState({
      disableBtnSubmit: true
    }, () => {

      axios.post('app/tugas', formData
      ).then(({ data }) => {
        // console.log(data);
        if (data.sta && data.stacod === 200) {
          outer.showDialog("Success", "Tugas telah berhasil diupload");
          outer.setState({ form: { book: '', tugas: null }, inputkey: new Date() });
          outer.getTugasStatus();
        } else {
          outer.showDialog("Warning", data.msg);
        }
      }).catch(function (error) {
        outer.showDialog('Warning', Errormsg["500"]);
      }).finally(() => {
        outer.setState({
          disableBtnSubmit: false
        });
      });
    });

  }

  getTugasStatus = () => {
    const outer = this;
    this.setState({
      disableBtnSubmit: true,
      loadingTrigger: true
    }, () => {
      axios.post('app/user/task/list', {}
      ).then(({ data, headers }) => {
        outer.setState({ penuntutanList: data.data });
        outer.setState({
          loadingTrigger: false
        });
        // // console.log(data);
      }).catch(function (error) {
        outer.showDialog('Warning', Errormsg["500"], "");
      }).finally(() => {
        outer.setState({
          disableBtnSubmit: false
        });
      });
    });

  }

  columns = [

    {
      name: <IntlMessages id="report.task.date" />,
      selector: 'datevw',
      sortable: true,
    },
    {
      name: "Complete",
      selector: 'complete',
      cell: row => <span
        style={
          row.complete ?
            { color: 'green' } : { color: "black" }
        }
      >
        {(row.complete) ?
          <CheckCircle style={{
            fontSize: 20,
          }} />
          :
          <PanoramaFishEye style={{
            fontSize: 20,
          }} />
        }
      </span>
    },
    // {
    //   name: "Status",
    //   selector: 'status',
    // }
  ];

  isValidForm = () => {
    return (
      !this.state.error.date
      && !this.state.error.book
      && !this.state.error.tugas)
  }

  changeDate = (date) => {
    // let momentParse = moment(date).format("YYYY-MM-DD");
    // console.log(date);
    this.setState({
      date: date
    })
  }

  handleFileInput(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        form: {
          ...this.state.form,
          tugas: file
        }
      });
    }

    reader.readAsDataURL(file)
  }

  render() {
    const { classes, theme } = this.props;
    const { error, penuntutanList } = this.state;
    const title = "Laporan Harian"
    return (
      <div style={{ marginTop: theme.spacing(4) }}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit">
              Penuntutan FT
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>


              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    clearable
                    required
                    value={this.state.date}
                    // placeholder="Tanggal"
                    label="Tanggal"
                    inputVariant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    minDate={moment().startOf('isoWeek').subtract(7, 'days')}
                    maxDate={new Date()}
                    onChange={date => this.changeDate(date)}
                    format="DD-MM-YYYY"
                    style={{ paddingRight: 5 }}

                  />
                  {
                    error.date &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.date}
                    />
                  }
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="book"
                    name="book"
                    label={"Nama Buku"}
                    value={this.state.form.book}
                    size="small"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {
                    error.book &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.book}
                    />
                  }
                </Grid>
                <Grid item xs={12}>
                  <label>File: </label>
                  <br />
                  <br />
                  <input
                    type="file"
                    key={this.state.inputkey}
                    onChange={(e) => this.handleFileInput(e)}
                  />
                  {/* {
                    error.tugas &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.tugas}
                    />
                  } */}
                  {(error.tugas != '') &&
                    <div style={{ fontSize: 10, color: 'red', paddingTop: 5 }}>{error.tugas}</div>
                  }
                </Grid>
              </Grid>
              <Button
                type="button"
                className={classes.submit}
                color="primary"
                variant="contained"
                fullWidth
                onClick={this.handleValidation}
                disabled={this.state.disableBtnSubmit}
              >
                Submit
              </Button>
            </form>
            <DataTable
              title="Daftar Penuntutan FT"
              columns={this.columns}
              data={penuntutanList}
              noDataComponent={
                <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                  <CardMedia style={{ textAlign: 'center' }}>
                    <MoodBad color="action" style={{
                      fontSize: 40,
                      marginTop: 10,
                      marginBottom: 10
                    }} />
                  </CardMedia>
                  <CardContent>
                    <Typography>
                      Data tidak tersedia
                    </Typography>
                  </CardContent>
                </Card>
              }
              highlightOnHover
              // customStyles={customStyles}
              pagination={false}
              progressPending={this.state.loadingTrigger}
              progressComponent={
                <Loader type="TailSpin" color="#204196" height={100} width={100} />
              }
            />
          </div>
        </Container>

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
      </div>
    );
  }
}

DbPenuntutanFT.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  // // console.log("state",state)
  return {
    param: state.param
  };
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param)),
    onDeselectParam: () => dispatch(deselectParam()),
  };
};

export default connect(
  mapStatestoProps, dispatchToProps
)(withStyles(styles)(withTheme(DbPenuntutanFT)));