import React from 'react';
import Box from '@material-ui/core/Box';
import {
    Link
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems:'center',
        marginLeft:'18%',
        paddingTop: 5
    }
  });
  
function Dashboardlogo(props){
    const classes = useStyles();
    // // console.log("props dashboard logo", props)
    let link = "/bo/dashboard";
    if(props.role == "Penatua") {
        link = "/bo/dashboardpenatua";
    };
    if(props.role == "User") {
        link = "/bo/dashboardft";
    };
    // if(props.refuser == 'admin'){
    //     link = "/bo/dashadmin";
    // }

    return (
        <div className={classes.container}>
            <Box component="span" m={0.25}>
                <Link to={link}>
                    <img id="logo" src={require("../assets/FTLogo.png")} alt="logo" width="60px" />  
                </Link>
            </Box>
        </div>
    )
}

export default Dashboardlogo