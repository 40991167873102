import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {IconButton, TextField, Checkbox, Card, FormControl, Paper,
  CardMedia ,CardContent, Typography, Select, InputLabel, MenuItem} from '@material-ui/core';
import {MoodBad} from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import {compareSequence} from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DataTable from 'react-data-table-component';
import moment from 'moment';
//import actions
import { addParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

const tableStyle = {
  table: {
    borderCollapse: "collapse",
    width: '100%',
    marginTop: 10
  },
  body: {
    border: 1,
    borderStyle: "solid",
    borderColor: "#dddddd",
    textAlign: 'center',
    padding: 8
  }
}



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions:[10,25,100],
  print:false,
  download:false,
  selectableRows:'single',
  isRowSelectable:function(){return false}
};

class DbReportVitalGroupft extends React.Component {
  constructor(props){
      super(props);
      let dat = new Date();
      dat.setDate(dat.getDate() - 7);
      this.state = {
        setdate : "",
        showdata : "table",
        page:{
          date: moment().format('YYYY-MM-DD'),
          datePar: new Date()
        },
        vitalgroupList:[],
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        dialogMode:'',
        detailOpen:false,
      }
  }

  columns = [
   
    {
      name: <IntlMessages id="report.task.date"/>,
      selector: 'date',
      sortable: true,
      format: row => moment(row.date).format("DD-MM-YYYY")
    },
    {
      name: <IntlMessages id="report.bible.target"/>,
      selector: 'target',
    },
    {
      name: <IntlMessages id="report.bible.real"/>,
      selector: 'realization',
    },
    {
      name: <IntlMessages id="report.bible.percent"/>,
      selector: 'percent',
      cell: row => <span
        style={
          row.percentnum == 0 ?
          {color: 'red'} : row.percentnum > 0 && row.percentnum < 26 ? {color: "pink"} : 
          row.percentnum > 25 && row.percentnum < 51 ? {color: "orange"} : row.percentnum > 50 && row.percentnum < 76 ? {color: 'green'} :
          row.percentnum > 75 && row.percentnum < 101 ? {color: "blue"} : row.percentnum > 100 && {color: "black"}
        }
      >
        {row.percent}
      </span>
    }
  ];

  componentDidMount(){
    this.getVitalGroupReport();
  }

  showDialog = (title,content,mode) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content,
      dialogMode:mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen:false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen:false
    })
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {
      this.setState({dialogOpen:false});
    }
  }
  
  changeType = (val) => {
    if(val) {
      this.setState({
        page: {
          ...this.state.page,
          type: val.target.value
        }
      }, () => {
        this.getVitalGroupReport();
      })
    }
  }


  getVitalGroupReport = () => {
    let outer = this;
    axios.post('bo/ft/report/vitalgroup', outer.state.page).then(({data})=>{
      if(data.sta && data.stacod === 200){
        this.setState({
          vitalgroupList: data.data
        })
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  

  changeStart = (date) => {
    let momentParse = moment(date).format("YYYY-MM-DD");
    this.setState({
      page: {
        ...this.state.page,
        datePar: date,
        date: momentParse,
      }
    }, () => {
      this.getVitalGroupReport();
    })
  }

  

  handleChangeShow = (val) => {
    this.setState({
      showdata: val.target.value
    })
  }

   
  render() {
    const { theme } = this.props;
    const { vitalgroupList} = this.state;
      
    return (
      <div style={{marginTop: theme.spacing(4), height: '100%'}}>
        <div style={{paddingTop:10}}>
        <Typography variant="h5">
          Laporan Kelompok Vital
        </Typography>
        </div>
        <div className="filter">
          

          <div style={{marginBottom: 10}}>
              {/* <InputLabel style={{fontSize: 12}} id="formtype">
                Tanggal 
              </InputLabel> */}
              <KeyboardDatePicker
                clearable
                label="Tanggal"
                inputVariant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.state.page.datePar}
                // placeholder="Tanggal"
                onChange={date => this.changeStart(date)}
                format="DD-MM-YYYY"
                fullWidth
              />
          </div>
          <div>
            <Typography variant="subtitle2">
                * Keterangan Persentase: 
            </Typography>
            <div>
              <span style={{color: 'red'}}>0</span> / <span style={{color: 'pink'}}>0-25%</span> / <span style={{color: 'orange'}}>26%-50%</span> /&nbsp;
              <span style={{color: 'green'}}>51%-75%</span> / <span style={{color: 'blue'}}>76%-100%</span> / <span style={{color: 'black'}}>&gt;100%</span>
            </div>
          </div>
        </div>
        {

          <table style={tableStyle.table}>
            <thead>
              <tr>
                <th style={tableStyle.body}>Target</th>
                <th style={tableStyle.body}>Realisasi</th>
                <th style={tableStyle.body}>Persentase</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableStyle.body}>{vitalgroupList.target}</td>
                <td style={tableStyle.body}>{vitalgroupList.realization}</td>
                <td style={tableStyle.body}>
                  {
                    vitalgroupList.percentnum == 0 ?
                    <span
                      style={
                        {color: 'red'}
                      }
                    >
                      {vitalgroupList.percent}
                    </span> :
                    vitalgroupList.percentnum > 0 && vitalgroupList.percentnum < 26 ?
                    <span
                      style={
                        {color: 'pink'}
                      }
                    >
                      {vitalgroupList.percent}
                    </span> :
                    vitalgroupList.percentnum > 25 && vitalgroupList.percentnum < 51 ?
                    <span
                      style={
                        {color: 'orange'}
                      }
                    >
                      {vitalgroupList.percent}
                    </span> :
                    vitalgroupList.percentnum > 50 && vitalgroupList.percentnum < 76 ?
                    <span
                      style={
                        {color: 'green'}
                      }
                    >
                      {vitalgroupList.percent}
                    </span> :
                    vitalgroupList.percentnum > 75 && vitalgroupList.percentnum < 101 ?
                    <span
                      style={
                        {color: 'blue'}
                      }
                    >
                      {vitalgroupList.percent}
                    </span> :
                    vitalgroupList.percentnum > 100 && 
                    <span
                      style={
                        {color: 'black'}
                      }
                    >
                      {vitalgroupList.percent}
                    </span>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        }
        

          <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
          />  
         
      </div>
    );
  }
}

DbReportVitalGroupft.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbReportVitalGroupft)));