import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, InputLabel,
  Radio, RadioGroup, Button, Select,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import { ValidateEmail } from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";
import { TagFaces } from '@material-ui/icons';

const queryString = require('query-string');

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername: {
    color: '#204196',
    fontWeight: 'bold'
  },
  labelerror: {
    color: '#ff1744',
    fontSize: 10,
    paddingTop: '5px'
  },
  konfirmasi: {
    paddingTop: '10px'
  },
  copyright: {
    paddingBottom: '10px'
  }
});

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

class DbSettingInputEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        userid: "",
        username: "",
        multiplier: 0,

      },
      error: {
        userid: "",
        username: "",
        multiplier: ""
      },
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      disableBtnSubmit: false,
      mode: 'add',
      ftpage: {
        keyword: "",
        count: 10
      },
      fulltimers: [],
      fulltimerobject: null,
      filtertable: {},
      filterAll: {},
    };

    this.changeFulltimer = this.changeFulltimer.bind(this);
  }

  componentDidMount() {
    let outer = this;
    let qparam = queryString.parse(this.props.location.search);
    let filter = this.props.location.state.filter;
    this.setState({ filtertable: filter, filterAll: this.props.location.state.filterAll });
    // // console.log("qparam ",qparam);
    if (qparam && qparam.i) {
      if (this.props.param != null) {
        let input = this.props.param.detail;
        this.setState({
          form: {
            username: input.username,
            userid: input.userid,
            multiplier: input.multiplier
          },
          mode: 'edit'
        }, () => {
          this.getEditFulltimerList();
        })
      } else {
        //get detail
        this.getConvDetail(qparam.i);
      }
    } else {
      // kembali ke list karena tidak menemukan parameter
      // this.props.history.push("/bo/setting/usrdefconv/list");
      this.backToList();
    }
    // if(isEmpty(this.props.param) != true) {
    //   let input = this.props.param.detail;
    //   this.setState({
    //     form: {
    //       username: input.username,
    //       userid: input.userid,
    //       multiplier: input.multiplier
    //     },
    //     mode: 'edit'
    //   }, () => {
    //     this.getEditFulltimerList();
    //   })
    // } else {
    //   // kembali ke list karena tidak menemukan parameter
    //   this.props.history.push("/bo/setting/usrdefconv/list");
    //   // this.getFulltimerList();
    // }
  }

  backToList = () => {
    this.props.history.push({ pathname: "/bo/setting/usrdefconv/list", filter: this.state.filtertable, filterAll: this.state.filterAll });
  }

  getConvDetail = (id) => {
    let outer = this;
    axios.post('bo/int/fttargetconv/detail', { id: id }).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        let input = data.data;
        this.setState({
          form: {
            username: input.username,
            userid: input.userid,
            multiplier: input.multiplier
          },
          mode: 'edit'
        }, () => {
          this.getEditFulltimerList();
        })
        // outer.setState({userList:data.data});
      }
    }).catch(function (error) {
      outer.setState({
        disableBtnSubmit: false
      });
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  inputChangeFulltimer(val) {
    this.setState({
      ftpage: {
        ...this.state.ftpage,
        keyword: val
      }
    }, () => {
      this.getFulltimerList();
    })
  }



  getEditFulltimerList() {
    let outer = this;
    axios.post('bo/int/master/cust', { id: outer.state.form.userid }).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          fulltimerobject: data.data
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  getFulltimerList() {
    let outer = this;
    axios.post('bo/int/master/cust/list', this.state.ftpage).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          fulltimers: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changeFulltimer(event, val) {
    if (val) {

      this.setState({
        fulltimerobject: val,
        form: {
          ...this.state.form,
          username: val.firstname + " " + val.lastname,
          userid: val.id
        }
      })
    } else {
      this.setState({
        fulltimerobject: null,
        form: {
          ...this.state.form,
          username: "",
          userid: ""
        }
      })
    }
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      if (this.state.dialogTitle != "Information") {
        this.setState({ dialogOpen: false });
      } else {
        this.setState({
          dialogOpen: false
        }, () => {
          // this.props.history.push("/bo/setting/usrdefconv/list");
          this.props.deselectParam();
          this.backToList();
        })
      }
    }
  }

  showDialog = (title, content) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content
    });
  }

  changeFocus = (e) => {
    let outer = this;
    let { form, error } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    if (inputValue.trim() === '') {
      form[inputName] = 0;
      this.setState({ form });
    }
  }

  handleChange = (e) => {
    let outer = this;
    let { form, error } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    if (inputValue.trim() != '') {
      const re = /^[0-9.]+$/;
      // const re = /^\$[0-9]+(\.[0-9][0-9])?$/;
      if (inputValue == '' || re.test(inputValue)) {
        form[inputName] = inputValue;
        this.setState({ form });
      }
    } else {
      form[inputName] = '';
      this.setState({ form });
    }
  }

  handleValidation = (e) => {
    let { form, error, mode } = this.state;

    if (form.userid.trim().length === 0) {
      error.userid = <IntlMessages id="setting.input.error.name" />;
    } else {
      error.userid = "";
    }

    let errmultiplier = '';
    if (form.multiplier <= 0) {
      errmultiplier = "Rumus harus lebih besar dari 0";
    } else {
      errmultiplier = "";
    }

    if (errmultiplier == "") {
      if (parseFloat(form.multiplier) == NaN) {
        errmultiplier = "Rumus harus berupa angka";
      } else {
        errmultiplier = "";
      }
    }

    if (!this.isValidForm()) {
      form.accept = false;
      error.accept = "";
    }

    this.setState({
      form: form,
      error: {
        ...error,
        multiplier: errmultiplier
      }
    }, () => {

      if (!this.isValidForm()) {
        return;
      }

      this.handleSubmit();

    });

  }

  handleSubmit = () => {
    let { form } = this.state;
    let postdata = { userid: form.userid, multiplier: parseFloat(form.multiplier) };
    const outer = this;
    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/fttargetconv/edit', postdata
      ).then(({ data }) => {
        if (data.sta && data.stacod === 200) {
          outer.emptyFied();
          outer.setState({
            disableBtnSubmit: false
          }, () => {
            outer.showDialog('Information', <IntlMessages id="setting.defaultmultiplier.success.edit" />);
          });
          setTimeout(() =>
            // this.props.history.push("/bo/setting/usrdefconv/list")
            this.backToList()
            , 5000)
        } else {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', data.msg);
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"]);
      });
    });

  }

  emptyFied() {
    this.setState({
      fulltimerobject: null,
      form: {
        ...this.state.form,
        id: "",
        userid: "",
        username: "",
        multiplier: 0
      },
      error: {
        ...this.state.error,
        id: "",
        userid: "",
        username: "",
        input: ""
      },
    })
  }

  isValidForm = () => {
    return (
      !this.state.error.userid &&
      !this.state.error.multiplier)
  }

  handleChangeInput = (val) => {
    this.setState({
      form: {
        ...this.state.form,
        input: val.target.value
      }
    })
  }





  render() {
    const { classes, theme } = this.props;
    const { error, fulltimers } = this.state;
    const title = "Profile"
    return (
      <div style={{ marginTop: theme.spacing(4) }}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href='/bo/dashboard'>
              Pengaturan
            </Link>
            <Link color="inherit" 
            // href="/bo/setting/usrdefconv/list"
            href='#'
            onClick={() => { this.backToList() }}
            >
              Rumus per FT
            </Link>
            <Link color="textPrimary"
              href="/bo/setting/usrdefconv/edit"
            >
              Ubah Rumus
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>


              <Grid container spacing={2}>

                <Grid item xs={12}>
                  *Note: Rumus diisi dengan total pengali konversi target waktu ke target orang. <br />
                  Misal: Rumus default adalah 3/2, jadi rumus bisa diisi dengan 1.5
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={fulltimers}
                    getOptionLabel={option => option && option.firstname}
                    value={this.state.fulltimerobject}
                    disabled={this.state.mode == "edit"}
                    onChange={this.changeFulltimer}
                    onFocus={(e)=>{this.inputChangeFulltimer('')}}
                    onInputChange={(event, val) => this.inputChangeFulltimer(val)}
                    renderInput={
                      param => (
                        <TextField
                          {...param}
                          label="Fulltimer"
                          fullWidth
                          required
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={error.userid ? true : false}
                        />
                      )
                    }
                  />
                  {
                    error.userid &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.userid}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="multiplier"
                    name="multiplier"
                    label={<IntlMessages id="setting.defaultmultiplier.multiplier" />}
                    value={this.state.form.multiplier}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    onBlur={this.changeFocus}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {
                    error.multiplier &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.multiplier}
                    />
                  }
                </Grid>
              </Grid>
              <Button
                type="button"
                className={classes.submit}
                color="primary"
                variant="contained"
                fullWidth
                onClick={this.handleValidation}
                disabled={this.state.disableBtnSubmit}
              >
                UPDATE
              </Button>
            </form>
          </div>
        </Container>

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
      </div>
    );
  }
}

DbSettingInputEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, { deselectParam }
)(withStyles(styles)(withTheme(DbSettingInputEdit)));