import React from 'react';
import Box from '@material-ui/core/Box';
import {
    Link
} from "react-router-dom";


function Headerfront(){
    return (
        <Box component="span" m={1}>
            <Link to="/">
                <img id="logo" src={require("../assets/FTLogo.png")} alt="logo" width={150} />  
            </Link>
        </Box>
    )
}

export default Headerfront