import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select,
  MenuItem, Breadcrumbs, IconButton, Checkbox, Card,
  CardMedia, CardContent, Typography, Link, colors
} from '@material-ui/core';
import { Add, Edit, Remove, List, Delete } from "@material-ui/icons"
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import Loader from 'react-loader-spinner'
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import { ValidateEmail } from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";
import DialogDetail from '../../../../../component/dialogdetail';

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";

import { MoodBad } from '@material-ui/icons';
import DataTable from 'react-data-table-component';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername: {
    color: '#204196',
    fontWeight: 'bold'
  },
  labelerror: {
    color: '#ff1744',
    fontSize: 10,
    paddingTop: '5px'
  },
  konfirmasi: {
    paddingTop: '10px'
  },
  copyright: {
    paddingBottom: '10px'
  }
});

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

class DbSettingShepherdingEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        penatuaname: "",
        penatuaid: "",
        freetimername: "",
        freetimerid: ""
        // desc:""

      },
      error: {
        penatuaname: "",
        penatuaid: "",
        freetimername: "",
        freetimerid: ""
      },
      page: {
        penatuaid: "",
        lokalid: "",
        keyword: '',
        page: 1,
        count: 100
      },
      localparam: {
        keyword: '',
        page: 1,
        count: 10
      },
      clusterparam: {
        localid: "",
        keyword: '',
        page: 1,
        count: 10
      },
      clusterlist: [],
      selectedCluster: {},
      locallist: [],
      selectedLocal: {},
      localftparam: {
        keyword: '',
        page: 1,
        count: 10
      },
      localftlist: [],
      selectedLocalft: {},
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      disableBtnSubmit: false,
      dialogMode: '',
      detailOpen: false,
      detailTitle: 'Shepherding Detail',
      loadingTrigger: false,
      detailContent: [],
      mode: 'add',
      penatuapage: {
        keyword: "",
        count: 10,
        lokalid: "",
        clusterid: "",
      },
      fulltimerpage: {
        keyword: "",
        count: 10,
        lokalid: "",
      },
      penatuas: [],
      fulltimers: [],
      selectedRows: [],
      shepherdingList: [],
      shepherdingListTotal: 0,
      selectedFT: {},
      filtertable: {},
      filterAll: {},
    };

    this.changePenatua = this.changePenatua.bind(this);
    this.changeFulltimer = this.changeFulltimer.bind(this);
  }

  componentDidMount() {
    let filter = this.props.location.state.filter;
    this.setState({ filtertable: filter, filterAll: this.props.location.state.filterAll});
    this.getPenatuaList();
    this.getFulltimerList();
    this.getLocal();
    this.getLocalFT();
    this.getCluster();
    this.getShepherdingList();
  }

  getShepherdingList = () => {
    let outer = this;
    this.setState({
      loadingTrigger: true
    });
    axios.post('bo/int/master/cust/shepherded/list', outer.state.page).then(({ data }) => {
      this.setState({
        loadingTrigger: false
      });
      if (data.sta && data.stacod === 200) {
        this.setState({
          shepherdingList: data.data.list,
          shepherdingListTotal: data.data.t
        })
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  getLocal() {
    let outer = this;
    axios.post('bo/int/master/local/list', outer.state.localparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          locallist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  getLocalFT() {
    let outer = this;
    axios.post('bo/int/master/local/list', outer.state.localftparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          localftlist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  getCluster() {
    let outer = this;
    axios.post('bo/int/master/cluster/list', outer.state.clusterparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          clusterlist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changeLocal = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedLocal: val,
        selectedCluster: {},
        penatuapage: {
          ...this.state.penatuapage,
          lokalid: val.id,
          clusterid: "",
        },
        fulltimerpage: {
          ...this.state.fulltimerpage,
          lokalid: val.id,
        },
        clusterparam: {
          ...this.state.clusterparam,
          localid: val.id
        }
      }, () => {
        outer.getPenatuaList();
        outer.getCluster();
      });
    } else {
      this.setState({
        selectedLocal: {},
        selectedCluster: {},
        penatuapage: {
          ...this.state.penatuapage,
          lokalid: "",
          clusterid: "",
        },
        fulltimerpage: {
          ...this.state.fulltimerpage,
          lokalid: "",
        },
        clusterparam: {
          ...this.state.clusterparam,
          localid: ""
        }
      }, () => {
        outer.getPenatuaList();
        outer.getCluster();
      });
    }
    this.inputChangeLocal('');
  }

  inputChangeLocal(val) {
    this.setState({
      localparam: {
        ...this.state.localparam,
        keyword: val
      }
    }, () => {
      this.getLocal();
    })
  }

  changeLocalFT = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedLocalft: val,
        fulltimerpage: {
          ...this.state.fulltimerpage,
          lokalid: val.id,
        },
      }, () => {
        outer.getFulltimerList();
      });
    } else {
      this.setState({
        selectedLocalft: {},
        fulltimerpage: {
          ...this.state.fulltimerpage,
          lokalid: "",
        },
      }, () => {
        outer.getFulltimerList();
      });
    }
  }

  inputChangeLocalFT(val) {
    this.setState({
      localftparam: {
        ...this.state.localftparam,
        keyword: val
      }
    }, () => {
      this.getLocalFT();
    })
  }

  inputChangeCluster(val) {
    this.setState({
      clusterparam: {
        ...this.state.clusterparam,
        keyword: val
      }
    }, () => {
      this.getCluster();
    })
  }

  changeCluster = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedCluster: val,
        penatuapage: {
          ...this.state.penatuapage,
          clusterid: val.id,
        },
      }, () => { outer.getCluster(); outer.getPenatuaList(); });
    } else {
      this.setState({
        selectedCluster: {},
        penatuapage: {
          ...this.state.penatuapage,
          clusterid: "",
        },
      }, () => { outer.getCluster(); outer.getPenatuaList(); });
    }
    this.inputChangeCluster('');
  }

  inputChangePenatua(val) {
    this.setState({
      penatuapage: {
        ...this.state.penatuapage,
        keyword: val
      }
    }, () => {
      this.getPenatuaList();
    })
  }

  getPenatuaList() {
    let outer = this;
    axios.post('bo/int/master/penatua/list', outer.state.penatuapage).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          penatuas: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changePenatua(event, val) {
    let outer = this;
    if (val) {
      this.setState({
        form: {
          ...this.state.form,
          penatuaname: val.firstname,
          penatuaid: val.id,

        },
        page: {
          ...this.state.page,
          penatuaid: val.id,
        }
      },
        () => { outer.getShepherdingList() });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          penatuaname: "",
          penatuaid: "",
        },
        page: {
          ...this.state.page,
          penatuaid: "",
        }
      },
        () => { outer.getShepherdingList() });
    }
  }

  inputChangeFulltimer(val) {
    this.setState({
      fulltimerpage: {
        ...this.state.fulltimerpage,
        keyword: val
      }
    }, () => {
      this.getFulltimerList();
    })
  }

  getFulltimerList() {
    let outer = this;
    axios.post('bo/int/master/cust/nonshepherd/list', outer.state.fulltimerpage).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          fulltimers: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changeFulltimer(event, val) {
    if (val) {

      this.setState({
        form: {
          ...this.state.form,
          freetimername: val.firstname,
          freetimerid: val.id,
          selectedFT: val
        }
      })
    } else {
      this.setState({
        form: {
          ...this.state.form,
          freetimername: "",
          freetimerid: "",
          selectedFT: {}
        }
      })
    }
  }

  handleSelectedRows = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    // // console.log('Selected Rows: ', state.selectedRows);
    this.setState({ selectedRows: state.selectedRows });
  };

  deleteShepherding = () => {
    let shepherdLength = this.state.selectedRows.length;
    if (shepherdLength == 0) {
      this.showDialog("Peringatan", "Pilih salah satu item untuk menghapus", "");
    } else {
      this.showDialog("Hapus Shepherding", "Anda akan menghapus " + shepherdLength + " shepherding. Apakah anda yakin?", "delete");
    }
  }

  columns = [
    // {
    //   name: 'Action',
    //   button: true,
    //   cell: row => {return <div>
    //     <IconButton color="primary" aria-label="detail" component="span" size="small" style={{marginRight:5}} onClick={()=>this.openServiceDetail(row)}>
    //         <List />
    //     </IconButton>
    //   </div>},
    // },
    {
      name: "Nama Fulltimer",
      selector: 'firstname',
      sortable: true,
    },
    {
      name: "Efata ID",
      selector: 'lastname',
      sortable: true,
    }
  ];

  handleDeleteService = () => {
    const outer = this;
    const { selectedRows } = this.state;
    let deleteService = [];
    selectedRows.forEach((serv) => {
      deleteService.push(serv.id);
    })

    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/shepherding/delete', { id: deleteService }
      ).then(({ data }) => {
        if (data.sta && data.stacod === 200) {
          outer.setState({
            disableBtnSubmit: false
          }, () => {
            outer.getShepherdingList();
            outer.showDialog('Information', <IntlMessages id="setting.shepherd.success.deleteshepherd" />, "");
          })

        } else {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', data.msg, "");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"], "");
      });
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen: false
    })
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      if (this.state.dialogMode == "delete") {
        this.setState({
          dialogOpen: false
        });
        this.handleDeleteService();
      } else {
        if (this.state.dialogTitle != "Information") {
          this.setState({ dialogOpen: false });
        } else {
          this.setState({
            dialogOpen: false
          }, () => {
            // this.props.history.push("/bo/setting/shepherding/list")
            // this.backToList();
            this.props.deselectParam();
          });
        }
      }
    }
  }
  backToList=()=>{
    this.props.history.push({ pathname: "/bo/setting/shepherding/list", filter:this.state.filtertable, filterAll:this.state.filterAll });
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  handleChange = (e) => {
    let outer = this;
    let { form, error } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if (inputName === "accept") {
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if (newAccept === true) {
        error[inputName] = ""
      }
    }

    this.setState({
      form: form,
      error: error
    }, () => {
      switch (inputName) {
        case "mobile":
          if (inputValue) {
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        default:
          break;
      }
    });
  }

  handleValidation = (e) => {
    let { form, error, mode } = this.state;

    if (form.penatuaid.trim().length === 0) {
      error.penatuaid = <IntlMessages id="setting.shepherd.error.penatuaname" />;
    } else {
      error.penatuaid = "";
    }

    if (form.freetimerid.trim().length === 0) {
      error.freetimerid = <IntlMessages id="setting.shepherd.error.fulltimername" />;
    } else {
      error.freetimerid = "";
    }



    if (!this.isValidForm()) {
      form.accept = false;
      error.accept = "";
    }

    this.setState({
      form: form,
      error: error
    }, () => {

      if (!this.isValidForm()) {
        return;
      }

      this.handleSubmit();

    });

  }

  handleSubmit = () => {
    let { form } = this.state;
    const outer = this;
    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/shepherding/add', form
      ).then(({ data }) => {
        if (data.sta && data.stacod === 200) {
          outer.setState({
            form: {
              penatuaid: "",
              penatuaname: "",
              freetimerid: "",
              freetimername: ""
            },
            error: {
              penatuaid: "",
              penatuaname: "",
              freetimerid: "",
              freetimername: ""
            },
            disableBtnSubmit: false,
            fulltimerpage: {
              ...this.state.fulltimerpage,
              keyword: "",
            },
            fulltimers: [],
            selectedFT: {},
          }, () => {
            outer.showDialog('Information', <IntlMessages id="setting.shepherd.success.addshepherd" />);
            outer.getShepherdingList();
            outer.getFulltimerList();
          });

        } else {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', data.msg);
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"]);
      });
    });

  }

  isValidForm = () => {
    return (
      !this.state.error.penatuaid &&
      !this.state.error.freetimerid)
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        count: val
      }
    }, () => {
      outer.getShepherdingList();
    })
  }

  onChangePage(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getShepherdingList();
    })
  }

  render() {
    const { classes, theme } = this.props;
    const { error, penatuas, fulltimers, locallist, shepherdingList, clusterlist, localftlist } = this.state;
    const title = "Profile"
    return (
      <div style={{ marginTop: theme.spacing(4) }}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href='/bo/dashboard'>
              Pengaturan
            </Link>
            <Link color="inherit" 
            // href="/bo/setting/shepherding/list"
            href='#'
            onClick={()=>{this.backToList()}}
            >
              Daftar Penggembalaan
            </Link>
            <Link color="textPrimary" href="/bo/setting/shepherding/edit">
              {
                this.state.mode == "add" ?
                  "Tambah Penggembalaan" : "Ubah Penggembalaan"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>


              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    options={locallist}
                    getOptionLabel={option => option.name}
                    value={this.state.selectedLocal}
                    onChange={this.changeLocal}
                    onFocus={(e)=>{this.inputChangeLocal('')}}
                    onInputChange={(event, val) => this.inputChangeLocal(val)}
                    renderInput={
                      param => (
                        <TextField
                          {...param}
                          id="outlined-basic"
                          // variant="outlined" 
                          label="Lokal Penatua"
                          size="small"
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={clusterlist}
                    getOptionLabel={option => option.name}
                    value={this.state.selectedCluster}
                    onChange={this.changeCluster}
                    onFocus={(e)=>{this.inputChangeCluster('')}}
                    onInputChange={(event, val) => this.inputChangeCluster(val)}
                    renderInput={
                      param => (
                        <TextField
                          {...param}
                          id="cluster"
                          label="Wilayah Penatua"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>

                  <Autocomplete
                    options={penatuas}
                    getOptionLabel={option => option.firstname}
                    // value={this.state.form.penatuaname}
                    onChange={this.changePenatua}
                    onFocus={(e)=>{this.inputChangePenatua('')}}
                    onInputChange={(event, val) => this.inputChangePenatua(val)}
                    renderInput={
                      param => (
                        <TextField
                          {...param}
                          label="Penatua"
                          fullWidth
                          required
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={error.penatuaid ? true : false}
                        />
                      )
                    }
                  />
                  {
                    error.penatuaid &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.penatuaid}
                    />
                  }
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={localftlist}
                    getOptionLabel={option => option.name}
                    value={this.state.selectedLocalft}
                    onChange={this.changeLocalFT}
                    onFocus={(e)=>{this.inputChangeLocalFT('')}}
                    onInputChange={(event, val) => this.inputChangeLocalFT(val)}
                    renderInput={
                      param => (
                        <TextField
                          {...param}
                          id="outlined-basic"
                          // variant="outlined" 
                          label="Lokal Fulltimer"
                          size="small"
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>

                  <Autocomplete
                    options={fulltimers}
                    getOptionLabel={option => option.firstname}
                    value={this.state.selectedFT}
                    // value={this.state.form.freetimername}
                    onChange={this.changeFulltimer}
                    onFocus={(e)=>{this.inputChangeFulltimer('')}}
                    onInputChange={(event, val) => this.inputChangeFulltimer(val)}
                    renderInput={
                      param => (
                        <TextField
                          {...param}
                          label="Fulltimer"
                          fullWidth
                          required
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={error.freetimerid ? true : false}
                        />
                      )
                    }
                  />
                  {
                    error.freetimerid &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.freetimerid}
                    />
                  }
                </Grid>

              </Grid>
              <Button
                type="button"
                className={classes.submit}
                color="primary"
                variant="contained"
                fullWidth
                onClick={this.handleValidation}
                disabled={this.state.disableBtnSubmit}
              >
                Tambah Penggembalaan
              </Button>
            </form>
          </div>
        </Container>
        {shepherdingList.length > 0 &&
          <IconButton
            style={{ color: 'red' }}
            aria-label="detail"
            component="span"
            onClick={() => { this.deleteShepherding() }}
          >
            <Delete />
          </IconButton>
        }
        <DataTable
          title="Penggembalaan"
          columns={this.columns}
          data={shepherdingList}
          noDataComponent={
            <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid', marginTop: 10 }}>
              <CardMedia style={{ textAlign: 'center' }}>
                <MoodBad color="action" style={{
                  fontSize: 40,
                  marginTop: 10,
                  marginBottom: 10
                }} />
              </CardMedia>
              <CardContent>
                <Typography>
                  Data tidak tersedia
                </Typography>
              </CardContent>
            </Card>

          }
          selectableRows
          selectableRowsComponent={Checkbox}
          highlightOnHover
          // subHeader
          noHeader
          customStyles={customStyles}
          onSelectedRowsChange={this.handleSelectedRows}
          pagination
          paginationRowsPerPageOptions={[100,250,500]}
          paginationServer
          paginationTotalRows={this.state.shepherdingListTotal}
          paginationPerPage={this.state.page.count}
          onChangePage={(val) => this.onChangePage(val)}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          progressPending={this.state.loadingTrigger}
          progressComponent={
            <Loader type="TailSpin" color="#204196" height={100} width={100} />
          }
          subHeaderComponent={
            (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                  size="small"
                  style={{ margin: '5px' }}
                  onChange={(val) => this.search(val)}
                />
                <IconButton
                  color="primary"
                  aria-label="detail"
                  component="span"
                  onClick={() => { this.addnew() }}
                >
                  <Add />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="detail"
                  component="span"
                  onClick={() => { this.deleteShepherding() }}
                >
                  <Delete />
                </IconButton>
              </div>
            )
          }
        />

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          mode={this.state.dialogMode}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
        <DialogDetail
          open={this.state.detailOpen}
          title={this.state.detailTitle}
          content={this.state.detailContent}
          handleClose={this.handleDetailClose}
          handleOk={this.handleDetailClose}
        />
      </div>
    );
  }
}

DbSettingShepherdingEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  // console.log("state", state)
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, { deselectParam }
)(withStyles(styles)(withTheme(DbSettingShepherdingEdit)));