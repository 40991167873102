import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
  IconButton, TextField, Checkbox, Card, FormControl,
  CardMedia, CardContent, Typography, Select, InputLabel, MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DataTable from 'react-data-table-component';
import moment from 'moment';
//import actions
import { addParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete: {
    color: '#204196',
    fontWeight: 'bold'
  },
  incomplete: {
    color: '#ff1744',
    fontWeight: 'bold'
  }
});

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions: [10, 25, 100],
  print: false,
  download: false,
  selectableRows: 'single',
  isRowSelectable: function () { return false }
};

class DbReportJalaWeek extends React.Component {
  constructor(props) {
    super(props);
    let dat = new Date();
    dat.setDate(dat.getDate() - 7);
    this.state = {
      setdate: "",
      page: {
        city: "",
        date: moment().format('YYYY-MM-DD'),
        datePar: new Date(),
        page: 1,
        count: 100,
      },
      localparam: {
        keyword: '',
        page: 1,
        count: 10
      },
      dataTotal:0,
      locallist: [],
      selectedLocal: {},
      startWeek: "",
      endWeek: "",
      bibleList: [],
      usrobject: null,
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      dialogMode: '',
      loadingTrigger:false,
      detailOpen: false,
    }
  }

  columns = [
    {
      name: <IntlMessages id="report.task.username" />,
      selector: 'name',
      sortable: true
    },
    {
      name: <IntlMessages id="report.task.usercity" />,
      selector: 'city',
      sortable: true
    },
    {
      name: <IntlMessages id="report.bible.target" />,
      selector: 'target',
    },
    {
      name: <IntlMessages id="report.bible.real" />,
      selector: 'realization',
    },
    {
      name: <IntlMessages id="report.bible.percent" />,
      selector: 'percent',
      cell: row => <span
        style={
          row.percentnum == 0 || row.percentnum < 0 ?
            { color: 'red' } : row.percentnum > 0 && row.percentnum < 26 ? { color: "pink" } :
              row.percentnum > 25 && row.percentnum < 51 ? { color: "orange" } : row.percentnum > 50 && row.percentnum < 76 ? { color: 'green' } :
                row.percentnum > 75 && row.percentnum < 101 ? { color: "blue" } : row.percentnum > 100 && { color: "black" }
        }
      >
        {row.percent}
      </span>
    },
    {
      name: <IntlMessages id="report.bible.freq" />,
      selector: 'freq',
    },
    {
      name: <IntlMessages id="report.bible.time" />,
      selector: 'time',
    },
    {
      name: <IntlMessages id="report.bible.efficiency" />,
      selector: 'efficiency',
    }
  ];

  componentDidMount() {
    this.getJalaReport();
    let startWeekMoment = moment(this.state.page.date).subtract(1, 'days').startOf('week');
    let endWeekMoment = moment(this.state.page.date).subtract(1, 'days').endOf('week');
    this.setState({
      startWeek: moment(startWeekMoment).add(1, 'days').format('DD MMM YYYY'),
      endWeek: moment(endWeekMoment).add(1, 'days').format('DD MMM YYYY')
    });
    this.getLocal();
  }

  getLocal() {
    let outer = this;
    axios.post('bo/int/master/local/list', outer.state.localparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          locallist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changeLocal = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedLocal: val,
        page: {
          ...this.state.page,
          lokalid: val.id
        }
      }, () => { outer.getJalaReport() });
    } else {
      this.setState({
        selectedLocal: {},
        page: {
          ...this.state.page,
          lokalid: "",
        }
      }, () => { outer.getJalaReport() });
    }
    this.inputChangeLocal('');
  }

  inputChangeLocal(val) {
    this.setState({
      localparam: {
        ...this.state.localparam,
        keyword: val
      }
    }, () => {
      this.getLocal();
    })
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen: false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      this.setState({ dialogOpen: false });
    }
  }

  changeCity = (val) => {
    if (val) {
      this.setState({
        page: {
          ...this.state.page,
          city: val.target.value
        }
      }, () => {
        this.getJalaReport();
      })
    }
  }

  getJalaReport = () => {
    let outer = this;
    this.setState({
      loadingTrigger:true
    });
    let url = 'bo/int/report/sheep/week';
    if (this.props.token.token.role == "Penatua") {
      url = 'bo/penatua/report/sheep/week';
    };
    axios.post(url, outer.state.page).then(({ data }) => {
      this.setState({
        loadingTrigger:false
      });
      if (data.sta && data.stacod === 200) {
        this.setState({
          jalaList: data.data.list,
          dataTotal: data.data.total,
        })
      } else {
        outer.showDialog("Warning", data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }



  changeDate = (date) => {
    let momentParse = moment(date).format("YYYY-MM-DD");
    let startWeekMoment = moment(momentParse).subtract(1, 'days').startOf('week');
    let endWeekMoment = moment(momentParse).subtract(1, 'days').endOf('week');
    this.setState({
      page: {
        ...this.state.page,
        datePar: date,
        date: momentParse,
      },
      startWeek: moment(startWeekMoment).add(1, 'days').format('DD MMM YYYY'),
      endWeek: moment(endWeekMoment).add(1, 'days').format('DD MMM YYYY')
    }, () => {
      this.getJalaReport();
    })
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        count: val
      }
    }, () => {
      outer.getJalaReport();
    })
  }

  onChangePage(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getJalaReport();
    })
  }

  render() {
    const { theme } = this.props;
    const { jalaList, startWeek, endWeek, locallist } = this.state;

    return (
      <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
        <div style={{ paddingTop: 10 }}>          
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Laporan Mingguan
            </Link>
            <Link color="textPrimary"
            // href="/bo/master/user/list" 
            href='/bo/report/jalaweek/list'>
              Laporan Jala Mingguan {startWeek + " - " + endWeek}
            </Link>            
          </Breadcrumbs>
        </div>
        <div className="filter">
          <div style={{ marginBottom: 10 }}>
            <Autocomplete
              options={locallist}
              getOptionLabel={option => option.name}
              value={this.state.selectedLocal}
              onChange={this.changeLocal}
              onFocus={(e)=>{this.inputChangeLocal('')}}
              onInputChange={(event, val) => this.inputChangeLocal(val)}
              renderInput={
                param => (
                  <TextField
                    {...param}
                    id="outlined-basic"
                    // variant="outlined" 
                    label="Lokal"
                    size="small"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
              }
            />
          </div>
          {/* <div>
              <FormControl
                style={{
                  minWidth: '100%',
                  marginBottom: 10
                }}
              >
                <TextField 
                  id="outlined-basic" 
                  label="Kota" 
                  variant="outlined" 
                  size="small" 
                  // style={{ margin: '5px' }}
                  onChange={(val) => this.changeCity(val)} 
                />
              </FormControl>
            </div> */}


          <div style={{ display: 'flex', alignItems: 'center' }}>

            <KeyboardDatePicker
              clearable
              value={this.state.page.datePar}
              // placeholder="Tanggal"
              label="Tanggal"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={date => this.changeDate(date)}
              format="DD-MM-YYYY"
            />
          </div>
          <div>
            <Typography variant="subtitle2">
              * Keterangan Persentase:
            </Typography>
            <div>
              <span style={{ color: 'red' }}>0</span> / <span style={{ color: 'pink' }}>0-25%</span> / <span style={{ color: 'orange' }}>26%-50%</span> /&nbsp;
              <span style={{ color: 'green' }}>51%-75%</span> / <span style={{ color: 'blue' }}>76%-100%</span> / <span style={{ color: 'black' }}>&gt;100%</span>
            </div>
          </div>
        </div>
        <DataTable
          columns={this.columns}
          data={jalaList}
          noDataComponent={
            <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
              <CardMedia style={{ textAlign: 'center' }}>
                <MoodBad color="action" style={{
                  fontSize: 40,
                  marginTop: 10,
                  marginBottom: 10
                }} />
              </CardMedia>
              <CardContent>
                <Typography>
                  Data tidak tersedia
                </Typography>
              </CardContent>
            </Card>
          }
          highlightOnHover
          noHeader
          customStyles={customStyles}
          pagination
          paginationRowsPerPageOptions={[100,250,500]}
          paginationServer
          paginationTotalRows={this.state.dataTotal}
          paginationPerPage={this.state.page.count}
          onChangePage={(val) => this.onChangePage(val)}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          progressPending={ this.state.loadingTrigger}
          progressComponent={
          <Loader type="TailSpin" color="#204196" height={100} width={100}/>
          }
        />

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />

      </div>
    );
  }
}

DbReportJalaWeek.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    token: state.token
  };
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbReportJalaWeek)));