import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
  IconButton, TextField, Checkbox, Card,
  CardMedia, CardContent, Typography, Breadcrumbs, Link
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
//import { Link } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from "react-redux";
import moment from 'moment';

import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';

import DataTable from 'react-data-table-component';

//import actions
import { addParam, deselectParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete: {
    color: '#204196',
    fontWeight: 'bold'
  },
  incomplete: {
    color: '#ff1744',
    fontWeight: 'bold'
  }
});

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions: [100, 25, 100],
  print: false,
  download: false,
  selectableRows: 'single',
  isRowSelectable: function () { return false }
};

class DbMasterPenatua extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        search: '',
        page: 1,
        count: 100,
        lokalid: "",
        clusterid: "",
      },
      localparam: {
        keyword: '',
        page: 1,
        count: 100
      },
      clusterparam: {
        localid: "",
        keyword: '',
        page: 1,
        count: 10
      },
      clusterlist: [],
      loadingTrigger: false,
      selectedCluster: {},
      userList: [],
      userListTotal: 0,
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      dialogMode: '',
      detailOpen: false,
      detailTitle: 'Penatua Detail',
      detailContent: [],
      selectedRows: [],
      locallist: [],
      selectedLocal: {},
      mount:false,
    }
  }

  columns = [
    {
      name: 'Action',
      button: true,
      cell: row => {
        return <div>
          <IconButton color="primary" aria-label="detail" component="span" size="small" style={{ marginRight: 5 }} onClick={() => this.openUserDetail(row)}>
            <List />
          </IconButton>
          <IconButton color="primary" aria-label="edit" component="span" size="small" onClick={() => this.editUser(row)}>
            <Edit />
          </IconButton>
          {/* <List style={{ margin: '5px' }} color="action" /> */}
          {/* <Edit style={{ margin: '5px' }} color="action" /> */}
        </div>
      },
    },
    {
      name: <IntlMessages id="master.user.firstname" />,
      selector: 'firstname',
      sortable: true,
    },
    {
      name: <IntlMessages id="master.user.lastname" />,
      selector: 'lastname',
      sortable: true,
    },
    {
      name: "Lokal",
      selector: 'lokal.name',
      sortable: true,
    },
    {
      name: "Wilayah",
      selector: 'cluster.name',
      sortable: true,
    },
    {
      name: <IntlMessages id="master.user.gender" />,
      selector: 'gender',
      sortable: true,
      hide: 'sm',
    },
    {
      name: <IntlMessages id="master.user.birthdate" />,
      selector: 'birthdatestr',
      sortable: true,
      hide: 'sm',
    },
  ];

  componentDidMount() {
    if (this.props.location.filter) {
      let filter = this.props.location.filterAll;
      this.setState(
        { page: this.props.location.filter, selectedLocal: 
          filter.selectedLocal,
          selectedCluster: filter.selectedCluster },()=>{
            this.getUserList();
          }
      )
    }else{
      this.getUserList();
    }
    this.getLocal();
    this.getCluster();
    this.setState({mount:true})
  }

  getLocal() {
    let outer = this;
    axios.post('bo/int/master/local/list', outer.state.localparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          locallist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  getCluster() {
    let outer = this;
    axios.post('bo/int/master/cluster/list', outer.state.clusterparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          clusterlist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen: false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({
        dialogOpen: false
      });
      this.props.history.push("/logout");
    } else {
      this.setState({
        dialogOpen: false
      });
      switch (this.state.dialogMode) {
        case "delete":
          this.handleDeleteUser();
          break;

        default:
          break;
      }
    }
  }

  getUserList = () => {
    let outer = this;
    this.setState({
      loadingTrigger: true
    });
    axios.post('bo/int/master/penatua/list', outer.state.page).then(({ data }) => {
      this.setState({
        loadingTrigger: false
      });
      if (data.sta && data.stacod === 200) {
        data.data.list.forEach((item) => {
          item.birthdatestr = moment(item.birthdate).format("DD-MM-YYYY");
        })
        outer.setState({
          userList: data.data.list,
          userListTotal: data.data.t
        });
      } else {
        outer.showDialog("warning", data.msg);
      }
    }).catch(function (error) {
      outer.setState({
        disableBtnSubmit: false
      });
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  openUserDetail = (row) => {
    // // console.log(row)
    let detailContent = [];
    Object.keys(row).forEach(function (key) {
      let name = '';
      let seq = 0;
      let view = true;
      switch (key) {
        case 'email':
          name = "Email";
          seq = 1;
          view = true;
          break;
        case 'firstname':
          name = <IntlMessages id="master.user.firstname" />;
          seq = 2;
          view = true;
          break;
        case 'lastname':
          name = <IntlMessages id="master.user.lastname" />;
          seq = 3;
          view = true;
          break;
        case 'gender':
          name = <IntlMessages id="master.user.gender" />;
          seq = 4;
          view = true;
          break;
        case 'birthdatestr':
          name = <IntlMessages id="master.user.birthdate" />;
          seq = 5;
          view = true;
          break;
        case 'address1':
          name = <IntlMessages id="master.user.address1" />;
          seq = 6;
          view = true;
          break;
        case 'address2':
          name = <IntlMessages id="master.user.address2" />;
          seq = 7;
          view = true;
          break;
        case 'province':
          name = <IntlMessages id="master.user.province" />;
          seq = 8;
          view = true;
          break;
        case 'city':
          name = <IntlMessages id="master.user.city" />;
          seq = 9;
          view = true;
          break;
        case 'mobile1':
          name = <IntlMessages id="master.user.mobile1" />;
          seq = 10;
          view = true;
          break;
        case 'mobile2':
          name = <IntlMessages id="master.user.mobile2" />;
          seq = 11;
          view = true;
          break;

        default:
          view = false;
          break;
      }
      // console.log("key", key);
      let content = { id: key, name: name, value: row[key], seq: seq, view: view }
      detailContent.push(content);
      // // console.log(key, row[key]);

    });
    detailContent.sort(compareSequence);
    // console.log(detailContent);
    this.setState({
      detailOpen: true,
      detailContent
    });
  }

  editUser = (row) => {
    this.props.onAddParam({ detail: row });
    this.props.history.push({ pathname: "/bo/master/penatua/edit", search: "?i=" + row.id, state:{ filter:this.state.page, filterAll:{selectedLocal:this.state.selectedLocal, 
      selectedCluster:this.state.selectedCluster}} });
  }

  handleSelectedRows = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    // // console.log('Selected Rows: ', state.selectedRows);
    this.setState({ selectedRows: state.selectedRows });
  };

  deleteUser = () => {
    let userLength = this.state.selectedRows.length;
    if (userLength == 0) {
      this.showDialog("Peringatan", "Pilih salah satu item untuk menghapus", "");
    } else {
      this.showDialog("Hapus User", "Anda akan menghapus " + userLength + " user. Apakah anda yakin?", "delete");
    }
  }

  handleDeleteUser = () => {

    const outer = this;
    const { selectedRows } = this.state;
    let deleteUser = [];
    selectedRows.forEach((user) => {
      deleteUser.push(user.id);
    })
    // console.log("delete user", deleteUser);

    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/master/penatua/delete', { id: deleteUser }
      ).then(({ data }) => {
        if (data.sta && data.stacod === 200) {
          outer.setState({
            disableBtnSubmit: false
          }, () => {
            outer.getUserList();
            outer.showDialog('Information', <IntlMessages id="master.user.success.deleteuser" />, "");
          })

        } else {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', data.msg, "");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"], "");
      });
    });
  }

  search(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page:1,
        keyword: val.target.value
      }
    }, () => {
      outer.getUserList();
    })
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        count: val
      }
    }, () => {
      outer.getUserList();
    })
  }

  onChangePage(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getUserList();
    })
  }
  changeLocal = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedLocal: val,
        selectedCluster: {},
        page: {
          ...this.state.page,
          page: 1,
          lokalid: val.id,
          clusterid: "",
        },
        clusterparam: {
          ...this.state.clusterparam,
          localid: val.id
        }
      }, () => {
        outer.getUserList()
        outer.getCluster();
      });
    } else {
      this.setState({
        selectedLocal: {},
        selectedCluster: {},
        page: {
          ...this.state.page,
          lokalid: "",
          clusterid: "",
        },
        clusterparam: {
          ...this.state.clusterparam,
          localid: ""
        }
      }, () => {
        outer.getUserList();
        outer.getCluster();
      });
    }
    this.inputChangeLocal('');
  }

  inputChangeLocal(val) {
    this.setState({
      localparam: {
        ...this.state.localparam,
        keyword: val
      }
    }, () => {
      this.getLocal();
    })
  }

  inputChangeCluster(val) {
    this.setState({
      clusterparam: {
        ...this.state.clusterparam,
        keyword: val
      }
    }, () => {
      this.getCluster();
    })
  }

  changeCluster = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedCluster: val,
        page: {
          ...this.state.page,
          page: 1,
          clusterid: val.id
        }
      }, () => { outer.getUserList() });
    } else {
      this.setState({
        selectedCluster: {},
        page: {
          ...this.state.page,
          page: 1,
          clusterid: "",
        }
      }, () => { outer.getUserList() });
    }
    this.inputChangeCluster('');
  }

  render() {
    const { classes, container, theme, content, title } = this.props;
    const { userList, locallist, clusterlist } = this.state;


    return (
      <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
        <div style={{ paddingTop: 10 }}>
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Master
            </Link>
            <Link color="textPrimary"
            // href="/bo/master/user/list" 
            href='/bo/master/penatua/list'>
              Daftar Penatua
            </Link>            
          </Breadcrumbs>
        </div>
        <div className="filter">
          <div style={{ marginBottom: 10 }}>
            <Autocomplete
              options={locallist}
              getOptionLabel={option => option.name}
              value={this.state.selectedLocal}
              onChange={this.changeLocal}
              onFocus={(e)=>{this.inputChangeLocal('')}}
              onInputChange={(event, val) => this.inputChangeLocal(val)}
              renderInput={
                param => (
                  <TextField
                    {...param}
                    id="outlined-basic"
                    // variant="outlined" 
                    label="Lokal"
                    size="small"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
              }
            />
          </div>
          <div style={{ marginBottom: 5 }}>
            <Autocomplete
              options={clusterlist}
              getOptionLabel={option => option.name}
              value={this.state.selectedCluster}
              onChange={this.changeCluster}
              onFocus={(e)=>{this.inputChangeCluster('')}}
              onInputChange={(event, val) => this.inputChangeCluster(val)}
              renderInput={
                param => (
                  <TextField
                    {...param}
                    id="cluster"
                    label="Wilayah"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
              }
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              id="outlined-basic"
              label="Search"
              value={this.state.page.keyword}
              variant="outlined"
              size="small"
              style={{ margin: '5px' }}
              onChange={(val) => this.search(val)}
            />
            <IconButton
              color="primary"
              aria-label="detail"
              component="span"
              onClick={() => {
                this.props.onDeselectParam();
                this.props.history.push({
                  pathname: "/bo/master/penatua/edit",
                  state:{
                  filter: this.state.page,
                  filterAll: {
                    selectedLocal: this.state.selectedLocal,
                    selectedCluster: this.state.selectedCluster
                  }}
                });
              }}
            >
              <Add />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="detail"
              component="span"
              onClick={() => { this.deleteUser() }}
            >
              <Remove />
            </IconButton>
          </div>
        </div>
{(this.state.mount)&&
        <DataTable
          columns={this.columns}
          data={userList}
          noDataComponent={
            <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid', marginTop: 10 }}>
              <CardMedia style={{ textAlign: 'center' }}>
                <MoodBad color="action" style={{
                  fontSize: 40,
                  marginTop: 10,
                  marginBottom: 10
                }} />
              </CardMedia>
              <CardContent>
                <Typography>
                  Data tidak tersedia
                </Typography>
              </CardContent>
            </Card>

          }
          selectableRows
          selectableRowsComponent={Checkbox}
          highlightOnHover
          paginationDefaultPage={this.state.page.page}
          customStyles={customStyles}
          onSelectedRowsChange={this.handleSelectedRows}
          pagination
          paginationRowsPerPageOptions={[100,250,500]}
          paginationServer
          paginationTotalRows={this.state.userListTotal}
          paginationPerPage={this.state.page.count}
          onChangePage={(val) => this.onChangePage(val)}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          noHeader
          progressPending={this.state.loadingTrigger}
          progressComponent={
            <Loader type="TailSpin" color="#204196" height={100} width={100} />
          }
        />
        }
        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          mode={this.state.dialogMode}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
        <DialogDetail
          open={this.state.detailOpen}
          title={this.state.detailTitle}
          content={this.state.detailContent}
          handleClose={this.handleDetailClose}
          handleOk={this.handleDetailClose}
        />
      </div>
    );
  }
}

DbMasterPenatua.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param)),
    onDeselectParam: () => dispatch(deselectParam()),
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbMasterPenatua)));