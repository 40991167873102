import React from "react";

function InvalidBrowser() {
  return (
    <div style={{padding:'15px'}}>
      <h3>
        Browser tidak support, mohon gunakan browser chrome,firefox atau safari 
        <br/><br/><a href="/">kembali ke halaman pendaftaran</a> 
      </h3>
    </div>
  );
}

export default InvalidBrowser