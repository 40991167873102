import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select, CardContent,
  MenuItem, Breadcrumbs, Link, Card, CardHeader
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import {ValidateEmail} from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";
import { TagFaces } from '@material-ui/icons';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function isEmpty(obj) {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

class DbFulltimerTargetPeriodeEdit extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        form: {
          targetlist: []
        },
        error:{
          targetlist: ""
        },
        targobj : {
          baptis:{id:"",catid:"",catname:"Baptis / Bulan",catcode:"baptis",subcatid:"",subcatname:"",subcatcode:"",target:0},
          vital:{id:"",catid:"",catname:"Vital / Semester",catcode:"vital",subcatid:"",subcatname:"",subcatcode:"",target:0},
          vital1:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 1 / Semester",subcatcode:"vital1",target:0},
          vital2:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 2 / Semester",subcatcode:"vital2",target:0},
          vital3:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 3 / Semester",subcatcode:"vital3",target:0},
          vital4:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 4 / Semester",subcatcode:"vital4",target:0},
          vital5:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 5 / Semester",subcatcode:"vital5",target:0},
          vital6:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 6 / Semester",subcatcode:"vital6",target:0},
          kelompokvital:{id:"",catid:"",catname:"Kelompok Vital / Semester",catcode:"kelompokvital",subcatid:"",subcatname:"",subcatcode:"",target:0},
          spr:{id:"",catid:"",catname:"Kandidat SPR / Minggu",catcode:"spr",subcatid:"",subcatname:"",subcatcode:"",target:0}
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
        
    };
  }

  componentDidMount() {
    let outer = this;
    this.getTargetPeriode();
  }

  

  getTargetPeriode() {
    let outer = this;
    axios.post('bo/ft/user/ptarget', {}).then(({data})=>{
      if(data.sta && data.stacod === 200){
        let targobj = {
          baptis:{id:"",catid:"",catname:"Baptis / Bulan",catcode:"baptis",subcatid:"",subcatname:"",subcatcode:"",target:0},
          vital:{id:"",catid:"",catname:"Vital / Semester",catcode:"vital",subcatid:"",subcatname:"",subcatcode:"",target:0},
          vital1:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 1 / Semester",subcatcode:"vital1",target:0},
          vital2:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 2 / Semester",subcatcode:"vital2",target:0},
          vital3:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 3 / Semester",subcatcode:"vital3",target:0},
          vital4:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 4 / Semester",subcatcode:"vital4",target:0},
          vital5:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 5 / Semester",subcatcode:"vital5",target:0},
          vital6:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 6 / Semester",subcatcode:"vital6",target:0},
          kelompokvital:{id:"",catid:"",catname:"Kelompok Vital / Semester",catcode:"kelompokvital",subcatid:"",subcatname:"",subcatcode:"",target:0},
          spr:{id:"",catid:"",catname:"Kandidat SPR / Minggu",catcode:"spr",subcatid:"",subcatname:"",subcatcode:"",target:0}
        };
        data.data.forEach(target => {
          switch (target.catcode) {
            case "baptis":
              targobj.baptis.id=target.id;
              targobj.baptis.catid=target.catid;
              targobj.baptis.catname=target.catname + " / Bulan";
              targobj.baptis.catcode=target.catcode;
              targobj.baptis.subcatid=target.subcatid;
              targobj.baptis.subcatname=target.subcatname;
              targobj.baptis.subcatcode=target.subcatcode;
              targobj.baptis.target=target.target;
              break;
            case "vital":
              switch (target.subcatcode) {
                case "":
                  targobj.vital.id=target.id;
                  targobj.vital.catid=target.catid;
                  targobj.vital.catname=target.catname + " / Semester";
                  targobj.vital.catcode=target.catcode;
                  targobj.vital.subcatid=target.subcatid;
                  targobj.vital.subcatname=target.subcatname;
                  targobj.vital.subcatcode=target.subcatcode;
                  targobj.vital.target=target.target;
                  break;
                case "vital1":
                  targobj.vital1.id=target.id;
                  targobj.vital1.catid=target.catid;
                  targobj.vital1.catname=target.catname;
                  targobj.vital1.catcode=target.catcode;
                  targobj.vital1.subcatid=target.subcatid;
                  targobj.vital1.subcatname=target.subcatname + " / Semester";
                  targobj.vital1.subcatcode=target.subcatcode;
                  targobj.vital1.target=target.target;
                  break;
                case "vital2":
                  targobj.vital2.id=target.id;
                  targobj.vital2.catid=target.catid;
                  targobj.vital2.catname=target.catname;
                  targobj.vital2.catcode=target.catcode;
                  targobj.vital2.subcatid=target.subcatid;
                  targobj.vital2.subcatname=target.subcatname + " / Semester";
                  targobj.vital2.subcatcode=target.subcatcode;
                  targobj.vital2.target=target.target;
                  break;
                case "vital3":
                  targobj.vital3.id=target.id;
                  targobj.vital3.catid=target.catid;
                  targobj.vital3.catname=target.catname;
                  targobj.vital3.catcode=target.catcode;
                  targobj.vital3.subcatid=target.subcatid;
                  targobj.vital3.subcatname=target.subcatname + " / Semester";
                  targobj.vital3.subcatcode=target.subcatcode;
                  targobj.vital3.target=target.target;
                  break;
                case "vital4":
                  targobj.vital4.id=target.id;
                  targobj.vital4.catid=target.catid;
                  targobj.vital4.catname=target.catname;
                  targobj.vital4.catcode=target.catcode;
                  targobj.vital4.subcatid=target.subcatid;
                  targobj.vital4.subcatname=target.subcatname + " / Semester";
                  targobj.vital4.subcatcode=target.subcatcode;
                  targobj.vital4.target=target.target;
                  break;
                case "vital5":
                  targobj.vital5.id=target.id;
                  targobj.vital5.catid=target.catid;
                  targobj.vital5.catname=target.catname;
                  targobj.vital5.catcode=target.catcode;
                  targobj.vital5.subcatid=target.subcatid;
                  targobj.vital5.subcatname=target.subcatname + " / Semester";
                  targobj.vital5.subcatcode=target.subcatcode;
                  targobj.vital5.target=target.target;
                  break;
                case "vital6":
                  targobj.vital6.id=target.id;
                  targobj.vital6.catid=target.catid;
                  targobj.vital6.catname=target.catname;
                  targobj.vital6.catcode=target.catcode;
                  targobj.vital6.subcatid=target.subcatid;
                  targobj.vital6.subcatname=target.subcatname + " / Semester";
                  targobj.vital6.subcatcode=target.subcatcode;
                  targobj.vital6.target=target.target;
                  break;
              
                default:
                  break;
              }
              break;
            case "kelompokvital":
              targobj.kelompokvital.id=target.id;
              targobj.kelompokvital.catid=target.catid;
              targobj.kelompokvital.catname=target.catname + " / Semester";
              targobj.kelompokvital.catcode=target.catcode;
              targobj.kelompokvital.subcatid=target.subcatid;
              targobj.kelompokvital.subcatname=target.subcatname;
              targobj.kelompokvital.subcatcode=target.subcatcode;
              targobj.kelompokvital.target=target.target;
              break;
            case "spr":
              targobj.spr.id=target.id;
              targobj.spr.catid=target.catid;
              targobj.spr.catname=target.catname + " / Minggu";
              targobj.spr.catcode=target.catcode;
              targobj.spr.subcatid=target.subcatid;
              targobj.spr.subcatname=target.subcatname;
              targobj.spr.subcatcode=target.subcatcode;
              targobj.spr.target=target.target;
              break;
          
            default:
              break;
          }
        });
        outer.setState({
          form: {
            ...outer.state.form,
            // targetlist: data.data
          },
          targobj,
          mode: 'edit'
        })
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {
      this.setState({dialogOpen:false});
    }
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  handleValidation = (e) => {
    let {form,error,mode, targobj} = this.state;

    

    if(!this.isValidForm()){
      form.accept=false;
      error.accept="";
    }
    
    let targetlist = []
    Object.keys(targobj).forEach((key)=>{
      targetlist.push(targobj[key]);
    })

    this.setState({
      form:{...form, targetlist},
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
      
      this.handleSubmit();
      
    });

  }

  handleSubmit = () => {
    let {form} = this.state;
    const outer = this;
    this.setState({
      disableBtnSubmit:true
    }, () => {
        axios.post('bo/ft/user/ptarget/edit', form
        ).then(({data}) => {
          if (data.sta && data.stacod === 200) {
            outer.setState({
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="setting.targetperiode.success.edittargetperiode"/>);
            })
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
    });

  }

  

  isValidForm = () => {
    return true
  }

 

  handleChangeTarget(val, i, cat) {
    let targetlist = [...this.state.form.targetlist];
    let regex = /^[0-9]+$/;
      if(val != "") {
        targetlist[i].target = parseInt(val);
        if(regex.test(val)) {
          this.setState({
            form: {
              ...this.state.form,
              targetlist: targetlist
            }
          }, () => {
            let filtering = this.state.form.targetlist.filter(x => x.subcatcode != "" && x.catcode == "vital");
            let totaltarget = filtering.reduce(function(cnt, o) {
              return cnt + o.target;
            }, 0);
            let findIn = this.state.form.targetlist.findIndex(x => x.subcatcode == "" && x.catcode == "vital");
            let vitaltarget = [...this.state.form.targetlist];
            vitaltarget[findIn].target = totaltarget;
            this.setState({
              form: {
                ...this.state.form,
                targetlist: vitaltarget
              }
            })
          })
        }
      } else {
        targetlist[i].target = val;
        this.setState({
          form: {
            ...this.state.form,
            targetlist: targetlist
          }
        })
      }
  }

  handleFocus(val, i) {
    let targetlist = [...this.state.form.targetlist];
    if(val == "") {
      targetlist[i].target = 0;
      this.setState({
        form: {
          ...this.state.form,
          targetlist: targetlist
        }
      }, () => {
        let filtering = this.state.form.targetlist.filter(x => x.subcatcode != "" && x.catcode == "vital");
        let totaltarget = filtering.reduce(function(cnt, o) {
          return cnt + o.target;
        }, 0);
        let findIn = this.state.form.targetlist.findIndex(x => x.subcatcode == "" && x.catcode == "vital");
        let vitaltarget = [...this.state.form.targetlist];
        vitaltarget[findIn].target = totaltarget;
        this.setState({
          form: {
            ...this.state.form,
            targetlist: vitaltarget
          }
        })
      })
    } else {
      targetlist[i].target = parseInt(val);
      this.setState({
        form: {
          ...this.state.form,
          targetlist: targetlist
        }
      }, () => {
        let filtering = this.state.form.targetlist.filter(x => x.subcatcode != "" && x.catcode == "vital");
        let totaltarget = filtering.reduce(function(cnt, o) {
          return cnt + o.target;
        }, 0);
        let findIn = this.state.form.targetlist.findIndex(x => x.subcatcode == "" && x.catcode == "vital");
        let vitaltarget = [...this.state.form.targetlist];
        vitaltarget[findIn].target = totaltarget;
        this.setState({
          form: {
            ...this.state.form,
            targetlist: vitaltarget
          }
        })
      })
    }
  }

  handleChange = (e) => {
    let outer=this;
    let {targobj,error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    if(inputValue.trim() != ''){
      const re = /^[0-9\b]+$/;
      if (inputValue == '' || re.test(inputValue)) {
        targobj[inputName].target = +inputValue;
        this.setState({targobj},()=>{
          let subvital = ['vital1','vital2','vital3','vital4','vital5','vital6']; 
          if(subvital.includes(inputName)){
            targobj.vital.target = +targobj.vital1.target + +targobj.vital2.target + +targobj.vital3.target + +targobj.vital4.target + +targobj.vital5.target + +targobj.vital6.target;
            outer.setState({targobj});
          }
        });
      }
    }else{
      targobj[inputName].target = '';
      this.setState({targobj},()=>{
        let subvital = ['vital1','vital2','vital3','vital4','vital5','vital6']; 
        if(subvital.includes(inputName)){
          targobj.vital.target = +targobj.vital1.target + +targobj.vital2.target + +targobj.vital3.target + +targobj.vital4.target + +targobj.vital5.target + +targobj.vital6.target;
          outer.setState({targobj});
        }
      });
    }
  }

  changeFocus = (e) =>{
    let outer=this;
    let {targobj,error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    if(inputValue.trim() === ''){
      targobj[inputName].target = 0;
      this.setState({targobj},()=>{
        let subvital = ['vital1','vital2','vital3','vital4','vital5','vital6']; 
        if(subvital.includes(inputName)){
          targobj.vital.target = +targobj.vital1.target + +targobj.vital2.target + +targobj.vital3.target + +targobj.vital4.target + +targobj.vital5.target + +targobj.vital6.target;
          outer.setState({targobj});
        }
      });
    }
  }

  render(){
    const { classes, theme } = this.props;
    const { error, fulltimers, form, categories, targobj } = this.state;
    const catForm = Object.keys(targobj).map((key, i) => {
      return (

      <Grid item xs={12}>
        <TextField
          id={targobj[key].subcatcode != '' ? targobj[key].subcatcode : targobj[key].catcode}
          name={targobj[key].subcatcode != '' ? targobj[key].subcatcode : targobj[key].catcode}
          label={targobj[key].subcatcode != '' ? targobj[key].subcatname : targobj[key].catname}
          value={targobj[key].target}
          size="small"
          variant="outlined"
          fullWidth
          disabled={targobj[key].catcode == 'vital' && targobj[key].subcatcode == ''}
          onChange={this.handleChange}
          onBlur={this.changeFocus}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>  
      // <Card
      //   key={i}
      //   style={{marginBottom: 10}}
      //   raised
      // >
      //   <CardHeader
      //     title={cat.catname}
      //     subheader={cat.subcatname != "" && cat.subcatname}
      //   />
      //   <CardContent>
      //       <div className={classes.paper}>
      //           <Grid container spacing={2}>
      //               {/* <Grid item xs={12}>
      //                   <Autocomplete
      //                     options={categories}
      //                     getOptionLabel={option => option.name || ''}
      //                     value={cat.catobject || ''}
      //                     onChange={(event, val) => this.changeCategory(val, i)}
      //                     onInputChange={(event, val) => this.inputChangeCategory(val, i)}
      //                     onFocus={(event, val) => this.inputChangeCategory('', i)}
      //                     renderInput={
      //                       param => (
      //                         <TextField 
      //                           {...param}
      //                           id="category"
      //                           label="Kategori" 
      //                           fullWidth
      //                           required
      //                           InputLabelProps={{
      //                             shrink: true,
      //                           }}
      //                           error={cat.errorcatid?true:false}
      //                         />
      //                       )
      //                     }
      //                   />
      //                   {
      //                     cat.errorcatid &&   
      //                       <FormControlLabel
      //                         classes={{label:classes.labelerror}}
      //                         control={<div>&nbsp;&nbsp;&nbsp;</div>}
      //                         label={cat.errorcatid}
      //                     />
      //                   }
      //               </Grid> */}
      //               {/* {
      //                 cat.subcategories.length > 0 &&
      //                 <Grid item xs={12}>
      //                     <Autocomplete
      //                       options={cat.subcategories}
      //                       getOptionLabel={option => option.name || ''}
      //                       value={cat.subcatobject || ''}
      //                       onChange={(event, val) => this.changeSubCategory(val, i)}
      //                       onInputChange={(event, val) => this.inputChangeSubCategory(val, i)}
      //                       onFocus={(event, val) => this.inputChangeSubCategory('', i)}
      //                       renderInput={
      //                         param => (
      //                           <TextField 
      //                             {...param}
      //                             id="subcategory"
      //                             label="Sub Kategori" 
      //                             fullWidth
      //                             InputLabelProps={{
      //                               shrink: true,
      //                             }}
      //                           />
      //                         )
      //                       }
      //                     />
      //                 </Grid>
      //               } */}
      //               <Grid item xs={12}>
      //                 <TextField
      //                   id="target"
      //                   name="target"
      //                   label={cat.catcode == "vital" && cat.subcatcode == "" ? "Target Total" : "Target"}
      //                   value={cat.target}
      //                   disabled={cat.catcode == "vital" && cat.subcatcode == ""}
      //                   size="small"
      //                   fullWidth
      //                   onBlur={(val) => this.handleFocus(val.target.value, i)}
      //                   onChange={(val) => {
      //                     this.handleChangeTarget(val.target.value, i, cat)
      //                   }}
      //                   InputLabelProps={{
      //                     shrink: true,
      //                   }}
      //                 />
      //               </Grid>
      //           </Grid>
      //       </div>
      //   </CardContent>
      // </Card>
      )
    })
    return (
      <div style={{marginTop: theme.spacing(4)}}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit">
              Fulltimer
            </Link>
            <Link color="textPrimary" href="/bo/targetperiode/edit">
              {
                this.state.mode == "add" ?
                "Tambah Target Periode" : "Ubah Target Periode"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
              <form className={classes.form} noValidate>

                  
                  <Grid container spacing={2}>
                    
                    {/* <Grid item xs={12}>
                      <Button
                        type="button"
                        className={classes.submit}
                        color="primary"
                        variant="outlined"
                        onClick={() => this.addCat()}
                        fullWidth
                      >
                        TAMBAH KATEGORI
                      </Button>
                    </Grid> */}
                    <br/>
                    {/* <Grid item xs={12}> */}
                      {catForm}
                    {/* </Grid> */}
                    <br/>
                                     
      
                  </Grid>
                  <Button
                    type="button"
                    className={classes.submit}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={this.handleValidation}
                    disabled={this.state.disableBtnSubmit}
                  >
                    UPDATE
                  </Button>
                </form>
          </div>
        </Container>

        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );
  }
}

DbFulltimerTargetPeriodeEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbFulltimerTargetPeriodeEdit)));