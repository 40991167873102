import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {IconButton, TextField, Checkbox, Card, Tooltip, Grid,
  CardMedia ,CardContent, Typography, Button} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {MoodBad} from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import {compareSequence} from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import IntlMessages from "../../../../../util/IntlMessages";

import DataTable from 'react-data-table-component';
import DbScheduleEdit from './../edit/index';
//import actions
import { addParam } from "../../../../../store/actions/configAction";

import WeekScheduler from './../../../../../component/weekschedule';
import moment from 'moment';

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});





class DbSchedule extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        page:{
          keyword: '',
          page:1,
          count:10
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        dialogMode:'',
        detailOpen:false,
        detailTitle:'Schedule Detail',
        detailContent:[],
        selectedRows:{},
        selectedTarget: '',
        nextMon: "",
        nextMonOpen: false,
        openEdit: false,
        events: {},
        remaining: {},
        scheduleId: ""
      }
  }

  columns = [
    // {
    //   name: 'Action',
    //   button: true,
    //   cell: row => {return <div>
    //     <IconButton color="primary" aria-label="detail" component="span" size="small" style={{marginRight:5}} onClick={()=>this.openServiceDetail(row)}>
    //         <List />
    //     </IconButton>
    //   </div>},
    // },
    {
      name: <IntlMessages id="setting.shepherd.penatuaname"/>,
      selector: 'penatuaname',
      sortable: true,
    },
    {
      name: <IntlMessages id="setting.shepherd.fulltimer"/>,
      selector: 'username',
      sortable: true,
    },
  ];

  componentDidMount(){
    let firstWeek = moment().startOf('week');
    let checkMonday = moment(firstWeek).add(1, "day");
    let nextMonday = moment(checkMonday).add(1, "week").format("DD-MM-YYYY");
    if(moment().day() == 0){
      nextMonday = checkMonday.format("DD-MM-YYYY");
    }
    this.setState({
      nextMon: nextMonday
    });
    this.getScheduleList();
    this.getRemaining();
  }

  showDialog = (title,content,mode) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content,
      dialogMode:mode
    });
  }

  

  handleDialogClose = () => {
    this.setState({
      dialogOpen:false
    })
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({
        dialogOpen:false
      });
      this.props.history.push("/logout");
    } else {

      this.setState({
        dialogOpen:false
      });
      switch (this.state.dialogMode) {
        case "delete":
          this.handleDeleteSchedule();
          break;
        case "reset":
          this.handleResetSchedule();
          break;
      
        default:
          break;
      }
    }
  }

  getRemaining = () => {
    let outer = this;
    axios.post('bo/ft/user/target/remaining', {}).then(({data})=>{
      
      if(data.sta && data.stacod === 200){
        
        this.setState({
          remaining: data.data
        });
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  getScheduleList = () => {
    let outer = this;
    axios.post('bo/ft/user/schedule/list', {}).then(({data})=>{
      
      if(data.sta && data.stacod === 200){
        
        Object.keys(data.data).forEach((e) => {
          if(data.data[e].length > 0) {
            data.data[e].forEach((item) => {
              item.startTime = moment(item.timestart.replace('Z', ''));
              item.endTime = moment(item.timeend.replace('Z', ''));
              switch(e) {
                case "sunday":
                item.day = "0"
                break;
                case "monday":
                item.day = "1"
                break;
                case "tuesday":
                item.day = "2"
                break;
                case "wednesday":
                item.day = "3"
                break;
                case "thursday":
                item.day = "4"
                break;
                case "friday":
                item.day = "5"
                break;
                case "saturday":
                item.day = "6"
                break;
                default:
                break;
              }
              switch(item.servicetypecode) {
                case "korporat":
                item.color = "red"
                break; 
                case "lewihall":
                item.color = "#7FFF00"
                break; 
                case "lewiks":
                item.color = "blue"
                break; 
                case "transport":
                item.color = "#FFC300"
                break; 
                // case "kolam":
                // item.color = "#FF33D4"
                // break; 
                // case "pelayanan":
                // item.color = "purple"
                // break; 
                case "lain":
                item.color = "#424949"
                break; 
                case "kokyn":
                item.color = "#7B3F00"
                break; 
                case "domba":
                item.color = "#2cbfbf"
                break; 
                case "injil":
                item.color = "#75224a"
                break; 
                default:
                break;
              }
            })
          }
        });
        this.setState({
          events: data.data
        });
      } else {
        outer.showDialog("Warning", data.msg, "");
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }
 

  deleteSchedule = (id) => {
      this.setState({
        scheduleId: id
      }, () => {
        this.showDialog("Hapus Schedule","Anda akan menghapus data ini. Apakah anda yakin?","delete");
      })
  }
   
  handleDeleteSchedule = () => {
    
    const outer = this;
    
    this.setState({
      disableBtnSubmit:true
    }, () => {
      axios.post('bo/ft/user/schedule/del', {id:this.state.scheduleId}
      ).then(({data}) => {
        if (data.sta && data.stacod === 200) {
          outer.setState({
            disableBtnSubmit:false,
            scheduleId: ""
          }, () => {
            outer.showDialog('Information',<IntlMessages id="fulltimer.schedule.success.deleteschedule"/>,"");
            outer.updateRemaining(data.data);
            outer.getScheduleList();
            outer.closeEdit();
          })
          
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',data.msg,"");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
      });
    });
  }

  handleResetSchedule = () => {
    
    const outer = this;
    
    this.setState({
      disableBtnSubmit:true
    }, () => {
      axios.post('bo/ft/schedule/reset', {}
      ).then(({data}) => {
        if (data.sta && data.stacod === 200) {
          outer.setState({
            disableBtnSubmit:false,
            scheduleId: ""
          }, () => {
            outer.showDialog('Information',"Jadwal telah berhasil di reset","");
            outer.getScheduleList();
            outer.getRemaining();
          })
          
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',data.msg,"");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
      });
    });
  }

  handleChangeWeek = () => {
    
    const outer = this;
    
    this.setState({
      disableBtnSubmit:true
    }, () => {
      axios.post('bo/ft/schedule/weekchange', {}
      ).then(({data}) => {
        if (data.sta && data.stacod === 200) {
          outer.setState({
            disableBtnSubmit:false,
            scheduleId: ""
          }, () => {
            outer.showDialog('Information',"Tanggal mulai jadwal telah berhasil diubah","");
            outer.getScheduleList();
          })
          
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',data.msg,"");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
      });
    });
  }

  closeEdit() {
    this.setState({
      openEdit: false,
      selectedRows: {}
    });
  }

  openDetil(event) {
    this.setState({
      openEdit: true,
      selectedRows: event
    });
  }

  openInfo(val, data) {
    this.showDialog(val, data);
  }

  updateRemaining(val) {
    this.setState({
      remaining: val
    })
  }

  onSelectCard(id) {
    this.setState({
      selectedTarget: id,
      openEdit: true
    })
  }

  warningReset = () => {
    this.showDialog("Reset Schedule","Anda akan menghapus semua realisasi dan schedule. Apakah anda yakin?","reset");
  }
   
  render() {
    const { classes, container, theme, content, title } = this.props;
    const { remaining} = this.state;
    return (
      <div style={{marginTop: theme.spacing(4), height: '100%'}}>
          <Alert style={{marginTop: 5}} severity="info">
            <span>Jika terjadi perubahan atau penambahan jadwal, maka jadwal tersebut akan muncul dan diterapkan pada hari senin di minggu selanjutnya</span> 
          </Alert>
          <Tooltip
            title="Tambah Jadwal"
          >

            <IconButton
              color="primary" 
              aria-label="detail" 
              component="span" 
              // onClick={()=>{this.props.history.push("/bo/master/fulltimer/edit")}} 
              onClick={()=>{this.setState({openEdit: true, selectedTarget: ""})}} 
            >
                <Add />
            </IconButton>
          </Tooltip>
            {/* <Button onClick={this.warningReset} style={{color:'red'}}>
              Hapus Schedule
            </Button>
            <Button onClick={this.handleChangeWeek} color="primary">
              Ubah Tanggal Mulai
            </Button> */}
          <br/>
            <Grid style={{textAlign: 'center'}} container spacing={3}>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                {
                  remaining.korporat &&
                  <Card onClick={() => this.onSelectCard(remaining.korporat.id)} style={{color: 'red', cursor: 'pointer'}}>
                    <CardContent>
                      <Typography>
                          {remaining.korporat.name}
                      </Typography>
                      <Typography>
                          {remaining.korporat.remaintarget}
                      </Typography>
                    </CardContent>
                  
                  </Card>
                }
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                {
                  remaining.lewihall &&
                  <Card onClick={() => this.onSelectCard(remaining.lewihall.id)} style={{color: 'green' , cursor: 'pointer'}}>
                    <CardContent>
                      <Typography>
                        {remaining.lewihall.name}
                      </Typography>
                      <Typography>
                          {remaining.lewihall.remaintarget}
                      </Typography>
                    </CardContent>
                  
                  </Card>
                }
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                {
                  remaining.lewiks &&
                  <Card onClick={() => this.onSelectCard(remaining.lewiks.id)} style={{color: 'blue' , cursor: 'pointer'}}>
                    <CardContent >
                      <Typography>
                          {remaining.lewiks.name}
                      </Typography>
                      <Typography>
                          {remaining.lewiks.remaintarget}
                      </Typography>
                    </CardContent>
                  
                  </Card>
                }
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                {
                  remaining.transport &&
                  <Card onClick={() => this.onSelectCard(remaining.transport.id)} style={{color: '#FFC300' , cursor: 'pointer'}}>
                    <CardContent>
                      <Typography>
                          {remaining.transport.name}
                      </Typography>
                      <Typography>
                          {remaining.transport.remaintarget}
                      </Typography>
                    </CardContent>
                  
                  </Card>
                }
              </Grid>
              {/* <Grid item xs={6} sm={6} md={4} lg={4}>
                {
                  remaining.kolam &&
                  <Card onClick={() => this.onSelectCard(remaining.kolam.id)} style={{color: '#FF33D4' , cursor: 'pointer'}}>
                    <CardContent>
                      <Typography>
                          {remaining.kolam.name}
                      </Typography>
                      <Typography>
                          {remaining.kolam.remaintarget}
                      </Typography>
                    </CardContent>
                  
                  </Card>
                }
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                {
                  remaining.pelayanan &&
                  <Card onClick={() => this.onSelectCard(remaining.pelayanan.id)} style={{color: "purple" , cursor: 'pointer'}}>
                    <CardContent>
                      <Typography>
                          {remaining.pelayanan.name}
                      </Typography>
                      <Typography>
                          {remaining.pelayanan.remaintarget}
                      </Typography>
                    </CardContent>
                  
                  </Card>
                }
              </Grid> */}
              <Grid item xs={6} sm={6} md={4} lg={4}>
                {
                  remaining.lainnya &&
                  <Card onClick={() => this.onSelectCard(remaining.lainnya.id)} style={{color: '#424949' , cursor: 'pointer'}}>
                    <CardContent>
                      <Typography>
                          {remaining.lainnya.name}
                      </Typography>
                      <Typography>
                          {remaining.lainnya.remaintarget}
                      </Typography>
                    </CardContent>
                  
                  </Card>
                }
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                {
                  remaining.kokyn &&
                  <Card onClick={() => this.onSelectCard(remaining.kokyn.id)} style={{color: '#7B3F00' , cursor: 'pointer'}}>
                    <CardContent>
                      <Typography>
                          {remaining.kokyn.name}
                      </Typography>
                      <Typography>
                          {remaining.kokyn.remaintarget}
                      </Typography>
                    </CardContent>
                  
                  </Card>
                }
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                {
                  remaining.domba &&
                  <Card onClick={() => this.onSelectCard(remaining.domba.id)} style={{color: '#2cbfbf' , cursor: 'pointer'}}>
                    <CardContent>
                      <Typography>
                          {remaining.domba.name}
                      </Typography>
                      <Typography>
                          {remaining.domba.remaintarget}
                      </Typography>
                    </CardContent>
                  
                  </Card>
                }
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                {
                  remaining.injil &&
                  <Card onClick={() => this.onSelectCard(remaining.injil.id)} style={{color: '#75224a' , cursor: 'pointer'}}>
                    <CardContent>
                      <Typography>
                          {remaining.injil.name}
                      </Typography>
                      <Typography>
                          {remaining.injil.remaintarget}
                      </Typography>
                    </CardContent>
                  
                  </Card>
                }
              </Grid>
            </Grid>
          <br/>
          {/* {
            this.state.nextMonOpen &&
            <> */}
          <Alert style={{marginTop: 5}} severity="info">
            Jadwal berikutnya: {this.state.nextMon} 
          </Alert>
          <br/>
            {/* </>
          } */}
        
          

          <WeekScheduler 
            eventProp={this.state.events}
            openDetil={(detil) => this.openDetil(detil)}
          />
         
          <DbScheduleEdit
            openEdit={this.state.openEdit}
            selected={this.state.selectedRows}
            selectedTarget={this.state.selectedTarget}
            closeModal={() => this.closeEdit()}
            getSchedule={() => this.getScheduleList()}
            openInfo={(val, data) => this.openInfo(val, data)}
            delSchedule={(id) => this.deleteSchedule(id)}
            addRemaining={(remain) => this.updateRemaining(remain)}
          />
          <DialogBasic
            mode={this.state.dialogMode}
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
          />  
          <DialogDetail
            open={this.state.detailOpen} 
            title={this.state.detailTitle}
            content={this.state.detailContent}
            handleClose={this.handleDetailClose}
            handleOk={this.handleDetailClose}
            />  
      </div>
    );
  }
}

DbSchedule.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbSchedule)));