import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {IconButton, TextField, Checkbox, Card, FormControl, Paper,
  CardMedia ,CardContent, Typography, Select, InputLabel, MenuItem} from '@material-ui/core';
import {MoodBad} from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import {compareSequence} from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DataTable from 'react-data-table-component';
import moment from 'moment';
//import actions
import { addParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions:[10,25,100],
  print:false,
  download:false,
  selectableRows:'single',
  isRowSelectable:function(){return false}
};

class DbReportAnugerahBibleft extends React.Component {
  constructor(props){
      super(props);
      let dat = new Date();
      dat.setDate(dat.getDate() - 7);
      this.state = {
        setdate : "",
        showdata : "table",
        page:{
          type: "weekly",
          start: moment().subtract(7, "day").format('YYYY-MM-DD'),
          startPar: dat,
          end: moment().format('YYYY-MM-DD'),
          endPar: new Date()
        },
        loadingTrigger:false,
        bibleList:[],
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        dialogMode:'',
        detailOpen:false,
      }
  }

  columns = [
   
    {
      name: <IntlMessages id="report.task.date"/>,
      selector: 'date',
      sortable: true,
      format: row => moment(row.date).format("DD-MM-YYYY")
    },
    {
      name: <IntlMessages id="report.bible.target"/>,
      selector: 'target',
    },
    {
      name: <IntlMessages id="report.bible.real"/>,
      selector: 'realization',
    },
    {
      name: <IntlMessages id="report.bible.percent"/>,
      selector: 'percent',
      cell: row => <span
        style={
          row.percentnum == 0 ?
          {color: 'red'} : row.percentnum > 0 && row.percentnum < 26 ? {color: "pink"} : 
          row.percentnum > 25 && row.percentnum < 51 ? {color: "orange"} : row.percentnum > 50 && row.percentnum < 76 ? {color: 'green'} :
          row.percentnum > 75 && row.percentnum < 101 ? {color: "blue"} : row.percentnum > 100 && {color: "black"}
        }
      >
        {row.percent}
      </span>
    },
    {
      name: <IntlMessages id="report.bible.freq"/>,
      selector: 'freq',
    },
    {
      name: <IntlMessages id="report.bible.time"/>,
      selector: 'time',
    },
    {
      name: <IntlMessages id="report.bible.efficiency"/>,
      selector: 'efficiency',
    }
  ];

  componentDidMount(){
    this.getBibleReport();
  }

  showDialog = (title,content,mode) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content,
      dialogMode:mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen:false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen:false
    })
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {
      this.setState({dialogOpen:false});
    }
  }
  
  changeType = (val) => {
    if(val) {
      this.setState({
        page: {
          ...this.state.page,
          type: val.target.value
        }
      }, () => {
        this.getBibleReport();
      })
    }
  }


  getBibleReport = () => {
    let outer = this;
    this.setState({
      loadingTrigger:true
    });

    axios.post('bo/ft/report/bible/user', outer.state.page).then(({data})=>{
      this.setState({
        loadingTrigger:false
      });
      if(data.sta && data.stacod === 200){
        this.setState({
          bibleList: data.data
        })
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  

  changeStart = (date) => {
    let momentParse = moment(date).format("YYYY-MM-DD");
    this.setState({
      page: {
        ...this.state.page,
        startPar: date,
        start: momentParse,
      }
    }, () => {
      this.getBibleReport();
    })
  }

  changeEnd = (date) => {
    let momentParse = moment(date).format("YYYY-MM-DD");
    this.setState({
      page: {
        ...this.state.page,
        endPar: date,
        end: momentParse,
      }
    }, () => {
      this.getBibleReport();
    })
  }

  handleChangeSet = (val) => {
    let outer = this;
    this.setState({
      setdate: val.target.value
    }, () => {
      let dat = new Date();
      // dat.setDate(dat.getDate() - 7);
      if(outer.state.setdate == "1minggu") {
        dat.setDate(dat.getDate() - 7);
        outer.setState({
          page: {
            ...this.state.page,
            start: moment().subtract(7, "day").format('YYYY-MM-DD'),
            startPar: dat,
            end: moment().format('YYYY-MM-DD'),
            endPar: new Date()
          }
        }, () => {
          outer.getBibleReport();
        });
      }
      if(outer.state.setdate == "2minggu") {
        dat.setDate(dat.getDate() - 14);
        outer.setState({
          page: {
            ...this.state.page,
            start: moment().subtract(14, "day").format('YYYY-MM-DD'),
            startPar: dat,
            end: moment().format('YYYY-MM-DD'),
            endPar: new Date()
          }
        }, () => {
          outer.getBibleReport();
        });
      }
      if(outer.state.setdate == "bulan") {
        dat.setDate(dat.getDate() - 30);
        outer.setState({
          page: {
            ...this.state.page,
            start: moment().subtract(30, "day").format('YYYY-MM-DD'),
            startPar: dat,
            end: moment().format('YYYY-MM-DD'),
            endPar: new Date()
          }
        }, () => {
          outer.getBibleReport();
        });
      }
    })
  }

  handleChangeShow = (val) => {
    this.setState({
      showdata: val.target.value
    })
  }

   
  render() {
    const { theme } = this.props;
    const { bibleList} = this.state;
      
    return (
      <div style={{marginTop: theme.spacing(4), height: '100%'}}>
        <div style={{paddingTop:10}}>
        <Typography variant="h5">
          Laporan Injil Anugerah
        </Typography>
        </div>
        <div className="filter">
          <div>
            <FormControl
              style={{
                minWidth: '100%',
                marginBottom: 10
              }}
            >

              <InputLabel id="settype" style={{backgroundColor:"#fff", paddingLeft: 4, paddingRight: 4}} shrink variant="outlined">
                Pilih Tipe
              </InputLabel>
              <Select
                value={this.state.page.type}
                onChange={this.changeType}
                variant="outlined"
                label="Pilih Tipe"
                labelId="settype"
                style={{width: '100%'}}
              >
                <MenuItem value="weekly">Mingguan</MenuItem>
                <MenuItem value="monthly">Bulanan</MenuItem>
                {/* <MenuItem value="yearly">Tahunan</MenuItem> */}

              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl
              style={{
                minWidth: '100%',
                marginBottom: 10
              }}
            >

              <InputLabel id="set"  style={{backgroundColor:"#fff", paddingLeft: 4, paddingRight: 4}} shrink variant="outlined">
                Set Date
              </InputLabel>
              <Select
                value={this.state.setdate}
                onChange={this.handleChangeSet}
                variant="outlined"
                label="Set Date"
                labelId="set"
                style={{width: '100%'}}
              >
                <MenuItem value="1minggu">1 Minggu</MenuItem>
                <MenuItem value="2minggu">2 Minggu</MenuItem>
                <MenuItem value="bulan">1 Bulan</MenuItem>

              </Select>
            </FormControl>
          </div>

          <div style={{marginBottom: 10}}>
              {/* <InputLabel style={{fontSize: 12}} id="formtype">
                Start Date
              </InputLabel> */}
              <KeyboardDatePicker
                clearable
                value={this.state.page.startPar}
                // placeholder="Start"
                label="Start Date"
                inputVariant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={date => this.changeStart(date)}
                format="DD-MM-YYYY"
                fullWidth
              />
          </div>
          <div style={{marginBottom: 10}}>
              {/* <InputLabel style={{fontSize: 12}} id="formtype">
                End Date
              </InputLabel> */}
              <KeyboardDatePicker
                clearable
                value={this.state.page.endPar}
                // placeholder="End"
                label="End Date"
                inputVariant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={date => this.changeEnd(date)}
                format="DD-MM-YYYY"
                fullWidth
              />
          </div>
          <div>
              <FormControl
                style={{
                  minWidth: '100%',
                  marginBottom: 10
                }}
              >
                <InputLabel id="show" style={{backgroundColor:"#fff", paddingLeft: 4, paddingRight: 4}} shrink variant="outlined">
                  Tampilkan sebagai
                </InputLabel>
                <Select
                  value={this.state.showdata}
                  onChange={this.handleChangeShow}
                  variant="outlined"
                  label="Tampilkan sebagai"
                  labelId="show"
                  style={{width: '100%'}}
                >
                  <MenuItem value="table">Tabel</MenuItem>
                  <MenuItem value="chart">Grafik</MenuItem>

                </Select>
              </FormControl>
          </div>
          <div>
            <Typography variant="subtitle2">
                * Keterangan Persentase: 
            </Typography>
            <div>
              <span style={{color: 'red'}}>0</span> / <span style={{color: 'pink'}}>0-25%</span> / <span style={{color: 'orange'}}>26%-50%</span> /&nbsp;
              <span style={{color: 'green'}}>51%-75%</span> / <span style={{color: 'blue'}}>76%-100%</span> / <span style={{color: 'black'}}>&gt;100%</span>
            </div>
          </div>
        </div>
        {
          this.state.showdata == "table" &&
          <DataTable
            noHeader
            columns={this.columns}
            data={bibleList}
            noDataComponent={
              <Card style={{maxWidth: 200, borderWidth: 1, borderStyle: 'solid', marginTop: 10}}>
                <CardMedia style={{textAlign: 'center'}}>
                  <MoodBad color="action" style={{
                    fontSize: 40, 
                    marginTop: 10, 
                    marginBottom: 10
                  }}/>
                </CardMedia>
                <CardContent>
                  <Typography>
                    Data tidak tersedia
                  </Typography>
                </CardContent>
              </Card>
            }
            highlightOnHover
            customStyles={customStyles}
            pagination={false}
            progressPending={ this.state.loadingTrigger}
          progressComponent={<Loader type="TailSpin" color="#204196" height={100} width={100}/>}
          />
        }
        {
          this.state.showdata == "chart" &&
          <Paper style={{marginTop: 350}}>
              <div style={{width: '100%', height: 300}}>

                <ResponsiveContainer>

                  <LineChart
                    data={bibleList}
                    margin={{
                      top: 10,
                      bottom: 10,
                      right: 15
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis/>
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="target" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="realization" stroke="#FF0000" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
          </Paper>
        }

          <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
          />  
         
      </div>
    );
  }
}

DbReportAnugerahBibleft.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbReportAnugerahBibleft)));