import React from "react";
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";

function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        Not found path : <code>{location.pathname}</code><br/><br/><a href="/">back to home</a> 
      </h3>
    </div>
  );
}

export default NoMatch