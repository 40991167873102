import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import axios from '../config/axios';

const PrivateRoute = ({ component: Component, roles, token, ...rest }) => (
    <Route {...rest} render={props => {
        let role = token !== null ? token.role : "";
        if (token === null) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }
        
        axios.defaults.headers.common['Authorization'] = "Bearer " + token.token.tkn;
        // check if route is restricted by role
        // if (roles && roles.indexOf(role) === -1) {
        
        if (roles) {
            // role not authorised so redirect to home page
            let matchRole = roles.find(x => x == token.token.role);
            if(!matchRole) {

                if(token.token.role == "Administrator" || token.token.role == "Internal") {
    
                    return <Redirect to={{ pathname: '/bo/dashboard'}} />
                } else {
                    if(token.token.role == "Penatua") {
                        return <Redirect to={{ pathname: '/bo/dashboardpenatua'}} />
                    } else {
                        return <Redirect to={{ pathname: '/bo/dashboardft'}} />
                    }
                }
            }
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)

const mapStatestoProps = state => {
    return {
        token: state.token
    };
};
  
export default connect(
mapStatestoProps
)(PrivateRoute);
 