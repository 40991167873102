import React from 'react';
import { 
  Button, CssBaseline, TextField, FormControlLabel, Grid,
  Box, Typography, Container 
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {ValidateEmail} from '../utility/validation';
import Setting from '../config/setting';
import Copyright from '../component/copyright';
// import { loadReCaptcha } from 'react-recaptcha-v3'
import Headerfront from '../component/headerfront'
import DialogBasic from '../component/dialogbasic';
import { connect } from "react-redux";
import axios from '../config/axios';
import { Redirect, Link } from "react-router-dom";
import Errormsg from "../config/errormsg";
import IntlMessages from "../util/IntlMessages";
import { loadReCaptcha } from 'react-recaptcha-v3'
import { ReCaptcha } from 'react-recaptcha-v3'

//import actions
import { addToken } from "../store/actions/configAction";

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

class ForgotPasswordConfirm extends React.Component {
  _isMounted = false;
  _postValid = false;

  constructor(props){
      super(props);
      this.state = {
        form: {
          email:"",
          resetcode: "",
          newpwd: "",
          confirmpwd: "",
          t:''
        },
        error:{
          email:"",
          resetcode: "",
          newpwd: "",
          confirmpwd: "",
          notmatch: ""
        },
        reloadRecaptcha:new Date(),
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        isSubmitSuccess:false,
        reset: false
    };
  }

  componentDidMount() {
    if(this.props.location.state) {
      this.setState({
        form: {
          ...this.state.form,
          email: this.props.location.state.email
        }
      })
    }
    this._isMounted = true;

    const outer = this;
    loadReCaptcha(Setting["recaptcha-site-key"]);
    this.intervalId = setInterval(function(){ 
      outer.reloadRecaptcha();
    }, Setting["recaptcha-interval"]);
  }

  componentWillUnmount(){
    this._isMounted = false;
    clearInterval(this.intervalId);
  }

  handleDialogClose = () => {
    const outer = this;
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    const outer = this;
    if(this._postValid && this.state.reset && 
      this.state.form.newpwd &&
      this.state.form.confirmpwd &&
      this.state.error.notmatch == "") {
      this.props.history.push("/");
    }
    this.setState({dialogOpen:false});
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  reloadRecaptcha =() => {
    if (this._isMounted) {
      this.setState({
        reloadRecaptcha:new Date()
      });
    }
  }

  handleChange = (e) => {
    let outer=this;
    let form = this.state.form;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;

    this.setState({
      form:form
    }, () => {
      switch(inputName){
        case "email":
          if(inputValue){
            form[inputName]=inputValue.toLowerCase();
            outer.setState({form:form});
          }
        break; 
        default:
        break;
      }
    });
  }

  handleValidation = (e) => {
    let {form,error} = this.state;

    if(form.email.trim().length === 0){
      error.email="Alamat email harus diinput";
    }else{
      error.email="";
    }

    if(form.resetcode.trim().length === 0){
      error.resetcode="Kode harus diinput";
    }else{
      error.resetcode="";
    }

    if(this.state.reset) {
      if(form.newpwd.trim().length === 0){
        error.newpwd="Password harus diinput";
      }else{
        error.newpwd="";
      }

      if(form.confirmpwd.trim().length === 0){
        error.confirmpwd="Konfirmasi password harus diinput";
      }else{
        error.confirmpwd="";
      }
      
      if(form.newpwd != form.confirmpwd) {
        error.notmatch="Password dan konfirmasi password tidak sama";
      } else {
        error.notmatch=""
      }
    }

    
    this.setState({
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
  
      let validEmail = ValidateEmail(form.email.trim());
      if(!validEmail){
          error.email="Format alamat email tidak benar";   
      }else{
          error.email="";  
      }
  
      this.setState({
        error:error
      }, () => {
        if(!this.isValidForm()){
          return;
        }

        if(this.state.reset) {
          if(this.state.error.notmatch == "") {
            this.handleSubmitReset();
          }
        } else {
            this.handleSubmit();
        }

      });
  
    });
  }

  handleSubmit = (e) => {
    let {form} = this.state;
    const outer = this;
    this.setState({
      disableBtnSubmit:true
    }, () => {
      // this.props.onAddToken({token:"login token"});
      // outer.setState({toDashboard:true})
      axios.post('bo/int/forgotpass/conf', form
      ).then(({data}) => {
        if (data.sta && data.stacod === 200) {
          outer._postValid = true;
          outer.reloadRecaptcha();
          outer.showDialog("Success", "Input your new password");
          outer.setState({
            disableBtnSubmit: false,
            reset: true
          });
        } else {
          outer._postValid = false;
          outer.setState({
            disableBtnSubmit:false
          });
          outer.reloadRecaptcha();
          outer.showDialog('Warning',data.msg);
        }
      }).catch(function (error) {
        outer._postValid = false;
        outer.setState({
          disableBtnSubmit:false
        });
        outer.reloadRecaptcha();
        outer.showDialog('Warning',Errormsg["500"]);
      });

    });
    
  }

  handleSubmitReset = (e) => {
    let {form} = this.state;
    const outer = this;
    this.setState({
      disableBtnSubmit:true
    }, () => {
      // this.props.onAddToken({token:"login token"});
      // outer.setState({toDashboard:true})
      axios.post('bo/int/forgotpass/reset', form
      ).then(({data}) => {
        if (data.sta && data.stacod === 200) {
          outer.showDialog("Information", "Now, you can login with your new password");
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          // outer.reloadRecaptcha();
          outer.showDialog('Warning',data.msg);
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        // outer.reloadRecaptcha();
        outer.showDialog('Warning',Errormsg["500"]);
      });

    });

  }

  isValidForm = () => {
    if(this.state.reset) {
      return !this.state.error.newpwd &&     
      !this.state.error.confirmpwd 
    } else {
      return !this.state.error.email &&
      !this.state.error.resetcode && !this.state.error.notmatch
    }
  }

  verifyCallback = (recaptchaToken) => {
    let {form} = this.state;
    form.t=recaptchaToken;
    this.setState({form:form})
  }

  render(){
    const { classes, token } = this.props;
    const { error,form } = this.state;

      return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Headerfront />
            <Typography component="h1" variant="h5" align="center">
              {
                this.state.reset ?
                "Reset Password" :
                "Input your reset code from your email"
              }
            </Typography>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="email"
                    name="email"
                    label={<IntlMessages id="emailaddress"/>}
                    value={this.state.form.email} 
                    variant="outlined"
                    disabled={this.state.reset}
                    required
                    fullWidth
                    onChange={this.handleChange} 
                    error={error.email?true:false}
                  />
                    {
                      error.email &&   
                        <FormControlLabel
                          classes={{label:classes.labelerror}}
                          control={<div>&nbsp;&nbsp;&nbsp;</div>}
                          label={error.email}
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="resetcode"
                    name="resetcode"
                    label={"Reset Code"}
                    value={this.state.form.resetcode} 
                    variant="outlined"
                    disabled={this.state.reset}
                    required
                    fullWidth
                    onChange={this.handleChange} 
                    error={error.resetcode?true:false}
                  />
                    {
                      error.resetcode &&   
                        <FormControlLabel
                          classes={{label:classes.labelerror}}
                          control={<div>&nbsp;&nbsp;&nbsp;</div>}
                          label={error.resetcode}
                        />
                    }
                </Grid>

                {
                  this.state.reset &&
                  <Grid item xs={12}>
                    <TextField
                      id="newpwd"
                      name="newpwd"
                      type="password"
                      label="Password Baru"
                      value={this.state.form.newpwd}
                      variant="outlined"
                      required
                      fullWidth
                      onChange={this.handleChange}
                      error={error.newpwd?true:false}
                    />
                      {
                          error.newpwd &&   
                            <FormControlLabel
                              classes={{label:classes.labelerror}}
                              control={<div>&nbsp;&nbsp;&nbsp;</div>}
                              label={error.newpwd}
                            />
                      }
                  </Grid>
                }

                {
                  this.state.reset &&
                  <Grid item xs={12}>
                    <TextField
                      id="confirmpwd"
                      name="confirmpwd"
                      type="password"
                      label="Konfirmasi Password"
                      value={this.state.form.confirmpwd}
                      variant="outlined"
                      required
                      fullWidth
                      onChange={this.handleChange}
                      error={error.confirmpwd?true:false}
                    />
                      {
                          error.confirmpwd &&   
                            <FormControlLabel
                              classes={{label:classes.labelerror}}
                              control={<div>&nbsp;&nbsp;&nbsp;</div>}
                              label={error.confirmpwd}
                            />
                      }
                      {
                          error.notmatch &&   
                            <FormControlLabel
                              classes={{label:classes.labelerror}}
                              control={<div>&nbsp;&nbsp;&nbsp;</div>}
                              label={error.notmatch}
                            />
                      }
                  </Grid>
                }



              </Grid>
              <Button
                type="button"
                className={classes.submit}
                color="secondary"
                variant="contained"
                fullWidth
                onClick={this.handleValidation}
                disabled={this.state.disableBtnSubmit || this.state.form.t == ''}
              >
                submit
              </Button>
              <Grid container justify="flex-end" className={classes.konfirmasi}>
                <Grid item>
                  <Link to="/" variant="body2">
                    Kembali ke login
                  </Link>
                </Grid>
              </Grid>
              <Grid container justify="flex-end" className={classes.konfirmasi}>
                <Grid item>
                  <Link to="/forgotpassword" variant="body2">
                    Kembali ke lupa password
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          <Box mt={5} className={classes.copyright}>
            <Copyright />
          </Box>
            <ReCaptcha
                sitekey={Setting["recaptcha-site-key"]}
                action='forgotpwdconf'
                verifyCallback={this.verifyCallback}
                key={this.state.reloadRecaptcha} 
            />
            <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />
        </Container>
      );
  }
}

ForgotPasswordConfirm.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    token: state.token
  };
};

const dispatchToProps = dispatch => {
  return {
    onAddToken: token => dispatch(addToken(token))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(ForgotPasswordConfirm));