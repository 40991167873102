import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, InputLabel,
  Radio, RadioGroup, Button, Select,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import {ValidateEmail} from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";
import { TagFaces } from '@material-ui/icons';

const queryString = require('query-string');

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function isEmpty(obj) {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

class DbSettingInputEdit extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        form: {
          id:"",
          userid:"",
          username:"",
          input: "Flexible",
          
        },
        error:{
          userid:"",
          username:"",
          input: ""
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
        ftpage: {
          keyword: "",
          count: 10
        },
        fulltimers:[],
        fulltimerobject: null,
        filtertable:{},
        filterAll:{}
    };

    this.changeFulltimer = this.changeFulltimer.bind(this);
  }

  componentDidMount() {
    let outer = this;
    let qparam = queryString.parse(this.props.location.search); 
    let filter= this.props.location.state.filter;
    this.setState({filtertable:filter,filterAll:this.props.location.state.filterAll});
    
    // // console.log("qparam ",qparam);
    if(qparam && qparam.i){
      if(this.props.param != null){
        let input = this.props.param.detail;
        this.setState({
          form:{
            id: input.id,
            username: input.username,
            userid: input.userid,
            input: input.input
          },
          mode:'edit'
        }, () => {
          this.getEditFulltimerList();
        })
      }else{
        //get detail
        this.getSettingDetail(qparam.i);
      }
    }else{
      this.getFulltimerList();
    }
    // if(isEmpty(this.props.param) != true) {
    //   let input = this.props.param.detail;
    //   this.setState({
    //     form: {
    //       id: input.id,
    //       username: input.username,
    //       userid: input.userid,
    //       input: input.input
    //     },
    //     mode: 'edit'
    //   }, () => {
    //     this.getEditFulltimerList();
    //   })
    // } else {
    //   this.getFulltimerList();
    // }
  }

  getSettingDetail = (id) => {
    let outer = this;
    axios.post('bo/int/fulltimer/input/detail', {id:id}).then(({data})=>{
      if(data.sta && data.stacod === 200){        
        let input = data.data;
        this.setState({
          form:{
            id: input.id,
            username: input.username,
            userid: input.userid,
            input: input.input
          },
          mode:'edit'
        }, () => {
          this.getEditFulltimerList();
        })
        // outer.setState({userList:data.data});
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  inputChangeFulltimer(val) {
    this.setState({
      ftpage: {
        ...this.state.ftpage,
        keyword: val
      }
    }, () => {
      this.getFulltimerList();
    })
  }

 

  getEditFulltimerList() {
    let outer = this;
    axios.post('bo/int/master/cust', {id: outer.state.form.userid}).then(({data})=>{
      if(data.sta && data.stacod === 200){
        outer.setState({
          fulltimerobject:data.data
        });
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  getFulltimerList() {
    let outer = this;
    axios.post('bo/int/master/cust/list', this.state.ftpage).then(({data})=>{
      if(data.sta && data.stacod === 200){
        outer.setState({
          fulltimers:data.data.list
        });
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  changeFulltimer(event, val) {
    if(val) {

      this.setState({
        fulltimerobject: val,
        form: {
          ...this.state.form,
          username: val.firstname + " " + val.lastname,
          userid: val.id
        }
      })
    } else {
      this.setState({
        fulltimerobject: null,
        form: {
          ...this.state.form,
          username: "",
          userid: ""
        }
      })
    }
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {
      if(this.state.dialogTitle != "Information") {
        this.setState({dialogOpen:false});
      } else {
        this.setState({
          dialogOpen: false
        }, () => {
          // this.props.history.push("/bo/setting/input/list");
          this.backToList();
          this.props.deselectParam();
        })
      }
    }
  }
  backToList=()=>{
    this.props.history.push({ pathname: "/bo/setting/input/list", filter:this.state.filtertable, filterAll:this.state.filterAll });
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  handleChange = (e) => {
    let outer=this;
    let {form,error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if(inputName === "accept"){
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if(newAccept === true){
        error[inputName]=""
      }
    }

    this.setState({
      form:form,
      error:error
    });
  }

  handleValidation = (e) => {
    let {form,error,mode} = this.state;

    if(form.userid.trim().length === 0){
      error.userid=<IntlMessages id="setting.input.error.name"/>;
    }else{
      error.userid="";
    }
    

    if(!this.isValidForm()){
      form.accept=false;
      error.accept="";
    }

    this.setState({
      form:form,
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
      
      this.handleSubmit();
      
    });

  }

  handleSubmit = () => {
    let {form} = this.state;
    const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        if(this.state.mode == "add") {
          
          axios.post('bo/int/fulltimer/input/add', form
          ).then(({data}) => {
            if (data.sta && data.stacod === 200) {
              outer.emptyFied();
              outer.setState({
                disableBtnSubmit:false
              }, () => {
                outer.showDialog('Information',<IntlMessages id="setting.input.success.addinput"/>);
              });
              
            } else {
              outer.setState({
                disableBtnSubmit:false
              });
              outer.showDialog('Warning',data.msg);
            }
          }).catch(function (error) {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',Errormsg["500"]);
          });
        } else {
          axios.post('bo/int/fulltimer/input/edit', form
          ).then(({data}) => {
            if (data.sta && data.stacod === 200) {
              outer.emptyFied();
              outer.setState({
                disableBtnSubmit:false
              }, () => {
                outer.showDialog('Information',<IntlMessages id="setting.input.success.editinput"/>);
              })
              
            } else {
              outer.setState({
                disableBtnSubmit:false
              });
              outer.showDialog('Warning',data.msg);
            }
          }).catch(function (error) {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',Errormsg["500"]);
          });
        }
      });

  }

  emptyFied() {
    this.setState({
      fulltimerobject: null,
      form: {
        ...this.state.form,
        id: "",
        userid:"",
        username:"",
        input:"Flexible"
      },
      error:{
        ...this.state.error,
        id: "",
        userid:"",
        username:"",
        input:""
      },
    })
  }

  isValidForm = () => {
    return (
    !this.state.error.userid &&     
    !this.state.error.input )
  }

  handleChangeInput = (val) => {
    this.setState({
      form: {
        ...this.state.form,
        input: val.target.value
      }
    })
  }

  

 

  render(){
    const { classes, theme } = this.props;
    const { error, fulltimers } = this.state;
    const title = "Profile"
    return (
      <div style={{marginTop: theme.spacing(4)}}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Pengaturan
            </Link>
            <Link color="inherit" 
            // href="/bo/setting/input/list">
            href='#'
            onClick={()=>{this.backToList()}}
            >
              Daftar Input 
            </Link>
            <Link color="textPrimary" href="/bo/setting/input/edit">
              {
                this.state.mode == "add" ?
                "Tambah Input Setting" : "Ubah Input Setting"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
              <form className={classes.form} noValidate>

                  
                  <Grid container spacing={2}>
                                     
                    <Grid item xs={12}>
                      <Autocomplete
                        options={fulltimers}
                        getOptionLabel={option => option && option.firstname}
                        value={this.state.fulltimerobject}
                        disabled={this.state.mode == "edit"}
                        onChange={this.changeFulltimer}
                        onFocus={(e)=>{this.inputChangeFulltimer('')}}
                        onInputChange={(event, val) => this.inputChangeFulltimer(val)}
                        renderInput={
                          param => (
                            <TextField 
                              {...param} 
                              label="Fulltimer"
                              fullWidth
                              required
                              variant="outlined"
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={error.userid?true:false}
                            />
                          )
                        }
                      />
                      {
                          error.userid &&   
                            <FormControlLabel
                              classes={{label:classes.labelerror}}
                              control={<div>&nbsp;&nbsp;&nbsp;</div>}
                              label={error.userid}
                            />
                      }
                    </Grid>
                    
                    <Grid item xs={12}>
                      <FormControl
                        style={{
                          minWidth: '100%',
                          marginBottom: 10
                        }}
                      >
                      <InputLabel id="forminput" style={{backgroundColor:"#fff", paddingLeft: 4, paddingRight: 4}} shrink variant="outlined">
                          <IntlMessages id="setting.input.input"/><span> *</span>
                      </InputLabel>
                      <Select
                        value={this.state.form.input}
                        onChange={this.handleChangeInput}
                        variant="outlined"
                        label="Tipe Input *"
                        labelId={"forminput"}
                        fullWidth
                      >
                        <MenuItem value="Flexible">Flexible</MenuItem>
                        <MenuItem value="Strict">Strict</MenuItem>
                        <MenuItem value="Tolerant">Tolerant</MenuItem>

                      </Select>
                      </FormControl>
                    </Grid>                    
      
                    <Grid item xs={12}>
                      *Note:
                      <ul>
                        <li>
                          Flexible: Dapat menginput jurnal di lain hari
                        </li>
                        <li>
                          Tolerant: Dapat menginput jurnal di hari yang sama
                        </li>
                        <li>
                          Strict: Dapat menginput jurnal saat itu juga
                        </li>
                      </ul>
                    </Grid>       
                  </Grid>
                  <Button
                    type="button"
                    className={classes.submit}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={this.handleValidation}
                    disabled={this.state.disableBtnSubmit}
                  >
                    UPDATE
                  </Button>
                </form>
          </div>
        </Container>

        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );
  }
}

DbSettingInputEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbSettingInputEdit)));