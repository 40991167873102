import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DashboardAdmin from './dashadmin';
import Typography from '@material-ui/core/Typography';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import ErrorOutlined from '@material-ui/icons/ErrorOutlined';
import Chip from '@material-ui/core/Chip';
import { createMuiTheme } from '@material-ui/core/styles';
import axios from '../config/axios';
import DialogBasic from '../component/dialogbasic';
import InsertDriveFileOutlined from '@material-ui/icons/InsertDriveFileOutlined';
import MUIDataTable from "mui-datatables";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../config/errormsg";

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

const columns = [
  {
    name: "Tanggal",
    options: {
      filter: true
    }
  },
  {
    name: "Nama",
    options: {
      filter: true
    }
  },
  {
    name: "Email",
    options: {
      filter: true
    }
  },
  {
    name: "Kota",
    options: {
      filter: true
    }
  },
  {
    name: "UTBK",
    options: {
      filter: true
    }
  },
  {
    name: "Action",
    options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) =>  {
        let url = "/bo/detailstudent?id="+value
        return (
          <a target="_blank" href={url}>Detail</a>
        )
      }
    }
  },      
];

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions:[10,25,100],
  print:false,
  download:false,
  selectableRows:'single',
  isRowSelectable:function(){return false}
};

class DbHomePage extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        total:0,
        rows:[],
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        visibleloader:false,
    };
  }

  componentDidMount() {
    const outer = this;
    axios.defaults.headers.common['Authorization'] ='Bearer '+window.localStorage.getItem("tkn");
    this.setState({
      visibleloader:true
    } , () => {
      axios.post('app/adm/registerlist', {}
      ).then(({data}) => {
        if (data.sta && data.stacod === 200) {
          let rows=[]
          for(let i = 0 ;i< data.data.length;i++){
            let elem=data.data[i];
            let number = i+1;
            let key = 'tbl-row-'+number;
            rows.push([elem.datetime,elem.name,elem.email,elem.city,elem.utbk,elem.userid]);
          }
          outer.setState({
            total:rows.length,
            rows:rows,
            visibleloader:false
          })
        }else{
          outer.showDialog('Warning', data.msg, "");
        }
      }).catch(function (error) {
        outer.setState({
          visibleloader:false
        })
        outer.showDialog('Warning',Errormsg["500"]);
      });
    })
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    this.setState({dialogOpen:false});
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({
        dialogOpen: false
      });
      this.props.history.push("/logout");
    } else {
      this.setState({
        dialogOpen: false
      });
    }
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  render(){
    const { classes } = this.props;
    const { rows,total} = this.state;
    const title = "Page Admin"
    const chipTotal = (
      <Chip
      icon={<InsertDriveFileOutlined />}
      label={total}
      color="primary"
    />
    )

    return (
      <div>
        <CssBaseline />
        Page 1
      </div>
    );

    // return (
    //   <DashboardAdmin title={title} content={content} />
    // );
  }
}

DbHomePage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DbHomePage);