import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {IconButton, TextField, Checkbox, Card, FormControl,
  CardMedia ,CardContent, Typography, Select, InputLabel, MenuItem} from '@material-ui/core';
import {MoodBad} from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import {compareSequence} from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DataTable from 'react-data-table-component';
import moment from 'moment';
//import actions
import { addParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

const tableStyle = {
  table: {
    borderCollapse: "collapse",
    width: '100%',
    marginTop: 10
  },
  body: {
    border: 1,
    borderStyle: "solid",
    borderColor: "#dddddd",
    textAlign: 'center',
    padding: 8
  }
}

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions:[10,25,100],
  print:false,
  download:false,
  selectableRows:'single',
  isRowSelectable:function(){return false}
};

class DbReportVitalFt extends React.Component {
  constructor(props){
      super(props);
      let dat = new Date();
      dat.setDate(dat.getDate() - 7);
      this.state = {
        setdate : "",
        page:{
          date: moment().format('YYYY-MM-DD'),
          datePar: new Date()
        },
        startWeek: "",
        endWeek: "",
        vitalList:[],
        usrobject: null,
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        dialogMode:'',
        detailOpen:false,
      }
  }

  columns = [
    {
      name: <IntlMessages id="report.task.username"/>,
      selector: 'name',
      sortable: true
    },
    {
      name: <IntlMessages id="report.task.usercity"/>,
      selector: 'city',
      sortable: true
    },
    {
      name: <IntlMessages id="report.bible.target"/>,
      selector: 'target',
    },
    {
      name: <IntlMessages id="report.bible.real"/>,
      selector: 'realization',
    },
    {
      name: <IntlMessages id="report.bible.percent"/>,
      selector: 'percent',
      cell: row => <span
        style={
          row.percentnum == 0 ?
          {color: 'red'} : row.percentnum > 0 && row.percentnum < 26 ? {color: "pink"} : 
          row.percentnum > 25 && row.percentnum < 51 ? {color: "orange"} : row.percentnum > 50 && row.percentnum < 76 ? {color: 'green'} :
          row.percentnum > 75 && row.percentnum < 101 ? {color: "blue"} : row.percentnum > 100 && {color: "black"}
        }
      >
        {row.percent}
      </span>
    }
  ];

  componentDidMount(){
    this.getVitalReport();
    let start = '';
    let end = '';
    if(moment(this.state.page.date).month() < 6){
      start = '1 Jan '+moment(this.state.page.date).year(); 
      end = '30 Jun '+moment(this.state.page.date).year(); 
    }else{
      start = '1 Jul '+moment(this.state.page.date).year(); 
      end = '31 Dec '+moment(this.state.page.date).year(); 
    }
    // let startWeekMoment = moment(this.state.page.date).subtract(1, 'days').startOf('week');
    // let endWeekMoment = moment(this.state.page.date).subtract(1, 'days').endOf('week');
    this.setState({
      startWeek: start,
      endWeek: end
    });
  }

  showDialog = (title,content,mode) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content,
      dialogMode:mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen:false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen:false
    })
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {
      this.setState({dialogOpen:false});
    }
  }
  


  getVitalReport = () => {
    let outer = this;
    let url = 'bo/ft/report/vital';
    axios.post(url, outer.state.page).then(({data})=>{
      if(data.sta && data.stacod === 200){
        this.setState({
          vitalList: data.data
        }, () => {
          // console.log("vital list", this.state.vitalList)
        })
      } else {
        outer.showDialog("Warning", data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  

  changeDate = (date) => {
    let momentParse = moment(date).format("YYYY-MM-DD");
    let start = '';
    let end = '';
    if(moment(this.state.page.date).month() < 6){
      start = '1 Jan '+moment(this.state.page.date).year(); 
      end = '30 Jun '+moment(this.state.page.date).year(); 
    }else{
      start = '1 Jul '+moment(this.state.page.date).year(); 
      end = '31 Dec '+moment(this.state.page.date).year(); 
    }
    // let startWeekMoment = moment(momentParse).subtract(1, 'days').startOf('week');
    // let endWeekMoment = moment(momentParse).subtract(1, 'days').endOf('week');
    this.setState({
      page: {
        ...this.state.page,
        datePar: date,
        date: momentParse,
      },
      startWeek: start,
      endWeek: end
    }, () => {
      this.getVitalReport();
    })
  }

   
  render() {
    const { theme } = this.props;
    const { vitalList, startWeek, endWeek} = this.state;
      
    return (
      <div style={{marginTop: theme.spacing(4), height: '100%'}}>
          <div style={{paddingTop:10}}>
            <Typography variant="h5" component="h5">
              Laporan Vital
            </Typography>
          </div>
          <div className="filter">
            

            <div style={{ display: 'flex', alignItems: 'center' }}>
              
              <KeyboardDatePicker
                clearable
                label="Tanggal"
                inputVariant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.state.page.datePar}
                // placeholder="Tanggal"
                onChange={date => this.changeDate(date)}
                format="DD-MM-YYYY"
              />
            </div>
            <div>
                <Typography variant="subtitle2">
                    * Keterangan Persentase: 
                </Typography>
                <div>
                  <span style={{color: 'red'}}>0</span> / <span style={{color: 'pink'}}>0-25%</span> / <span style={{color: 'orange'}}>26%-50%</span> /&nbsp;
                  <span style={{color: 'green'}}>51%-75%</span> / <span style={{color: 'blue'}}>76%-100%</span> / <span style={{color: 'black'}}>&gt;100%</span>
                </div>
            </div>
            <div>
                <Typography variant="subtitle2">
                    Target : {vitalList && vitalList.target}
                </Typography>
                <Typography variant="subtitle2">
                    Realisasi : {vitalList && vitalList.realization}
                </Typography>
                {
                  vitalList.percentnum &&
                  <Typography style={
                      vitalList.percentnum == 0 ?
                      {color: 'red'} : vitalList.percentnum > 0 && vitalList.percentnum < 26 ? {color: "pink"} : 
                      vitalList.percentnum > 25 && vitalList.percentnum < 51 ? {color: "orange"} : vitalList.percentnum > 50 && vitalList.percentnum < 76 ? {color: 'green'} :
                      vitalList.percentnum > 75 && vitalList.percentnum < 101 ? {color: "blue"} : vitalList.percentnum > 100 && {color: "black"}
                    } variant="subtitle2">
                      Persentase : {vitalList && vitalList.percent}
                  </Typography>
                }
                
            </div>
          </div>
          {
            vitalList.vital1 && vitalList.vital2 && vitalList.vital3 && vitalList.vital4 && vitalList.vital5 && vitalList.vital6 &&
            <table style={tableStyle.table}>
              <thead>

                <tr>
                  <th style={tableStyle.body}>Vital</th>
                  <th style={tableStyle.body}>Target</th>
                  <th style={tableStyle.body}>Realisasi</th>
                  <th style={tableStyle.body}>Persentase</th>
                </tr>
              </thead>
              <tbody>

                <tr>
                  <td style={tableStyle.body}>Vital 1</td>
                  <td style={tableStyle.body}>{vitalList.vital1.target}</td>
                  <td style={tableStyle.body}>{vitalList.vital1.realization}</td>
                  <td style={tableStyle.body}><span
                  style={
                    vitalList.vital1.percentnum == 0 ?
                    {color: 'red'} : vitalList.vital1.percentnum > 0 && vitalList.vital1.percentnum < 26 ? {color: "pink"} : 
                    vitalList.vital1.percentnum > 25 && vitalList.vital1.percentnum < 51 ? {color: "orange"} : vitalList.vital1.percentnum > 50 && vitalList.vital1.percentnum < 76 ? {color: 'green'} :
                    vitalList.vital1.percentnum > 75 && vitalList.vital1.percentnum < 101 ? {color: "blue"} : vitalList.vital1.percentnum > 100 && {color: "black"}
                  }
                >
                  {vitalList.vital1.percent}
                </span></td>
                </tr>
                <tr>
                  <td style={tableStyle.body}>Vital 2</td>
                  <td style={tableStyle.body}>{vitalList.vital2.target}</td>
                  <td style={tableStyle.body}>{vitalList.vital2.realization}</td>
                  <td style={tableStyle.body}><span
                  style={
                    vitalList.vital2.percentnum == 0 ?
                    {color: 'red'} : vitalList.vital2.percentnum > 0 && vitalList.vital2.percentnum < 26 ? {color: "pink"} : 
                    vitalList.vital2.percentnum > 25 && vitalList.vital2.percentnum < 51 ? {color: "orange"} : vitalList.vital2.percentnum > 50 && vitalList.vital2.percentnum < 76 ? {color: 'green'} :
                    vitalList.vital2.percentnum > 75 && vitalList.vital2.percentnum < 101 ? {color: "blue"} : vitalList.vital2.percentnum > 100 && {color: "black"}
                  }
                >
                  {vitalList.vital2.percent}
                </span></td>
                </tr>
                <tr>
                  <td style={tableStyle.body}>Vital 3</td>
                  <td style={tableStyle.body}>{vitalList.vital3.target}</td>
                  <td style={tableStyle.body}>{vitalList.vital3.realization}</td>
                  <td style={tableStyle.body}><span
                  style={
                    vitalList.vital3.percentnum == 0 ?
                    {color: 'red'} : vitalList.vital3.percentnum > 0 && vitalList.vital3.percentnum < 26 ? {color: "pink"} : 
                    vitalList.vital3.percentnum > 25 && vitalList.vital3.percentnum < 51 ? {color: "orange"} : vitalList.vital3.percentnum > 50 && vitalList.vital3.percentnum < 76 ? {color: 'green'} :
                    vitalList.vital3.percentnum > 75 && vitalList.vital3.percentnum < 101 ? {color: "blue"} : vitalList.vital3.percentnum > 100 && {color: "black"}
                  }
                >
                  {vitalList.vital3.percent}
                </span></td>
                </tr>
                <tr>
                  <td style={tableStyle.body}>Vital 4</td>
                  <td style={tableStyle.body}>{vitalList.vital4.target}</td>
                  <td style={tableStyle.body}>{vitalList.vital4.realization}</td>
                  <td style={tableStyle.body}><span
                  style={
                    vitalList.vital4.percentnum == 0 ?
                    {color: 'red'} : vitalList.vital4.percentnum > 0 && vitalList.vital4.percentnum < 26 ? {color: "pink"} : 
                    vitalList.vital4.percentnum > 25 && vitalList.vital4.percentnum < 51 ? {color: "orange"} : vitalList.vital4.percentnum > 50 && vitalList.vital4.percentnum < 76 ? {color: 'green'} :
                    vitalList.vital4.percentnum > 75 && vitalList.vital4.percentnum < 101 ? {color: "blue"} : vitalList.vital4.percentnum > 100 && {color: "black"}
                  }
                >
                  {vitalList.vital4.percent}
                </span></td>
                </tr>
                <tr>
                  <td style={tableStyle.body}>Vital 5</td>
                  <td style={tableStyle.body}>{vitalList.vital5.target}</td>
                  <td style={tableStyle.body}>{vitalList.vital5.realization}</td>
                  <td style={tableStyle.body}><span
                  style={
                    vitalList.vital5.percentnum == 0 ?
                    {color: 'red'} : vitalList.vital5.percentnum > 0 && vitalList.vital5.percentnum < 26 ? {color: "pink"} : 
                    vitalList.vital5.percentnum > 25 && vitalList.vital5.percentnum < 51 ? {color: "orange"} : vitalList.vital5.percentnum > 50 && vitalList.vital5.percentnum < 76 ? {color: 'green'} :
                    vitalList.vital5.percentnum > 75 && vitalList.vital5.percentnum < 101 ? {color: "blue"} : vitalList.vital5.percentnum > 100 && {color: "black"}
                  }
                >
                  {vitalList.vital5.percent}
                </span></td>
                </tr>
                <tr>
                  <td style={tableStyle.body}>Vital 6</td>
                  <td style={tableStyle.body}>{vitalList.vital6.target}</td>
                  <td style={tableStyle.body}>{vitalList.vital6.realization}</td>
                  <td style={tableStyle.body}><span
                  style={
                    vitalList.vital6.percentnum == 0 ?
                    {color: 'red'} : vitalList.vital6.percentnum > 0 && vitalList.vital6.percentnum < 26 ? {color: "pink"} : 
                    vitalList.vital6.percentnum > 25 && vitalList.vital6.percentnum < 51 ? {color: "orange"} : vitalList.vital6.percentnum > 50 && vitalList.vital6.percentnum < 76 ? {color: 'green'} :
                    vitalList.vital6.percentnum > 75 && vitalList.vital6.percentnum < 101 ? {color: "blue"} : vitalList.vital6.percentnum > 100 && {color: "black"}
                  }
                >
                  {vitalList.vital6.percent}
                </span></td>
                </tr>
              </tbody>
            </table>
          }

          <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
          />  
         
      </div>
    );
  }
}

DbReportVitalFt.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    token: state.token
  };
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbReportVitalFt)));