import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
  IconButton, TextField, Paper, Card, FormControl,
  CardMedia, CardContent, Typography, Select, InputLabel, MenuItem, Button, Breadcrumbs, Link
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IntlMessages from "../../../../../util/IntlMessages";
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import DataTable from 'react-data-table-component';
import moment from 'moment';
//import actions
import { addParam } from "../../../../../store/actions/configAction";
import './../../../../App.css';

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete: {
    color: '#204196',
    fontWeight: 'bold'
  },
  incomplete: {
    color: '#ff1744',
    fontWeight: 'bold'
  }
});

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions: [10, 25, 100],
  print: false,
  download: false,
  selectableRows: 'single',
  isRowSelectable: function () { return false }
};

class DbReportRealizationDetailUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setdate: "",
      username: "",
      useremail: "",
      showdata: "table",
      loadingTrigger:false,
      page: {
        userid: "",
        date: moment().format('YYYY-MM-DD'),
        datePar: new Date()
      },
      pageUser: {
        keyword: "",
        page: 1,
        results: 10
      },
      localparam: {
        keyword: '',
        page: 1,
        count: 10
      },
      locallist: [],
      selectedLocal: {},
      users: [],
      realList: [],
      usrobject: null,
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      dialogMode: '',
      detailOpen: false,
    }
  }

  columns = [

    {
      name: <IntlMessages id="report.task.date" />,
      selector: 'date',
      sortable: true,
      width: '110px'
    },
    {
      name: "Hari",
      selector: 'day',
      width: '80px'
    },
    {
      name: "Nama Jadwal",
      selector: 'scheduletitle',
      wrap: true
    },
    {
      name: "Tipe",
      selector: 'servicetype',
      wrap: true
    },
    {
      name: "Sub Tipe",
      selector: 'subservicetype',
      wrap: true
    },
    {
      name: "Waktu Mulai",
      selector: 'start',
      width: '80px',
      right: true
    },
    {
      name: "Waktu Akhir",
      selector: 'end',
      width: '80px',
      right: true
    },
    {
      name: "Jumlah Waktu (menit)",
      selector: 'totalmin',
      width: '80px',
      right: true
    },
    {
      name: "Deskripsi Realisasi",
      selector: 'desc',
      wrap: true
    },
  ];

  componentDidMount() {
    this.getUserList();
    this.getLocal();
  }

  getLocal() {
    let outer = this;
    axios.post('bo/int/master/local/list', outer.state.localparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          locallist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changeLocal = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedLocal: val,
        pageUser: {
          ...this.state.pageUser,
          lokalid: val.id,
        }
      }, () => {
        outer.getUserList();
      });
    } else {
      this.setState({
        selectedLocal: {},
        pageUser: {
          ...this.state.pageUser,
          lokalid: "",
        }
      }, () => {
        outer.getUserList();
      });
    }
    this.inputChangeLocal('');
  }

  inputChangeLocal(val) {
    this.setState({
      localparam: {
        ...this.state.localparam,
        keyword: val
      }
    }, () => {
      this.getLocal();
    })
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen: false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      this.setState({ dialogOpen: false });
    }
  }

  getUserList() {
    let outer = this;
    this.setState({
      loadingTrigger:true
    });
    let url = 'bo/int/master/cust/list';
    if (this.props.token.token.role == "Penatua") {
      url = 'bo/penatua/sheep/list';
    };
    axios.post(url, outer.state.pageUser).then(({ data }) => {
      this.setState({
        loadingTrigger:false
      });
      if (data.sta && data.stacod === 200) {
        outer.setState({
          users: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changeUser = (event, val) => {
    if (val) {
      this.setState({
        usrobject: val,
        page: {
          ...this.state.page,
          userid: val.id
        }
      }, () => {
        this.getRealReport();
      })
    }
  }

  inputChangeFT(val) {
    this.setState({
      pageUser: {
        ...this.state.pageUser,
        keyword: val
      }
    }, () => {
      this.getUserList();
    })
  }

  getRealReport = () => {
    let outer = this;
    axios.post('bo/int/report/nonshepherding/detail', outer.state.page).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        this.setState({
          realList: data.data.list,
          username: data.data.username,
          useremail: data.data.useremail,
        })
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changeDate = (date) => {
    let momentParse = moment(date).format("YYYY-MM-DD");
    this.setState({
      page: {
        ...this.state.page,
        datePar: date,
        date: momentParse,
      }
    }, () => {
      this.getRealReport();
    })
  }

  convertArrayOfObjectsToCSV = (array) => {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;
        let content = item[key];
        result += content.toString().replace(/\n/g, " ");

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  downloadCSV = (array) => {
    if (array.length > 0) {
      const link = document.createElement('a');
      let csv = this.convertArrayOfObjectsToCSV(array);
      if (csv == null) return;

      const filename = this.state.username + ' ' + this.state.page.date + '.csv';

      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }

      link.setAttribute('href', encodeURI(csv));
      link.setAttribute('download', filename);
      link.click();
    }
  }


  render() {
    const { theme } = this.props;
    const { realList, users, locallist } = this.state;

    return (
      <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
        <div style={{ paddingTop: 10 }}>          
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Laporan List Detail
            </Link>
            <Link color="textPrimary"
            // href="/bo/master/user/list" 
            href='/bo/report/nonshepherd/detail'>
              Laporan Non Penggembalaan Harian 1 Minggu
            </Link>            
          </Breadcrumbs>          
        </div>
        <div className="filter">
          <div style={{ marginBottom: 10 }}>
            <Autocomplete
              options={locallist}
              getOptionLabel={option => option.name}
              value={this.state.selectedLocal}
              onChange={this.changeLocal}
              onFocus={(e)=>{this.inputChangeLocal('')}}
              onInputChange={(event, val) => this.inputChangeLocal(val)}
              renderInput={
                param => (
                  <TextField
                    {...param}
                    id="outlined-basic"
                    // variant="outlined" 
                    label="Lokal"
                    size="small"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
              }
            />
          </div>
          <div
            style={{
              marginBottom: 10
            }}
          >

            <Autocomplete
              options={users}
              getOptionLabel={option => (option.firstname)}
              value={this.state.usrobject}
              onChange={this.changeUser}
              onFocus={(e)=>{this.inputChangeFT('')}}
              onInputChange={(event, val) => this.inputChangeFT(val)}
              renderInput={
                param => (
                  <TextField
                    {...param}
                    id="usrauto"
                    label="Pilih Fulltimer"
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
              }
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>


            <KeyboardDatePicker
              clearable
              value={this.state.page.datePar}
              // placeholder="Start"
              label="Tanggal"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={date => this.changeDate(date)}
              format="DD-MM-YYYY"
              style={{ paddingRight: 5 }}
            />
          </div>
          <div>
            <Button onClick={() => this.downloadCSV(realList)} color="primary"
              variant="contained" fullWidth>Export to CSV</Button>
          </div>
        </div>
        <DataTable
          columns={this.columns}
          data={realList}
          noDataComponent={
            <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
              <CardMedia style={{ textAlign: 'center' }}>
                <MoodBad color="action" style={{
                  fontSize: 40,
                  marginTop: 10,
                  marginBottom: 10
                }} />
              </CardMedia>
              <CardContent>
                <Typography>
                  Data tidak tersedia
                </Typography>
              </CardContent>
            </Card>
          }
          highlightOnHover
          noHeader
          customStyles={customStyles}
          pagination={false}
          progressPending={ this.state.loadingTrigger}
          progressComponent={
          <Loader type="TailSpin" color="#204196" height={100} width={100}/>
          }
        />

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />

      </div>
    );
  }
}

DbReportRealizationDetailUser.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    token: state.token
  };
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbReportRealizationDetailUser)));