import { ADD_TOKEN, SELECT_TOKEN, DESELECT_TOKEN, ADD_PARAM, SELECT_PARAM, DESELECT_PARAM } from "../actions/actionTypes";

const initState = {
    token: null,
    param: null,
};

export const reducer = (state = initState, action) => {
    switch (action.type) {
        case ADD_TOKEN:
            return {
                ...state,
                token: action.token
            }
        case SELECT_TOKEN:
            return { state }
        case DESELECT_TOKEN:
            return {
                ...state,
                token: null
            }
        case ADD_PARAM:
            return {
                ...state,
                param: action.param
            }
        case SELECT_PARAM:
            return { state }
        case DESELECT_PARAM:
            return {
                ...state,
                param: null
            }
        default:
            return state
    }
}
