import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
  IconButton, TextField, Paper, Card, FormControl,
  CardMedia, CardContent, Typography, Select, InputLabel, MenuItem, Button, Breadcrumbs, Link
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IntlMessages from "../../../../../util/IntlMessages";
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import DataTable, { defaultThemes } from 'react-data-table-component';
import moment from 'moment';
//import actions
import { addParam } from "../../../../../store/actions/configAction";
import './../../../../App.css';


const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headRow: {
    style: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '0px',
      borderBottomColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
      borderRightStyle: 'solid',
      borderRightWidth: '1px',
      borderRightColor: defaultThemes.default.divider.default,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: defaultThemes.default.divider.default,
    },
  },
  cells: {
    style: {
      borderRightStyle: 'solid',
      borderRightWidth: '1px',
      borderRightColor: defaultThemes.default.divider.default,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: defaultThemes.default.divider.default,
    },
  },
  rows: {
    style: {
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '0px',
        borderBottomColor: defaultThemes.default.divider.default,
      },
    },
  },
  pagination: {
    style: {
      borderTopStyle: 'solid',
      borderTopWidth: '0px',
      borderTopColor: defaultThemes.default.divider.default,
    },
  }
};



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete: {
    color: '#204196',
    fontWeight: 'bold'
  },
  incomplete: {
    color: '#ff1744',
    fontWeight: 'bold'
  }
});

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions: [10, 25, 100],
  print: false,
  download: false,
  selectableRows: 'single',
  isRowSelectable: function () { return false }
};

class DbReportRealizationDetailUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setdate: "",
      username: "",
      useremail: "",
      showdata: "table",
      page: {
        keyword: "",
        page: 1,
        count: 100,
        all: false,
        type: "weekly",
        date: moment().format('YYYY-MM-DD'),
        datePar: new Date()
      },
      pageUser: {
        keyword: "",
        page: 1,
        results: 10
      },
      localparam: {
        keyword: '',
        page: 1,
        count: 10
      },
      locallist: [],
      selectedLocal: {},
      loading: false,
      search: "",
      users: [],
      realList: [],
      usrobject: null,
      dialogOpen: false,
      disableBtnExport: false,
      dialogTitle: '',
      dialogContent: '',
      dialogMode: '',
      detailOpen: false,
      realListTotal: 0,
      loadingTrigger:false,
      resetPage: false
    }
  }

  columns = [

    {
      name: "Name",
      selector: 'name',
      sortable: true,
      width: '110px'
    },
    {
      name: "TW Korporat",
      selector: 'twkorporat'
    },
    {
      name: "TW Lewi Hall",
      selector: 'twlewihall'
    },
    {
      name: "TW Lewi Kaum Saleh",
      selector: 'twlewiks'
    },
    {
      name: "TW Transportasi",
      selector: 'twtransport'
    },
    {
      name: "TW Lain-lain",
      selector: 'twlain'
    },
    {
      name: "TW Kokyn",
      selector: 'twkokyn'
    },
    {
      name: "LW Korporat",
      selector: 'lwkorporat'
    },
    {
      name: "LW Lewi Hall",
      selector: 'lwlewihall'
    },
    {
      name: "LW Lewi Kaum Saleh",
      selector: 'lwlewiks'
    },
    {
      name: "LW Transportasi",
      selector: 'lwtransport'
    },
    {
      name: "LW Lain-lain",
      selector: 'lwlain'
    },
    {
      name: "LW Kokyn",
      selector: 'lwkokyn'
    },
  ];

  componentDidMount() {
    this.getRealReport();
    this.getLocal();
  }

  getLocal() {
    let outer = this;
    axios.post('bo/int/master/local/list', outer.state.localparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          locallist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changeLocal = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedLocal: val,
        page: {
          ...this.state.page,
          lokalid: val.id
        }
      }, () => { outer.getRealReport() });
    } else {
      this.setState({
        selectedLocal: {},
        page: {
          ...this.state.page,
          lokalid: "",
        }
      }, () => { outer.getRealReport() });
    }
    this.inputChangeLocal('');
  }

  inputChangeLocal(val) {
    this.setState({
      localparam: {
        ...this.state.localparam,
        keyword: val
      }
    }, () => {
      this.getLocal();
    })
  }


  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen: false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      this.setState({ dialogOpen: false });
    }
  }

  getUserList() {
    let outer = this;
    let url = 'bo/int/master/cust/list';
    if (this.props.token.token.role == "Penatua") {
      url = 'bo/penatua/sheep/list';
    };
    axios.post(url, outer.state.pageUser).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          users: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changeUser = (event, val) => {
    if (val) {
      this.setState({
        usrobject: val,
        page: {
          ...this.state.page,
          userid: val.id
        }
      }, () => {
        this.getRealReport();
      })
    }
  }

  inputChangeFT(val) {
    this.setState({
      pageUser: {
        ...this.state.pageUser,
        keyword: val
      }
    }, () => {
      this.getUserList();
    })
  }

  changeType = (val) => {
    if (val) {
      this.setState({
        page: {
          ...this.state.page,
          page: 1,
          type: val.target.value
        },
        realList: [],
        resetPage: true
      }, () => {
        this.setState({ resetPage: false });
        this.getRealReport();
      })
    }
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        count: val
      },
      resetPage: true
    }, () => {
      outer.setState({ resetPage: false });
      outer.getRealReport();
    })
  }

  onChangePage(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val
      }
    }, () => {
      outer.getRealReport();
    })

  }

  getRealReport = () => {
    let outer = this;
    this.setState({
      loadingTrigger:true
    });
    this.setState({
      loading: true
    }, () => {
      axios.post('bo/int/report/nonshepherding/alluser', outer.state.page).then(({ data }) => {
        this.setState({
          loadingTrigger:false
        });
        if (data.sta && data.stacod === 200) {
          this.setState({
            realList: data.data.list,
            realListTotal: data.data.total
          })
        } else {
          outer.showDialog('Warning', data.msg, "");
        }
        this.setState({ loading: false });
      }).catch(function (error) {
        outer.showDialog('Warning', Errormsg["500"], "");
        this.setState({ loading: false });
      });
    })
  }

  changeDate = (date) => {
    let momentParse = moment(date).format("YYYY-MM-DD");
    this.setState({
      page: {
        ...this.state.page,
        datePar: date,
        date: momentParse,
        page: 1
      },
      realList: [],
      resetPage: true
    }, () => {
      this.setState({ resetPage: false });
      this.getRealReport();
    })
  }

  convertArrayOfObjectsToCSV = (array) => {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    if (this.state.page.type == 'weekly') {
      result = 'Nama,TW Korporat,TW Lewi Hall,TW Lewi Kaum Saleh,TW Transportasi,TW Lain-lain,TW Penuntutan,LW Korporat,LW Lewi Hall,LW Lewi Kaum Saleh,LW Transportasi,LW Lain-lain,LW Penuntutan';
    } else {
      result = 'Nama,TD Korporat,TD Lewi Hall,TD Lewi Kaum Saleh,TD Transportasi,TD Lain-lain,TD Penuntutan,TD-7 Korporat,TD-7 Lewi Hall,TD-7 Lewi Kaum Saleh,TD-7 Transportasi,TD-7 Lain-lain,TD-7 Penuntutan';
    }
    // result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  downloadCSV = () => {
    let outer = this;
    this.setState({
      disableBtnExport: true,
      loading: true
    }, () => {
      let page = {
        keyword: '',
        page: 1,
        count: 10,
        all: true,
        type: this.state.page.type,
        date: this.state.page.date
      }
      axios.post('/bo/int/report/nonshepherding/alluser', page).then(({ data }) => {
        if (data.sta && data.stacod === 200) {
          if (data.data.list.length > 0) {
            let array = data.data.list;
            if (array.length > 0) {
              const link = document.createElement('a');
              let csv = this.convertArrayOfObjectsToCSV(array);
              if (csv == null) return;

              const filename = this.state.page.date + ' ' + this.state.page.type + '.csv';

              if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${csv}`;
              }

              link.setAttribute('href', encodeURI(csv));
              link.setAttribute('download', filename);
              link.click();
            }
            outer.setState({ disableBtnExport: false, loading: false });
          }
        } else {
          outer.setState({ disableBtnExport: false, loading: false });
          outer.showDialog('Warning', data.msg, "");
        }
        this.setState({ loading: false });
      }).catch(function (error) {
        outer.showDialog('Warning', Errormsg["500"], "");
        outer.setState({ disableBtnExport: false, loading: false });
      });
    })
    // if(array.length > 0){
    //   const link = document.createElement('a');
    //   let csv = this.convertArrayOfObjectsToCSV(array);
    //   if (csv == null) return;

    //   const filename =  this.state.page.date +' ' + this.state.page.type +'.csv';

    //   if (!csv.match(/^data:text\/csv/i)) {
    //     csv = `data:text/csv;charset=utf-8,${csv}`;
    //   }

    //   link.setAttribute('href', encodeURI(csv));
    //   link.setAttribute('download', filename);
    //   link.click();
    // }
  }

  // search = (val) => {
  //   const { realList } = this.state;
  //   let filteredList = realList.filter((ft)=> ft.name.toLowerCase().includes(val.target.value));
  //   this.setState({realListFiltered: filteredList, search:val.target.value});
  // }

  search(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        keyword: val.target.value,
        page: 1
      },
      resetPage: true
    }, () => {
      outer.setState({ resetPage: false });
      outer.getRealReport();
    })
  }

  render() {
    const { theme } = this.props;
    const { realList, users, locallist } = this.state;

    return (
      <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
        <div style={{ paddingTop: 10 }}>
          {this.state.page.type == 'weekly' ?
            <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Laporan Mingguan
            </Link>
            <Link color="textPrimary"
            // href="/bo/master/user/list" 
            href='/bo/report/nonshepherd/alluser'>
              Laporan Non Penggembalaan Mingguan
            </Link>            
            </Breadcrumbs>
            :
            <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Laporan Harian
            </Link>
            <Link color="textPrimary"
            // href="/bo/master/user/list"
            >
              Laporan Non Penggembalaan Harian
            </Link>            
          </Breadcrumbs>
          }
        </div>
        <div className="filter">
          <div style={{ marginBottom: 10 }}>
            <Autocomplete
              options={locallist}
              getOptionLabel={option => option.name}
              value={this.state.selectedLocal}
              onChange={this.changeLocal}
              onFocus={(e)=>{this.inputChangeLocal('')}}
              onInputChange={(event, val) => this.inputChangeLocal(val)}
              renderInput={
                param => (
                  <TextField
                    {...param}
                    id="outlined-basic"
                    // variant="outlined" 
                    label="Lokal"
                    size="small"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
              }
            />
          </div>
          <div>
            <FormControl
              style={{
                minWidth: '100%',
                marginBottom: 10
              }}
            >

              <InputLabel id="settype" style={{ backgroundColor: "#fff", paddingLeft: 4, paddingRight: 4 }} shrink variant="outlined">
                Pilih Tipe
              </InputLabel>
              <Select
                value={this.state.page.type}
                onChange={this.changeType}
                variant="outlined"
                label="Pilih Tipe"
                labelId="settype"
                style={{ width: '100%' }}
              >
                <MenuItem value="weekly">Mingguan</MenuItem>
                <MenuItem value="daily">Harian</MenuItem>
                {/* <MenuItem value="yearly">Tahunan</MenuItem> */}

              </Select>
            </FormControl>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>


            <KeyboardDatePicker
              clearable
              value={this.state.page.datePar}
              // placeholder="Start"
              label="Tanggal"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={date => this.changeDate(date)}
              format="DD-MM-YYYY"
              style={{ paddingRight: 5 }}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
            <FormControl
              style={{
                minWidth: '100%'
              }}
            >
              <TextField
                id="outlined-basic"
                label="Search FT"
                variant="outlined"
                size="small"
                value={this.state.page.keyword}
                style={{ margin: '5px' }}
                onChange={(val) => this.search(val)}
              />
            </FormControl>
          </div>
          <div>
            <Button onClick={() => this.downloadCSV()} color="primary"
              disabled={this.state.disableBtnExport}
              variant="contained" fullWidth>Export to CSV</Button>
          </div>
          <div>
            <Typography variant="subtitle2">
              * Keterangan:
            </Typography>
            {this.state.page.type == 'weekly' ?
              <div>
                TW : This Week / Minggu yang dipilih <br />
                LW : Last Week / Minggu kemarin dari <br />
                minggu yang dipilih <br />
                Satuan Unit : Jam
              </div>
              :
              <div>
                TD : This Day / Hari yang dipilih <br />
                TD-7 : This Day - 7 / Hari yang sama <br />
                minggu lalu <br />
                Satuan Unit : Jam
              </div>
            }
          </div>
        </div>
        <DataTable
          columns={[
            {
              name: "Name",
              selector: 'name',
              sortable: true,
              width: '180px'
            },
            {
              name: this.state.page.type == 'weekly' ? "TW Korporat" : "TD Korporat",
              selector: 'twkorporat',
              center: true
            },
            {
              name: this.state.page.type == 'weekly' ? "TW Lewi Hall" : "TD Lewi Hall",
              selector: 'twlewihall',
              center: true
            },
            {
              name: this.state.page.type == 'weekly' ? "TW Lewi Kaum Saleh" : "TD Lewi Kaum Saleh",
              selector: 'twlewiks',
              center: true
            },
            {
              name: this.state.page.type == 'weekly' ? "TW Transportasi" : "TD Transportasi",
              selector: 'twtransport',
              center: true
            },
            {
              name: this.state.page.type == 'weekly' ? "TW Lain-lain" : "TD Lain-lain",
              selector: 'twlain',
              center: true
            },
            {
              name: this.state.page.type == 'weekly' ? "TW Penuntutan" : "TD Penuntutan",
              selector: 'twkokyn',
              center: true
            },
            {
              name: this.state.page.type == 'weekly' ? "LW Korporat" : "TD-7 Korporat",
              selector: 'lwkorporat',
              center: true
            },
            {
              name: this.state.page.type == 'weekly' ? "LW Lewi Hall" : "TD-7 Lewi Hall",
              selector: 'lwlewihall',
              center: true
            },
            {
              name: this.state.page.type == 'weekly' ? "LW Lewi Kaum Saleh" : "TD-7 Lewi Kaum Saleh",
              selector: 'lwlewiks',
              center: true
            },
            {
              name: this.state.page.type == 'weekly' ? "LW Transportasi" : "TD-7 Transportasi",
              selector: 'lwtransport',
              center: true
            },
            {
              name: this.state.page.type == 'weekly' ? "LW Lain-lain" : "TD-7 Lain-lain",
              selector: 'lwlain',
              center: true
            },
            {
              name: this.state.page.type == 'weekly' ? "LW Penuntutan" : "TD-7 Penuntutan",
              selector: 'lwkokyn',
              center: true
            },
          ]}
          data={realList}
          noDataComponent={
            <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
              <CardMedia style={{ textAlign: 'center' }}>
                <MoodBad color="action" style={{
                  fontSize: 40,
                  marginTop: 10,
                  marginBottom: 10
                }} />
              </CardMedia>
              <CardContent>
                <Typography>
                  Data tidak tersedia
                </Typography>
              </CardContent>
            </Card>
          }
          highlightOnHover
          noHeader
          customStyles={customStyles}
          pagination={true}
          paginationServer
          paginationRowsPerPageOptions={[100,250,500]}
          paginationTotalRows={this.state.realListTotal}
          paginationPerPage={this.state.page.count}
          paginationResetDefaultPage={this.state.resetPage}
          onChangePage={(val) => this.onChangePage(val)}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          progressPending={ this.state.loadingTrigger}
          progressComponent={
          <Loader type="TailSpin" color="#204196" height={100} width={100}/>
          }
        />
        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />

      </div>
    );
  }
}

DbReportRealizationDetailUser.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    token: state.token
  };
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbReportRealizationDetailUser)));