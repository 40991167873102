import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
  IconButton, TextField, Checkbox, Card,
  CardMedia, CardContent, Typography, Breadcrumbs, Link
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';

import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';

import DataTable from 'react-data-table-component';

//import actions
import { addParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete: {
    color: '#204196',
    fontWeight: 'bold'
  },
  incomplete: {
    color: '#ff1744',
    fontWeight: 'bold'
  }
});

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions: [10, 25, 100],
  print: false,
  download: false,
  selectableRows: 'single',
  isRowSelectable: function () { return false }
};

const ExpandableComponent = ({ data }) => {
  return <div style={{marginRight:40, marginLeft:40}}>
    <DataTable
      columns={[{
        name: "Nama Fulltimer",
        selector: 'username',
        sortable: true,
      },
      {
        name: "Efata Id",
        selector: 'usersaintid',
        sortable: true,
      },]}
      data={data.fulltimerlist}
      noDataComponent={
        <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid', marginTop: 10 }}>
          <CardMedia style={{ textAlign: 'center' }}>
            <MoodBad color="action" style={{
              fontSize: 40,
              marginTop: 10,
              marginBottom: 10
            }} />
          </CardMedia>
          <CardContent>
            <Typography>
              Data tidak tersedia
            </Typography>
          </CardContent>
        </Card>

      }
      noHeader
      highlightOnHover
      customStyles={customStyles}
    />
  </div>
};

class DbSettingShepherding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        keyword: '',
        page: 1,
        count: 100,
        lokalid: "",
      },
      localparam: {
        keyword: '',
        page: 1,
        count: 10
      },
      clusterparam: {
        localid: "",
        keyword: '',
        page: 1,
        count: 10
      },
      clusterlist: [],
      selectedCluster: {},
      shepherdingList: [],
      loadingTrigger:false,
      shepherdingListTotal: 0,
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      dialogMode: '',
      detailOpen: false,
      detailTitle: 'Shepherding Detail',
      detailContent: [],
      selectedRows: [],
      locallist: [],
      selectedLocal: {},
      mounted: false
    }
  }

  columns = [
    // {
    //   name: 'Action',
    //   button: true,
    //   cell: row => {return <div>
    //     <IconButton color="primary" aria-label="detail" component="span" size="small" style={{marginRight:5}} onClick={()=>this.openServiceDetail(row)}>
    //         <List />
    //     </IconButton>
    //   </div>},
    // },
    {
      name: <IntlMessages id="setting.shepherd.penatuaname" />,
      selector: 'penatuaname',
      sortable: true,
    },
    {
      name: "Lokal",
      selector: 'lokal',
      sortable: true,
    }, {
      name: 'Wilayah',
      selector: 'cluster',
      sortable: true,
    },
  ];

  componentDidMount() {
    if (this.props.location.filter) {
      let filter= this.props.location.filterAll
      this.setState(
        {
          page: this.props.location.filter, 
          selectedCluster:filter.selectedCluster,
          selectedLocal:filter.selectedLocal,
        }, () => {
          this.getShepherdingList();
        }
      )
    } else {
      this.getShepherdingList();
    }
    this.getLocal();
    this.getCluster();
    this.setState({mounted:true});
  }

  getLocal() {
    let outer = this;
    axios.post('bo/int/master/local/list', outer.state.localparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          locallist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  getCluster() {
    let outer = this;
    axios.post('bo/int/master/cluster/list', outer.state.clusterparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          clusterlist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen: false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({
        dialogOpen: false
      });
      this.props.history.push("/logout");

    } else {

      this.setState({
        dialogOpen: false
      });
      switch (this.state.dialogMode) {
        case "delete":
          this.handleDeleteService();
          break;
        default:
          break;
      }
    }
  }

  getShepherdingList = () => {
    let outer = this;
    this.setState({
          loadingTrigger:true
        });
    axios.post('bo/int/shepherding/list', outer.state.page).then(({ data }) => {
      this.setState({
              loadingTrigger:false
            });
      if (data.sta && data.stacod === 200) {
        this.setState({
          shepherdingList: data.data.list,
          shepherdingListTotal: data.data.total
        })
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.setState({
        disableBtnSubmit: false
      });
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  openServiceDetail = (row) => {
    // // console.log(row)
    let detailContent = [];
    Object.keys(row).forEach(function (key) {
      let name = '';
      let seq = 0;
      let view = true;
      switch (key) {
        case 'penatuaname':
          name = <IntlMessages id="setting.shepherd.penatuaname" />;
          seq = 1;
          view = true;
          break;
        case 'username':
          name = <IntlMessages id="setting.shepherd.fulltimer" />;
          seq = 2;
          view = true;
          break;

        default:
          view = false;
          break;
      }
      // // console.log("key", key);
      let content = { id: key, name: name, value: row[key], seq: seq, view: view }
      detailContent.push(content);
      // // console.log(key, row[key]);

    });
    detailContent.sort(compareSequence);
    // // console.log(detailContent);
    this.setState({
      detailOpen: true,
      detailContent
    });

  }

  editService = (row) => {
    this.props.onAddParam({ detail: row });
    this.props.history.push({ pathname: "/bo/setting/shepherding/edit", state:{filter:this.state.page ,
      filterAll:{selectedCluster:this.state.selectedCluster,selectedLocal:this.state.selectedLocal}}})
  }

  handleSelectedRows = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    // // console.log('Selected Rows: ', state.selectedRows);
    this.setState({ selectedRows: state.selectedRows });
  };

  deleteShepherding = () => {
    let shepherdLength = this.state.selectedRows.length;
    if (shepherdLength == 0) {
      this.showDialog("Peringatan", "Pilih salah satu item untuk menghapus", "");
    } else {
      this.showDialog("Hapus Shepherding", "Anda akan menghapus " + shepherdLength + " shepherding. Apakah anda yakin?", "delete");
    }
  }

  handleDeleteService = () => {

    const outer = this;
    const { selectedRows } = this.state;
    let deleteService = [];
    selectedRows.forEach((serv) => {
      deleteService.push(serv.id);
    })

    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/shepherding/delete', { id: deleteService }
      ).then(({ data }) => {
        if (data.sta && data.stacod === 200) {
          outer.setState({
            disableBtnSubmit: false
          }, () => {
            outer.getShepherdingList();
            outer.showDialog('Information', <IntlMessages id="setting.shepherd.success.deleteshepherd" />, "");
          })

        } else {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', data.msg, "");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"], "");
      });
    });
  }

  search(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page:1,
        keyword: val.target.value
      }
    }, () => {
      outer.getShepherdingList();
    })
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        count: val
      }
    }, () => {
      outer.getShepherdingList();
    })
  }

  onChangePage(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getShepherdingList();
    })
  }

  addnew() {
    this.props.onAddParam({});
    // this.props.history.push("/bo/setting/shepherding/edit");
    this.props.history.push({ pathname: "/bo/setting/shepherding/edit", state:{filter:this.state.page ,
    filterAll:{selectedCluster:this.state.selectedCluster,selectedLocal:this.state.selectedLocal}}
  });
  }

  changeLocal = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedLocal: val,
        selectedCluster: {},
        page: {
          ...this.state.page,
          page:1,
          lokalid: val.id,
          clusterid: "",
        },
        clusterparam: {
          ...this.state.clusterparam,
          localid: val.id
        }
      }, () => {
        outer.getShepherdingList()
        outer.getCluster();
      });
    } else {
      this.setState({
        selectedLocal: {},
        selectedCluster: {},
        page: {
          ...this.state.page,
          page:1,
          lokalid: "",
          clusterid: "",
        },
        clusterparam: {
          ...this.state.clusterparam,
          localid: ""
        }
      }, () => {
        outer.getShepherdingList();
        outer.getCluster();
      });
    }
    this.inputChangeLocal('');
  }

  inputChangeLocal(val) {
    this.setState({
      localparam: {
        ...this.state.localparam,
        keyword: val
      }
    }, () => {
      this.getLocal();
    })
  }

  inputChangeCluster(val) {
    this.setState({
      clusterparam: {
        ...this.state.clusterparam,
        keyword: val
      }
    }, () => {
      this.getCluster();
    })
  }

  changeCluster = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedCluster: val,
        page: {
          ...this.state.page,
          page:1,
          clusterid: val.id
        }
      }, () => { outer.getShepherdingList() });
    } else {
      this.setState({
        selectedCluster: {},
        page: {
          ...this.state.page,
          page:1,
          clusterid: "",
        }
      }, () => { outer.getShepherdingList() });
    }
    this.inputChangeCluster('');
  }

  render() {
    const { classes, container, theme, content, title } = this.props;
    const { shepherdingList, locallist, clusterlist } = this.state;

    return (
      <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
        <div style={{ paddingTop: 10 }}>
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Pengaturan
            </Link>
            <Link color="textPrimary"
            // href="/bo/master/user/list" 
            href='/bo/setting/shepherding/list'>
              Daftar Penggembalaan
            </Link>            
          </Breadcrumbs>
        </div>
        <div className="filter">
          <div style={{ marginBottom: 10 }}>
            <Autocomplete
              options={locallist}
              getOptionLabel={option => option.name}
              value={this.state.selectedLocal}
              onChange={this.changeLocal}
              onFocus={(e)=>{this.inputChangeLocal('')}}
              onInputChange={(event, val) => this.inputChangeLocal(val)}
              renderInput={
                param => (
                  <TextField
                    {...param}
                    id="outlined-basic"
                    // variant="outlined" 
                    label="Lokal"
                    size="small"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
              }
            />
          </div>
          <div style={{ marginBottom: 5 }}>
            <Autocomplete
              options={clusterlist}
              getOptionLabel={option => option.name}
              value={this.state.selectedCluster}
              onChange={this.changeCluster}
              onFocus={(e)=>{this.inputChangeCluster('')}}
              onInputChange={(event, val) => this.inputChangeCluster(val)}
              renderInput={
                param => (
                  <TextField
                    {...param}
                    id="cluster"
                    label="Wilayah"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
              }
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              value={this.state.page.keyword}
              size="small"
              style={{ margin: '5px' }}
              onChange={(val) => this.search(val)}
            />
            <IconButton
              color="primary"
              aria-label="detail"
              component="span"
              onClick={() => { this.addnew() }}
            >
              <Add />
            </IconButton>
            {/* <IconButton
              color="primary"
              aria-label="detail"
              component="span"
              onClick={() => { this.deleteShepherding() }}
            >
              <Remove />
            </IconButton> */}
          </div>
        </div>
        {(this.state.mounted)&&
        <DataTable
          title="Penggembalaan"
          columns={this.columns}
          data={shepherdingList}
          noDataComponent={
            <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid', marginTop: 10 }}>
              <CardMedia style={{ textAlign: 'center' }}>
                <MoodBad color="action" style={{
                  fontSize: 40,
                  marginTop: 10,
                  marginBottom: 10
                }} />
              </CardMedia>
              <CardContent>
                <Typography>
                  Data tidak tersedia
                </Typography>
              </CardContent>
            </Card>

          }
          // selectableRows
          // selectableRowsComponent={Checkbox}
          highlightOnHover
          // subHeader
          noHeader
          customStyles={customStyles}
          onSelectedRowsChange={this.handleSelectedRows}
          pagination
          paginationServer
          paginationRowsPerPageOptions={[100,250,500]}
          paginationDefaultPage={this.state.page.page}
          paginationTotalRows={this.state.shepherdingListTotal}
          paginationPerPage={this.state.page.count}
          onChangePage={(val) => this.onChangePage(val)}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          expandableRows
          expandableRowsComponent={<ExpandableComponent />}
          progressPending={ this.state.loadingTrigger}
          progressComponent={
          <Loader type="TailSpin" color="#204196" height={100} width={100}/>
          }
          subHeaderComponent={
            (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                  size="small"
                  style={{ margin: '5px' }}
                  onChange={(val) => this.search(val)}
                />
                <IconButton
                  color="primary"
                  aria-label="detail"
                  component="span"
                  onClick={() => { this.addnew() }}
                >
                  <Add />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="detail"
                  component="span"
                  onClick={() => { this.deleteShepherding() }}
                >
                  <Remove />
                </IconButton>
              </div>
            )
          }
        />
        }
        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          mode={this.state.dialogMode}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
        <DialogDetail
          open={this.state.detailOpen}
          title={this.state.detailTitle}
          content={this.state.detailContent}
          handleClose={this.handleDetailClose}
          handleOk={this.handleDetailClose}
        />
      </div>
    );
  }
}

DbSettingShepherding.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbSettingShepherding)));