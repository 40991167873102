import React from "react";
import ReactDOM from "react-dom";
import Timetable from 'react-timetable-events';
import moment from 'moment';
import {IconButton} from '@material-ui/core';
import { Add, Edit, Remove, List } from "@material-ui/icons"


class WeekScheduler extends React.Component {

    constructor(props){
        super(props);
        this.state = {
           
          }
    }


    renderTask = (event, defaultAttributes, styles) => {
        // defaultAttributes.className = 
        return (
            <div  onClick={() => this.props.openDetil(event)} 
                style={{
                    height: defaultAttributes.style.height,
                    marginTop: defaultAttributes.style.marginTop,
                    border: 'solid',
                    borderWidth: 1,
                    borderColor: event.color,
                    borderRadius: 5,
                    position: 'absolute',
                    width: '100%',
                    lineHeight: '15vh',
                    backgroundColor: "#ffffff",
                    // fontSize: '0.7em',
                    fontSize: 9,
                    fontWeight: 300,
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden'
                }}
                title={event.name}
                key={event.id}>
                <span style={
                    {
                        color: "black",
                        cursor: 'pointer',
                    }
                } className={styles.event_info} >{ event.name } </span>
                {/* <span style={
                    {
                        color: "black",
                        cursor: 'pointer',
                    }
                } className={styles.event_info}>{ event.datestart } </span> */}
                <span style={{color: "black", cursor: 'pointer'}} className={styles.event_info}>
                { event.startTime.format('HH:mm') } - { event.endTime.format('HH:mm') }
                </span>
            </div>
        )
    }

    

    render() {

        

        return (
            <Timetable
                events={this.props.eventProp}
                renderEvent={this.renderTask}
                hoursInterval= {[ 0, 24 ]}
                timeLabel={"Waktu"}
                getDayLabel={(day) => {
                    if(day == "monday") {
                        return "Senin"
                    }
                    if(day == "tuesday") {
                        return "Selasa"
                    }
                    if(day == "wednesday") {
                        return "Rabu"
                    }
                    if(day == "thursday") {
                        return "Kamis"
                    }
                    if(day == "friday") {
                        return "Jumat"
                    }
                    if(day == "saturday") {
                        return "Sabtu"
                    }
                    if(day == "sunday") {
                        return "Minggu"
                    }
                }}
            />
        );
    }
}

export default WeekScheduler;
