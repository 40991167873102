import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select, Input, InputAdornment, IconButton,
  MenuItem, Breadcrumbs, Link, OutlinedInput, InputLabel
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ValidateEmail } from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";
import urlConfig from "./../../../../../config/backend";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const queryString = require('query-string');

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername: {
    color: '#204196',
    fontWeight: 'bold'
  },
  labelerror: {
    color: '#ff1744',
    fontSize: 10,
    paddingTop: '5px'
  },
  konfirmasi: {
    paddingTop: '10px'
  },
  copyright: {
    paddingBottom: '10px'
  }
});

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

class DbMasterFulltimerEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewProfile: '',
      form: {
        id: "",
        email: "",
        jalaemail: "",
        firstname: "",
        lastname: "",
        gender: "",
        birthdate: new Date(),
        married: "",
        child: 0,
        address1: "",
        address2: "",
        mobile1: "",
        mobile2: "",
        city: "",
        country: "",
        province: "",
        pwd: "",
        lokalid: "",
        hall: "",
        profilepict: null,
        fttypeid: "",
        tipe: ""
      },
      error: {
        id: "",
        email: "",
        jalaemail: "",
        firstname: "",
        lastname: "",
        gender: "",
        birthdate: "",
        married: "",
        child: "",
        address1: "",
        address2: "",
        mobile1: "",
        mobile2: "",
        city: "",
        country: "",
        province: "",
        pwd: "",
        lokalid: "",
        hall: "",
        profilepict: null,
        fulltimertypeid: ""
      },
      localparam: {
        keyword: '',
        page: 1,
        count: 10
      },
      locallist: [],
      selectedLocal: {},
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      disableBtnSubmit: false,
      mode: 'add',
      alowPeriod: [],
      showPassword: false,
      filtertable: {},
      filterLokal: {},
      fttypepage: {
        keyword: "",
        count: 10
      },
      fttypes: [],
      fttypesobject: null,
    };
    this.changeFttypes = this.changeFttypes.bind(this);
  }

  // loadData(){
  //   const outer = this;
  //   axios.defaults.headers.common['Authorization'] ='Bearer '+window.localStorage.getItem("tkn");
  //   axios.post('app/usr/profileget', {'userid':window.localStorage.getItem("uid")}
  //   ).then(({data}) => {
  //     if (data.sta && data.stacod === 200) {
  //       let elemData=data.data;

  //       let form={
  //         email:elemData.email,
  //         name:elemData.name,
  //         gender:elemData.gender,
  //         schoolorigin:elemData.schoolorigin,
  //         yeargraduate:elemData.yeargraduate,
  //         address:elemData.address,
  //         city:elemData.city,
  //         mobile:elemData.mobile,
  //         line:elemData.line,
  //         fb:elemData.fb,
  //         ig:elemData.ig,
  //       }
  //       outer.setState({
  //         form:form,
  //         alowPeriod:data.data.period
  //       })
  //     }
  //   }).catch(function (error) {
  //     outer.showDialog('Warning',Errormsg["500"]);
  //   });
  // }

  componentDidMount() {
    // this.loadData();
    let outer = this;
    // // console.log("user ", this.props.param);
    let qparam = queryString.parse(this.props.location.search);
    let filter = this.props.location.state.filter;
    this.setState({ filtertable: filter, filterLokal: this.props.location.state.selectedLokal });
    // // console.log("qparam ",qparam);
    this.getLocal();
    if (qparam && qparam.i) {
      if (this.props.param != null) {
        let user = this.props.param.detail;
        this.setState({
          form: {
            id: user.id,
            email: user.email,
            jalaemail: user.jalaemail,
            firstname: user.firstname,
            lastname: user.lastname,
            gender: user.gender,
            birthdate: new Date(user.birthdate),
            married: user.married,
            child: user.child,
            address1: user.address1,
            address2: user.address2,
            mobile1: user.mobile1,
            mobile2: user.mobile2,
            city: user.city,
            country: user.country,
            province: user.province,
            lokalid: user.lokal.id,
            hall: user.hall,
            pwd: "",
            fttypeid: "",
            tipe: user.tipe
          },
          selectedLocal: user.lokal,
          previewProfile: user.profilepict != "" ?
            urlConfig.urlBackendProfile + user.profilepict : "",
          mode: 'edit'
        })
      } else {
        //get detail
        this.getUserDetail(qparam.i);
      }
    }
    // if(this.props.param != null){
    //   let user = this.props.param.detail;
    //   this.setState({
    //     form:{
    //       id:user.id,
    //       email:user.email,
    //       jalaemail:user.jalaemail,
    //       firstname:user.firstname,
    //       lastname:user.lastname,
    //       gender:user.gender,
    //       birthdate:new Date(user.birthdate),
    //       married: user.married,
    //       child: user.child,
    //       address1:user.address1,
    //       address2:user.address2,
    //       mobile1:user.mobile1,
    //       mobile2:user.mobile2,
    //       city:user.city,
    //       country:user.country,
    //       province:user.province,
    //       pwd:""
    //     },
    //     previewProfile: user.profilepict != "" ? 
    //     urlConfig.urlBackendProfile + user.profilepict : "",
    //     mode:'edit'
    //   },()=>{// console.log("form",outer.state.form)})
    // }
  }

  getUserDetail = (id) => {
    let outer = this;
    axios.post('bo/int/master/cust', { id: id }).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        let user = data.data;
        this.setState({
          form: {
            id: user.id,
            email: user.email,
            jalaemail: user.jalaemail,
            firstname: user.firstname,
            lastname: user.lastname,
            gender: user.gender,
            birthdate: new Date(user.birthdate),
            married: user.married,
            child: user.child,
            address1: user.address1,
            address2: user.address2,
            mobile1: user.mobile1,
            mobile2: user.mobile2,
            city: user.city,
            country: user.country,
            province: user.province,
            lokalid: user.lokal.id,
            hall: user.hall,
            pwd: "",
            fttypeid: "",
            tipe: user.tipe
          },
          selectedLocal: user.lokal,
          previewProfile: user.profilepict != "" ?
            urlConfig.urlBackendProfile + user.profilepict : "",
          mode: 'edit'
        })
        // outer.setState({userList:data.data});
      }
    }).catch(function (error) {
      outer.setState({
        disableBtnSubmit: false
      });
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      if (this.state.dialogTitle != "Information") {
        this.setState({ dialogOpen: false });
      } else {
        this.setState({
          dialogOpen: false
        }, () => {
          // this.props.history.push("/bo/master/fulltimer/list",{filter:this.state.filtertable});
          this.backToList();
          this.props.deselectParam();
        })
      }
    }
  }

  showDialog = (title, content) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content
    });
  }

  backToList = () => {
    this.props.history.push({ pathname: "/bo/master/fulltimer/list", filter: this.state.filtertable, selectedLokal: this.state.filterLokal });
  }

  handleChange = (e) => {
    let outer = this;
    let { form, error } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if (inputName === "accept") {
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if (newAccept === true) {
        error[inputName] = ""
      }
    }

    this.setState({
      form: form,
      error: error
    }, () => {
      switch (inputName) {
        case "mobile1":
          if (inputValue) {
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        case "mobile2":
          if (inputValue) {
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        case "child":
          if (inputValue) {
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        default:
          break;
      }
    });
  }

  handleValidation = (e) => {
    // console.log("validate")
    let { form, error, mode } = this.state;

    if (form.email.trim().length === 0) {
      error.email = <IntlMessages id="master.user.error.email" />;
    } else {
      error.email = "";
    }

    // if (form.jalaemail.trim().length === 0) {
    //   error.jalaemail = <IntlMessages id="master.user.error.jalaemail" />;
    // } else {
    //   error.jalaemail = "";
    // }

    if (form.firstname.trim().length === 0) {
      error.firstname = <IntlMessages id="master.user.error.firstname" />;
    } else {
      error.firstname = "";
    }

    if (form.lastname.trim().length === 0) {
      error.lastname = <IntlMessages id="master.user.error.lastname" />;
    } else {
      error.lastname = "";
    }


    if (form.gender.trim().length === 0) {
      error.gender = <IntlMessages id="master.user.error.gender" />;
    } else {
      error.gender = "";
    }

    if (form.fttypeid.trim().length === 0 && form.id == '') {
      error.fulltimertypeid = <IntlMessages id="master.user.error.fttype" />;
    } else {
      error.fulltimertypeid = "";
    }

    if (form.child < 0) {
      error.child = <IntlMessages id="master.user.error.child" />;
    } else {
      error.child = "";
    }

    if (form.pwd.trim().length === 0 && mode == 'add') {
      error.pwd = <IntlMessages id="master.user.error.pwd" />;
    } else {
      error.pwd = "";
    }

    if (form.lokalid.trim().length === 0) {
      error.lokalid = "Lokal harus diinput";
    } else {
      error.lokalid = "";
    }

    // if (form.hall.trim().length === 0) {
    //   error.hall = "Hall harus diinput";
    // } else {
    //   error.hall = "";
    // }

    // if(form.birthdate.trim().length === 0){
    // error.birthdate=<IntlMessages id="master.user.error.birthdate"/>;
    // }else{
    //   error.birthdate="";
    // }


    if (!this.isValidForm()) {
      form.accept = false;
      error.accept = "";
    }

    this.setState({
      form: form,
      error: error
    }, () => {

      if (!this.isValidForm()) {
        return;
      }

      this.handleSubmit();

    });

  }

  getLocal() {
    let outer = this;
    axios.post('bo/int/master/local/list', outer.state.localparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          locallist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  handleSubmit = () => {
    let { form } = this.state;
    const formData = new FormData();
    Object.entries(form).map(([key, value]) => {
      if (value != undefined) {
        switch (key) {
          case 'birthdate':
            formData.append(key, formatDate(value));
            break;
          default:
            formData.append(key, value)
            break;
        }
      } else {
        formData.append(key, '')
      }
    });


    if (this.state.mode == 'add') {

      const outer = this;
      this.setState({
        disableBtnSubmit: true
      }, () => {
        axios.post('bo/int/master/cust/add', formData
        ).then(({ data }) => {
          if (data.sta && data.stacod === 200) {
            outer.setState({
              form: {
                id: "",
                email: "",
                jalaemail: "",
                firstname: "",
                lastname: "",
                gender: "",
                birthdate: new Date(),
                married: "",
                child: 0,
                address1: "",
                address2: "",
                mobile1: "",
                mobile2: "",
                city: "",
                country: "",
                province: "",
                pwd: "",
                profilepict: null
              },
              error: {
                id: "",
                email: "",
                jalaemail: "",
                firstname: "",
                lastname: "",
                gender: "",
                birthdate: "",
                married: "",
                child: "",
                address1: "",
                address2: "",
                mobile1: "",
                mobile2: "",
                city: "",
                country: "",
                province: "",
                pwd: "",
                profilepict: null
              },
              previewProfile: '',
              disableBtnSubmit: false
            }, () => {
              if (data.msg == "Ok") {
                outer.showDialog('Information', <IntlMessages id="master.user.success.adduser" />);
              } else {
                outer.showDialog('Information', data.msg);
              }
            })

          } else {
            outer.setState({
              disableBtnSubmit: false
            });
            outer.showDialog('Warning', data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', Errormsg["500"]);
        });
      });

    } else {
      const outer = this;
      this.setState({
        disableBtnSubmit: true
      }, () => {
        axios.post('bo/int/master/cust/edit', formData
        ).then(({ data }) => {
          if (data.sta && data.stacod === 200) {
            outer.setState({
              form: {
                id: "",
                email: "",
                jalaemail: "",
                firstname: "",
                lastname: "",
                gender: "",
                birthdate: new Date(),
                married: "",
                child: 0,
                address1: "",
                address2: "",
                mobile1: "",
                mobile2: "",
                city: "",
                country: "",
                province: "",
                pwd: "",
                profilepict: null
              },
              error: {
                id: "",
                email: "",
                jalaemail: "",
                firstname: "",
                lastname: "",
                gender: "",
                birthdate: "",
                married: "",
                child: "",
                address1: "",
                address2: "",
                mobile1: "",
                mobile2: "",
                city: "",
                country: "",
                province: "",
                pwd: "",
                profilepict: null
              },
              previewProfile: '',
              disableBtnSubmit: false
            }, () => {
              if (data.msg == "Ok") {
                outer.showDialog('Information', <IntlMessages id="master.user.success.edituser" />);
              } else {
                outer.showDialog('Information', data.msg);
              }
            });
            // this.props.history.push("/bo/master/fulltimer/list");
            // this.props.deselectParam();

          } else {
            outer.setState({
              disableBtnSubmit: false
            });
            outer.showDialog('Warning', data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', Errormsg["500"]);
        });
      });

    }

  }

  isValidForm = () => {
    return (
      !this.state.error.email &&
      // !this.state.error.jalaemail &&
      !this.state.error.firstname &&
      !this.state.error.lastname &&
      !this.state.error.married &&
      !this.state.error.gender &&
      !this.state.error.lokalid &&
      // !this.state.error.hall &&
      !this.state.error.pwd)
  }

  changeLocal = (event, val) => {
    if (val) {

      this.setState({
        selectedLocal: val,
        form: {
          ...this.state.form,
          lokalid: val.id
        }
      });
    } else {
      this.setState({
        selectedLocal: {},
        form: {
          ...this.state.form,
          lokalid: "",
        }
      })
    }
    this.inputChangeLocal('');
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    // console.log("e detail", e.target.files[0]);

    reader.onloadend = () => {
      // console.log("reader result", reader.result)
      this.setState({
        form: {
          ...this.state.form,
          profilepict: file
        },
        previewProfile: reader.result
      });
    }

    reader.readAsDataURL(file)
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  inputChangeLocal(val) {
    this.setState({
      localparam: {
        ...this.state.localparam,
        keyword: val
      }
    }, () => {
      this.getLocal();
    })
  }

  getEditFttypeList() {
    let outer = this;
    axios.post('bo/int/master/fttype', { id: outer.state.form.fttypeid }).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          fttypesobject: data.data
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  getFttypeList() {
    let outer = this;
    axios.post('bo/int/master/fttype/list', outer.state.fttypepage).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          fttypes: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  changeFttypes(event, val) {
    if (val) {
      this.setState({
        fttypesobject: val,
        form: {
          ...this.state.form,
          fttypename: val.name,
          fttypeid: val.id,
          target: val.target
        }
      })
    } else {
      this.setState({
        fttypesobject: null,
        form: {
          ...this.state.form,
          fttypeid: "",
          fttypename: "",
          target: 0
        }
      })
    }
  }

  inputChangeFttype(val) {
    this.setState({
      fttypepage: {
        ...this.state.fttypepage,
        keyword: val
      }
    }, () => {
      this.getFttypeList();
    })
  }

  render() {
    const { classes, theme } = this.props;
    const { error, alowPeriod, locallist, fttypes } = this.state;
    const title = "Profile"
    return (
      <div style={{ marginTop: theme.spacing(4) }}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Master
            </Link>
            <Link color="inherit"
              // href="/bo/master/fulltimer/list"
              href='#'
              onClick={() => { this.backToList() }}>
              Daftar Fulltimer
            </Link>
            <Link color="textPrimary" href="/bo/master/fulltimer/edit">
              {
                this.state.mode == "add" ?
                  "Tambah Fulltimer" : "Ubah Fulltimer"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
            <image src={this.state.previewProfile} style={{ width: 100 }} />
            <form className={classes.form} noValidate>
              <input type="hidden" value="disableautofill" />

              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <TextField
                    id="email"
                    name="email"
                    label={<IntlMessages id="emailaddress" />}
                    value={this.state.form.email}
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.email ? true : false}
                  />
                  {
                    error.email &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.email}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="jalaemail"
                    name="jalaemail"
                    label={<IntlMessages id="master.user.jalaemail" />}
                    value={this.state.form.jalaemail}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  // error={error.jalaemail ? true : false}
                  />
                  {/* {
                    error.jalaemail &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.jalaemail}
                    />
                  } */}
                </Grid>

                <Grid item xs={12}>
                  <FormControl component="fieldset" className={classes.formControl} style={{ width: '100%' }}>
                    {/* {(this.state.mode == 'add')?
                        <FormLabel component="legend" htmlFor='pwd' error={error.pwd?true:false} style={{fontSize:12}}><IntlMessages id="password"/>*</FormLabel>
                      :  
                        <FormLabel component="legend" htmlFor='pwd' error={error.pwd?true:false} style={{fontSize:12}}><IntlMessages id="password"/></FormLabel>
                      } */}
                    <InputLabel style={{ top: -6, left: 13 }} shrink required={this.state.mode == 'add'} component="legend" htmlFor='pwd' error={error.pwd ? true : false}><IntlMessages id="password" /></InputLabel>
                    {/* <PasswordField
                        hintText="At least 8 characters"
                        floatingLabelText="Enter your password"
                        errorText="Your password is too short"
                        id="pwd"
                        name="pwd"
                        label="Password"
                        value={this.state.form.pwd} 
                        required
                        fullWidth
                        onChange={this.handleChange} 
                        error={error.pwd?true:false}
                      /> */}
                    <OutlinedInput
                      id="pwd"
                      name="pwd"
                      autoComplete="new-password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={this.state.form.pwd}
                      onChange={this.handleChange}
                      label="Password"
                      required={this.state.mode == 'add'}
                      notched={true}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {
                      error.pwd &&
                      <FormControlLabel
                        classes={{ label: classes.labelerror }}
                        control={<div>&nbsp;&nbsp;&nbsp;</div>}
                        label={error.pwd}
                      />
                    }
                  </FormControl>
                </Grid>


                <Grid item xs={12}>
                  <TextField
                    id="firstname"
                    name="firstname"
                    label={<IntlMessages id="master.user.firstname" />}
                    value={this.state.form.firstname}
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.firstname ? true : false}
                  />
                  {
                    error.firstname &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.firstname}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="lastname"
                    name="lastname"
                    label={<IntlMessages id="master.user.lastname" />}
                    value={this.state.form.lastname}
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.lastname ? true : false}
                  />
                  {
                    error.lastname &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.lastname}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <FormControl component="fieldset" className={classes.formControl} style={{ width: '100%' }}>
                    <FormLabel component="legend" error={error.gender ? true : false} style={{ fontSize: 12 }}><IntlMessages id="master.user.gender" />*</FormLabel>
                    <RadioGroup aria-label="gender" id="gender" name="gender" value={this.state.form.gender} onChange={this.handleChange}>
                      <FormControlLabel value="Male" control={<Radio />} label={<IntlMessages id="master.user.male" />} />
                      <FormControlLabel value="Female" control={<Radio />} label={<IntlMessages id="master.user.female" />} />
                    </RadioGroup>
                    {
                      error.gender &&
                      <FormControlLabel
                        classes={{ label: classes.labelerror }}
                        control={<div>&nbsp;&nbsp;&nbsp;</div>}
                        label={error.gender}
                      />
                    }
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    options={locallist}
                    onFocus={(e) => { this.inputChangeLocal('') }}
                    getOptionLabel={option => option.name}
                    value={this.state.selectedLocal}
                    onChange={this.changeLocal}
                    onInputChange={(event, val) => this.inputChangeLocal(val)}
                    renderInput={
                      param => (
                        <TextField
                          {...param}
                          id="local"
                          label="Lokal"
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={error.lokalid ? true : false}
                        />
                      )
                    }
                  />
                  {
                    error.lokalid &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.lokalid}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="hall"
                    name="hall"
                    label="Hall"
                    value={this.state.form.hall}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  // error={error.hall ? true : false}
                  />
                  {
                    // error.hall &&
                    // <FormControlLabel
                    //   classes={{ label: classes.labelerror }}
                    //   control={<div>&nbsp;&nbsp;&nbsp;</div>}
                    //   label={error.hall}
                    // />
                  }
                </Grid>

                {/* TODO: Buat pakai strip / component material picker? */}
                <Grid item xs={12}>
                  <FormControl component="fieldset" className={classes.formControl} style={{ width: '100%' }}>
                    {/* <FormLabel component="legend" error={error.birthdate?true:false} style={{fontSize:12}}><IntlMessages id="master.user.birthdate"/></FormLabel> */}
                    <KeyboardDatePicker
                      clearable
                      label={<IntlMessages id="master.user.birthdate" />}
                      required
                      inputVariant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.form.birthdate}
                      placeholder="10-10-2018"
                      onChange={date => this.setState({ form: { ...this.state.form, birthdate: date } })}
                      maxDate={new Date()}
                      format="DD-MM-YYYY"
                    />
                    {
                      error.birthdate &&
                      <FormControlLabel
                        classes={{ label: classes.labelerror }}
                        control={<div>&nbsp;&nbsp;&nbsp;</div>}
                        label={error.birthdate}
                      />
                    }
                  </FormControl>
                </Grid>
                {(this.state.form.id == '') ?
                  <Grid item xs={12}>

                    <Autocomplete
                      options={fttypes}
                      getOptionLabel={option => option && option.name}
                      value={this.state.fttypesobject}
                      onChange={this.changeFttypes}
                      onFocus={(e) => { this.inputChangeFttype('') }}
                      onInputChange={(event, val) => this.inputChangeFttype(val)}
                      renderInput={
                        param => (
                          <TextField
                            {...param}
                            label="Tipe Target FT"
                            fullWidth
                            required
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={error.fulltimertypeid ? true : false}
                          />
                        )
                      }
                    />
                    {
                      error.fulltimertypeid &&
                      <FormControlLabel
                        classes={{ label: classes.labelerror }}
                        control={<div>&nbsp;&nbsp;&nbsp;</div>}
                        label={error.fulltimertypeid}
                      />
                    }
                  </Grid>
                  :
                  <>
                    <div style={{ paddingLeft: 10, fontSize: 12, width: '100%' }}>
                      Tipe Target FT:
                    </div>
                    <br />
                    <div style={{ paddingLeft: 20, fontSize: 16, fontWeight: 'bold', width: '100%', marginBottom: 10 }}>
                      {this.state.form.tipe}
                    </div>
                  </>
                }
                {/* <Grid item xs={12}>
                  <FormControl component="fieldset" className={classes.formControl} style={{ width: '100%' }}>
                    <FormLabel component="legend" error={error.married ? true : false} style={{ fontSize: 12 }}><IntlMessages id="master.user.married" />*</FormLabel>
                    <RadioGroup aria-label="married" id="married" name="married" value={this.state.form.married} onChange={this.handleChange}>
                      <FormControlLabel value="Married" control={<Radio />} label={<IntlMessages id="master.user.marriedstat" />} />
                      <FormControlLabel value="Single" control={<Radio />} label={<IntlMessages id="master.user.singlestat" />} />
                    </RadioGroup>
                    {
                      error.married &&
                      <FormControlLabel
                        classes={{ label: classes.labelerror }}
                        control={<div>&nbsp;&nbsp;&nbsp;</div>}
                        label={error.married}
                      />
                    }
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="child"
                    name="child"
                    disabled={this.state.form.married == "Single"}
                    label={<IntlMessages id="master.user.child" />}
                    value={this.state.form.child}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.child ? true : false}
                  />
                  {
                    error.child &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.child}
                    />
                  }
                </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    id="address1"
                    name="address1"
                    label={<IntlMessages id="master.user.address1" />}
                    value={this.state.form.address1}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.address1 ? true : false}
                  />
                  {
                    error.address1 &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.address1}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="address2"
                    name="address2"
                    label={<IntlMessages id="master.user.address2" />}
                    value={this.state.form.address2}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.address2 ? true : false}
                  />
                  {
                    error.address2 &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.address2}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="country"
                    name="country"
                    label={<IntlMessages id="master.user.country" />}
                    value={this.state.form.country}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.country ? true : false}
                  />
                  {
                    error.country &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.country}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="province"
                    name="province"
                    label={<IntlMessages id="master.user.province" />}
                    value={this.state.form.province}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.province ? true : false}
                  />
                  {
                    error.province &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.province}
                    />
                  }
                </Grid>


                <Grid item xs={12}>
                  <TextField
                    id="city"
                    name="city"
                    label={<IntlMessages id="master.user.city" />}
                    value={this.state.form.city}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.city ? true : false}
                  />
                  {
                    error.city &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.city}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="mobile1"
                    name="mobile1"
                    label={<IntlMessages id="master.user.mobile1" />}
                    value={this.state.form.mobile1}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.mobile1 ? true : false}
                  />
                  {
                    error.mobile1 &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.mobile1}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="mobile2"
                    name="mobile2"
                    label={<IntlMessages id="master.user.mobile2" />}
                    value={this.state.form.mobile2}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.mobile2 ? true : false}
                  />
                  {
                    error.mobile2 &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.mobile2}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <label>Gambar Profil: </label>
                  <br />
                  <img
                    src={this.state.previewProfile}
                    style={{ width: 100, marginBottom: 10 }}
                  />
                  <br />
                  <input
                    type="file"
                    onChange={(e) => this.handleImageChange(e)}
                  />
                </Grid>

              </Grid>
              <Button
                type="button"
                className={classes.submit}
                color="primary"
                variant="contained"
                fullWidth
                onClick={this.handleValidation}
                disabled={this.state.disableBtnSubmit}
              >
                UPDATE
              </Button>
            </form>
          </div>
        </Container>

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
      </div>
    );
  }
}

DbMasterFulltimerEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, { deselectParam }
)(withStyles(styles)(withTheme(DbMasterFulltimerEdit)));