import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import App from './routes/App';
import theme from './theme';
// import NextApp from './NextApp';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import configureStore from "./store/configureStore";
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';

export const store = configureStore();

ReactGA.initialize('UA-213473342-2');

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />

    <Provider store={store.store}>
      <PersistGate
        loading={null}
        persistor={store.persistor}
      >
        <App />
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  document.querySelector('#root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
