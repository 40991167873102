import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select, CardContent,
  MenuItem, Breadcrumbs, Link, Card, CardHeader
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import {ValidateEmail} from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";
import { TagFaces } from '@material-ui/icons';

const queryString = require('query-string');

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function isEmpty(obj) {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

class DbSettingTargetPeriodeEdit extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        form: {
          id:"",
          ftid:"",
          ftname:"",
          targetlist: []
        },
        error:{
          fulltimerid:"",
          targetlist: ""
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
        ftpage: {
          keyword: "",
          count: 10
        },
        targobj : {
          baptis:{id:"",catid:"",catname:"Baptis",catcode:"baptis",subcatid:"",subcatname:"",subcatcode:"",target:0},
          vital:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"",subcatcode:"",target:0},
          vital1:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 1",subcatcode:"vital1",target:0},
          vital2:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 2",subcatcode:"vital2",target:0},
          vital3:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 3",subcatcode:"vital3",target:0},
          vital4:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 4",subcatcode:"vital4",target:0},
          vital5:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 5",subcatcode:"vital5",target:0},
          vital6:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 6",subcatcode:"vital6",target:0},
          kelompokvital:{id:"",catid:"",catname:"Kelompok Vital",catcode:"kelompokvital",subcatid:"",subcatname:"",subcatcode:"",target:0},
          spr:{id:"",catid:"",catname:"Kandidat SPR",catcode:"spr",subcatid:"",subcatname:"",subcatcode:"",target:0}
        },
        fulltimers:[],
        fulltimerobject: null,
        catpage: {
          keyword: "",
          count: 10
        },
        categories:[],
        filtertable:{},
        filterAll:{},
        
    };

    this.changeFulltimer = this.changeFulltimer.bind(this);
  }

  componentDidMount() {
    let outer = this;
    let qparam = queryString.parse(this.props.location.search); 
    let filter= this.props.location.state.filter;
        this.setState({filtertable:filter, filterAll:this.props.location.state.filterAll});
    // // console.log("qparam ",qparam);
    if(qparam && qparam.i){
      if(this.props.param != null){
        let targ = this.props.param.detail;
        let targobj = {
          baptis:{id:"",catid:"",catname:"Baptis",catcode:"baptis",subcatid:"",subcatname:"",subcatcode:"",target:0},
          vital:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"",subcatcode:"",target:0},
          vital1:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 1",subcatcode:"vital1",target:0},
          vital2:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 2",subcatcode:"vital2",target:0},
          vital3:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 3",subcatcode:"vital3",target:0},
          vital4:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 4",subcatcode:"vital4",target:0},
          vital5:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 5",subcatcode:"vital5",target:0},
          vital6:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 6",subcatcode:"vital6",target:0},
          kelompokvital:{id:"",catid:"",catname:"Kelompok Vital",catcode:"kelompokvital",subcatid:"",subcatname:"",subcatcode:"",target:0},
          spr:{id:"",catid:"",catname:"Kandidat SPR",catcode:"spr",subcatid:"",subcatname:"",subcatcode:"",target:0}
        };
        targ.targetlist.forEach(target => {
          switch (target.catcode) {
            case "baptis":
              targobj.baptis.id=target.id;
              targobj.baptis.catid=target.catid;
              targobj.baptis.catname=target.catname;
              targobj.baptis.catcode=target.catcode;
              targobj.baptis.subcatid=target.subcatid;
              targobj.baptis.subcatname=target.subcatname;
              targobj.baptis.subcatcode=target.subcatcode;
              targobj.baptis.target=target.target;
              break;
            case "vital":
              switch (target.subcatcode) {
                case "":
                  targobj.vital.id=target.id;
                  targobj.vital.catid=target.catid;
                  targobj.vital.catname=target.catname;
                  targobj.vital.catcode=target.catcode;
                  targobj.vital.subcatid=target.subcatid;
                  targobj.vital.subcatname=target.subcatname;
                  targobj.vital.subcatcode=target.subcatcode;
                  targobj.vital.target=target.target;
                  break;
                case "vital1":
                  targobj.vital1.id=target.id;
                  targobj.vital1.catid=target.catid;
                  targobj.vital1.catname=target.catname;
                  targobj.vital1.catcode=target.catcode;
                  targobj.vital1.subcatid=target.subcatid;
                  targobj.vital1.subcatname=target.subcatname;
                  targobj.vital1.subcatcode=target.subcatcode;
                  targobj.vital1.target=target.target;
                  break;
                case "vital2":
                  targobj.vital2.id=target.id;
                  targobj.vital2.catid=target.catid;
                  targobj.vital2.catname=target.catname;
                  targobj.vital2.catcode=target.catcode;
                  targobj.vital2.subcatid=target.subcatid;
                  targobj.vital2.subcatname=target.subcatname;
                  targobj.vital2.subcatcode=target.subcatcode;
                  targobj.vital2.target=target.target;
                  break;
                case "vital3":
                  targobj.vital3.id=target.id;
                  targobj.vital3.catid=target.catid;
                  targobj.vital3.catname=target.catname;
                  targobj.vital3.catcode=target.catcode;
                  targobj.vital3.subcatid=target.subcatid;
                  targobj.vital3.subcatname=target.subcatname;
                  targobj.vital3.subcatcode=target.subcatcode;
                  targobj.vital3.target=target.target;
                  break;
                case "vital4":
                  targobj.vital4.id=target.id;
                  targobj.vital4.catid=target.catid;
                  targobj.vital4.catname=target.catname;
                  targobj.vital4.catcode=target.catcode;
                  targobj.vital4.subcatid=target.subcatid;
                  targobj.vital4.subcatname=target.subcatname;
                  targobj.vital4.subcatcode=target.subcatcode;
                  targobj.vital4.target=target.target;
                  break;
                case "vital5":
                  targobj.vital5.id=target.id;
                  targobj.vital5.catid=target.catid;
                  targobj.vital5.catname=target.catname;
                  targobj.vital5.catcode=target.catcode;
                  targobj.vital5.subcatid=target.subcatid;
                  targobj.vital5.subcatname=target.subcatname;
                  targobj.vital5.subcatcode=target.subcatcode;
                  targobj.vital5.target=target.target;
                  break;
                case "vital6":
                  targobj.vital6.id=target.id;
                  targobj.vital6.catid=target.catid;
                  targobj.vital6.catname=target.catname;
                  targobj.vital6.catcode=target.catcode;
                  targobj.vital6.subcatid=target.subcatid;
                  targobj.vital6.subcatname=target.subcatname;
                  targobj.vital6.subcatcode=target.subcatcode;
                  targobj.vital6.target=target.target;
                  break;
              
                default:
                  break;
              }
              break;
            case "kelompokvital":
              targobj.kelompokvital.id=target.id;
              targobj.kelompokvital.catid=target.catid;
              targobj.kelompokvital.catname=target.catname;
              targobj.kelompokvital.catcode=target.catcode;
              targobj.kelompokvital.subcatid=target.subcatid;
              targobj.kelompokvital.subcatname=target.subcatname;
              targobj.kelompokvital.subcatcode=target.subcatcode;
              targobj.kelompokvital.target=target.target;
              break;
            case "spr":
              targobj.spr.id=target.id;
              targobj.spr.catid=target.catid;
              targobj.spr.catname=target.catname;
              targobj.spr.catcode=target.catcode;
              targobj.spr.subcatid=target.subcatid;
              targobj.spr.subcatname=target.subcatname;
              targobj.spr.subcatcode=target.subcatcode;
              targobj.spr.target=target.target;
              break;
          
            default:
              break;
          }
        });
        this.setState({
          form: {
            id: targ.id,
            ftname: targ.ftname,
            ftid: targ.ftid,
            // targetlist: targ.targetlist,
          },
          targobj,
          mode: 'edit'
        }, () => {
          this.getEditFulltimerList();
        })
      }else{
        //get detail
        this.getPeriodeTargetDetail(qparam.i);
      }
    }else{
      this.getCategoryList();
      this.getFulltimerList();
    }
    // if(isEmpty(this.props.param) != true) {
    //   let targ = this.props.param.detail;
    //   let targobj = {
    //     baptis:{id:"",catid:"",catname:"Baptis",catcode:"baptis",subcatid:"",subcatname:"",subcatcode:"",target:0},
    //     vital:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"",subcatcode:"",target:0},
    //     vital1:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 1",subcatcode:"vital1",target:0},
    //     vital2:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 2",subcatcode:"vital2",target:0},
    //     vital3:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 3",subcatcode:"vital3",target:0},
    //     vital4:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 4",subcatcode:"vital4",target:0},
    //     vital5:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 5",subcatcode:"vital5",target:0},
    //     vital6:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 6",subcatcode:"vital6",target:0},
    //     kelompokvital:{id:"",catid:"",catname:"Kelompok Vital",catcode:"kelompokvital",subcatid:"",subcatname:"",subcatcode:"",target:0},
    //     spr:{id:"",catid:"",catname:"Kandidat SPR",catcode:"spr",subcatid:"",subcatname:"",subcatcode:"",target:0}
    //   };
    //   targ.targetlist.forEach(target => {
    //     switch (target.catcode) {
    //       case "baptis":
    //         targobj.baptis.id=target.id;
    //         targobj.baptis.catid=target.catid;
    //         targobj.baptis.catname=target.catname;
    //         targobj.baptis.catcode=target.catcode;
    //         targobj.baptis.subcatid=target.subcatid;
    //         targobj.baptis.subcatname=target.subcatname;
    //         targobj.baptis.subcatcode=target.subcatcode;
    //         targobj.baptis.target=target.target;
    //         break;
    //       case "vital":
    //         switch (target.subcatcode) {
    //           case "":
    //             targobj.vital.id=target.id;
    //             targobj.vital.catid=target.catid;
    //             targobj.vital.catname=target.catname;
    //             targobj.vital.catcode=target.catcode;
    //             targobj.vital.subcatid=target.subcatid;
    //             targobj.vital.subcatname=target.subcatname;
    //             targobj.vital.subcatcode=target.subcatcode;
    //             targobj.vital.target=target.target;
    //             break;
    //           case "vital1":
    //             targobj.vital1.id=target.id;
    //             targobj.vital1.catid=target.catid;
    //             targobj.vital1.catname=target.catname;
    //             targobj.vital1.catcode=target.catcode;
    //             targobj.vital1.subcatid=target.subcatid;
    //             targobj.vital1.subcatname=target.subcatname;
    //             targobj.vital1.subcatcode=target.subcatcode;
    //             targobj.vital1.target=target.target;
    //             break;
    //           case "vital2":
    //             targobj.vital2.id=target.id;
    //             targobj.vital2.catid=target.catid;
    //             targobj.vital2.catname=target.catname;
    //             targobj.vital2.catcode=target.catcode;
    //             targobj.vital2.subcatid=target.subcatid;
    //             targobj.vital2.subcatname=target.subcatname;
    //             targobj.vital2.subcatcode=target.subcatcode;
    //             targobj.vital2.target=target.target;
    //             break;
    //           case "vital3":
    //             targobj.vital3.id=target.id;
    //             targobj.vital3.catid=target.catid;
    //             targobj.vital3.catname=target.catname;
    //             targobj.vital3.catcode=target.catcode;
    //             targobj.vital3.subcatid=target.subcatid;
    //             targobj.vital3.subcatname=target.subcatname;
    //             targobj.vital3.subcatcode=target.subcatcode;
    //             targobj.vital3.target=target.target;
    //             break;
    //           case "vital4":
    //             targobj.vital4.id=target.id;
    //             targobj.vital4.catid=target.catid;
    //             targobj.vital4.catname=target.catname;
    //             targobj.vital4.catcode=target.catcode;
    //             targobj.vital4.subcatid=target.subcatid;
    //             targobj.vital4.subcatname=target.subcatname;
    //             targobj.vital4.subcatcode=target.subcatcode;
    //             targobj.vital4.target=target.target;
    //             break;
    //           case "vital5":
    //             targobj.vital5.id=target.id;
    //             targobj.vital5.catid=target.catid;
    //             targobj.vital5.catname=target.catname;
    //             targobj.vital5.catcode=target.catcode;
    //             targobj.vital5.subcatid=target.subcatid;
    //             targobj.vital5.subcatname=target.subcatname;
    //             targobj.vital5.subcatcode=target.subcatcode;
    //             targobj.vital5.target=target.target;
    //             break;
    //           case "vital6":
    //             targobj.vital6.id=target.id;
    //             targobj.vital6.catid=target.catid;
    //             targobj.vital6.catname=target.catname;
    //             targobj.vital6.catcode=target.catcode;
    //             targobj.vital6.subcatid=target.subcatid;
    //             targobj.vital6.subcatname=target.subcatname;
    //             targobj.vital6.subcatcode=target.subcatcode;
    //             targobj.vital6.target=target.target;
    //             break;
            
    //           default:
    //             break;
    //         }
    //         break;
    //       case "kelompokvital":
    //         targobj.kelompokvital.id=target.id;
    //         targobj.kelompokvital.catid=target.catid;
    //         targobj.kelompokvital.catname=target.catname;
    //         targobj.kelompokvital.catcode=target.catcode;
    //         targobj.kelompokvital.subcatid=target.subcatid;
    //         targobj.kelompokvital.subcatname=target.subcatname;
    //         targobj.kelompokvital.subcatcode=target.subcatcode;
    //         targobj.kelompokvital.target=target.target;
    //         break;
    //       case "spr":
    //         targobj.spr.id=target.id;
    //         targobj.spr.catid=target.catid;
    //         targobj.spr.catname=target.catname;
    //         targobj.spr.catcode=target.catcode;
    //         targobj.spr.subcatid=target.subcatid;
    //         targobj.spr.subcatname=target.subcatname;
    //         targobj.spr.subcatcode=target.subcatcode;
    //         targobj.spr.target=target.target;
    //         break;
        
    //       default:
    //         break;
    //     }
    //   });
    //   this.setState({
    //     form: {
    //       id: targ.id,
    //       ftname: targ.ftname,
    //       ftid: targ.ftid,
    //       // targetlist: targ.targetlist,
    //     },
    //     targobj,
    //     mode: 'edit'
    //   }, () => {
    //     this.getEditFulltimerList();
    //   })
    // } else {
    //   this.getCategoryList();
    //   this.getFulltimerList();
    // }
  }

  getPeriodeTargetDetail = (id) => {
    let outer = this;
    axios.post('bo/int/fulltimer/ptarget/detail', {id:id}).then(({data})=>{
      if(data.sta && data.stacod === 200){        
        let targ = data.data;
        let targobj = {
          baptis:{id:"",catid:"",catname:"Baptis",catcode:"baptis",subcatid:"",subcatname:"",subcatcode:"",target:0},
          vital:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"",subcatcode:"",target:0},
          vital1:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 1",subcatcode:"vital1",target:0},
          vital2:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 2",subcatcode:"vital2",target:0},
          vital3:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 3",subcatcode:"vital3",target:0},
          vital4:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 4",subcatcode:"vital4",target:0},
          vital5:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 5",subcatcode:"vital5",target:0},
          vital6:{id:"",catid:"",catname:"Vital",catcode:"vital",subcatid:"",subcatname:"Vital 6",subcatcode:"vital6",target:0},
          kelompokvital:{id:"",catid:"",catname:"Kelompok Vital",catcode:"kelompokvital",subcatid:"",subcatname:"",subcatcode:"",target:0},
          spr:{id:"",catid:"",catname:"Kandidat SPR",catcode:"spr",subcatid:"",subcatname:"",subcatcode:"",target:0}
        };
        targ.targetlist.forEach(target => {
          switch (target.catcode) {
            case "baptis":
              targobj.baptis.id=target.id;
              targobj.baptis.catid=target.catid;
              targobj.baptis.catname=target.catname;
              targobj.baptis.catcode=target.catcode;
              targobj.baptis.subcatid=target.subcatid;
              targobj.baptis.subcatname=target.subcatname;
              targobj.baptis.subcatcode=target.subcatcode;
              targobj.baptis.target=target.target;
              break;
            case "vital":
              switch (target.subcatcode) {
                case "":
                  targobj.vital.id=target.id;
                  targobj.vital.catid=target.catid;
                  targobj.vital.catname=target.catname;
                  targobj.vital.catcode=target.catcode;
                  targobj.vital.subcatid=target.subcatid;
                  targobj.vital.subcatname=target.subcatname;
                  targobj.vital.subcatcode=target.subcatcode;
                  targobj.vital.target=target.target;
                  break;
                case "vital1":
                  targobj.vital1.id=target.id;
                  targobj.vital1.catid=target.catid;
                  targobj.vital1.catname=target.catname;
                  targobj.vital1.catcode=target.catcode;
                  targobj.vital1.subcatid=target.subcatid;
                  targobj.vital1.subcatname=target.subcatname;
                  targobj.vital1.subcatcode=target.subcatcode;
                  targobj.vital1.target=target.target;
                  break;
                case "vital2":
                  targobj.vital2.id=target.id;
                  targobj.vital2.catid=target.catid;
                  targobj.vital2.catname=target.catname;
                  targobj.vital2.catcode=target.catcode;
                  targobj.vital2.subcatid=target.subcatid;
                  targobj.vital2.subcatname=target.subcatname;
                  targobj.vital2.subcatcode=target.subcatcode;
                  targobj.vital2.target=target.target;
                  break;
                case "vital3":
                  targobj.vital3.id=target.id;
                  targobj.vital3.catid=target.catid;
                  targobj.vital3.catname=target.catname;
                  targobj.vital3.catcode=target.catcode;
                  targobj.vital3.subcatid=target.subcatid;
                  targobj.vital3.subcatname=target.subcatname;
                  targobj.vital3.subcatcode=target.subcatcode;
                  targobj.vital3.target=target.target;
                  break;
                case "vital4":
                  targobj.vital4.id=target.id;
                  targobj.vital4.catid=target.catid;
                  targobj.vital4.catname=target.catname;
                  targobj.vital4.catcode=target.catcode;
                  targobj.vital4.subcatid=target.subcatid;
                  targobj.vital4.subcatname=target.subcatname;
                  targobj.vital4.subcatcode=target.subcatcode;
                  targobj.vital4.target=target.target;
                  break;
                case "vital5":
                  targobj.vital5.id=target.id;
                  targobj.vital5.catid=target.catid;
                  targobj.vital5.catname=target.catname;
                  targobj.vital5.catcode=target.catcode;
                  targobj.vital5.subcatid=target.subcatid;
                  targobj.vital5.subcatname=target.subcatname;
                  targobj.vital5.subcatcode=target.subcatcode;
                  targobj.vital5.target=target.target;
                  break;
                case "vital6":
                  targobj.vital6.id=target.id;
                  targobj.vital6.catid=target.catid;
                  targobj.vital6.catname=target.catname;
                  targobj.vital6.catcode=target.catcode;
                  targobj.vital6.subcatid=target.subcatid;
                  targobj.vital6.subcatname=target.subcatname;
                  targobj.vital6.subcatcode=target.subcatcode;
                  targobj.vital6.target=target.target;
                  break;
              
                default:
                  break;
              }
              break;
            case "kelompokvital":
              targobj.kelompokvital.id=target.id;
              targobj.kelompokvital.catid=target.catid;
              targobj.kelompokvital.catname=target.catname;
              targobj.kelompokvital.catcode=target.catcode;
              targobj.kelompokvital.subcatid=target.subcatid;
              targobj.kelompokvital.subcatname=target.subcatname;
              targobj.kelompokvital.subcatcode=target.subcatcode;
              targobj.kelompokvital.target=target.target;
              break;
            case "spr":
              targobj.spr.id=target.id;
              targobj.spr.catid=target.catid;
              targobj.spr.catname=target.catname;
              targobj.spr.catcode=target.catcode;
              targobj.spr.subcatid=target.subcatid;
              targobj.spr.subcatname=target.subcatname;
              targobj.spr.subcatcode=target.subcatcode;
              targobj.spr.target=target.target;
              break;
          
            default:
              break;
          }
        });
        this.setState({
          form: {
            id: targ.id,
            ftname: targ.ftname,
            ftid: targ.ftid,
            // targetlist: targ.targetlist,
          },
          targobj,
          mode: 'edit'
        }, () => {
          this.getEditFulltimerList();
        })
        // outer.setState({userList:data.data});
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  inputChangeFulltimer(val) {
    this.setState({
      ftpage: {
        ...this.state.ftpage,
        keyword: val
      }
    }, () => {
      this.getFulltimerList();
    })
  }

 

  getEditFulltimerList() {
    let outer = this;
    axios.post('bo/int/master/cust', {id: outer.state.form.ftid}).then(({data})=>{
      if(data.sta && data.stacod === 200){
        outer.setState({
          fulltimerobject:data.data
        });
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  getFulltimerList() {
    let outer = this;
    axios.post('bo/int/master/cust/list', this.state.ftpage).then(({data})=>{
      if(data.sta && data.stacod === 200){
        outer.setState({
          fulltimers:data.data.list
        });
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  changeFulltimer(event, val) {
    if(val) {

      this.setState({
        fulltimerobject: val,
        form: {
          ...this.state.form,
          ftname: val.firstname + " " + val.lastname,
          ftid: val.id
        }
      })
    } else {
      this.setState({
        fulltimerobject: null,
        form: {
          ...this.state.form,
          ftname: "",
          ftid: ""
        }
      })
    }
  }

  // inputChangeCategory(val, i) {
  //   let catlist = [];
  //   this.state.form.targetlist.forEach((item)=>{
  //     if(item.catid != ''){
  //       catlist.push(item.catid);
  //     }
  //   })
  //   this.setState({
  //     catpage: {
  //       ...this.state.catpage,
  //       keyword: val
  //     }
  //   }, () => {
  //     this.getCategoryList();
  //   })
  // }

  getCategoryList() {
    let outer = this;
    axios.post('bo/int/master/category/list', this.state.catpage).then(({data})=>{
      if(data.sta && data.stacod === 200){
        outer.setState({
          categories:data.data.list
        }, () => {
          let targetlist = [];
          outer.state.categories.forEach((item, i) => {
            targetlist.push({
              id: "",
              catid: item.id,
              catcode: item.code,
              catname: item.name,
              subcatid: "",
              subcatcode: "",
              subcatname: "",
              target: 0
              
            })
            if(item.code == "vital") {
              outer.getSubCategory(item)
            }
          });
          outer.setState({
            form: {
              ...outer.state.form,
              targetlist: targetlist
            }
          })
        });
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  getSubCategory(item) {
    let sublist = {
      categoryid: item.id,
      count: 10
    };
    let outer = this;
    axios.post('bo/int/master/subcategory/list', sublist).then(({data})=>{
      if(data.sta && data.stacod === 200){
        let targetlist = this.state.form.targetlist;
        data.data.list.forEach((sub, i) => {
          targetlist.push({
              id: "",
              catid: item.id,
              catcode: item.code,
              catname: item.name,
              subcatid: sub.id,
              subcatcode: sub.code,
              subcatname: sub.name,
              target: 0
          })
        })
        this.setState({
          form: {
            ...this.state.form,
            targetlist: targetlist
          }
        })
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  // addCat() {
  //   let catField = {
  //     id: "",
  //     catid: "",
  //     catcode: "",
  //     catname: "",
  //     subcatid: "",
  //     subcatcode: "",
  //     subcatname: "",
  //     target: 0,
  //     catobject: null,
  //     subcatobject: null,
  //     subcatpage: {
  //       categoryid: "",
  //       keyword: "",
  //       count: 10
  //     },
  //     subcategories:[],
  //     errorcatid: ""
  //   };

  //   let targetlist = [...this.state.form.targetlist, catField];
  //   this.setState({
  //     form : {
  //       ...this.state.form,
  //       targetlist: targetlist
  //     }
  //   });
  // }

  // changeCategory(val, i) {
  //   if(val) {

  //     let targetlist = [...this.state.form.targetlist];
  //     targetlist[i].catobject = val;
  //     targetlist[i].catid = val.id;
  //     targetlist[i].subcatpage.categoryid = val.id;
  //     targetlist[i].catname = val.name;
  //     targetlist[i].catcode = val.code;
  //     this.setState({
  //       form: {
  //         ...this.state.form,
  //         targetlist: targetlist
  //       }
  //     }, () => {
  //       this.getSubCategory(i);
  //     })
  //   }
  // }

  // inputChangeSubCategory(val, i) {
  //   let subcatlist = [];
  //   this.state.form.targetlist.forEach((item)=>{
  //     if(item.subcatid != ''){
  //       subcatlist.push(item.subcatid);
  //     }
  //   });
  //   let targetlist = [...this.state.form.targetlist];
  //   targetlist[i].subcatpage.keyword = val;
  //   this.setState({
  //     form: {
  //       ...this.state.form,
  //       targetlist: targetlist
  //     }
  //   }, () => {
  //     this.getSubCategory(i);
  //   })
  // }

  // getSubCategory(i) {
  //   let outer = this;
  //   axios.post('bo/int/master/subcategory/list', this.state.form.targetlist[i].subcatpage).then(({data})=>{
  //     if(data.sta && data.stacod === 200){
  //       let targetlist = [...this.state.form.targetlist];
  //       targetlist[i].subcategories = data.data.list;
  //       this.setState({
  //         form: {
  //           ...this.state.form,
  //           targetlist: targetlist
  //         }
  //       })
  //     }
  //   }).catch(function (error) {
  //       outer.showDialog('Warning',Errormsg["500"],"");
  //   });
  // }

  // changeSubCategory(val, i) {
  //   if(val) {

  //     let targetlist = [...this.state.form.targetlist];
  //     targetlist[i].subcatobject = val;
  //     targetlist[i].subcatid = val.id;
  //     targetlist[i].subcatname = val.name;
  //     targetlist[i].subcatcode = val.code;
  //     this.setState({
  //       form: {
  //         ...this.state.form,
  //         targetlist: targetlist
  //       }
  //     })
  //   }
  // }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {
      if(this.state.dialogTitle != "Information") {
        this.setState({dialogOpen:false});
      } else {
        this.setState({
          dialogOpen: false
        }, () => {
          // this.props.history.push("/bo/setting/targetperiode/list");
          this.backToList();
          this.props.deselectParam();
        })
      }
    }
  }
  backToList=()=>{
    this.props.history.push({ pathname: "/bo/setting/targetperiode/list", filter:this.state.filtertable, filterAll:this.state.filterAll });
  }
  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  handleValidation = (e) => {
    let {form,error,mode,targobj} = this.state;

    if(form.ftid.trim().length === 0){
      error.fulltimerid=<IntlMessages id="setting.targetperiode.error.name"/>;
    }else{
      error.fulltimerid="";
    }

    // if(form.targetlist.length === 0){
    //   error.targetlist=<IntlMessages id="setting.targetperiode.error.targetlist"/>;
    // }else{
    //   error.targetlist="";
    // }
    
    // let findCatEmpty = form.targetlist.filter(x => x.catid == "");
    // // console.log("findcatempty", findCatEmpty);
    // let iEmp = form.targetlist.findIndex(x => x.catid == "");
    // // console.log("iemp", iEmp);
    // if(findCatEmpty.length > 0) {
    //   form.targetlist[iEmp].errorcatid=<IntlMessages id="setting.targetperiode.error.targetlist.catid"/>;
    // } 
    let targetlist = []
    Object.keys(targobj).forEach((key)=>{
      targetlist.push(targobj[key]);
    })

    if(!this.isValidForm()){
      form.accept=false;
      error.accept="";
    }

    this.setState({
      form:{...form, targetlist},
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
      
      this.handleSubmit();
      
    });

  }

  handleSubmit = () => {
    let {form} = this.state;
    // console.log("form handle submit", form);
    const outer = this;
    this.setState({
      disableBtnSubmit:true
    }, () => {
      if(this.state.mode == "add") {
        
        axios.post('bo/int/fulltimer/ptarget/add', form
        ).then(({data}) => {
          if (data.sta && data.stacod === 200) {
            outer.emptyFied();
            outer.setState({
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="setting.targetperiode.success.addtargetperiode"/>);
            });
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      } else {
        axios.post('bo/int/fulltimer/ptarget/edit', form
        ).then(({data}) => {
          if (data.sta && data.stacod === 200) {
            outer.emptyFied();
            outer.setState({
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="setting.targetperiode.success.edittargetperiode"/>);
            })
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      }
    });

  }

  emptyFied() {
    this.setState({
      fulltimerobject: null,
      form: {
        ...this.state.form,
        id: "",
        ftid:"",
        ftname:"",
        targetlist:[]
      },
      error:{
        ...this.state.error,
        id: "",
        fulltimerid:""
      },
    }, () => {
      this.getCategoryList();
    })
  }

  isValidForm = () => {
    return (
    !this.state.error.fulltimerid )
  }

 

  handleChangeTarget(val, i, cat) {
    let targetlist = [...this.state.form.targetlist];
    let regex = /^[0-9]+$/;
      if(val != "") {
        targetlist[i].target = parseInt(val);
        if(regex.test(val)) {
          this.setState({
            form: {
              ...this.state.form,
              targetlist: targetlist
            }
          }, () => {
            let filtering = this.state.form.targetlist.filter(x => x.subcatcode != "" && x.catcode == "vital");
            let totaltarget = filtering.reduce(function(cnt, o) {
              return cnt + o.target;
            }, 0);
            let findIn = this.state.form.targetlist.findIndex(x => x.subcatcode == "" && x.catcode == "vital");
            let vitaltarget = [...this.state.form.targetlist];
            vitaltarget[findIn].target = totaltarget;
            this.setState({
              form: {
                ...this.state.form,
                targetlist: vitaltarget
              }
            })
          })
        }
      } else {
        targetlist[i].target = val;
        this.setState({
          form: {
            ...this.state.form,
            targetlist: targetlist
          }
        })
      }
  }

  handleFocus(val, i) {
    let targetlist = [...this.state.form.targetlist];
    if(val == "") {
      targetlist[i].target = 0;
      this.setState({
        form: {
          ...this.state.form,
          targetlist: targetlist
        }
      }, () => {
        let filtering = this.state.form.targetlist.filter(x => x.subcatcode != "" && x.catcode == "vital");
        let totaltarget = filtering.reduce(function(cnt, o) {
          return cnt + o.target;
        }, 0);
        let findIn = this.state.form.targetlist.findIndex(x => x.subcatcode == "" && x.catcode == "vital");
        let vitaltarget = [...this.state.form.targetlist];
        vitaltarget[findIn].target = totaltarget;
        this.setState({
          form: {
            ...this.state.form,
            targetlist: vitaltarget
          }
        })
      })
    } else {
      targetlist[i].target = parseInt(val);
      this.setState({
        form: {
          ...this.state.form,
          targetlist: targetlist
        }
      }, () => {
        let filtering = this.state.form.targetlist.filter(x => x.subcatcode != "" && x.catcode == "vital");
        let totaltarget = filtering.reduce(function(cnt, o) {
          return cnt + o.target;
        }, 0);
        let findIn = this.state.form.targetlist.findIndex(x => x.subcatcode == "" && x.catcode == "vital");
        let vitaltarget = [...this.state.form.targetlist];
        vitaltarget[findIn].target = totaltarget;
        this.setState({
          form: {
            ...this.state.form,
            targetlist: vitaltarget
          }
        })
      })
    }
  }

  handleChange = (e) => {
    let outer=this;
    let {targobj,error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    targobj[inputName].target = +inputValue;
    this.setState({targobj},()=>{
      let subvital = ['vital1','vital2','vital3','vital4','vital5','vital6']; 
      if(subvital.includes(inputName)){
        targobj.vital.target = +targobj.vital1.target + +targobj.vital2.target + +targobj.vital3.target + +targobj.vital4.target + +targobj.vital5.target + +targobj.vital6.target;
        outer.setState({targobj});
      }
    });

  }

  render(){
    const { classes, theme } = this.props;
    const { error, fulltimers, form, categories, targobj } = this.state;
    const catForm = Object.keys(targobj).map((key, i) => {
      return (

      <Grid item xs={12}>
        <TextField
          id={targobj[key].subcatcode != '' ? targobj[key].subcatcode : targobj[key].catcode}
          name={targobj[key].subcatcode != '' ? targobj[key].subcatcode : targobj[key].catcode}
          label={targobj[key].subcatcode != '' ? targobj[key].subcatname : targobj[key].catname}
          value={targobj[key].target}
          size="small"
          variant="outlined"
          fullWidth
          disabled={targobj[key].catcode == 'vital' && targobj[key].subcatcode == ''}
          onChange={this.handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>  
      // <Card
      //   key={i}
      //   style={{marginBottom: 10}}
      //   raised
      // >
      //   <CardHeader
      //     title={cat.catname}
      //     subheader={cat.subcatname != "" && cat.subcatname}
      //   />
      //   <CardContent>
      //       <div className={classes.paper}>
      //           <Grid container spacing={2}>
      //               {/* <Grid item xs={12}>
      //                   <Autocomplete
      //                     options={categories}
      //                     getOptionLabel={option => option.name || ''}
      //                     value={cat.catobject || ''}
      //                     onChange={(event, val) => this.changeCategory(val, i)}
      //                     onInputChange={(event, val) => this.inputChangeCategory(val, i)}
      //                     onFocus={(event, val) => this.inputChangeCategory('', i)}
      //                     renderInput={
      //                       param => (
      //                         <TextField 
      //                           {...param}
      //                           id="category"
      //                           label="Kategori" 
      //                           fullWidth
      //                           required
      //                           InputLabelProps={{
      //                             shrink: true,
      //                           }}
      //                           error={cat.errorcatid?true:false}
      //                         />
      //                       )
      //                     }
      //                   />
      //                   {
      //                     cat.errorcatid &&   
      //                       <FormControlLabel
      //                         classes={{label:classes.labelerror}}
      //                         control={<div>&nbsp;&nbsp;&nbsp;</div>}
      //                         label={cat.errorcatid}
      //                     />
      //                   }
      //               </Grid> */}
      //               {/* {
      //                 cat.subcategories.length > 0 &&
      //                 <Grid item xs={12}>
      //                     <Autocomplete
      //                       options={cat.subcategories}
      //                       getOptionLabel={option => option.name || ''}
      //                       value={cat.subcatobject || ''}
      //                       onChange={(event, val) => this.changeSubCategory(val, i)}
      //                       onInputChange={(event, val) => this.inputChangeSubCategory(val, i)}
      //                       onFocus={(event, val) => this.inputChangeSubCategory('', i)}
      //                       renderInput={
      //                         param => (
      //                           <TextField 
      //                             {...param}
      //                             id="subcategory"
      //                             label="Sub Kategori" 
      //                             fullWidth
      //                             InputLabelProps={{
      //                               shrink: true,
      //                             }}
      //                           />
      //                         )
      //                       }
      //                     />
      //                 </Grid>
      //               } */}
      //               <Grid item xs={12}>
      //                 <TextField
      //                   id="target"
      //                   name="target"
      //                   label={cat.catcode == "vital" && cat.subcatcode == "" ? "Target Total" : "Target"}
      //                   value={cat.target}
      //                   disabled={cat.catcode == "vital" && cat.subcatcode == ""}
      //                   size="small"
      //                   fullWidth
      //                   onBlur={(val) => this.handleFocus(val.target.value, i)}
      //                   onChange={(val) => {
      //                     this.handleChangeTarget(val.target.value, i, cat)
      //                   }}
      //                   InputLabelProps={{
      //                     shrink: true,
      //                   }}
      //                 />
      //               </Grid>
      //           </Grid>
      //       </div>
      //   </CardContent>
      // </Card>
      )
    })
    return (
      <div style={{marginTop: theme.spacing(4)}}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href='/bo/dashboard'>
              Pengaturan
            </Link>
            <Link color="inherit" 
            // href="/bo/setting/targetperiode/list"
            href='#'
            onClick={()=>{this.backToList()}}
            >
              Daftar Target Periode
            </Link>
            <Link color="textPrimary" href="/bo/setting/targetperiode/edit">
              {
                this.state.mode == "add" ?
                "Tambah Target Periode" : "Ubah Target Periode"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
              <form className={classes.form} noValidate>

                  
                  <Grid container spacing={2}>
                                     
                    <Grid item xs={12}>
                      <Autocomplete
                        options={fulltimers}
                        getOptionLabel={option => option && option.firstname }
                        value={this.state.fulltimerobject}
                        disabled={this.state.mode == "edit"}
                        onChange={this.changeFulltimer}
                        onFocus={(e)=>{this.inputChangeFulltimer('')}}
                        onInputChange={(event, val) => this.inputChangeFulltimer(val)}
                        renderInput={
                          param => (
                            <TextField 
                              {...param} 
                              label="Fulltimer"
                              fullWidth
                              required
                              variant="outlined"
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={error.fulltimerid?true:false}
                            />
                          )
                        }
                      />
                      {
                          error.fulltimerid &&   
                            <FormControlLabel
                              classes={{label:classes.labelerror}}
                              control={<div>&nbsp;&nbsp;&nbsp;</div>}
                              label={error.fulltimerid}
                            />
                      }
                    </Grid>
                    
                    {/* <Grid item xs={12}>
                      <Button
                        type="button"
                        className={classes.submit}
                        color="primary"
                        variant="outlined"
                        onClick={() => this.addCat()}
                        fullWidth
                      >
                        TAMBAH KATEGORI
                      </Button>
                    </Grid> */}
                    <br/>
                      {catForm}
                    <br/>
                    {/* <Grid item xs={12}>
                      <TextField
                        id="target"
                        name="target"
                        label={this.state.targobj.baptis.catname}
                        value={this.state.targobj.baptis}
                        size="small"
                        fullWidth
                        disabled
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>  
                    <br/> */}
      
                  </Grid>
                  <Button
                    type="button"
                    className={classes.submit}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={this.handleValidation}
                    disabled={this.state.disableBtnSubmit}
                  >
                    UPDATE
                  </Button>
                </form>
          </div>
        </Container>

        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );
  }
}

DbSettingTargetPeriodeEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbSettingTargetPeriodeEdit)));