import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
  IconButton, TextField, Checkbox, Card, FormControl,
  CardMedia, CardContent, Typography, Select, InputLabel, MenuItem
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DataTable from 'react-data-table-component';
import moment from 'moment';
//import actions
import { addParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete: {
    color: '#204196',
    fontWeight: 'bold'
  },
  incomplete: {
    color: '#ff1744',
    fontWeight: 'bold'
  }
});

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions: [10, 25, 100],
  print: false,
  download: false,
  selectableRows: 'single',
  isRowSelectable: function () { return false }
};

class DbReportTask extends React.Component {
  constructor(props) {
    super(props);
    let dat = new Date();
    dat.setDate(dat.getDate() - 7);
    this.state = {
      setdate: "",
      page: {
        start: moment().subtract(7, "day").format('YYYY-MM-DD'),
        startPar: dat,
        end: moment().format('YYYY-MM-DD'),
        endPar: new Date()
      },
      taskList: [],
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      loadingTrigger: false,
      dialogMode: '',
      detailOpen: false,
    }
  }

  columns = [

    {
      name: <IntlMessages id="report.task.username" />,
      selector: 'username',
      sortable: true,
    },
    {
      name: <IntlMessages id="report.task.usercity" />,
      selector: 'usercity',
      sortable: true,
    }
  ];
  columnsExpand = [

    {
      name: <IntlMessages id="report.task.date" />,
      selector: 'date'
    },
    {
      name: "Total",
      selector: 'total'
    },
    {
      name: "Group",
      selector: 'group'
    }
  ];

  componentDidMount() {
    this.getTaskReport();
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen: false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      this.setState({ dialogOpen: false });
    }
  }


  getTaskReport = () => {
    let outer = this;

    this.setState({
      loadingTrigger: true
    });

    axios.post('bo/int/report/task', outer.state.page).then(({ data }) => {

      this.setState({
        loadingTrigger: false
      });
      if (data.sta && data.stacod === 200) {
        this.setState({
          taskList: data.data
        })
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }



  changeStart = (date) => {
    let momentParse = moment(date).format("YYYY-MM-DD");
    this.setState({
      page: {
        ...this.state.page,
        startPar: date,
        start: momentParse,
      }
    }, () => {
      this.getTaskReport();
    })
  }

  changeEnd = (date) => {
    let momentParse = moment(date).format("YYYY-MM-DD");
    this.setState({
      page: {
        ...this.state.page,
        endPar: date,
        end: momentParse,
      }
    }, () => {
      this.getTaskReport();
    })
  }

  handleChangeSet = (val) => {
    let outer = this;
    this.setState({
      setdate: val.target.value
    }, () => {
      let dat = new Date();
      // dat.setDate(dat.getDate() - 7);
      if (outer.state.setdate == "1minggu") {
        dat.setDate(dat.getDate() - 7);
        outer.setState({
          page: {
            start: moment().subtract(7, "day").format('YYYY-MM-DD'),
            startPar: dat,
            end: moment().format('YYYY-MM-DD'),
            endPar: new Date()
          }
        }, () => {
          outer.getTaskReport();
        });
      }
      if (outer.state.setdate == "2minggu") {
        dat.setDate(dat.getDate() - 14);
        outer.setState({
          page: {
            start: moment().subtract(14, "day").format('YYYY-MM-DD'),
            startPar: dat,
            end: moment().format('YYYY-MM-DD'),
            endPar: new Date()
          }
        }, () => {
          outer.getTaskReport();
        });
      }
      if (outer.state.setdate == "bulan") {
        dat.setDate(dat.getDate() - 30);
        outer.setState({
          page: {
            start: moment().subtract(30, "day").format('YYYY-MM-DD'),
            startPar: dat,
            end: moment().format('YYYY-MM-DD'),
            endPar: new Date()
          }
        }, () => {
          outer.getTaskReport();
        });
      }
    })
  }


  render() {
    const { theme } = this.props;
    const { taskList } = this.state;
    const ExpandableReport = (taskListDetil) => {
      return (
        <DataTable
          title={"Total Tugas " + taskListDetil.data.username}
          columns={this.columnsExpand}
          data={taskListDetil.data.task}
          highlightOnHover
          customStyles={customStyles}
          pagination={false}
        />
      )
    };

    return (
      <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
        <div style={{ paddingTop: 10 }}>
          <Typography variant="h5" component="h5">
            Laporan Tugas
          </Typography>
        </div>
        <div className="filter">
          <div>
            <FormControl
              style={{
                minWidth: '100%',
                marginBottom: 10
              }}
            >

              <InputLabel id="set" style={{ backgroundColor: "#fff", paddingLeft: 4, paddingRight: 4 }} shrink variant="outlined">
                Set Date
              </InputLabel>
              <Select
                value={this.state.setdate}
                onChange={this.handleChangeSet}
                variant="outlined"
                label="Set Date"
                labelId="set"
                style={{ width: '100%' }}
              >
                <MenuItem value="1minggu">1 Minggu</MenuItem>
                <MenuItem value="2minggu">2 Minggu</MenuItem>
                <MenuItem value="bulan">1 Bulan</MenuItem>

              </Select>
            </FormControl>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>


            <KeyboardDatePicker
              clearable
              value={this.state.page.startPar}
              // placeholder="Start"
              label="Mulai"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={date => this.changeStart(date)}
              format="DD-MM-YYYY"
              style={{ paddingRight: 5 }}
            />
            <KeyboardDatePicker
              clearable
              value={this.state.page.endPar}
              // placeholder="End"
              label="Akhir"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={date => this.changeEnd(date)}
              format="DD-MM-YYYY"
            />
          </div>
        </div>
        <DataTable
          title="Laporan Tugas"
          columns={this.columns}
          data={taskList}
          noDataComponent={
            <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
              <CardMedia style={{ textAlign: 'center' }}>
                <MoodBad color="action" style={{
                  fontSize: 40,
                  marginTop: 10,
                  marginBottom: 10
                }} />
              </CardMedia>
              <CardContent>
                <Typography>
                  Data tidak tersedia
                </Typography>
              </CardContent>
            </Card>

          }
          highlightOnHover
          // subHeader
          noHeader
          expandableRows
          expandableRowsComponent={
            <ExpandableReport />
          }
          customStyles={customStyles}
          pagination={false}
          progressPending={this.state.loadingTrigger}
          progressComponent={
            <Loader type="TailSpin" color="#204196" height={100} width={100} />
          }
          subHeaderComponent={
            (
              <div>
                <div>
                  <FormControl
                    style={{
                      minWidth: '100%',
                      marginBottom: 10
                    }}
                  >

                    <InputLabel id="set" style={{ backgroundColor: "#fff", paddingLeft: 4, paddingRight: 4 }} shrink variant="outlined">
                      Set Date
                    </InputLabel>
                    <Select
                      value={this.state.setdate}
                      onChange={this.handleChangeSet}
                      variant="outlined"
                      label="Set Date"
                      labelId="set"
                      style={{ width: '100%' }}
                    >
                      <MenuItem value="1minggu">1 Minggu</MenuItem>
                      <MenuItem value="2minggu">2 Minggu</MenuItem>
                      <MenuItem value="bulan">1 Bulan</MenuItem>

                    </Select>
                  </FormControl>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>


                  <KeyboardDatePicker
                    clearable
                    value={this.state.page.startPar}
                    // placeholder="Start"
                    label="Mulai"
                    inputVariant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={date => this.changeStart(date)}
                    format="DD-MM-YYYY"
                    style={{ paddingRight: 5 }}
                  />
                  <KeyboardDatePicker
                    clearable
                    value={this.state.page.endPar}
                    // placeholder="End"
                    label="Akhir"
                    inputVariant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={date => this.changeEnd(date)}
                    format="DD-MM-YYYY"
                  />
                </div>
              </div>
            )
          }
        />

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />

      </div>
    );
  }
}

DbReportTask.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbReportTask)));