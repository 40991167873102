import React from 'react';
import {
  Card, CssBaseline, Grid, Paper,
  Typography, Chip, CardContent, CardMedia, Icon
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import axios from '../config/axios';
import DialogBasic from '../component/dialogbasic';
import { MoodBad, Warning, AssignmentLate  } from '@material-ui/icons';
import {
  AccountCircleRounded, TrackChangesRounded
} from '@material-ui/icons'
import MUIDataTable from "mui-datatables";
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../config/errormsg";
import DataTable from 'react-data-table-component';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete: {
    color: '#204196',
    fontWeight: 'bold'
  },
  incomplete: {
    color: '#ff1744',
    fontWeight: 'bold'
  }
});

const columns = [
  {
    name: "Tanggal",
    options: {
      filter: true
    }
  },
  {
    name: "Nama",
    options: {
      filter: true
    }
  },
  {
    name: "Email",
    options: {
      filter: true
    }
  },
  {
    name: "Kota",
    options: {
      filter: true
    }
  },
  {
    name: "UTBK",
    options: {
      filter: true
    }
  }
];

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions: [10, 25, 100],
  print: false,
  download: false,
  selectableRows: 'single',
  isRowSelectable: function () { return false }
};

class DbHomeAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dashData: {
        fttotal: 0,
        morethantarget: 0,
        ttachieved: []
      },
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      visibleloader: false,
      dataTotal: 0,
      datasaint: [],
      showdatasaint: [],
      loadingTrigger: false,
      page: {
        page: 1,
        count: 10,
      },
      fgmsg: "",
      totalPage: 1,
      canPreviousPage: true,
      canNextPage: false,
    };
  }

  componentDidMount() {
    this.getDashboard();
  }

  getDashboard() {
    let outer = this;
    this.setState({
      loadingTrigger: true
    });
    axios.post('bo/int/dashboard', {}).then(({ data }) => {
      this.setState({
        loadingTrigger: false
      });
      if (data.sta && data.stacod === 200) {
        let fgmsg = "";
        if (!data.fgsta) {
          fgmsg = data.fgmsg;
        }
        let datasaint = data.data.saintidcheckdata;
        let totalsaint = data.data.saintidchecktotal;
        let datactr = [];
        if (totalsaint > this.state.page.count) {
          datasaint.map((val, index) => {
            if (index < this.state.page.count) {
              datactr.push(val);
            }
          });
        }
        else {
          datactr = datasaint;
        }
        outer.setState({
          dashData: {
            ...outer.state.dashData,
            fttotal: data.data.fttotal,
            morethantarget: data.data.morethantarget,
            ttachieved: data.data.totaltargetachievedbyweek,
          },
          dataTotal: data.data.saintidchecktotal,
          datasaint: data.data.saintidcheckdata,
          showdatasaint: datactr,
          fgmsg: fgmsg,
          totalPage: Math.ceil(totalsaint / this.state.page.count),
          canNextPage:(data.data.saintidchecktotal<=this.state.page.count),
        })
      } else {
        outer.showDialog("warning", data.msg);
      }
    }).catch(function (error) {
      outer.showDialog("warning", Errormsg["500"], "");
    })
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  getDataSaint = (val) => {
    let datasaint = this.state.datasaint;
    let totalsaint = this.state.dataTotal;
    let totalawal = this.state.page.count * (val - 1);
    let totalakhir = totalawal + this.state.page.count;
    let datactr = [];
    if (totalsaint > this.state.page.count) {
      datasaint.map((val, index) => {
        if (index >= totalawal && index < totalakhir) {
          datactr.push(val);
        }
      });
    }
    else {
      datactr = datasaint;
    }
    this.setState({
      showdatasaint: datactr
    })
  }

  onChangePage(val) {
    let outer = this;
    let checkNext = false;
    let checkPrev = false;
    if (val >= this.state.totalPage) {
      checkNext = true;
    }
    if (val <= 1) {
      checkPrev = true;
    }
    this.setState({
      page: {
        ...this.state.page,
        page: val
      },
      canNextPage: checkNext,
      canPreviousPage: checkPrev,
    }, () => {
      this.getDataSaint(val);
    })
  }

  onChangeRows(val) {
    let outer = this;
    let dis=false;
    if(this.state.dataTotal<=val){
      dis=true;
    }
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        count: val,
      },
      canNextPage:dis,
      canPreviousPage:true,
      totalPage: Math.ceil(this.state.dataTotal / val),
    }, () => {
      this.getDataSaint(1);
    })
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {

      this.setState({ dialogOpen: false });
    }
  }

  showDialog = (title, content) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content
    });
  }

  columns = [
    {
      name: "FG Efata ID",
      selector: 'fgsaintid',
      sortable: true
    },
    {
      name: "FT Efata ID",
      selector: 'ftsaintid',
      sortable: true
    },
  ];

  render() {
    const { classes } = this.props;
    const { rows, total, dashData, fgmsg, page,canPreviousPage, canNextPage,totalPage, showdatasaint, dataTotal, loadingTrigger } = this.state;
    const title = "Dashboard Admin"

    return (
      <div>
        <CssBaseline />
        <div className={classes.paper}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography component="h5" variant="h5">
                Dashboard Administrator
              </Typography>
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Grid container spacing={5}>
                  <Grid item md={6} xs={12}>
                    <Card>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={3}>
                            <AccountCircleRounded
                              style={{
                                fontSize: 100,
                                color: 'green'
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              component="h5"
                              variant="h5"
                              style={{ textAlign: 'right' }}
                            >
                              Total Fulltimer
                            </Typography>
                            <Typography
                              component="h2"
                              variant="h3"
                              style={{ textAlign: 'right' }}
                            >
                              {dashData.fttotal}
                            </Typography>
                          </Grid>
                        </Grid>

                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Card>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={3}>
                            <TrackChangesRounded
                              style={{
                                fontSize: 100,
                                color: 'purple'
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              component="h5"
                              variant="h5"
                              style={{ textAlign: 'right' }}
                            >
                              Melebihi Target
                            </Typography>
                            <Typography
                              component="h2"
                              variant="h3"
                              style={{ textAlign: 'right' }}
                            >
                              {dashData.morethantarget}
                            </Typography>
                          </Grid>
                        </Grid>

                      </CardContent>
                    </Card>
                  </Grid>

                </Grid>
              </div>

              <div style={{ textAlign: 'left' }}>
                <Loader
                  visible={this.state.visibleloader}
                  type="ThreeDots"
                  color="#204196"
                  height={45}
                  width={45}
                />
              </div>
              {
                dashData.ttachieved.length > 0 &&
                <Paper className={classes.root}>
                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: 10,
                    }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                    >
                      Target Tercapai (Mingguan)
                    </Typography>
                  </div>
                  <div style={{ width: '100%', height: 300 }}>

                    <ResponsiveContainer>

                      <LineChart
                        data={dashData.ttachieved}
                        margin={{
                          top: 10,
                          bottom: 10,
                          right: 15
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="week" />
                        <YAxis dataKey="total" />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="total" stroke="#8884d8" activeDot={{ r: 8 }} />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </Paper>
              }
            </Grid>
          </Grid>
        </div>
        {(showdatasaint.length > 0) && <>
          <div style={{ marginTop: 20 }}>
            <Card>
              <CardContent>
              <div style={{ fontSize: 20, textAlign: 'center', marginTop: 15 }}>
                Sinkronisasi Efata ID Efata FG dan Efata FT
              </div>
                {(fgmsg !== "") && <>
                  <div style={{ fontSize: 16, color: 'red', textAlign: 'center', marginTop: 15 }}>
                    <Icon><Warning /></Icon> {fgmsg}
                  </div>
                </>}
                {showdatasaint.map((val) => {
                  let msg = "";
                  if (val.fgsaintid === "") {
                    msg = "Fulltimer dari Efata FT dengan efata id '" + val.ftsaintid + "' tidak ditemukan di Efata FG.";
                  }
                  else {
                    msg = "Fulltimer dari Efata FG dengan efata id '" + val.fgsaintid + "' tidak ditemukan di Efata FT.";
                  }
                  return <>
                    <Card style={{ marginTop: 10, marginBottom: 10, backgroundColor:'#0b56a5', color:'white' }}>
                      <CardContent>
                          {/* <Icon><AssignmentLate style={{fontSize:60, padding:0, margin:0}}/></Icon> {msg} */}
                          <Grid container style={{flexWrap:'wrap'}}>
                          <Grid item xs={1}>
                            <AssignmentLate
                              style={{
                                fontSize: 40,
                                textAlign:'right',
                                color: 'white'
                              }}
                            />
                          </Grid>
                          <Grid item xs={11} style={{margin:'auto'}}>
                            <Typography
                              style={{fontSize:16, }}
                            >
                              {msg}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </>
                })}
                <div className="pagination" style={{ marginTop: 10 }}>
                  <button onClick={() => this.onChangePage(1)} disabled={canPreviousPage}>
                    {'<<'}
                  </button>{' '}
                  <button onClick={() => this.onChangePage(page.page - 1)} disabled={canPreviousPage}>
                    {'<'}
                  </button>{' '}
                  <button onClick={() => this.onChangePage(page.page + 1)} disabled={canNextPage}>
                    {'>'}
                  </button>{' '}
                  <button onClick={() => this.onChangePage(totalPage)} disabled={canNextPage}>
                    {'>>'}
                  </button>{' '}
                  <span>
                    Page{' '}
                    <strong>
                      {page.page} of {totalPage}
                    </strong>{' '}
                  </span>
                  <select
                    value={page.count}
                    onChange={e => {
                      this.onChangeRows(e.target.value);
                    }}
                  >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
                
                {/* <DataTable
                columns={this.columns}
                data={showdatasaint}
                noDataComponent={
                  <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                    <CardMedia style={{ textAlign: 'center' }}>
                      <MoodBad color="action" style={{
                        fontSize: 40,
                        marginTop: 10,
                        marginBottom: 10
                      }} />
                    </CardMedia>
                    <CardContent>
                      <Typography>
                        Data tidak tersedia
                      </Typography>
                    </CardContent>
                  </Card>
                }
                highlightOnHover
                noHeader
                customStyles={customStyles}
                pagination
                paginationServer
                paginationTotalRows={dataTotal}
                paginationPerPage={this.state.page.count}
                onChangePage={(val) => this.onChangePage(val)}
                onChangeRowsPerPage={(val) => this.onChangeRows(val)}
                progressPending={loadingTrigger}
                progressComponent={<Loader type="TailSpin" color="#204196" height={100} width={100} />}
              /> */}
              </CardContent>
            </Card>
          </div>
        </>}
        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
      </div>
    );

    // return (
    //   <DashboardAdmin title={title} content={content} />
    // );
  }
}

DbHomeAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DbHomeAdmin);