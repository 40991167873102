import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../config/axios';
import DialogBasic from '../../../../component/dialogbasic';
import { ValidateEmail } from '../../../../utility/validation';
import Errormsg from "../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { addParam, deselectParam } from "../../../../store/actions/configAction";
import moment from 'moment';
import queryString from 'query-string';

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../util/IntlMessages";

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  delbutton: {
    backgroundColor: 'red',
    color: 'white',
    margin: theme.spacing(3, 0, 2),
  },
  labelusername: {
    color: '#204196',
    fontWeight: 'bold'
  },
  labelerror: {
    color: '#ff1744',
    fontSize: 10,
    paddingTop: '5px'
  },
  konfirmasi: {
    paddingTop: '10px'
  },
  copyright: {
    paddingBottom: '10px'
  }
});

class DbSettingDriveConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {
        date: ""
      },
      code: "",
      connstatus: "",
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      dialogMode: '',
      disableBtnSubmit: false,
      mode: 'add',
    };
  }

  componentDidMount() {
    const UrlQueryStrings = this.props.location.search;
    const queryValues = queryString.parse(UrlQueryStrings);
    // console.log("query string", queryValues);
    let authcode = '';
    if (queryValues && queryValues.code) {
      authcode = queryValues.code;
    }
    const googleconnect = sessionStorage.getItem("googleconnect");
    // console.log("googleconnect", googleconnect, authcode);
    let outer = this;
    if (googleconnect != null) {
      this.setState({
        code: authcode
      }, () => {
        if (authcode != '') {
          outer.handleTokenSubmit();
        }
      });
    }
    this.getDriveStatus();
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {

      switch (this.state.dialogMode) {
        case "delete":
          this.handleTokenDelete();
          break;

        default:
          break;
      }
    }
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode,
    });
  }

  deleteConnection = () => {
    this.showDialog("Hapus Koneksi", "Anda akan menghapus koneksi ke google drive. Apakah anda yakin?", "delete");
  }

  changeFocus = (e) => {
    let { form } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    if (inputValue == "") {
      form[inputName] = 0;
    } else {
      form[inputName] = inputValue;
    }

    this.setState({
      form: form
    })
  }

  getDriveStatus = () => {
    let { date, code } = this.state;
    let parseDate = moment(date).format("YYYY-MM-DD");
    const outer = this;
    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/drive/status', {}
      ).then(({ data, headers }) => {
        outer.setState({ connstatus: data.data });
      }).catch(function (error) {
        outer.showDialog('Warning', Errormsg["500"], "");
      }).finally(() => {
        outer.setState({
          disableBtnSubmit: false
        });
      });
    });

  }

  handleTokenSubmit = () => {
    let { code } = this.state;
    const outer = this;
    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/drive/save', { code }
      ).then(({ data }) => {
        if (data.sta && data.stacod == 200) {
          outer.setState({ connstatus: 'Connected' });
        } else {
          outer.showDialog("Warning", data.msg, "");
        }
        // if(data.msg == 'gtoken'){
        //   sessionStorage.setItem("googleconnect", "drive");
        //   window.location.href = data.data;
        // }
      }).catch(function (error) {
        outer.showDialog('Warning', Errormsg["500"], "");
      }).finally(() => {
        outer.setState({
          disableBtnSubmit: false
        });
      });
    });

  }

  handleTokenDelete = () => {
    let { code } = this.state;
    const outer = this;
    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/drive/delete', {}
      ).then(({ data }) => {
        if (data.sta && data.stacod == 200) {
          outer.setState({ connstatus: 'Not connected' });
        }
        // if(data.msg == 'gtoken'){
        //   sessionStorage.setItem("googleconnect", "drive");
        //   window.location.href = data.data;
        // }
      }).catch(function (error) {
        outer.showDialog('Warning', Errormsg["500"], "");
      }).finally(() => {
        outer.setState({
          disableBtnSubmit: false,
          dialogOpen: false
        });
      });
    });

  }

  handleSubmit = () => {
    const outer = this;
    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/drive', {}
      ).then(({ data }) => {
        if (data.msg == 'gtoken') {
          sessionStorage.setItem("googleconnect", "drive");
          window.location.href = data.data;
        }
      }).catch(function (error) {
        outer.showDialog('Warning', Errormsg["500"], "");
      }).finally(() => {
        outer.setState({
          disableBtnSubmit: false
        });
      });
    });

  }

  isValidForm = () => {
    return (
      !this.state.error.date)
  }

  render() {
    const { classes, theme } = this.props;
    const { error, connstatus } = this.state;
    const title = "Laporan Harian"
    return (
      <div style={{ marginTop: theme.spacing(4) }}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/bo/setting/driveconn">
              Koneksi Google Drive
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>


              <Grid container spacing={2}>
                <Grid item xs={12}>
                  Connection Status: <span style={{ color: (connstatus == 'Connected') ? 'green' : 'red', fontWeight: 'bold' }}>{connstatus}</span>
                </Grid>
              </Grid>
                <Button
                  type="button"
                  className={classes.submit}
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={this.handleSubmit}
                  disabled={this.state.disableBtnSubmit || connstatus == 'Connected'}
                >
                  Connect to Google Drive
                  </Button> 
                {/* <Button
                  type="button"
                  className={classes.delbutton}
                  variant="contained"
                  fullWidth
                  onClick={this.deleteConnection}
                  disabled={this.state.disableBtnSubmit}
                >
                  Delete connection
                  </Button> */}
            </form>
          </div>
        </Container>

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          mode={this.state.dialogMode}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
      </div>
    );
  }
}

DbSettingDriveConnect.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  // // console.log("state",state)
  return {
    param: state.param
  };
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param)),
    onDeselectParam: () => dispatch(deselectParam()),
  };
};

export default connect(
  mapStatestoProps, dispatchToProps
)(withStyles(styles)(withTheme(DbSettingDriveConnect)));