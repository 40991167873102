import React from 'react';
import { 
  Button, CssBaseline, TextField, FormControlLabel, Grid,
  Box, Typography, Container 
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {ValidateEmail} from '../utility/validation';
import Setting from '../config/setting';
import Copyright from '../component/copyright';
// import { loadReCaptcha } from 'react-recaptcha-v3'
import Headerfront from '../component/headerfront'
import DialogBasic from '../component/dialogbasic';
import { connect } from "react-redux";
import axios from '../config/axios';
import { Redirect, Link } from "react-router-dom";
import Errormsg from "../config/errormsg";
import IntlMessages from "../util/IntlMessages";
import { loadReCaptcha } from 'react-recaptcha-v3'
import { ReCaptcha } from 'react-recaptcha-v3'
//import actions
import { addToken } from "../store/actions/configAction";

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

class Login extends React.Component {
  _isMounted = false;
  constructor(props){
      super(props);
      this.state = {
        form: {
          email:"",
          pwd:"",
          t:""
        },
        error:{
          email:"",
          pwd:"",
          global:""
        },
        reloadRecaptcha:new Date(),
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        isSubmitSuccess:false,
        toDashboard: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const outer = this;
    loadReCaptcha(Setting["recaptcha-site-key"]);
    this.intervalId = setInterval(function(){ 
      outer.reloadRecaptcha();
    }, Setting["recaptcha-interval"]);
  }

  componentWillUnmount(){
    this._isMounted = false;
    clearInterval(this.intervalId);
  }

  handleDialogClose = () => {
    const outer = this;
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    const outer = this;
    this.setState({dialogOpen:false});
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  reloadRecaptcha =() => {
    if (this._isMounted) {
      this.setState({
        reloadRecaptcha:new Date()
      });
    }
  }

  handleChange = (e) => {
    let outer=this;
    let form = this.state.form;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;

    this.setState({
      form:form
    }, () => {
      switch(inputName){
        case "email":
          if(inputValue){
            form[inputName]=inputValue.toLowerCase();
            outer.setState({form:form});
          }
        break; 
        default:
        break;
      }
    });
  }

  handleValidation = (e) => {
    let {form,error} = this.state;

    if(form.email.trim().length === 0){
      error.email="Alamat email harus diinput";
    }else{
      error.email="";
    }

    if(form.pwd.trim().length === 0){
      error.pwd="Password harus diinput";
    }else{
      error.pwd="";
    }

    this.setState({
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
  
      let validEmail = ValidateEmail(form.email.trim());
      if(!validEmail){
          error.email="Format alamat email tidak benar";   
      }else{
          error.email="";  
      }
  
      this.setState({
        error:error
      }, () => {
        if(!this.isValidForm()){
          return;
        }

        this.handleSubmit();

      });
  
    });
  }

  handleSubmit = (e) => {
    let {form} = this.state;
    const outer = this;
    this.setState({
      disableBtnSubmit:true
    }, () => {
      // this.props.onAddToken({token:"login token"});
      // outer.setState({toDashboard:true})
      axios.post('bo/int/login', form
      ).then(({data}) => {
        if (data.sta && data.stacod === 200) {
          this.props.onAddToken({token:data.data});
          axios.defaults.headers.common['Authorization'] = "Bearer " + data.data.tkn;
          outer.setState({
            isSubmitSuccess:true,
          }, () => {
            outer.setState({
              toDashboard:true
            });  
          });
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.reloadRecaptcha();
          outer.showDialog('Warning',data.msg);
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.reloadRecaptcha();
        outer.showDialog('Warning',Errormsg["500"]);
      });

    });

  }

  isValidForm = () => {
    return !this.state.error.email &&     
    !this.state.error.pwd &&
    !this.state.error.global 
  }

  verifyCallback = (recaptchaToken) => {
    let {form} = this.state;
    form.t=recaptchaToken;
    this.setState({form:form})
  }

  render(){
    const { classes, token } = this.props;
    const { error,form } = this.state;

    if (token !== null) {
      let path ='/bo/dashboard'
      if(token.token.role == "Administrator" || token.token.role == "Internal") {
        path = '/bo/dashboard';
      } else {
        if(token.token.role == "Penatua") {
          path = '/bo/dashboardpenatua'
        } else {
          path = '/bo/dashboardft'
        }
      }
      // return <Redirect to={path} />
      if(window){
          window.location.replace(path);
      }
    }else{

      const env = process.env.NODE_ENV;
      let LabelDev=<span/>
      if(process.env.NODE_ENV !== "production"){
        LabelDev=<span style={{color:'red'}}>(Development)</span>
      }

      return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Headerfront />
            <Typography align="center" component="h1" variant="h5">
              Login Efata FT {LabelDev}
            </Typography>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="email"
                    name="email"
                    label={<IntlMessages id="emailaddress"/>}
                    value={this.state.form.email} 
                    variant="outlined"
                    required
                    fullWidth
                    onChange={this.handleChange} 
                    error={error.email?true:false}
                  />
                    {
                      error.email &&   
                        <FormControlLabel
                          classes={{label:classes.labelerror}}
                          control={<div>&nbsp;&nbsp;&nbsp;</div>}
                          label={error.email}
                        />
                    }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="pwd"
                    name="pwd"
                    type="password"
                    label="Password"
                    value={this.state.form.pwd}
                    variant="outlined"
                    required
                    fullWidth
                    onChange={this.handleChange}
                    error={error.pwd?true:false}
                  />
                    {
                        error.pwd &&   
                          <FormControlLabel
                            classes={{label:classes.labelerror}}
                            control={<div>&nbsp;&nbsp;&nbsp;</div>}
                            label={error.pwd}
                          />
                    }
                </Grid>

              </Grid>
              <Button
                type="button"
                className={classes.submit}
                color="primary"
                variant="contained"
                fullWidth
                onClick={this.handleValidation}
                disabled={this.state.disableBtnSubmit || this.state.form.t == ''}
              >
                LOGIN
              </Button>
              <Grid container justify="flex-end" className={classes.konfirmasi}>
                <Grid item>
                  <Link to="/forgotpassword" variant="body2">
                    Lupa password ?
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          <Box mt={5} className={classes.copyright}>
            <Copyright />
          </Box>
            <ReCaptcha
                sitekey={Setting["recaptcha-site-key"]}
                action='login'
                verifyCallback={this.verifyCallback}
                key={this.state.reloadRecaptcha} 
            />
            <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />
        </Container>
      );
    }
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    token: state.token
  };
};

const dispatchToProps = dispatch => {
  return {
    onAddToken: token => dispatch(addToken(token))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(Login));