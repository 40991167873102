import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
  IconButton, TextField, Checkbox, Card,
  CardMedia, CardContent, Typography, Breadcrumbs, Link
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';

import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';

import DataTable from 'react-data-table-component';

//import actions
import { addParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete: {
    color: '#204196',
    fontWeight: 'bold'
  },
  incomplete: {
    color: '#ff1744',
    fontWeight: 'bold'
  }
});

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions: [10, 25, 100],
  print: false,
  download: false,
  selectableRows: 'single',
  isRowSelectable: function () { return false }
};

class DbSettingInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        keyword: '',
        page: 1,
        count: 100
      },
      localparam: {
        keyword: '',
        page: 1,
        count: 10
      },
      locallist: [],
      selectedLocal: {},
      inputList: [],
      inputListTotal: 0,
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      dialogMode: '',
      detailOpen: false,
      detailTitle: 'Fulltimer Input Detail',
      detailContent: [],
      loadingTrigger:false,
      selectedRows: [],
      mount:false,
    }
  }

  columns = [
    {
      name: 'Action',
      button: true,
      cell: row => {
        return <div>
          <IconButton color="primary" aria-label="detail" component="span" size="small" style={{ marginRight: 5 }} onClick={() => this.openInputDetail(row)}>
            <List />
          </IconButton>
          <IconButton color="primary" aria-label="edit" component="span" size="small" onClick={() => this.editInput(row)}>
            <Edit />
          </IconButton>
          {/* <List style={{ margin: '5px' }} color="action" /> */}
          {/* <Edit style={{ margin: '5px' }} color="action" /> */}
        </div>
      },
    },
    {
      name: <IntlMessages id="setting.input.name" />,
      selector: 'username',
      sortable: true,
    },
    {
      name: <IntlMessages id="setting.input.email" />,
      selector: 'email',
      sortable: true,
    },
    {
      name: <IntlMessages id="setting.input.input" />,
      selector: 'input',
      sortable: true,
    },
  ];

  componentDidMount() {
    if (this.props.location.filter) {
      this.setState(
        {
          page: this.props.location.filter, 
          selectedLocal:this.props.location.filterAll,
        }, () => {
          this.getInputList();
        }
      )
    } else {
      this.getInputList();
    }
    this.getLocal();
    this.setState({mount:true});
  }

  getLocal() {
    let outer = this;
    axios.post('bo/int/master/local/list', outer.state.localparam).then(({ data }) => {
      if (data.sta && data.stacod === 200) {
        outer.setState({
          locallist: data.data.list
        });
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen: false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({
        dialogOpen: false
      });
      this.props.history.push("/logout");

    } else {

      this.setState({
        dialogOpen: false
      });
      switch (this.state.dialogMode) {
        case "delete":
          this.handleDeleteInput();
          break;

        default:
          break;
      }
    }
  }

  getInputList = () => {
    let outer = this;

    this.setState({
      loadingTrigger: true
    });
    axios.post('bo/int/fulltimer/input/list', outer.state.page).then(({ data }) => {
      this.setState({
        loadingTrigger: false
      });
      if (data.sta && data.stacod === 200) {
        this.setState({
          inputList: data.data.list,
          inputListTotal: data.data.total
        })
      } else {
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
      outer.setState({
        disableBtnSubmit: false
      });
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  openInputDetail = (row) => {
    // // console.log(row)
    let detailContent = [];
    Object.keys(row).forEach(function (key) {
      let name = '';
      let seq = 0;
      let view = true;
      switch (key) {
        case 'username':
          name = <IntlMessages id="setting.input.name" />;
          seq = 1;
          view = true;
          break;
        case 'email':
          name = <IntlMessages id="setting.input.email" />;
          seq = 2;
          view = true;
          break;
        case 'input':
          name = <IntlMessages id="setting.input.input" />;
          seq = 3;
          view = true;
          break;

        default:
          view = false;
          break;
      }
      let content = { id: key, name: name, value: row[key], seq: seq, view: view }
      detailContent.push(content);
      // // console.log(key, row[key]);

    });
    detailContent.sort(compareSequence);
    this.setState({
      detailOpen: true,
      detailContent
    });

  }

  editInput = (row) => {
    this.props.onAddParam({ detail: row });
    this.props.history.push({ pathname: "/bo/setting/input/edit", search: "?i=" + row.id,state:{ filter:this.state.page, filterAll:this.state.selectedLocal}  });
  }

  handleSelectedRows = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    // // console.log('Selected Rows: ', state.selectedRows);
    this.setState({ selectedRows: state.selectedRows });
  };

  deleteInput = () => {
    let inputLength = this.state.selectedRows.length;
    if (inputLength == 0) {
      this.showDialog("Peringatan", "Pilih salah satu item untuk menghapus", "");
    } else {
      this.showDialog("Hapus Input", "Anda akan menghapus " + inputLength + " input. Apakah anda yakin?", "delete");
    }
  }

  handleDeleteInput = () => {

    const outer = this;
    const { selectedRows } = this.state;
    let deleteInput = [];
    selectedRows.forEach((inp) => {
      deleteInput.push(inp.id);
    })

    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/fulltimer/input/delete', { id: deleteInput }
      ).then(({ data }) => {
        if (data.sta && data.stacod === 200) {
          outer.setState({
            disableBtnSubmit: false
          }, () => {
            outer.getInputList();
            outer.showDialog('Information', <IntlMessages id="setting.input.success.deleteinput" />, "");
          })

        } else {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', data.msg, "");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"], "");
      });
    });
  }

  search(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page:1,
        keyword: val.target.value
      }
    }, () => {
      outer.getInputList();
    })
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        count: val
      }
    }, () => {
      outer.getInputList();
    })
  }

  onChangePage(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getInputList();
    })
  }

  addnew() {
    this.props.onAddParam({});
    // this.props.history.push("/bo/setting/input/edit");
    this.props.history.push({ pathname: "/bo/setting/input/edit",state:{ filter:this.state.page, filterAll:this.state.selectedLocal }});
  }

  changeLocal = (event, val) => {
    let outer = this;
    if (val) {
      this.setState({
        selectedLocal: val,
        page: {
          ...this.state.page,
          lokalid: val.id
        }
      }, () => { outer.getInputList() });
    } else {
      this.setState({
        selectedLocal: {},
        page: {
          ...this.state.page,
          lokalid: "",
        }
      }, () => { outer.getInputList() });
    }
    this.inputChangeLocal('');
  }

  inputChangeLocal(val) {
    this.setState({
      localparam: {
        ...this.state.localparam,
        keyword: val
      }
    }, () => {
      this.getLocal();
    })
  }

  render() {
    const { classes, container, theme, content, title } = this.props;
    const { inputList, locallist } = this.state;


    return (
      <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
        <div style={{ paddingTop: 10 }}>
           <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href='/bo/dashboard'>
              Pengaturan
            </Link>
            <Link color="textPrimary"
            // href="/bo/master/user/list" 
            href='/bo/setting/input/list'>
              Daftar Input
            </Link>            
          </Breadcrumbs>
        </div>
        <div className="filter">
          <Autocomplete
            options={locallist}
            getOptionLabel={option => option.name}
            value={this.state.selectedLocal}
            onChange={this.changeLocal}
            onFocus={(e)=>{this.inputChangeLocal('')}}
            onInputChange={(event, val) => this.inputChangeLocal(val)}
            renderInput={
              param => (
                <TextField
                  {...param}
                  id="outlined-basic"
                  // variant="outlined" 
                  label="Lokal"
                  size="small"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )
            }
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              value={this.state.page.keyword}
              size="small"
              style={{ margin: '5px' }}
              onChange={(val) => this.search(val)}
            />
            <IconButton
              color="primary"
              aria-label="detail"
              component="span"
              onClick={() => this.addnew()}
            >
              <Add />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="detail"
              component="span"
              onClick={() => { this.deleteInput() }}
            >
              <Remove />
            </IconButton>
          </div>
        </div>
        {(this.state.mount)&&
        <DataTable
          title="Fulltimer Input Setting"
          columns={this.columns}
          data={inputList}
          noDataComponent={
            <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid', marginTop: 10 }}>
              <CardMedia style={{ textAlign: 'center' }}>
                <MoodBad color="action" style={{
                  fontSize: 40,
                  marginTop: 10,
                  marginBottom: 10
                }} />
              </CardMedia>
              <CardContent>
                <Typography>
                  Data tidak tersedia
                </Typography>
              </CardContent>
            </Card>

          }
          selectableRows
          selectableRowsComponent={Checkbox}
          highlightOnHover
          // subHeader
          noHeader
          customStyles={customStyles}
          onSelectedRowsChange={this.handleSelectedRows}
          pagination
          paginationRowsPerPageOptions={[100,250,500]}
          paginationServer
          paginationDefaultPage={this.state.page.page}
          paginationTotalRows={this.state.inputListTotal}
          paginationPerPage={this.state.page.count}
          onChangePage={(val) => this.onChangePage(val)}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          progressPending={this.state.loadingTrigger}
          progressComponent={
            <Loader type="TailSpin" color="#204196" height={100} width={100} />
          }
          subHeaderComponent={
            (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                  size="small"
                  style={{ margin: '5px' }}
                  onChange={(val) => this.search(val)}
                />
                <IconButton
                  color="primary"
                  aria-label="detail"
                  component="span"
                  onClick={() => this.addnew()}
                >
                  <Add />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="detail"
                  component="span"
                  onClick={() => { this.deleteInput() }}
                >
                  <Remove />
                </IconButton>
              </div>
            )
          }
        />
        }
        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          mode={this.state.dialogMode}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
        <DialogDetail
          open={this.state.detailOpen}
          title={this.state.detailTitle}
          content={this.state.detailContent}
          handleClose={this.handleDetailClose}
          handleOk={this.handleDetailClose}
        />
      </div>
    );
  }
}

DbSettingInput.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbSettingInput)));