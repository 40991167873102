import axios from 'axios';
import urlConfig from './backend';

// export default axios.create({
//   baseURL: urlConfig.urlBackend,
//   headers: {
//     'Content-Type': 'application/json',
//   }
// });

const instance = axios.create({
  baseURL: urlConfig.urlBackend,
  headers: {
    'Content-Type': 'application/json',
  }
});

instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  // // console.log("intercep respons ", response.data);
  response.data.forcelogout = false;
  if (response.data) {
    if (!response.data.sta && response.data.stacod == 200) {
      if (response.data.msg == 'expired token'){
        response.data.msg = 'Sesi anda telah berakhir, silahkan login kembali.';
        response.data.forcelogout = true;
        return response;
      }else{
        return response;
      }
    }else{
      return response;
    }
  }else{
    return response;
  }
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
})

export default instance;