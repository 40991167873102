import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import {ValidateEmail} from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";
import { TagFaces } from '@material-ui/icons';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function isEmpty(obj) {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

class DbFulltimerTargetEdit extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        form: {
          id:"",
          target: 0,
          korporat: 0,
          lewihall: 0,
          lewiks: 0,
          transport: 0,
          kolam: 0,
          pelayanan: 0,
          lainnya: 0,
          kokyn: 15,
          timeremain: 0,
          conversion: 0,
          injil: 0,
          domba: 0,
          baptis: 0
          
        },
        error:{
          target: "",
          korporat: "",
          lewihall: "",
          lewiks: "",
          transport: "",
          kolam: "",
          pelayanan: "",
          lainnya: "",
          kokyn: "",
          timeremain: "",
          conversion: "",
          injil: "",
          domba: "",
          baptis: ""
        },
        multiplier:1.5,
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
    };

  }

  componentDidMount() {
    this.getMultiplier();
  }

  getMultiplier() {
    let outer = this;
    axios.post('bo/ft/deftargetconv', {}).then(({data})=>{
      // console.log("MTPL",data.data)
      if(data.sta && data.stacod === 200){
        outer.setState({
          multiplier: data.data.multiplier
        },()=>{
          outer.getTarget(); 
        })
      } else {
        outer.showDialog("Warning", data.msg);
        outer.getTarget(); 
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
        outer.getTarget(); 
    });
  }

  getTarget() {
    let outer = this;
    axios.post('bo/ft/user/target', {}).then(({data})=>{
      if(data.sta && data.stacod === 200){
        // console.log("data target", data.data)
        let targ = data.data;
        let total = parseFloat(targ.korporat) + parseFloat(targ.lewihall) + parseFloat(targ.lewiks) +
        parseFloat(targ.transport) + parseFloat(targ.lainnya) +
        parseFloat(targ.kokyn);
        let target = parseFloat(targ.target);
        let conv = parseInt((target - total) * this.state.multiplier);
        outer.setState({
          form: {
            id: targ.id,
            target: targ.target,
            korporat: targ.korporat,
            lewihall: targ.lewihall,
            lewiks: targ.lewiks,
            transport: targ.transport,
            // kolam: targ.kolam,
            // pelayanan: targ.pelayanan,
            lainnya: targ.lainnya,
            kokyn: targ.kokyn,
            timeremain: target - total < 0 ? 0 : parseFloat(target - total).toFixed(1),
            conversion: (Math.trunc(conv)) < 0 ? 0 : (Math.trunc(conv)),
            injil: targ.injil,
            domba: targ.domba
            // baptis: targ.baptis
          }
        })
      } else {
        outer.showDialog("Warning", data.msg);
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  
  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {
      this.setState({dialogOpen:false});
    }
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  changeFocus = (e) => {
    let outer = this;
    let {form, error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    if(inputName == "korporat" ||
      inputName == "lewihall" ||
      inputName == "lewiks" ||
      inputName == "transport" ||
      inputName == "lainnya" ||
      inputName == "injil" ||
      inputName == "kokyn"
    ) {
      if(inputValue == "") {
        form[inputName] = 0;
        let total = parseFloat(form["korporat"]) + parseFloat(form["lewihall"]) + parseFloat(form["lewiks"]) +
        parseFloat(form["transport"]) + parseFloat(form["lainnya"]) +
        parseFloat(form["kokyn"]);
        let target = parseFloat(form["target"]);
        form["timeremain"] = (target - total) < 0 ? 0 : parseFloat(target - total).toFixed(1);
        let conv = parseInt((target - total) * this.state.multiplier );
        form["conversion"] = (Math.trunc(conv)) < 0 ? 0 : (Math.trunc(conv)) ;
        if(form["injil"] == ''){
          form["domba"] = Math.trunc(conv);  
        }else{
          let biblecount = Math.trunc(conv) - parseInt(form["injil"]);
          form["domba"] = biblecount;  
        }
      } else {
        form[inputName] = inputValue;
        let total = parseFloat(form["korporat"]) + parseFloat(form["lewihall"]) + parseFloat(form["lewiks"]) +
        parseFloat(form["transport"]) + parseFloat(form["lainnya"]) +
        parseFloat(form["kokyn"]);
        let target = parseFloat(form["target"]);
        form["timeremain"] = (target - total) < 0 ? 0 : parseFloat(target - total).toFixed(1);
        let conv = parseInt((target - total) * this.state.multiplier);
        form["conversion"] = (Math.trunc(conv)) < 0 ? 0 : (Math.trunc(conv)) ;
        if(form["injil"] == ''){
          form["domba"] = Math.trunc(conv);  
        }else{
          let biblecount = Math.trunc(conv) - parseInt(form["injil"]);
          form["domba"] = biblecount;  
        }
      }
    }

    this.setState({
      form: form
    })
  }

  handleChange = (e) => {
    let outer=this;
    let {form,error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    let total = parseFloat(form["korporat"]) + parseFloat(form["lewihall"]) + parseFloat(form["lewiks"]) +
    parseFloat(form["transport"]) + parseFloat(form["lainnya"]) +
    parseFloat(form["kokyn"]);
    let target = parseFloat(form["target"]);
    if(!isNaN(total)) {
      form["timeremain"] = (target - total) < 0 ? 0 : parseFloat(target - total).toFixed(1);
      let conv = parseInt((target - total) * this.state.multiplier);
      form["conversion"] = (Math.trunc(conv)) < 0 ? 0 : (Math.trunc(conv)) ;
      if(form["injil"] == ''){
        form["domba"] = Math.trunc(conv);  
      }else{
        let biblecount = Math.trunc(conv) - parseInt(form["injil"]);
        form["domba"] = biblecount;  
      }
    } else {
      form["timeremain"] = 0;
      form["conversion"] = 0;
      form["domba"] = 0;
    }
    if(inputName === "accept"){
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if(newAccept === true){
        error[inputName]=""
      }
    }

    this.setState({
      form:form,
      error:error
    }, () => {
      switch(inputName){
        case "korporat":
          if(inputValue){
            let regex = /^[0-9.]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break;  
        case "lewihall":
          if(inputValue){
            let regex = /^[0-9.]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break;  
        case "lewiks":
          if(inputValue){
            let regex = /^[0-9.]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break;
        case "transport":
          if(inputValue){
            let regex = /^[0-9.]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break;
        case "kolam":
          if(inputValue){
            let regex = /^[0-9.]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break;
        case "pelayanan":
          if(inputValue){
            let regex = /^[0-9.]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break;
        case "lainnya":
          if(inputValue){
            let regex = /^[0-9.]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break;  
        case "injil":
          if(inputValue){
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break;  
        case "baptis":
          if(inputValue){
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break;  
        default:
        break;
      }
    });
  }

  handleValidation = (e) => {
    let {form,error,mode} = this.state;

    

    if(!this.isValidForm()){
      form.accept=false;
      error.accept="";
    }

    this.setState({
      form:form,
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
      
      this.handleSubmit();
      
    });

  }

  handleSubmit = () => {
    let {form} = this.state;
    form.korporat = form.korporat != 0 ? parseFloat(form.korporat) : 0;
    form.lewihall = form.lewihall != 0 ? parseFloat(form.lewihall) : 0;
    form.lewiks = form.lewiks != 0 ? parseFloat(form.lewiks) : 0;
    form.transport = form.transport != 0 ? parseFloat(form.transport) : 0;
    form.kokyn = form.kokyn != 0 ? parseFloat(form.kokyn) : 0;
    form.lainnya = form.lainnya != 0 ? parseFloat(form.lainnya) : 0;
    form.injil = form.injil != 0 ? parseInt(form.injil) : 0;
    const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
          
          axios.post('bo/ft/user/target/edit', form
          ).then(({data}) => {
            if (data.sta && data.stacod === 200) {
              outer.setState({
                disableBtnSubmit:false
              }, () => {
                outer.showDialog('Information',<IntlMessages id="setting.target.success.addtarget"/>);
                outer.getTarget();
              })
              
            } else {
              outer.setState({
                disableBtnSubmit:false
              });
              outer.showDialog('Warning',data.msg);
            }
          }).catch(function (error) {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',Errormsg["500"]);
          });
      });

  }

  

  isValidForm = () => {
    return (
    !this.state.error.fulltimerid &&     
    !this.state.error.fulltimertypeid )
  }

  

 

  render(){
    const { classes, theme } = this.props;
    const { error, fttypes, fulltimers } = this.state;
    const title = "Profile"
    return (
      <div style={{marginTop: theme.spacing(4)}}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit">
              Setting Target
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
              <form className={classes.form} noValidate>

                  
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="target"
                        name="target"
                        label={<IntlMessages id="setting.target.target"/>}
                        value={this.state.form.target}
                        size="small"
                        variant="outlined"
                        fullWidth
                        disabled
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="korporat"
                        name="korporat"
                        label={<IntlMessages id="setting.target.corporat"/>}
                        value={this.state.form.korporat}
                        onBlur={this.changeFocus}
                        size="small"
                        variant="outlined"
                        // type="number"
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="lewihall"
                        name="lewihall"
                        label={<IntlMessages id="setting.target.lewihall"/>}
                        value={this.state.form.lewihall}
                        onBlur={this.changeFocus}
                        size="small"
                        variant="outlined"
                        // type="number"
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="lewiks"
                        name="lewiks"
                        label={<IntlMessages id="setting.target.lewiks"/>}
                        value={this.state.form.lewiks}
                        onBlur={this.changeFocus}
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="transport"
                        name="transport"
                        label={<IntlMessages id="setting.target.transport"/>}
                        value={this.state.form.transport}
                        onBlur={this.changeFocus}
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <TextField
                        id="kolam"
                        name="kolam"
                        label={<IntlMessages id="setting.target.pool"/>}
                        value={this.state.form.kolam}
                        onBlur={this.changeFocus}
                        size="small"
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="pelayanan"
                        name="pelayanan"
                        label={<IntlMessages id="setting.target.service"/>}
                        value={this.state.form.pelayanan}
                        onBlur={this.changeFocus}
                        size="small"
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <TextField
                        id="lainnya"
                        name="lainnya"
                        label={<IntlMessages id="setting.target.etc"/>}
                        value={this.state.form.lainnya}
                        onBlur={this.changeFocus}
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="kokyn"
                        name="kokyn"
                        label={<IntlMessages id="setting.target.kokyn"/>}
                        value={this.state.form.kokyn}
                        size="small"
                        variant="outlined"
                        onBlur={this.changeFocus}
                        fullWidth
                        // disabled
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                        
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="timeremain"
                        name="timeremain"
                        label={<IntlMessages id="setting.target.timeremain"/>}
                        value={this.state.form.timeremain}
                        size="small"
                        variant="outlined"
                        fullWidth
                        disabled
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                        
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="konversi"
                        name="konversi"
                        label={<IntlMessages id="setting.target.conversion"/>}
                        value={this.state.form.conversion}
                        disabled
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="injil"
                        name="injil"
                        label={<IntlMessages id="setting.target.bible"/>}
                        value={this.state.form.injil}
                        size="small"
                        variant="outlined"
                        fullWidth
                        onBlur={this.changeFocus}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="domba"
                        name="domba"
                        label={<IntlMessages id="setting.target.sheep"/>}
                        value={this.state.form.domba}
                        size="small"
                        variant="outlined"
                        disabled
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <TextField
                        id="baptis"
                        name="baptis"
                        label={<IntlMessages id="setting.target.baptism"/>}
                        value={this.state.form.baptis}
                        size="small"
                        fullWidth
                        onBlur={this.changeFocus}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>                       */}
      
                  </Grid>
                  <Button
                    type="button"
                    className={classes.submit}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={this.handleValidation}
                    disabled={this.state.disableBtnSubmit}
                  >
                    UPDATE
                  </Button>
                </form>
          </div>
        </Container>

        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );
  }
}

DbFulltimerTargetEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbFulltimerTargetEdit)));