export function compareSequence(a, b) {
    const objA = a.seq;
    const objB = b.seq;
  
    let comparison = 0;
    if (objA > objB) {
      comparison = 1;
    } else if (objA < objB) {
      comparison = -1;
    }
    return comparison;
}
  