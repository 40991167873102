import React from 'react';
import {
  // BrowserRouter as Router,Route,Switch,Redirect
  Router, Route, Switch, Redirect
} from "react-router-dom";
import Container from '@material-ui/core/Container';
import Login from './login';
import Logout from './logout';
import ForgotPassword from './forgotpassword';
import ForgotPasswordConfirm from './forgotpasswordconfirm';
import NoMatch from '../component/nomatch';
import DashAdmin from './dashadmin';
import InvalidBrowser from '../component/invalidbrowser';
import BrowserDetection from 'react-browser-detection';
import { IntlProvider } from "react-intl";
import AppLocale from "../lngProvider";
import MomentUtils from '@date-io/moment'; // choose your lib
import {
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { connect } from "react-redux";
import PrivateRoute from '../component/privateroute';
import axios from '../config/axios';
import { createBrowserHistory } from "history";
import ReactGA from 'react-ga';

// ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const history = createBrowserHistory();
history.listen(location => {
  // console.log(location);
  if (location.pathname != '/') {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }
});

class App extends React.Component {

  componentDidMount() {
    if (window.location.pathname != '/') {
      ReactGA.pageview(window.location.pathname);
    }
    // let dataprops = this.props.token;
    // axios.defaults.headers.common['Authorization'] = "Bearer " + dataprops.token.tkn;
  }

  render() {
    // const {token, location, initURL} = this.props;
    // if (location.pathname === '/') {
    //     if (token === null) {
    //       return ( <Redirect to={'/'}/> );
    //     } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
    //       return ( <Redirect to={'/dashadmin'}/> );
    //     } else {
    //       return ( <Redirect to={initURL}/> );
    //     }
    // }

    const currentAppLocale = AppLocale['id'];
    const MainApp = (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Container>
            <Router history={history}>
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/forgotpassword" component={ForgotPassword} />
                <Route exact path="/forgotpasswordconfirm" component={ForgotPasswordConfirm} />
                {/* <Route exact path="/callback" render={() => (<Redirect to="/bo/callback" />)} /> */}
                <Route
                  path="/callback"
                  component={({ location }) => {
                    let type = sessionStorage.getItem("googleconnect");
                    if (type == 'drive') {
                      return <Redirect
                        to={{
                          ...location,
                          pathname: location.pathname.replace(/callback/, 'bo/setting/driveconn'),
                        }}
                      />
                    } else {
                      return <Redirect
                        to={{
                          ...location,
                          pathname: location.pathname.replace(/callback/, 'bo/export/daily'),
                        }}
                      />
                    }
                  }}
                />
                <Route path="/logout" component={Logout} />
                <PrivateRoute path="/bo" component={DashAdmin} />
                <Route path="*" component={NoMatch} />
              </Switch>
            </Router>
          </Container>
        </MuiPickersUtilsProvider>
      </IntlProvider>
    )

    const browserHandler = {
      chrome: () => MainApp,
      firefox: () => MainApp,
      safari: () => MainApp,
      default: (browser) => <InvalidBrowser />,
    };

    return (
      <BrowserDetection>
        {browserHandler}
      </BrowserDetection>
    );
  }
}

const mapStatestoProps = state => {
  return {
    token: state.token
  };
};

export default connect(mapStatestoProps)(App);
