import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {IconButton, TextField, Checkbox, Card, FormControl,
  CardMedia ,CardContent, Typography, Select, InputLabel, MenuItem, Collapse} from '@material-ui/core';
import {MoodBad} from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import {compareSequence} from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import IntlMessages from "../../../../../util/IntlMessages";
import './../../../../App.css';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DataTable from 'react-data-table-component';
import moment from 'moment';
//import actions
import { addParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};



const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

const tableStyle = {
  table: {
    borderCollapse: "collapse",
    width: 275
  },
  body: {
    border: 1,
    borderStyle: "solid",
    borderColor: "#dddddd",
    textAlign: 'center',
    padding: 8
  }
}

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions:[10,25,100],
  print:false,
  download:false,
  selectableRows:'single',
  isRowSelectable:function(){return false}
};

class DbReportFullTask extends React.Component {
  constructor(props){
      // console.log("window inner width", window.innerWidth)
      super(props);
      let dat = new Date();
      dat.setDate(dat.getDate() - 7);
      this.state = {
        setdate : "",
        page:{
          start: moment().subtract(7, "day").format('YYYY-MM-DD'),
          startPar: dat,
          end: moment().format('YYYY-MM-DD'),
          endPar: new Date()
        },
        taskList:[],
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        dialogMode:'',
        detailOpen:false,
        window: 0
      }
  }

  columns = [
   
    {
      name: <IntlMessages id="report.task.username"/>,
      selector: 'username',
      sortable: true,
    },
    {
      name: <IntlMessages id="report.task.usercity"/>,
      selector: 'usercity',
      sortable: true,
    }
  ];
  columnsExpand = [
   
    {
      name: <IntlMessages id="report.task.date"/>,
      selector: 'date'
    },
    {
      name: "Total",
      selector: 'total'
    },
    {
      name: "Group",
      selector: 'group'
    }
  ];

  componentDidMount(){
    this.getTaskReport();
  }

  showDialog = (title,content,mode) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content,
      dialogMode:mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen:false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen:false
    })
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir, silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");
    } else {
      this.setState({dialogOpen:false});
    }
  }


  getTaskReport = () => {
    let outer = this;
    axios.post('bo/int/report/task', outer.state.page).then(({data})=>{
      if(data.sta && data.stacod === 200){
        this.setState({
          taskList: data.data
        })
      }else{
        outer.showDialog('Warning', data.msg, "");
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  

  changeStart = (date) => {
    
    let momentParse = moment(date).format("YYYY-MM-DD");
    this.setState({
      page: {
        ...this.state.page,
        startPar: date,
        start: momentParse,
      }
    }, () => {
      this.getTaskReport();
    })
  }

  changeEnd = (date) => {
    let momentParse = moment(date).format("YYYY-MM-DD");
    this.setState({
      page: {
        ...this.state.page,
        endPar: date,
        end: momentParse,
      }
    }, () => {
      this.getTaskReport();
    })
  }

  handleChangeSet = (val) => {
    let outer = this;
    this.setState({
      setdate: val.target.value
    }, () => {
      let dat = new Date();
      // dat.setDate(dat.getDate() - 7);
      if(outer.state.setdate == "1minggu") {
        dat.setDate(dat.getDate() - 7);
        outer.setState({
          page: {
            start: moment().subtract(7, "day").format('YYYY-MM-DD'),
            startPar: dat,
            end: moment().format('YYYY-MM-DD'),
            endPar: new Date()
          }
        }, () => {
          outer.getTaskReport();
        });
      }
      if(outer.state.setdate == "2minggu") {
        dat.setDate(dat.getDate() - 14);
        outer.setState({
          page: {
            start: moment().subtract(14, "day").format('YYYY-MM-DD'),
            startPar: dat,
            end: moment().format('YYYY-MM-DD'),
            endPar: new Date()
          }
        }, () => {
          outer.getTaskReport();
        });
      }
      if(outer.state.setdate == "bulan") {
        dat.setDate(dat.getDate() - 30);
        outer.setState({
          page: {
            start: moment().subtract(30, "day").format('YYYY-MM-DD'),
            startPar: dat,
            end: moment().format('YYYY-MM-DD'),
            endPar: new Date()
          }
        }, () => {
          outer.getTaskReport();
        });
      }
    })
  }

  openDate() {
    this.setState({
      taskList: []
    })
  }

  closeDate() {
    this.getTaskReport()
  }

   
  render() {
    const { theme } = this.props;
    const { taskList} = this.state;
    
      
    return (
      <div style={{marginTop: theme.spacing(4), height: '100%'}}>
        <div style={{paddingTop:10}}>
          <Typography component="h5" variant="h5">
            Laporan Tugas
          </Typography>
        </div>
        <div className="filter">
          <div>
            <FormControl
              style={{
                minWidth: '100%',
                marginBottom: 10
              }}
            >

              <InputLabel id="set" style={{backgroundColor:"#fff", paddingLeft: 4, paddingRight: 4}} shrink variant="outlined">
                Jangka Waktu Tanggal
              </InputLabel>
              <Select
                value={this.state.setdate}
                onChange={this.handleChangeSet}
                variant="outlined"
                label="Jangka Waktu Tanggal"
                labelId="set"
                style={{width: '100%'}}
              >
                <MenuItem value="1minggu">1 Minggu</MenuItem>
                <MenuItem value="2minggu">2 Minggu</MenuItem>
                <MenuItem value="bulan">1 Bulan</MenuItem>

              </Select>
            </FormControl>
          </div>
          
          <div style={{ display: 'flex', alignItems: 'center' }}>
            
            
            <KeyboardDatePicker
              clearable
              label="Mulai"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.page.startPar}
              // placeholder="Start"
              onOpen={() => this.openDate()}
              onClose={() => this.closeDate()}
              onChange={date => this.changeStart(date)}
              format="DD-MM-YYYY"
              style={{paddingRight: 5}}
            />
            <KeyboardDatePicker
              clearable
              label="Akhir"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.page.endPar}
              // placeholder="End"
              onOpen={() => this.openDate()}
              onClose={() => this.closeDate()}
              onChange={date => this.changeEnd(date)}
              format="DD-MM-YYYY"
            />
          </div>
        </div>
        <br/>
        <div style={{resize: 'both'}}>

          {
            taskList.length > 0 &&
            <table style={tableStyle.table}>
              <tr>
                <th
                  style={tableStyle.body}
                  rowSpan={2}
                >Kota</th>
                <th
                  style={tableStyle.body} 
                  rowSpan={2}>Username</th>
                {
                  taskList[0].task.map((tas, i) => {
                    return (
                      <th 
                        style={tableStyle.body}
                        key={i} 
                        colSpan={2} >{moment(tas.date).format("DD-MM-YYYY")}</th>
                    )
                  })
                  
                }
              </tr>
              <tr>
              {
                taskList[0].task.map((gr, j) => {
                  return (
                    <>
                    <td 
                      style={tableStyle.body}
                    >Total</td>
                    <td
                      style={tableStyle.body}
                    >Kelompok</td>
                    </>
                  
                  )
                })
              }
              </tr>
              {
                taskList.map((item, k) => {
                  return (
                    <tr key={k}>
                      <td
                        style={tableStyle.body}
                      >{item.usercity}</td>
                      <td
                        style={tableStyle.body}
                      >{item.username}</td>
                      {
                        item.task.map((ta, l) => {
                          return (
                            <>
                            <td
                              style={tableStyle.body}
                            >{ta.total}</td>
                            <td
                              style={tableStyle.body}
                            >{ta.group}</td>
                            </>
                          )
                        })
                      }
                    </tr>

                  )
                })
              }
            </table>
          }
        </div>

          <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
          />  
         
      </div>
    );
  }
}

DbReportFullTask.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbReportFullTask)));